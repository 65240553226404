import axios from "axios";

const callApi = (url, config) => axios(url, config);

export const getCollection = ({
  url,
  collection_id,
  data,
  count_only = false,
  strict = false,
  addition = {},
}) => {
  const params = {
    collection_id,
    count_only,
    strict,
    data,
    ...addition,
  };
  const headers = {
    "X-Api-Key": `${process.env.REACT_APP_TEAM_KEY}`,
    "Content-Type": `application/json`,
  };

  return callApi(url, {
    params,
    headers,
    method: "get",
  });
};

export const putCollection = async ({
  url,
  collection_id,
  data,
  count = null,
  strict = false,
  checkTokenExpiredYesUpdate,
}) => {
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const params = { collection_id, strict, data, count };
  const headers = {
    Authorization: `Bearer ${TOKEN_KEY}`,
    "X-Api-Key": `${process.env.REACT_APP_TEAM_KEY}`,
    "Content-Type": `application/json`,
  };

  return callApi(url, {
    data: { ...params },
    headers,
    method: "put",
  });
};

export const patchCollection = async ({
  url,
  collection_id,
  data,
  count = null,
  strict = false,
  checkTokenExpiredYesUpdate,
}) => {
  let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

  if (refreshed) {
    localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

  const params = { collection_id, strict, data, count };
  const headers = {
    Authorization: `Bearer ${TOKEN_KEY}`,
    "X-Api-Key": `${process.env.REACT_APP_TEAM_KEY}`,
    "Content-Type": `application/json`,
  };

  return callApi(url, {
    data: { ...params },
    headers,
    method: "patch",
  });
};

export default {
  getCollection,
  putCollection,
  patchCollection,
};
