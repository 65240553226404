import React, { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(407px, 1fr));
    gap: 15px;
    padding: 0 30px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
`;

const TableTitle = styled.div`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin-left: 15px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
    padding-top: 20px;
  }
`;

const Website = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  row-gap: 15px;

  > div {
    :nth-child(1) {
      display: flex;
    }

    :nth-child(2) {
      h3 {
        margin-top: 0;
        font-size: 16px;
        font-weight: 500;
      }

      span {
        font-size: 14px;
        color: rgb(119, 119, 119);
      }
    }
  }

  img {
    object-fit: contain;
    border-radius: 5px;
    width: 100%;
  }

  @media (min-width: 701px) {
    padding: 20px 15px;
    border-radius: 5px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }

  @media (hover: hover) {
    cursor: pointer;
  }
`;

const Websites = ({ getWebsites, setWebsites, websites, initialWebsites }) => {
  useEffect(() => {
    getWebsites();

    return () => setWebsites(initialWebsites);
  }, []);

  return (
    <Container>
      <TableTitle>Сайты</TableTitle>
      {websites.loaded &&
        websites.data.map((website) => (
          <Website key={website.id} href={website.url} target="_blank">
            <div>
              <img
                src={`${process.env.REACT_APP_URL}/images/${website.image}`}
                alt="website image"
              />
            </div>
            <div>
              <h3>{website.name}</h3>
              <span>{website.description}</span>
            </div>
          </Website>
        ))}
    </Container>
  );
};
export default Websites;
