export default () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <g clipPath="url(#clip0)">
          <rect
            x="8"
            y="6"
            width="32"
            height="32"
            rx="10"
            fill="url(#paint0_linear)"
          />
          <path
            d="M30.113 11.66c-1.152-.019-1.994-.298-2.892-.596a39.071 39.071 0 0 0-.568-.186c-2.483-.783-5.557-.739-8.372.58a11.508 11.508 0 0 0-5.303 5.067c-.202.389-.397.795-.591 1.2-.525 1.095-1.048 2.186-1.729 2.942-3.25 3.33-4.727 6.78-3.674 8.965 2.269 4.713 34.983-10.61 32.714-15.324-.89-1.845-4.709-2.975-9.585-2.647z"
            fill="#012B5B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.055 29.482c9.072-4.249 14.485-11.722 13.013-14.778-1.471-3.056-10.313-2.51-19.384 1.74-9.07 4.25-14.935 10.59-13.464 13.647 1.472 3.056 10.765 3.64 19.835-.609z"
            fill="#1884FF"
          />
          <g filter="url(#filter1_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.832 28.116c4.29-2.01 7.204-4.81 6.508-6.257-.696-1.445-4.738-.988-9.029 1.022-4.29 2.01-7.204 4.81-6.508 6.256.696 1.445 4.738.989 9.029-1.021z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix values="0 0 0 0 0.313726 0 0 0 0 0.443137 0 0 0 0 0.964706 0 0 0 0.35 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="10.025"
          y="13.34"
          width="31.094"
          height="24.317"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3.838" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="21.5"
          y1="17.5"
          x2="35"
          y2="37"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".021" stopColor="#0151AE" />
          <stop offset="1" stopColor="#036DE6" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            d="M8 22c0-5.6 0-8.4 1.09-10.54a10 10 0 0 1 4.37-4.37C15.6 6 18.4 6 24 6c5.6 0 8.4 0 10.54 1.09a10 10 0 0 1 4.37 4.37C40 13.6 40 16.4 40 22c0 5.6 0 8.4-1.09 10.54a10.001 10.001 0 0 1-4.37 4.37C32.4 38 29.6 38 24 38c-5.6 0-8.4 0-10.54-1.09a10 10 0 0 1-4.37-4.37C8 30.4 8 27.6 8 22z"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
