import * as types from "./actionTypes";

export const setConsultation = (payload) => ({
  type: types.CONSULTATION_SET,
  payload,
});

export const getConsultation = (id) => ({
  type: types.CONSULTATION_GET,
  id,
});

export const getConsultationSuccess = (payload) => ({
  type: types.CONSULTATION_GET_SUCCESS,
  payload,
});

export const resetConsultation = () => ({
  type: types.CONSULTATION_RESET,
});
