import * as types from "./actionTypes";

export const getOrganizations = (date_time) => ({
  type: types.ORGANIZATIONS_FETCH,
  date_time,
});

export const getOrganizationsSuccess = (payload) => ({
  type: types.ORGANIZATIONS_FETCH_SUCCESS,
  payload,
});

export const updateOrganizations = () => ({
  type: types.ORGANIZATIONS_UPDATE,
});

export const updateOrganizationsSuccess = (payload) => ({
  type: types.ORGANIZATIONS_UPDATE_SUCCESS,
  payload,
});

export const resetOrganizations = () => ({
  type: types.ORGANIZATIONS_RESET,
});
