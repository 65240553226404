import React from "react";
import Consultations from "../Consultations";
import Accounting from "../Accounting";
import Excursions from "../Excursions";
import Visuals from "../Visuals";

export default ({
  point_accounting,
  point_excursions,
  point_consultations,
  point_visuals,
  isToday,
}) => {
  return (
    <>
      {point_accounting && <Accounting isToday={isToday} />}
      {point_consultations && <Consultations isToday={isToday} />}
      {point_excursions && <Excursions isToday={isToday} />}
      {point_visuals && <Visuals isToday={isToday} />}
    </>
  );
};
