const construction = {
  x: 5,
  y: 4,
  image: "286",
  data: [
    {
      id: 1,
      data: [
        {
          id: 1,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 2,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 3,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 4,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
      ],
    },
    {
      id: 2,
      data: [
        {
          id: 1,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 2,
          is_done: false,
          is_empty: true,
          date_time: "",
          date_updated: "",
        },
        {
          id: 3,
          is_done: false,
          is_empty: true,
          date_time: "",
          date_updated: "",
        },
        {
          id: 4,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          id: 1,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 2,
          is_done: true,
          is_empty: true,
          date_time: "",
          date_updated: "",
        },
        {
          id: 3,
          is_done: true,
          is_empty: true,
          date_time: "",
          date_updated: "",
        },
        {
          id: 4,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
      ],
    },
    {
      id: 4,
      data: [
        {
          id: 1,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 2,
          is_done: true,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 3,
          is_done: true,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 4,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
      ],
    },
    {
      id: 5,
      data: [
        {
          id: 1,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 2,
          is_done: true,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 3,
          is_done: true,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
        {
          id: 4,
          is_done: false,
          is_empty: false,
          date_time: "",
          date_updated: "",
        },
      ],
    },
  ],
};

const assigned = [
  "Сидоров Сидоров Сидоров",
  "Матвеев Матвеев Матвеев",
  "Иванов Иванов Иванов",
  "Смирнов Смирнов Смирнов",
  "Горький Горький Горький",
];

const floors = ["1", "2", "3", "4"];
const shelving = ["123", "221", "331", "42", "522"];

const addFixed = () => {
  root.classList.add("root-fixed");
};

const delFixed = () => {
  root.classList.remove("root-fixed");
};

export { construction, assigned, shelving, floors, addFixed, delFixed };
