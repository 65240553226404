import * as types from "./actionTypes";

const initialState = {
  reader_visit: 0,
  book_lending: 0,
  book_return: 0,
  isAccountingUpdate: false,
  isAccountingGet: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ACCOUNTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAccountingGet: true,
      };

    case types.UPDATE_ACCOUNTING:
      return {
        ...state,
        isAccountingUpdate: false,
        isAccountingGet: false,
      };

    case types.UPDATE_ACCOUNTING_SUCCESS:
      return {
        ...state,
        isAccountingUpdate: action.payload,
      };

    case types.RESET_ACCOUNTING:
      return initialState;

    default:
      return state;
  }
};
