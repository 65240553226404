import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import Button from "../../nekrasovka-ui/Button";
import { connect } from "react-redux";
import styled from "styled-components";
import { resetVisual, setVisual } from "../../reducers/visual/actions";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import Calendar from "react-calendar";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";

const buttons = {
  location: ["Читальный зал", "Другой отдел", "Другая организация"],
  type: [
    "Книжные знаки",
    "Прикладная графика",
    "Спичечные этикетки (зарубежные)",
    "Спичечные этикетки (отечественные)",
    "Плакаты",
    "Открытки",
    "Фотографии",
    "Гравюры",
    "Репродукции",
    "Декоративная бумага",
    "Картотека В. И. Якубовича",
  ],
};

const Visual = ({
  visualData,
  setVisual,
  resetVisual,
  isUserCanEdit,
  onSave,
  onClose,
  visualColumns,
}) => {
  let date_time;
  const today = new Date();

  if (visualData.date_time) {
    date_time = new Date(visualData.date_time);
  } else date_time = today;

  const isDisabled = !isUserCanEdit;
  const { dispatch } = useContext(AlertContext);
  const [date, setDate] = useState(date_time);
  const [isCalendar, setIsCalendar] = useState(false);
  const [isType, setIsType] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(date_time, "dd.MM.yyyy", { locale })
  );

  useEffect(() => {
    return () => resetVisual();
  }, []);

  const handleVisual = (name, value) => {
    const temp = visualData;
    temp[name] = value;

    setVisual({ ...temp });
  };

  const handleCount = (value) => {
    const max = 100000;
    let count = Number(value);

    if (value === 0) count = "";
    if (value > max) {
      dispatch({
        type: "ALERT_ON",
        text: `Не больше ${max}`,
        name: "warning",
      });

      count = Math.floor(value / 10);
    }

    handleVisual("count", count);
  };

  const handleLocation = (name, value) => {
    const isActive = buttons[name][value] === visualData[name];
    const selectedBtn = isActive ? "" : buttons[name][value];

    handleVisual("location", selectedBtn);
  };

  const checkButtonActive = (name) => buttons[name].indexOf(visualData[name]);

  const handleType = (name, value) => {
    const isActive = buttons[name][value] === visualData[name];
    const selectedBtn = isActive ? "" : buttons[name][value];

    handleVisual("type", selectedBtn);
  };

  const handleDate = (d) => {
    const sDate = format(d, "dd.MM.yyyy", { locale });
    const fDate = format(d, "yyyy-MM-dd HH:mm:ss", { locale });

    handleVisual("date_time", fDate);

    setDate(d);
    setIsCalendar(false);
    setSelectedDate(sDate);
  };

  const checkVisual = () => {
    const { location, reader, type, count } = visualData;
    const isSourceNotEmpty = !!location && !!reader && !!type && !!count;

    if (!visualData.date_time) {
      const fDate = format(today, "yyyy-MM-dd HH:mm:ss", { locale });

      handleVisual("date_time", fDate);
    }

    if (isSourceNotEmpty) {
      onSave();
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Вы не заполнили обязательные поля.",
        name: "warning",
      });
    }
  };

  return (
    <VisualContainer>
      <VisualAction>
        <div>
          {visualColumns.date_time} *<span>(обязательное поле)</span>
        </div>
        <VisualCalendarContainer
          isDisabled={isDisabled}
          onClick={() => isUserCanEdit && setIsCalendar(!isCalendar)}
        >
          {selectedDate}
          <ContentModalDateTime
            isContentModal={isCalendar}
            setIsContentModal={setIsCalendar}
          >
            <VisualCalendar>
              <Calendar
                selectRange={false}
                onChange={handleDate}
                value={date}
                maxDate={today}
                locale="ru-RU"
              />
            </VisualCalendar>
          </ContentModalDateTime>
          <i className="fa fa-calendar" aria-hidden="true" />
        </VisualCalendarContainer>
      </VisualAction>
      <VisualAction>
        <div>
          {visualColumns.location} *<span>(обязательное поле)</span>
        </div>
        <VisualLocationButtons>
          <ButtonsGroup
            isDisabled={isDisabled}
            groupName="location"
            handleButton={handleLocation}
            activeButton={checkButtonActive("location")}
            buttons={buttons["location"]}
          />
        </VisualLocationButtons>
        <VisualActionReaderInput>
          Другое
          <input
            disabled={isDisabled}
            value={
              buttons.location.indexOf(visualData.location) >= 0
                ? ""
                : visualData.location || ""
            }
            onChange={({ target: { value } }) =>
              handleVisual("location", value)
            }
          />
        </VisualActionReaderInput>
      </VisualAction>
      <VisualActionReader>
        <div>
          {visualColumns.reader} *<span>(обязательное поле)</span>
          <br />
          <span>
            номер читательского билета, название отдела, название организации
          </span>
        </div>
        <input
          disabled={isDisabled}
          value={visualData.reader || ""}
          onChange={({ target: { value } }) => handleVisual("reader", value)}
        />
      </VisualActionReader>
      <VisualAction>
        <div>
          {visualColumns.type} *<span>(обязательное поле)</span>
        </div>
        <VisualTypeContainer
          isDisabled={isDisabled}
          onClick={() => isUserCanEdit && setIsType(!isType)}
        >
          <input
            type="text"
            value={visualData.type || ""}
            disabled={isDisabled}
          />
          <ContentModalType
            isContentModal={isType}
            setIsContentModal={setIsType}
          >
            <VisualTypeButtons
              groupName="type"
              handleButton={handleType}
              activeButton={checkButtonActive("type")}
              buttons={buttons["type"]}
            />
          </ContentModalType>
        </VisualTypeContainer>
      </VisualAction>
      <VisualActionCount>
        <div>
          {visualColumns.count} *<span>(обязательное поле)</span>
        </div>
        <input
          disabled={isDisabled}
          type="number"
          value={visualData.count || ""}
          onChange={({ target: { value } }) => handleCount(value)}
        />
      </VisualActionCount>
      <VisualAction>
        <div>{visualColumns.comment}</div>
        <textarea
          disabled={isDisabled}
          value={visualData.comment || ""}
          rows="5"
          onChange={({ target: { value } }) => handleVisual("comment", value)}
        />
      </VisualAction>
      {isUserCanEdit && (
        <VisualSubmit>
          <Button
            onClick={checkVisual}
            name="Сохранить"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
          <Button
            onClick={onClose}
            name="Отмена"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
        </VisualSubmit>
      )}
    </VisualContainer>
  );
};

const mapStateToProps = ({ visual: { visualData } }) => ({
  visualData,
});

export default connect(mapStateToProps, {
  setVisual,
  resetVisual,
})(Visual);

const ContentModalDateTime = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div::after {
    right: 0;
    left: 15px;
  }
`;

const ContentModalType = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div {
    padding: 10px;

    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VisualContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &,
  textarea {
    font-size: 14px;
  }

  @media (min-width: 769px) {
    border: 1px solid #c4c4c4;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 15px 15px;
    border: none;
  }
`;

const VisualAction = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    font-weight: 500;

    > span {
      opacity: 0.5;
      font-size: 80%;
      margin-left: 5px;
      font-weight: normal;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 2px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  textarea[disabled] {
    resize: none;
  }

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

const VisualActionCount = styled(VisualAction)`
  > input {
    width: 100px;
  }
`;

const VisualActionReader = styled(VisualAction)`
  > :first-child {
    > :nth-child(3) {
      margin-left: 0;
    }
  }
`;

const VisualLocationButtons = styled.div`
  button {
    border: none;
    border-radius: 0;
  }

  @media (min-width: 931px) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;
  }

  @media (max-width: 930px) {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    button {
      width: 100%;
    }
  }

  @media (min-width: 769px) {
    button {
      padding: 4px 10px;
    }
  }
`;

const VisualTypeButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const VisualActionReaderInput = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const VisualSubmit = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const VisualCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  padding: 10px;
  border-radius: 2px;
  border: 1px solid #c4c4c4;

  cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
  font-size: 14px;

  .fa {
    margin-left: 10px;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const VisualCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: calc(100vw - 30px);
    }
  }
`;
