import React from "react";
import Icon from "../../../../nekrasovka-ui/Icon";
import styled from "styled-components";
import ContentModal from "../../../../nekrasovka-ui/ContentModal";
import ButtonsGroup from "../../../../nekrasovka-ui/ButtonsGroup";

const TableBooksNotFound = ({
  head,
  data,
  handlePreviousClick,
  handleNextClick,
  currentPage,
  totalPages,
  handleComment,
  verification,
  verificationOpen,
  setVerificationOpen,
  handleVerification,
  handleVerificationClose,
  hiddenHeaders,
  isOUBF,
}) => {
  return (
    <Table id="books_not_found_table">
      <div>
        <table>
          <thead>
            <TrWithComment>
              {head
                .filter((item) => hiddenHeaders.includes(item))
                .map((item, headIndex) => {
                  let name = "";

                  if (item === "Статус поиска книги") {
                    name = "(нажмите на ячейку, чтобы выбрать статус)";
                  }

                  if (item === "Комментарии") {
                    name = "(нажмите на ячейку, чтобы ввести комментарий)";
                  }

                  return (
                    <th key={headIndex}>
                      {item}
                      {!!name && (
                        <>
                          <br />
                          <span>{name}</span>
                        </>
                      )}
                    </th>
                  );
                })}
            </TrWithComment>
          </thead>
          <tbody>
            {data.map((dataItem, dataIndex) => {
              const titleAndAuthor = `${dataItem.title}${dataItem.author ? ", " + dataItem.author : ""}`;
              const locationHICM = Array.from(
                Object.entries(dataItem.location)
                  .filter(([_, value]) => value !== null)
                  .map(([_, value]) => value),
              ).join(", ");

              return (
                <tr key={dataIndex}>
                  {hiddenHeaders.includes("Дата") && (
                    <td>{dataItem.created_at}</td>
                  )}
                  {hiddenHeaders.includes("Баркод или инвентарный номер") && (
                    <td>{dataItem.barcode}</td>
                  )}
                  {hiddenHeaders.includes("Автор и название") && (
                    <td>{titleAndAuthor}</td>
                  )}
                  {hiddenHeaders.includes("Местонахождение") && (
                    <td>{locationHICM}</td>
                  )}
                  {hiddenHeaders.includes(
                    "Email сотрудника ОБИО, который вёл поиск книги",
                  ) && <td>{dataItem.submitter}</td>}
                  {hiddenHeaders.includes("Статус поиска книги") && (
                    <td>
                      <VisualAction>
                        <VisualTypeContainer isDisabled={!isOUBF}>
                          <input
                            disabled={!isOUBF}
                            readOnly
                            type="text"
                            value={verification[dataItem.status - 1]}
                            onClick={() => setVerificationOpen(dataIndex)}
                          />
                          <ContentModalType
                            isContentModal={verificationOpen === dataIndex}
                            setIsContentModal={handleVerificationClose}
                          >
                            <VisualTypeButtons
                              groupName={dataIndex}
                              activeButton={`${dataItem.status - 1}`}
                              buttons={verification}
                              handleButton={handleVerification}
                            />
                          </ContentModalType>
                        </VisualTypeContainer>
                      </VisualAction>
                    </td>
                  )}
                  {hiddenHeaders.includes(
                    "Email сотрудника ОБФ, который обновил статус поиска книги",
                  ) && <td>{dataItem.finder}</td>}
                  {hiddenHeaders.includes("Комментарии") && (
                    <td>
                      <TableTdInput
                        type="text"
                        value={dataItem.comment}
                        onChange={({ target }) => {
                          handleComment(dataIndex, target.value);
                        }}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!!data.length ? (
        <TablePagination>
          <TablePaginationIconButton
            onClick={handlePreviousClick}
            isDisabled={currentPage === 1}
          >
            <Icon icon="arrowLineLeft" height={30} />
          </TablePaginationIconButton>
          <div>
            <span>Страница</span>
            <span>{currentPage}</span>
          </div>
          <TablePaginationIconButton
            onClick={handleNextClick}
            isDisabled={currentPage === totalPages}
          >
            <Icon icon="arrowLineRight" height={30} />
          </TablePaginationIconButton>
        </TablePagination>
      ) : (
        <NoData>Нет данных</NoData>
      )}
    </Table>
  );
};

export default TableBooksNotFound;

const NoData = styled.div`
  padding-top: 15px;
  font-size: 14px;
`;

const TablePaginationIconButton = styled.div`
  ${({ isDisabled }) => isDisabled && "visibility: hidden"};
`;

const TableTdInput = styled.input`
  border: none;
  outline: none;
  background-color: initial;
`;

const TablePagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  > div {
    display: flex;
    align-items: center;
    column-gap: 10px;

    > span {
      :nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 0 10px;
      }
    }
  }

  @media (hover: hover) {
    svg {
      cursor: pointer;
    }
  }
`;

const Table = styled.div`
  :nth-child(1),
  :nth-child(2) {
    > div:nth-child(2) {
      overflow-x: scroll;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  :nth-child(1) {
    tbody tr {
      cursor: pointer;
    }

    th:nth-child(n + 2) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: center;
    }
  }

  :nth-child(2) {
    tbody tr {
      cursor: pointer;
    }

    th:nth-child(n + 3) {
      text-align: left;
    }

    td:nth-child(-n + 2) {
      text-align: center;
    }
  }

  table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 10px;
    }

    tbody {
      tr {
        :not(:last-child) {
          border-bottom: 1px solid #d8d8d8;
        }
      }
    }

    thead {
      tr {
        th {
          font-weight: 500;
          background: #d8d8d8;
          text-align: left;
          position: sticky;
          top: 0;
          z-index: 1;

          :first-child {
            border-top-left-radius: 5px;
          }

          :last-child {
            border-top-right-radius: 5px;
          }
        }
      }
    }
  }
`;

const VisualAction = styled.div`
  input {
    padding: 10px;
    outline-style: none;
    border: none;
    margin: 0;
    background-color: initial;
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const ContentModalType = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div {
    padding: 0;

    > div {
      border: none;
    }

    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VisualTypeButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const TrWithComment = styled.tr`
  span {
    font-size: 11px;
  }
`;
