import * as types from "./actionTypes";

export const getConsultations = (date_time) => ({
  type: types.CONSULTATIONS_FETCH,
  date_time,
});

export const getConsultationsSuccess = (payload) => ({
  type: types.CONSULTATIONS_FETCH_SUCCESS,
  payload,
});

export const getConsultationsTypicalResponses = (date_time) => ({
  type: types.CONSULTATIONS_TYPICAL_RESPONSES_FETCH,
  date_time,
});

export const getConsultationsTypicalResponsesSuccess = (payload) => ({
  type: types.CONSULTATIONS_TYPICAL_RESPONSES_FETCH_SUCCESS,
  payload,
});

export const updateConsultations = () => ({
  type: types.CONSULTATIONS_UPDATE,
});

export const updateConsultationsSuccess = (payload) => ({
  type: types.CONSULTATIONS_UPDATE_SUCCESS,
  payload,
});

export const resetConsultations = () => ({
  type: types.CONSULTATIONS_RESET,
});
