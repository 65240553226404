import React from "react";

export default (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-1158 -716)">
        <path
          transform="translate(1158 716)"
          id="path0_fill"
          d="M 7.5 7.5L 0 7.5L 0 0L 7.5 0L 7.5 7.5ZM 18.75 0L 11.25 0L 11.25 7.5L 18.75 7.5L 18.75 0ZM 30 0L 22.5 0L 22.5 7.5L 30 7.5L 30 0ZM 7.5 11.25L 0 11.25L 0 18.75L 7.5 18.75L 7.5 11.25ZM 18.75 11.25L 11.25 11.25L 11.25 18.75L 18.75 18.75L 18.75 11.25ZM 30 11.25L 22.5 11.25L 22.5 18.75L 30 18.75L 30 11.25ZM 7.5 22.5L 0 22.5L 0 30L 7.5 30L 7.5 22.5ZM 18.75 22.5L 11.25 22.5L 11.25 30L 18.75 30L 18.75 22.5ZM 30 22.5L 22.5 22.5L 22.5 30L 30 30L 30 22.5Z"
        />
      </g>
    </svg>
  );
};
