import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

const NewsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background-color: white;
  cursor: pointer;

  @media (min-width: 701px) {
    border-radius: 5px;
    padding: 20px 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const NewsCardHeader = styled.div`
  display: flex;
  column-gap: 8px;

  > div {
    :nth-child(1) {
      display: flex;
      align-items: center;

      img {
        height: 32px;
        width: 32px;
        border-radius: 5px;
      }
    }

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      span {
        :nth-child(1) {
          font-size: 14px;
        }

        :nth-child(2) {
          font-size: 11px;
          color: rgb(119, 119, 119);
        }
      }
    }
  }
`;

const NewsCardBody = styled.div`
  margin-top: 20px;
  word-break: break-word;

  .date-past,
  .date-past ~ a {
    display: none;
  }

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  > div {
    span {
      color: rgb(119, 119, 119);
    }

    img {
      display: none;
    }
  }

  h3 {
    font-size: 16px;
    margin: 0 0 15px;
    font-weight: 500;
  }

  > div {
    * {
      font-size: 14px;
    }

    br {
      display: none;
    }
  }
`;

const NewsCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const NewsCardFooterLabels = styled.div`
  display: flex;

  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgb(240, 240, 240);
`;

const NewsCardFooterCommentsSize = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 14px;
  border-radius: 50%;
  padding: 5px 10px;
  background-color: rgb(240, 240, 240);
  height: 27px;
  width: 27px;
`;

const NewsCard = ({ card, handleNewsCard }) => {
  const isMetadata = !!card.metadata.labels.results.length;
  const isBody = !!card.body;

  return (
    <NewsCardContainer onClick={() => handleNewsCard(card.id)}>
      <NewsCardHeader>
        <div>
          <img
            src={`https://filin.mail.ru/pic?from=ph&email=${card.history.createdBy.email}&width=180&height=180`}
            alt=""
          />
        </div>
        <div>
          <span>{card.history.createdBy.displayName}</span>
          <span>
            {format(new Date(card.history.createdDate), "dd.MM.yyyy", {
              locale,
            })}
          </span>
        </div>
      </NewsCardHeader>
      <NewsCardBody>
        <h3>{card.title}</h3>
        {/*{isBody && (*/}
        {/*  <div dangerouslySetInnerHTML={{ __html: card.body.view.value }} />*/}
        {/*)}*/}
      </NewsCardBody>
      <NewsCardFooter>
        {isMetadata && (
          <NewsCardFooterLabels>
            {card.metadata.labels.results.map((meta, index) => (
              <div key={index}>{meta.name}</div>
            ))}
          </NewsCardFooterLabels>
        )}
        <NewsCardFooterCommentsSize>
          {card.children.comment.size}
        </NewsCardFooterCommentsSize>
      </NewsCardFooter>
    </NewsCardContainer>
  );
};

export default NewsCard;
