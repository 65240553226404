import * as types from "./actionTypes";

const initialState = {
  collection_id: "consultations_orgs",
  organizationsData: [],
  isOrganizationsGet: false,
  isOrganizationsUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        organizationsData: action.payload,
        isOrganizationsGet: true,
      };
    case types.ORGANIZATIONS_UPDATE:
      return {
        ...state,
        isOrganizationsGet: false,
        isOrganizationsUpdate: false,
      };

    case types.ORGANIZATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        isOrganizationsUpdate: action.payload,
      };
    case types.ORGANIZATIONS_RESET:
      return initialState;

    default:
      return state;
  }
};
