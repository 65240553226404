import React from "react";
import styled from "styled-components";
import MobileDepartment from "../MobileDepartment";

const MobileDepartmentsContainer = styled.div``;

const TableTitle = styled.div`
  ${({ isDepartmentId }) =>
    isDepartmentId
      ? `display: none;`
      : `display: flex;
  column-gap: 5px;
  font-weight: 500;
  line-height: 19px;
  padding: 20px 15px 10px;`}
`;

const MobileDepartments = ({ data, department_id, handleDepartment }) => {
  return (
    <MobileDepartmentsContainer>
      <TableTitle isDepartmentId={!!department_id}>
        <span>Отделы</span>
      </TableTitle>
      <div>
        {data.map((item) => {
          return (
            <MobileDepartment
              state={item}
              key={item.id}
              handleDepartment={handleDepartment}
            />
          );
        })}
      </div>
    </MobileDepartmentsContainer>
  );
};

export default MobileDepartments;
