import * as types from "./actionTypes";

export const setVisual = (payload) => ({
  type: types.VISUAL_SET,
  payload,
});

export const getVisual = (id) => ({
  type: types.VISUAL_GET,
  id,
});

export const getVisualSuccess = (payload) => ({
  type: types.VISUAL_GET_SUCCESS,
  payload,
});

export const resetVisual = () => ({
  type: types.VISUAL_RESET,
});
