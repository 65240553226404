import React, { useEffect, useState } from "react";
import SimpleTable from "my-simple-react-table";
import "my-simple-react-table/dist/style.css";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Statistics = () => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    axios
      .post(`${process.env.REACT_APP_API}/bookcases/statistics/get`)
      .then((response) => {
        setData(response.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Navigation>
        <div>
          <BookcaseButton as={Link} to="/">
            Интранет
          </BookcaseButton>
          <BookcaseButton as={Link} to="/backoffice/dedusting">
            Обеспыливание
          </BookcaseButton>
        </div>
      </Navigation>
      <TableContainer>
        <TableTitle>Статистика по сотруднику</TableTitle>
        <SimpleTable
          data={data.users}
          columns={[
            { title: "N", format: (row) => row._index + 1 },
            { title: "Имя и фамилия сотрудника", key: "name" },
            { title: "Количество запланированных полок", key: "count" },
            { title: "Количество обеспыленныx полок", key: "is_done" },
            { title: "Процент выполнения", key: "percent" },
          ]}
        />
      </TableContainer>
      <TableContainer>
        <TableTitle>Статистика по стеллажам и полкам</TableTitle>
        <SimpleTable
          searchBox={false}
          data={data.bookcases}
          columns={[
            {
              title: "Количество стеллажей в библиотеке",
              key: "count",
            },
            {
              title: "Количество полок в библиотеке",
              key: "shelves",
            },
            {
              title: "Количество обеспыленных полок на текущую дату",
              key: "is_done",
            },
            {
              title: "Среднее количество убираемых полок в день",
              key: "",
            },
            {
              title:
                "Прогнозная дата, когда все пыльные стеллажи и полки будут обеспылены",
              key: "",
            },
            {
              title:
                "Сколько стеллажей и полок планируется обеспылить в течение определенного периода",
              key: "",
            },
          ]}
        />
      </TableContainer>
    </Container>
  );
};

export default Statistics;

const TableTitle = styled.div`
  font-weight: bold;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &:nth-child(3) {
    .px-3.row {
      display: none;
    }
  }

  .table-responsive {
    padding-top: 0 !important;
  }

  .simple-react-table .table thead th {
    vertical-align: top;
  }

  .form-control {
    float: left !important;
    margin-bottom: 20px !important;
  }

  * {
    font-size: 16px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 10px;

    > span {
      font-size: 13px;
    }
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: initial;
  color: #222222;
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  :not(:disabled) {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 769px) {
    padding: 15px 30px;
  }
`;
