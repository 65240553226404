import * as types from "./actionTypes";

export const setOrganization = (payload) => ({
  type: types.ORGANIZATION_SET,
  payload,
});

export const getOrganization = (id) => ({
  type: types.ORGANIZATION_GET,
  id,
});

export const getOrganizationSuccess = (payload) => ({
  type: types.ORGANIZATION_GET_SUCCESS,
  payload,
});

export const resetOrganization = () => ({
  type: types.ORGANIZATION_RESET,
});
