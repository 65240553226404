import React, { useEffect, useState } from "react";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import Unavalable from "../Unavalable";
import { isJsonString } from "../../helpers";

export default ({
  consultationData,
  isUserCanEdit,
  onClose,
  consultationColumns,
  handleSetConsultation,
  checkButtonActive,
  checkConsultation,
  buttons,
  tooltips,
  isUnavailable,
  handleUnavailable,
  setUnavailable,
  setIsUnavailable,
  resetConsultation,
}) => {
  const [isContentJsonString, setIsContentJsonString] = useState(false);
  const isDisabled = !isUserCanEdit;

  useEffect(() => {
    const isConsultationContent = !!consultationData.content;

    if (isConsultationContent) {
      if (isJsonString(consultationData.content)) {
        const parsedContent = JSON.parse(consultationData.content);

        setIsContentJsonString(true);
        setIsUnavailable(true);
        setUnavailable(parsedContent);
      }
    }
  }, [consultationData.content]);

  useEffect(() => {
    return () => resetConsultation();
  }, []);

  return (
    <ConsultationContainer>
      <ConsultationAction>
        <div>
          {consultationColumns.source} *<span>(обязательное поле)</span>
        </div>
        <ConsultationButtons>
          <ButtonsGroup
            isDisabled={isDisabled}
            groupName={"source"}
            handleButton={handleSetConsultation}
            activeButton={checkButtonActive("source")}
            buttons={buttons.source}
            buttonStyles={
              "border: none; border-radius: 0; @media (max-width: 768px) {width: 100%;}; @media (min-width: 769px) {padding: 4px 10px;}"
            }
          />
        </ConsultationButtons>
      </ConsultationAction>
      <ConsultationAction>
        <ConsultationUnavailableButton>
          <div>{consultationColumns.content}</div>
          <Button
            onClick={handleUnavailable}
            isActive={isUnavailable}
            isDisabled={isContentJsonString}
            name="Учёт отказов"
            buttonStyles={`outline: none; @media (min-width: 769px) {padding: 4px 10px;}`}
          />
        </ConsultationUnavailableButton>
        {isUnavailable ? (
          <Unavalable isDisabled={isDisabled} />
        ) : (
          <textarea
            disabled={isDisabled}
            value={consultationData.content || ""}
            rows="1"
            onChange={(e) => handleSetConsultation("content", e)}
          />
        )}
      </ConsultationAction>
      <ConsultationAction>
        <div>{consultationColumns.category}</div>
        <ConsultationCategoryButtons>
          <div>{consultationColumns.references}</div>
          <ConsultationButtonsGroup
            isDisabled={isDisabled}
            groupName={"references"}
            handleButton={handleSetConsultation}
            activeButton={checkButtonActive("references")}
            buttons={buttons.references}
            tooltip={tooltips.references}
            buttonStyles={
              "border: none; border-radius: 0; @media (max-width: 930px) {width: 100%;}; @media (min-width: 769px) {padding: 4px 10px;}"
            }
          />
        </ConsultationCategoryButtons>
        <ConsultationCategoryButtons>
          <div>{consultationColumns.consultations}</div>
          <ConsultationButtonsGroup
            isDisabled={isDisabled}
            groupName={"consultations"}
            handleButton={handleSetConsultation}
            activeButton={checkButtonActive("consultations")}
            buttons={buttons.consultations}
            tooltip={tooltips.consultations}
            buttonStyles={
              "border: none; border-radius: 0; @media (max-width: 930px) {width: 100%;}; @media (min-width: 769px) {padding: 4px 10px;}"
            }
          />
        </ConsultationCategoryButtons>
      </ConsultationAction>
      <ConsultationAction>
        <div>{consultationColumns.comment}</div>
        <textarea
          disabled={isDisabled}
          value={consultationData.comment || ""}
          rows="1"
          onChange={(e) => handleSetConsultation("comment", e)}
        />
      </ConsultationAction>
      {isUserCanEdit && (
        <ConsultationSubmit>
          <Button
            onClick={checkConsultation}
            name="Сохранить"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
          <Button
            onClick={onClose}
            name="Отмена"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
        </ConsultationSubmit>
      )}
    </ConsultationContainer>
  );
};

const ConsultationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &,
  textarea {
    font-size: 14px;
    resize: none;
  }

  @media (min-width: 769px) {
    border: 1px solid #c4c4c4;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 15px 15px;
    border: none;
  }
`;

const ConsultationButtonsGroup = styled(ButtonsGroup)`
  @media (min-width: 931px) {
    width: fit-content;

    > :not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }

    > :last-child {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
    }

    > :first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }
  }

  @media (max-width: 930px) {
    width: 100%;
    flex-direction: column;

    > :not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
      border-right: none;
    }

    > :last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    > :first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
`;

const ConsultationAction = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    font-weight: 500;

    > span {
      opacity: 0.5;
      font-size: 80%;
      margin-left: 5px;
      font-weight: normal;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 2px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
  }

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

const ConsultationSubmit = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ConsultationUnavailableButton = styled.div`
  display: flex;
  align-items: center;

  > input {
    padding: 0 10px;
  }

  @media (min-width: 931px) {
    column-gap: 10px;
  }

  @media (max-width: 930px) {
    justify-content: space-between;
  }
`;

const ConsultationButtons = styled.div`
  @media (min-width: 931px) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;

    > input {
      padding: 0 10px;
    }
  }

  @media (max-width: 930px) {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }
`;

const ConsultationCategoryButtons = styled(ConsultationButtons)`
  @media (min-width: 931px) {
    grid-template-columns: 100px 1fr;
  }
`;
