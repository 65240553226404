import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import Icon from "../../../../nekrasovka-ui/Icon";

const IssueCardContainer = styled.a`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;

  @media (min-width: 701px) {
    border-radius: 5px;
    padding: 20px 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const IssueCardHeader = styled.div`
  display: flex;
  column-gap: 8px;

  > div {
    :nth-child(1) {
      display: flex;
      align-items: center;

      img {
        height: 32px;
        width: 32px;
        border-radius: 5px;
      }
    }

    :nth-child(2) {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      span {
        :nth-child(1) {
          font-size: 14px;
        }

        :nth-child(2) {
          font-size: 11px;
          color: rgb(119, 119, 119);
        }
      }
    }
  }
`;

const IssueCardBody = styled.div`
  margin-top: 20px;
  word-break: break-word;

  .date-past,
  .date-past ~ a {
    display: none;
  }

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;

  > div {
    span {
      color: rgb(119, 119, 119);
    }

    img {
      display: none;
    }
  }

  h3 {
    font-size: 14px;
    margin: 0 0 15px;
    font-weight: 500;
  }

  > div {
    * {
      font-size: 14px;
    }

    br {
      display: none;
    }
  }
`;

const IssueCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const IssueCardFooterLabels = styled.div`
  display: flex;

  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgb(240, 240, 240);
`;

const IssueCardFooterCommentsSize = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 14px;
  border-radius: 50%;
  background-color: rgb(240, 240, 240);
  height: 27px;
  width: 27px;
`;

const IssueIconsContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-left: auto;
`;

const IssueCard = ({ issue }) => {
  return (
    <IssueCardContainer
      href={`https://team.nekrasovka.ru/jira/browse/${issue.key}`}
      target="_blank"
    >
      <IssueCardHeader>
        <div>
          <img
            src={`https://filin.mail.ru/pic?from=ph&email=${issue.fields.creator.emailAddress}&width=180&height=180`}
            alt=""
          />
        </div>
        <div>
          <span>{issue.fields.creator.displayName}</span>
          <span>
            {format(new Date(issue.fields.created), "dd.MM.yyyy", {
              locale,
            })}
          </span>
        </div>
      </IssueCardHeader>
      <IssueCardBody>
        <h3>{issue.fields.summary}</h3>
        {/*{isBody && (*/}
        {/*  <div dangerouslySetInnerHTML={{ __html: card.body.view.value }} />*/}
        {/*)}*/}
      </IssueCardBody>
      <IssueCardFooter>
        {/*{isMetadata && (*/}
        {/*  <IssueCardFooterLabels>*/}
        {/*    {card.metadata.labels.results.map((meta, index) => (*/}
        {/*      <div key={index}>{meta.name}</div>*/}
        {/*    ))}*/}
        {/*  </IssueCardFooterLabels>*/}
        {/*)}*/}
        <IssueIconsContainer>
          <IssueCardFooterCommentsSize>
            <Icon
              icon={`jira${issue.fields.issuetype.id}`}
              width={16}
              height={16}
            />
          </IssueCardFooterCommentsSize>
          <IssueCardFooterCommentsSize>
            {/*{card.children.comment.size}*/}
          </IssueCardFooterCommentsSize>
        </IssueIconsContainer>
      </IssueCardFooter>
    </IssueCardContainer>
  );
};

export default IssueCard;
