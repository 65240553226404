import React, { Fragment } from "react";
import { librarianNews } from "../../database/librarianNews";
import styled from "styled-components";

export default () => {
  return (
    <div className="content container-fluid">
      <LibrarianDescription>
        <span>Приложение позволяет учитывать следующие данные:</span>
        <span>- справки и консультации (на всех точках), </span>
        <span>- посещения (для нестационарных точек), </span>
        <span>
          - книговыдачу фонда открытого доступа (для нестационарных точек),
        </span>
        <span>- возвраты книг.</span>
        <span>
          Приложение автоматически сохраняет все введенные данные на серверах
          библиотеки по мере их добавления. Приложение адаптировано для
          использования на мобильных устройствах.
        </span>
      </LibrarianDescription>
      <LibrarianDescription>
        <h4>Новости приложения</h4>
      </LibrarianDescription>
      <LibrarianNewsContainer>
        {Object.keys(librarianNews).map((lnByDate, ldByDateId) => (
          <Fragment key={ldByDateId}>
            <LibrarianDescription>
              <div>{lnByDate}</div>
              {librarianNews[lnByDate].map((item, itemId) => (
                <LibrarianNewsCard key={itemId}>
                  <span dangerouslySetInnerHTML={{ __html: item.title }} />
                  {!!item.image && <img src={item.image} alt="картинка" />}
                </LibrarianNewsCard>
              ))}
            </LibrarianDescription>
          </Fragment>
        ))}
      </LibrarianNewsContainer>
    </div>
  );
};

const LibrarianDescription = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  padding: 15px;
  background: #ffffff;
`;

const LibrarianNewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  line-height: 23px;
`;

const LibrarianNewsCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  :not(:first-child) {
    margin-top: 20px;
  }

  @media (min-width: 769px) {
    img {
      max-width: 50%;
    }
  }
`;
