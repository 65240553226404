import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import Modal from "../../nekrasovka-ui/Modal";
import Button from "../../nekrasovka-ui/Button";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";

const Message = ({ setIsMessage, name, user, today }) => {
  const initialForm = { telegram: "", message: "", photo: "" };
  const [form, setForm] = useState(initialForm);
  const { dispatch } = useContext(AlertContext);
  const imageRef = useRef(null);
  const filesRef = useRef(null);

  const setImage = (file) => {
    const img = imageRef.current;

    if (!!file) {
      const oFReader = new FileReader();
      oFReader.readAsDataURL(file);

      oFReader.onload = (oFREvent) => {
        img.src = oFREvent.target.result;
        img.style.visibility = "visible";
      };
    } else {
      img.src = "";
      img.removeAttribute("style");
    }
  };

  const handleFile = (e) => {
    const maxSize = 10000000;
    const { files, name } = e.target;
    const { size } = files[0];

    if (size <= maxSize) {
      setForm((state) => ({
        ...state,
        [name]: files[0],
      }));

      setImage(files[0]);
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Размер изображения не больше 10 мб.",
        name: "warning",
      });
    }
  };

  const handleFileDelete = () => {
    const file = filesRef.current;
    file.value = "";

    setImage();
    setForm({ ...form, photo: "" });
  };

  const handleForm = (e) => {
    const { name, value } = e.target;

    setForm((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const sendToTelegramBot = async () => {
    const { telegram, message, photo } = form;
    let href,
      method,
      addi = "";
    let formData = new FormData();
    formData.append("chat_id", process.env.REACT_APP_TELEGRAM_CHAT_ID);
    formData.append("parse_mode", "html");

    if (!!telegram) {
      const regexTelegram = telegram.replaceAll(/@/g, "");
      href = `https://t.me/${regexTelegram}`;
      addi = `<b>[доп] email: <a href="mailto:${user}?body=${message}">${user}</a></b>`;
    } else href = `mailto:${user}?body=${message}`;

    const text = `<b>[${today}] Сообщение:</b>\n<i>${message}</i>\n<b>[ответить] -> <a href="${href}">${name}</a></b>\n${addi}`;

    if (!!photo) {
      method = "sendPhoto";

      formData.append("photo", photo);
      formData.append("caption", text);
    } else {
      method = "sendMessage";

      formData.append("text", text);
    }

    const url = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/${method}`;

    let request = new XMLHttpRequest();
    request.open("POST", url);
    request.send(formData);
  };

  const onClose = () => {
    setForm(initialForm);
    setIsMessage(false);
  };

  const onSave = () => {
    sendToTelegramBot();
    onClose();
  };

  return (
    <Modal>
      <MessageContainer>
        <MessageForm>
          <div>
            <div>Телеграм</div>
            <input
              onChange={handleForm}
              name="telegram"
              placeholder="Ваш логин в телеграм (для быстрой связи)"
              value={form.telegram}
            />
          </div>
          <div>
            <div>Email</div>
            <input disabled name="email" placeholder={user} />
          </div>
          <div>
            <div>Сообщение</div>
            <textarea
              onChange={handleForm}
              name="message"
              rows="10"
              value={form.message}
            />
          </div>
          <div>
            <i className="fa fa-image"></i>
            <input
              ref={filesRef}
              onChange={handleFile}
              name="photo"
              type="file"
              accept="image/png, image/jpeg"
              data-photo={
                !!form.photo
                  ? form.photo.name
                  : "Прикрепить фото (необязательно)"
              }
            />
            <img ref={imageRef} src="" alt="предпросмотр" />
            {!!form.photo && (
              <i className="fa fa-ban" onClick={handleFileDelete}></i>
            )}
          </div>
          <div>
            <Button name="Отправить" onClick={onSave} />
            <Button name="Отменить" onClick={onClose} />
          </div>
        </MessageForm>
      </MessageContainer>
    </Modal>
  );
};

const MessageContainer = styled.div`
  display: flex;

  max-width: 420px;
  padding: 15px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    margin-top: 50%;
  }
`;

const MessageForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  input,
  textarea {
    border: 1px solid rgb(196, 196, 196);
    border-radius: 2px;
    outline: none;
    padding: 10px;
    font-size: 14px;
  }

  > div {
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    :nth-child(1) {
      width: 100%;
    }

    :nth-child(3) {
      textarea {
        resize: none;
      }
    }

    :nth-child(4) {
      display: flex;
      position: relative;

      .fa.fa-image,
      input::before {
        opacity: 0.6;
      }

      .fa.fa-image {
        position: absolute;
        left: 11px;
        top: 14px;
      }

      .fa.fa-ban {
        cursor: pointer;
        position: absolute;
        right: -6px;
        top: -6px;
        color: red;
      }

      img {
        visibility: hidden;
        position: absolute;
        height: 100%;
        right: 0;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
      }

      input {
        width: 100%;
        cursor: pointer;
        color: transparent;

        ::-webkit-file-upload-button {
          visibility: hidden;
        }

        ::before {
          content: attr(data-photo);
          color: #222222;
          margin-left: 25px;
        }
      }
    }

    :nth-child(5) {
      display: flex;
      column-gap: 10px;
    }
  }
`;

export default Message;
