import React, { useState } from "react";
import KeycloakProvider from "react-keycloak-nekrasovka";

import Header from "./Header";
import Main from "./Main";
import Router from "./Router";
import AlertProvider from "../nekrasovka-ui/Alert/AlertProvider";

export default () => {
  const [isNotLibrarian, setNotLibrarian] = useState(true);

  return (
    <Router>
      <KeycloakProvider>
        <AlertProvider>
          {isNotLibrarian && <Header />}
          <Main
            setNotLibrarian={setNotLibrarian}
            isNotLibrarian={isNotLibrarian}
          />
        </AlertProvider>
      </KeycloakProvider>
    </Router>
  );
};
