import React from "react";
import styled from "styled-components";

export default ({ href, isImage, handleImage }) => {
  return (
    <Container isImage={isImage} onClick={handleImage}>
      <img src={href} alt="стеллаж" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background-color: rgb(237, 238, 233);

  > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  ${({ isImage }) =>
    isImage &&
    `
    position: absolute;
    top: 15px;
    left: 15px;
    width: 100%;
    z-index: 11;
    width: calc(100% - 30px);
    max-width: 100%;
 `}
`;
