import React from "react";

export default (props) => {
  return (
    <svg
      width={props.width || "13"}
      height={props.height || "13"}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.44771 0 0 0.44772 0 1V12C0 12.5523 0.44772 13 1 13H8.5C8.7761 13 9 12.7761 9 12.5C9 12.2239 8.7761 12 8.5 12H1V1H8.5C8.7761 1 9 0.77614 9 0.5C9 0.22386 8.7761 0 8.5 0H1ZM10.6036 3.89645C10.4083 3.70118 10.0917 3.70118 9.8964 3.89645C9.7012 4.09171 9.7012 4.40829 9.8964 4.60355L11.2929 6H4.5C4.22386 6 4 6.22386 4 6.5C4 6.77614 4.22386 7 4.5 7H11.2929L9.8964 8.39645C9.7012 8.59171 9.7012 8.90829 9.8964 9.1036C10.0917 9.2988 10.4083 9.2988 10.6036 9.1036L12.8536 6.85355C13.0488 6.65829 13.0488 6.34171 12.8536 6.14645L10.6036 3.89645Z"
        fill={props.fill || "black"}
      />
    </svg>
  );
};
