import React from "react";
import { BrowserRouter, StaticRouter } from "react-router-dom";

export default ({ type, renderProps, children }) => {
  return type === "server" ? (
    <StaticRouter {...renderProps}>{children}</StaticRouter>
  ) : (
    <BrowserRouter>{children}</BrowserRouter>
  );
};
