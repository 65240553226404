import React from "react";

export default (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 410 1125 1700"
    >
      <g id="all_walls_doors">
        <g id="ext_walls">
          <path
            className="cls-6"
            d="M804.4,1996.8c-214.5,22.7-428.9,45.4-643.4,68.2-2.6-496.8-5.1-993.7-7.7-1490.6,216-28.1,432-56.2,648-84.3,1,502.3,2.1,1004.5,3.1,1506.7Z"
          />
          <rect
            className="cls-6"
            x="804.4"
            y="1639.9"
            width="111.8"
            height="131"
          />
          <path
            className="cls-6"
            d="M804.4,2026.7c47.5-4.1,95-8.2,142.5-12.3v-243.6h-142.5v255.9Z"
          />
          <path
            className="cls-6"
            d="M1061.3,880.8h-258.9v-413.6c85.3-11.7,170.5-23.5,255.8-35.2,1,149.6,2.1,299.2,3.1,448.8Z"
          />
          <rect
            className="cls-2"
            x="125.8"
            y="569.9"
            width="42.9"
            height="70.5"
          />
          <rect
            className="cls-2"
            x="130.3"
            y="738.4"
            width="46"
            height="35.2"
          />
          <rect
            className="cls-2"
            x="130.3"
            y="863.2"
            width="46"
            height="35.2"
          />
          <rect className="cls-2" x="130.3" y="995" width="46" height="35.2" />
          <rect
            className="cls-2"
            x="130.3"
            y="1126.7"
            width="46"
            height="35.2"
          />
          <rect
            className="cls-2"
            x="130.3"
            y="1251.6"
            width="46"
            height="35.2"
          />
          <rect
            className="cls-2"
            x="134.9"
            y="1384.8"
            width="46"
            height="35.2"
          />
          <rect
            className="cls-2"
            x="134.9"
            y="1508.2"
            width="46"
            height="35.2"
          />
          <rect className="cls-2" x="134.9" y="1643" width="46" height="35.2" />
          <rect
            className="cls-2"
            x="134.9"
            y="1764.8"
            width="46"
            height="35.2"
          />
          <rect className="cls-2" x="134.9" y="1898" width="46" height="35.2" />
          <rect
            className="cls-2"
            x="134.9"
            y="2021.4"
            width="46"
            height="35.2"
          />
          <rect
            className="cls-2"
            x="150.6"
            y="2065"
            width="24.9"
            height="28.7"
          />
          <path
            className="cls-2"
            d="M380.8,2072.9c-8.9.9-17.9,1.9-26.8,2.8-.3-15.1-.5-30.1-.8-45.2,9.5-.3,19.1-.5,28.6-.8-.3,14.5-.7,28.9-1,43.2Z"
          />
          <path
            className="cls-2"
            d="M586.8,2053c-8.9.9-17.9,1.7-26.8,2.6-2.4-16.2-4.8-32.3-7.2-48.5,9.2-1.3,18.4-2.6,27.6-3.8,2.2,16.5,4.3,33.1,6.4,49.7Z"
          />
          <path
            className="cls-2"
            d="M795.3,2032.8c-8.9,1.1-17.9,2.1-26.8,3.1-.3-16.8-.5-33.6-.8-50.5,9.5-.3,19.1-.6,28.6-.9-.4,16.2-.7,32.2-1,48.3Z"
          />
          <rect
            className="cls-2"
            x="786.8"
            y="1886.9"
            width="21.7"
            height="19.2"
          />
          <rect
            className="cls-2"
            x="790.3"
            y="1768"
            width="21.7"
            height="29.1"
          />
          <rect className="cls-2" x="785.4" y="1644.1" width="41" height="18" />
          <rect className="cls-2" x="788" y="1508.7" width="41" height="20.5" />
          <rect
            className="cls-2"
            x="783.9"
            y="1385.3"
            width="41"
            height="20.5"
          />
          <rect
            className="cls-2"
            x="785.4"
            y="1254.9"
            width="41"
            height="20.5"
          />
          <rect
            className="cls-2"
            x="783.9"
            y="1124.5"
            width="41"
            height="20.5"
          />
          <rect className="cls-2" x="781.9" y="995" width="41" height="24.8" />
          <rect
            className="cls-2"
            x="781.9"
            y="870.6"
            width="41"
            height="20.5"
          />
          <rect className="cls-2" x="780.4" y="741.2" width="44" height="24" />
        </g>
        <g id="int_pillars">
          <rect
            id="c2r9"
            className="cls-1"
            x="577.6"
            y="1905"
            width="15"
            height="15"
          />
          <rect
            id="c2r8"
            className="cls-1"
            x="577.6"
            y="1775.7"
            width="15"
            height="15"
          />
          <rect
            id="c2r7"
            className="cls-1"
            x="577.6"
            y="1646.5"
            width="15"
            height="15"
          />
          <rect
            id="c2r6"
            className="cls-1"
            x="577.6"
            y="1519.2"
            width="15"
            height="15"
          />
          <rect
            id="c2r5"
            className="cls-1"
            x="577.6"
            y="1392.4"
            width="15"
            height="15"
          />
          <rect
            id="c2r4"
            className="cls-1"
            x="577.6"
            y="1262.6"
            width="15"
            height="15"
          />
          <rect
            id="c2r3"
            className="cls-1"
            x="577.6"
            y="1136.8"
            width="15"
            height="15"
          />
          <rect
            id="c2r2"
            className="cls-1"
            x="577.6"
            y="1007.2"
            width="15"
            height="15"
          />
          <rect
            id="c2r1"
            className="cls-1"
            x="577.6"
            y="877.5"
            width="15"
            height="15"
          />
          <rect
            id="c1r9"
            className="cls-1"
            x="372.2"
            y="1905"
            width="15"
            height="15"
          />
          <rect
            id="c1r8"
            className="cls-1"
            x="372.2"
            y="1775.7"
            width="15"
            height="15"
          />
          <rect
            id="c1r7"
            className="cls-1"
            x="372.2"
            y="1646.5"
            width="15"
            height="15"
          />
          <rect
            id="c1r6"
            className="cls-1"
            x="372.2"
            y="1519.2"
            width="15"
            height="15"
          />
          <rect
            id="c1r5"
            className="cls-1"
            x="372.2"
            y="1392.4"
            width="15"
            height="15"
          />
          <rect
            id="c1r4"
            className="cls-1"
            x="372.2"
            y="1262.6"
            width="15"
            height="15"
          />
          <rect
            id="c1r3"
            className="cls-1"
            x="372.2"
            y="1136.8"
            width="15"
            height="15"
          />
          <rect
            id="c1r2"
            className="cls-1"
            x="372.2"
            y="1007.2"
            width="15"
            height="15"
          />
          <rect
            id="c1r1"
            className="cls-1"
            x="372.2"
            y="877.5"
            width="15"
            height="15"
          />
          <rect
            id="c1ad2"
            className="cls-1"
            x="372.2"
            y="742.1"
            width="15"
            height="15"
          />
        </g>
        <g id="int_walls_const">
          <rect
            className="cls-19"
            x="540.7"
            y="598.2"
            width="260.4"
            height="148.8"
          />
          <path
            className="cls-19"
            d="M539.4,622h-109.8v-83.4c37-4.8,74.1-9.6,111.1-14.4-.4,32.5-.9,65.1-1.3,97.8Z"
          />
          <path
            className="cls-19"
            d="M788.5,598.2h-247.8v-74.1c83-10.8,166-21.6,249-32.4-.4,35.5-.8,71-1.2,106.5Z"
          />
        </g>
        <g id="lifts">
          <g id="lif2">
            <rect
              className="cls-11"
              x="826.4"
              y="1674.9"
              width="59.5"
              height="81.4"
            />
            <line
              className="cls-8"
              x1="885.9"
              y1="1674.9"
              x2="826.4"
              y2="1756.3"
            />
            <line
              className="cls-8"
              x1="885.9"
              y1="1756.3"
              x2="826.4"
              y2="1674.9"
            />
          </g>
          <g id="lift1">
            <rect
              className="cls-11"
              x="442.5"
              y="562"
              width="85"
              height="51.7"
            />
            <line className="cls-8" x1="442.5" y1="562" x2="527.5" y2="613.7" />
            <line className="cls-8" x1="527.5" y1="562" x2="442.5" y2="613.7" />
          </g>
        </g>
        <g id="_2stairs_door" data-name="2stairs_door">
          <rect className="cls-9" x="794.4" y="1814.5" width="20" height="40" />
          <line
            className="cls-10"
            x1="814.4"
            y1="1814.5"
            x2="794.4"
            y2="1854.5"
          />
        </g>
        <g id="side-door">
          <rect className="cls-9" x="792.4" y="653.6" width="20" height="40" />
          <line
            className="cls-10"
            x1="812.4"
            y1="653.6"
            x2="792.4"
            y2="693.6"
          />
        </g>
      </g>
      <g className="_1fl_walls_doors" data-name="1fl_walls_doors">
        <g id="_1fl_cleaning" data-name="1fl_cleaning">
          <path
            id="_1fl_cleaning_wall"
            data-name="1fl_cleaning_wall"
            className="cls-19"
            d="M154.8,863.2l135.1.2-.2-49.9s100.8.3,151.2.4v-65.5h12.8c-.2-84,.6-126.4.6-126.4h-24.7v-86.6l-275.5,44.2.7,283.6Z"
          />
          <g id="_1fl_cleaning_door" data-name="1fl_cleaning_door">
            <rect
              id="cleaning_hub"
              className="cls-9"
              x="448.6"
              y="692.7"
              width="10"
              height="40"
            />
            <line
              className="cls-10"
              x1="458.6"
              y1="692.7"
              x2="448.6"
              y2="732.7"
            />
          </g>
        </g>
        <rect
          id="_1fl_storage_wall"
          data-name="1fl_storage_wall"
          className="cls-19"
          x="540.7"
          y="753.3"
          width="258.1"
          height="121.8"
        />
        <g id="_1fl_national" data-name="1fl_national">
          <rect
            id="_1fl_national_wall"
            data-name="1fl_national_wall"
            className="cls-19"
            x="540.7"
            y="875.1"
            width="258.1"
            height="803.1"
          />
          <g id="_1fl_national_door" data-name="1fl_national_door">
            <rect
              id="nat_lit"
              className="cls-9"
              x="535.7"
              y="1446.8"
              width="10"
              height="40"
            />
            <line
              className="cls-10"
              x1="545.7"
              y1="1446.8"
              x2="535.7"
              y2="1486.8"
            />
          </g>
        </g>
        <rect
          id="_1fl_smallroom"
          data-name="1fl_smallroom"
          className="cls-19"
          x="742.3"
          y="1911.6"
          width="55.3"
          height="85.9"
        />
        <g id="_1fl_sec_wall" data-name="1fl_sec_wall">
          <line className="cls-14" x1="540.7" y1="1558" x2="639.3" y2="1558" />
          <line
            className="cls-14"
            x1="639.3"
            y1="1560.5"
            x2="639.3"
            y2="1511.9"
          />
          <line className="cls-14" x1="636.8" y1="1514" x2="722" y2="1514" />
          <line className="cls-14" x1="720" y1="1560.5" x2="720" y2="1511.9" />
          <line className="cls-14" x1="794.4" y1="1558" x2="717.6" y2="1558" />
        </g>
        <g id="_1fl_sec_door" data-name="1fl_sec_door">
          <rect className="cls-9" x="535.7" y="1614" width="10" height="40" />
          <line className="cls-10" x1="545.7" y1="1614" x2="535.7" y2="1654" />
        </g>
        <g id="_1fl_stairs" data-name="1fl_stairs">
          <g id="stairs1">
            <rect
              className="cls-8"
              x="545.5"
              y="598.2"
              width="65.9"
              height="85"
            />
            <path
              className="cls-8"
              d="M750.8,638.8l-25.3,25.3h-114.1v-65.9h164.6v14.5c-8.4,8.7-16.8,17.4-25.2,26.1Z"
            />
            <path
              className="cls-8"
              d="M741.6,602.8c-43.4,20.4-86.8,40.8-130.2,61.3v-59c43.4-.8,86.8-1.5,130.2-2.3Z"
            />
            <line className="cls-8" x1="629" y1="605.1" x2="630.6" y2="655" />
            <line
              className="cls-8"
              x1="641.2"
              y1="604.6"
              x2="642.6"
              y2="649.4"
            />
            <line
              className="cls-8"
              x1="652.4"
              y1="604.2"
              x2="653.7"
              y2="643.6"
            />
            <line className="cls-8" x1="663.9" y1="602.8" x2="665.1" y2="638" />
            <line
              className="cls-8"
              x1="675.3"
              y1="602.8"
              x2="676.3"
              y2="633.4"
            />
            <line
              className="cls-8"
              x1="686.1"
              y1="603.3"
              x2="686.9"
              y2="628.5"
            />
            <line
              className="cls-8"
              x1="697.1"
              y1="603.3"
              x2="697.8"
              y2="623.9"
            />
            <line
              className="cls-8"
              x1="707.3"
              y1="602.9"
              x2="707.8"
              y2="618.1"
            />
            <line
              className="cls-8"
              x1="546.5"
              y1="664.1"
              x2="609.8"
              y2="664.1"
            />
            <line
              className="cls-8"
              x1="546.5"
              y1="674.8"
              x2="609.8"
              y2="674.8"
            />
          </g>
          <g id="stairs2">
            <path
              className="cls-8"
              d="M943.1,1965.1c-20.4-43.4-40.8-86.8-61.3-130.2h59c.8,43.4,1.5,86.8,2.3,130.2Z"
            />
            <line
              className="cls-8"
              x1="940.8"
              y1="1852.5"
              x2="890.9"
              y2="1854.1"
            />
            <line
              className="cls-8"
              x1="941.3"
              y1="1864.7"
              x2="896.5"
              y2="1866.1"
            />
            <line
              className="cls-8"
              x1="941.7"
              y1="1875.9"
              x2="902.3"
              y2="1877.2"
            />
            <line
              className="cls-8"
              x1="943.1"
              y1="1887.4"
              x2="907.9"
              y2="1888.6"
            />
            <line
              className="cls-8"
              x1="943.1"
              y1="1898.8"
              x2="912.5"
              y2="1899.8"
            />
            <line
              className="cls-8"
              x1="942.7"
              y1="1909.6"
              x2="917.4"
              y2="1910.4"
            />
            <line
              className="cls-8"
              x1="942.7"
              y1="1920.6"
              x2="922"
              y2="1921.3"
            />
            <line
              className="cls-8"
              x1="943"
              y1="1930.9"
              x2="927.8"
              y2="1931.3"
            />
          </g>
        </g>
        <g id="lift_room_door">
          <rect className="cls-9" x="794.4" y="1691.9" width="20" height="40" />
          <line
            className="cls-10"
            x1="814.4"
            y1="1691.9"
            x2="794.4"
            y2="1731.9"
          />
        </g>
        <g id="_1fl_entr" data-name="1fl_entr">
          <path
            id="_1fl_entr_wall"
            data-name="1fl_entr_wall"
            className="cls-19"
            d="M742.3,1993.8c-121.8,14.3-243.6,28.6-365.4,42.9.4-41.7.9-83.4,1.3-125h364.1v82.1h0Z"
          />
          <g id="main_entr2">
            <rect
              className="cls-9"
              x="479.1"
              y="1906.3"
              width="40"
              height="10"
            />
            <line
              className="cls-10"
              x1="519.1"
              y1="1906.3"
              x2="479.1"
              y2="1916.3"
            />
          </g>
          <g id="staf_entr2">
            <rect
              className="cls-9"
              x="647.5"
              y="1906.3"
              width="40"
              height="10"
            />
            <line
              className="cls-10"
              x1="687.5"
              y1="1906.3"
              x2="647.5"
              y2="1916.3"
            />
          </g>
          <g id="main_entr1">
            <polygon
              className="cls-9"
              points="519.2 2036.2 481.3 2040.6 479.1 2020 517 2015.6 519.2 2036.2"
            />
            <line
              className="cls-10"
              x1="481.3"
              y1="2040.6"
              x2="517"
              y2="2015.6"
            />
          </g>
          <g id="staf_entr1">
            <polygon
              className="cls-9"
              points="689.5 2017.9 649.6 2022.6 647.5 2000.1 687.4 1995.6 689.5 2017.9"
            />
            <line
              className="cls-10"
              x1="649.6"
              y1="2022.6"
              x2="687.4"
              y2="1995.6"
            />
          </g>
        </g>
        <g id="_1fl_storage_door" data-name="1fl_storage_door">
          <rect
            id="storeroom"
            className="cls-9"
            x="535.7"
            y="806.5"
            width="10"
            height="40"
          />
          <line
            className="cls-10"
            x1="545.7"
            y1="806.5"
            x2="535.7"
            y2="846.5"
          />
        </g>
        <g id="_1fl_mainstairs_door" data-name="1fl_mainstairs_door">
          <rect
            id="stairs1-2"
            data-name="stairs1"
            className="cls-9"
            x="535.7"
            y="692.7"
            width="10"
            height="40"
          />
          <line
            className="cls-10"
            x1="545.7"
            y1="692.7"
            x2="535.7"
            y2="732.7"
          />
        </g>
      </g>
      <g className="_1fl_bookstands" data-name="1fl_bookstands">
        <rect
          id="_25"
          data-name="25"
          className="cls-7"
          x="626.8"
          y="1513.2"
          width="10"
          height="42"
        />
        <rect
          id="_26"
          data-name="26"
          className="cls-7"
          x="723"
          y="1545.2"
          width="42"
          height="10"
        />
        <rect
          id="_27.1"
          data-name="27.1"
          className="cls-7"
          x="592.7"
          y="880"
          width="35"
          height="10"
        />
        <rect
          id="_27.2"
          data-name="27.2"
          className="cls-7"
          x="627.8"
          y="880"
          width="35"
          height="10"
        />
        <rect
          id="_27.3"
          data-name="27.3"
          className="cls-7"
          x="663"
          y="880"
          width="35"
          height="10"
        />
        <rect
          id="_27.4"
          data-name="27.4"
          className="cls-7"
          x="698.2"
          y="880"
          width="35"
          height="10"
        />
        <rect
          id="_27.5"
          data-name="27.5"
          className="cls-7"
          x="733.3"
          y="880"
          width="35"
          height="10"
        />
        <rect
          id="_18.1.5"
          data-name="18.1.5"
          className="cls-7"
          x="605.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_18.1.4"
          data-name="18.1.4"
          className="cls-7"
          x="605.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_18.1.3"
          data-name="18.1.3"
          className="cls-7"
          x="605.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_18.1.2"
          data-name="18.1.2"
          className="cls-7"
          x="605.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_18.1.1"
          data-name="18.1.1"
          className="cls-7"
          x="605.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_18.1.6"
          data-name="18.1.6"
          className="cls-7"
          x="605.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.5"
          data-name="18.2.5"
          className="cls-7"
          x="615.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.4"
          data-name="18.2.4"
          className="cls-7"
          x="615.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.3"
          data-name="18.2.3"
          className="cls-7"
          x="615.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.2"
          data-name="18.2.2"
          className="cls-7"
          x="615.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.1"
          data-name="18.2.1"
          className="cls-7"
          x="615.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_18.2.6"
          data-name="18.2.6"
          className="cls-7"
          x="615.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.5"
          data-name="19.1.5"
          className="cls-7"
          x="626.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.4"
          data-name="19.1.4"
          className="cls-7"
          x="626.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.3"
          data-name="19.1.3"
          className="cls-7"
          x="626.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.2"
          data-name="19.1.2"
          className="cls-7"
          x="626.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.1"
          data-name="19.1.1"
          className="cls-7"
          x="626.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_19.1.6"
          data-name="19.1.6"
          className="cls-7"
          x="626.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.5"
          data-name="19.2.5"
          className="cls-7"
          x="636.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.4"
          data-name="19.2.4"
          className="cls-7"
          x="636.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.3"
          data-name="19.2.3"
          className="cls-7"
          x="636.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.2"
          data-name="19.2.2"
          className="cls-7"
          x="636.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.1"
          data-name="19.2.1"
          className="cls-7"
          x="636.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_19.2.6"
          data-name="19.2.6"
          className="cls-7"
          x="636.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.5"
          data-name="20.1.5"
          className="cls-7"
          x="647.3"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.4"
          data-name="20.1.4"
          className="cls-7"
          x="647.3"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.3"
          data-name="20.1.3"
          className="cls-7"
          x="647.3"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.2"
          data-name="20.1.2"
          className="cls-7"
          x="647.3"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.1"
          data-name="20.1.1"
          className="cls-7"
          x="647.3"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_20.1.6"
          data-name="20.1.6"
          className="cls-7"
          x="647.3"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.5"
          data-name="20.2.5"
          className="cls-7"
          x="657.3"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.4"
          data-name="20.2.4"
          className="cls-7"
          x="657.3"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.3"
          data-name="20.2.3"
          className="cls-7"
          x="657.3"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.2"
          data-name="20.2.2"
          className="cls-7"
          x="657.3"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.1"
          data-name="20.2.1"
          className="cls-7"
          x="657.3"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_20.2.6"
          data-name="20.2.6"
          className="cls-7"
          x="657.3"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.5"
          data-name="21.1.5"
          className="cls-7"
          x="668.1"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.4"
          data-name="21.1.4"
          className="cls-7"
          x="668.1"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.3"
          data-name="21.1.3"
          className="cls-7"
          x="668.1"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.2"
          data-name="21.1.2"
          className="cls-7"
          x="668.1"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.1."
          data-name="21.1.1."
          className="cls-7"
          x="668.1"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_21.1.6"
          data-name="21.1.6"
          className="cls-7"
          x="668.1"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.5"
          data-name="21.2.5"
          className="cls-7"
          x="678.1"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.4"
          data-name="21.2.4"
          className="cls-7"
          x="678.1"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.3"
          data-name="21.2.3"
          className="cls-7"
          x="678.1"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.2"
          data-name="21.2.2"
          className="cls-7"
          x="678.1"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.1"
          data-name="21.2.1"
          className="cls-7"
          x="678.1"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_21.2.6"
          data-name="21.2.6"
          className="cls-7"
          x="678.1"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.5"
          data-name="22.1.5"
          className="cls-7"
          x="689.3"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.4"
          data-name="22.1.4"
          className="cls-7"
          x="689.3"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.3"
          data-name="22.1.3"
          className="cls-7"
          x="689.3"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.2"
          data-name="22.1.2"
          className="cls-7"
          x="689.3"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.1"
          data-name="22.1.1"
          className="cls-7"
          x="689.3"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_22.1.6"
          data-name="22.1.6"
          className="cls-7"
          x="689.3"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.5"
          data-name="22.2.5"
          className="cls-7"
          x="699.3"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.4"
          data-name="22.2.4"
          className="cls-7"
          x="699.3"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.3"
          data-name="22.2.3"
          className="cls-7"
          x="699.3"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.2"
          data-name="22.2.2"
          className="cls-7"
          x="699.3"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.1"
          data-name="22.2.1"
          className="cls-7"
          x="699.3"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_22.2.6"
          data-name="22.2.6"
          className="cls-7"
          x="699.3"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.5"
          data-name="23.1.5"
          className="cls-7"
          x="710.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.4"
          data-name="23.1.4"
          className="cls-7"
          x="710.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.3"
          data-name="23.1.3"
          className="cls-7"
          x="710.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.2"
          data-name="23.1.2"
          className="cls-7"
          x="710.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.1"
          data-name="23.1.1"
          className="cls-7"
          x="710.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_23.1.6"
          data-name="23.1.6"
          className="cls-7"
          x="710.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.5"
          data-name="23.2.5"
          className="cls-7"
          x="720.2"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.4"
          data-name="23.2.4"
          className="cls-7"
          x="720.2"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.3"
          data-name="23.2.3"
          className="cls-7"
          x="720.2"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.2"
          data-name="23.2.2"
          className="cls-7"
          x="720.2"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.1"
          data-name="23.2.1"
          className="cls-7"
          x="720.2"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_23.2.6"
          data-name="23.2.6"
          className="cls-7"
          x="720.2"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.5"
          data-name="24.1.5"
          className="cls-7"
          x="731.1"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.4"
          data-name="24.1.4"
          className="cls-7"
          x="731.1"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.3"
          data-name="24.1.3"
          className="cls-7"
          x="731.1"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.2"
          data-name="24.1.2"
          className="cls-7"
          x="731.1"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.1"
          data-name="24.1.1"
          className="cls-7"
          x="731.1"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_24.1.6"
          data-name="24.1.6"
          className="cls-7"
          x="731.1"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.5"
          data-name="24.2.5"
          className="cls-7"
          x="741.1"
          y="958.9"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.4"
          data-name="24.2.4"
          className="cls-7"
          x="741.1"
          y="1003.1"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.3"
          data-name="24.2.3"
          className="cls-7"
          x="741.1"
          y="1048.1"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.2"
          data-name="24.2.2"
          className="cls-7"
          x="741.1"
          y="1092.2"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.1"
          data-name="24.2.1"
          className="cls-7"
          x="741.1"
          y="1137.2"
          width="10"
          height="45"
        />
        <rect
          id="_24.2.6"
          data-name="24.2.6"
          className="cls-7"
          x="741.1"
          y="913.9"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.5"
          data-name="11.1.5"
          className="cls-7"
          x="606.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.4"
          data-name="11.1.4"
          className="cls-7"
          x="606.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.3"
          data-name="11.1.3"
          className="cls-7"
          x="606.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.2"
          data-name="11.1.2"
          className="cls-7"
          x="606.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.1"
          data-name="11.1.1"
          className="cls-7"
          x="606.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_11.1.6"
          data-name="11.1.6"
          className="cls-7"
          x="606.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.5"
          data-name="11.2.5"
          className="cls-7"
          x="616.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.4"
          data-name="11.2.4"
          className="cls-7"
          x="616.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.3"
          data-name="11.2.3"
          className="cls-7"
          x="616.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.2"
          data-name="11.2.2"
          className="cls-7"
          x="616.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.1"
          data-name="11.2.1"
          className="cls-7"
          x="616.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_11.2.6"
          data-name="11.2.6"
          className="cls-7"
          x="616.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.5"
          data-name="12.1.5"
          className="cls-7"
          x="627.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.4"
          data-name="12.1.4"
          className="cls-7"
          x="627.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.3"
          data-name="12.1.3"
          className="cls-7"
          x="627.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.2"
          data-name="12.1.2"
          className="cls-7"
          x="627.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.1"
          data-name="12.1.1"
          className="cls-7"
          x="627.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_12.1.6"
          data-name="12.1.6"
          className="cls-7"
          x="627.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.5"
          data-name="12.2.5"
          className="cls-7"
          x="637.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.4"
          data-name="12.2.4"
          className="cls-7"
          x="637.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.3"
          data-name="12.2.3"
          className="cls-7"
          x="637.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.2"
          data-name="12.2.2"
          className="cls-7"
          x="637.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.1"
          data-name="12.2.1"
          className="cls-7"
          x="637.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_12.2.6"
          data-name="12.2.6"
          className="cls-7"
          x="637.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.5"
          data-name="13.1.5"
          className="cls-7"
          x="648.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.4"
          data-name="13.1.4"
          className="cls-7"
          x="648.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.3"
          data-name="13.1.3"
          className="cls-7"
          x="648.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.2"
          data-name="13.1.2"
          className="cls-7"
          x="648.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.1"
          data-name="13.1.1"
          className="cls-7"
          x="648.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_13.1.6"
          data-name="13.1.6"
          className="cls-7"
          x="648.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.5"
          data-name="13.2.5"
          className="cls-7"
          x="658.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.4"
          data-name="13.2.4"
          className="cls-7"
          x="658.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.3"
          data-name="13.2.3"
          className="cls-7"
          x="658.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.2"
          data-name="13.2.2"
          className="cls-7"
          x="658.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.1"
          data-name="13.2.1"
          className="cls-7"
          x="658.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_13.2.6"
          data-name="13.2.6"
          className="cls-7"
          x="658.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.5"
          data-name="14.1.5"
          className="cls-7"
          x="669.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.4"
          data-name="14.1.4"
          className="cls-7"
          x="669.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.3"
          data-name="14.1.3"
          className="cls-7"
          x="669.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.2"
          data-name="14.1.2"
          className="cls-7"
          x="669.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.1"
          data-name="14.1.1"
          className="cls-7"
          x="669.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_14.1.6"
          data-name="14.1.6"
          className="cls-7"
          x="669.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.5"
          data-name="14.2.5"
          className="cls-7"
          x="679.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.4"
          data-name="14.2.4"
          className="cls-7"
          x="679.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.3"
          data-name="14.2.3"
          className="cls-7"
          x="679.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.2"
          data-name="14.2.2"
          className="cls-7"
          x="679.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.1"
          data-name="14.2.1"
          className="cls-7"
          x="679.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_14.2.6"
          data-name="14.2.6"
          className="cls-7"
          x="679.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.5"
          data-name="15.1.5"
          className="cls-7"
          x="690.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.4"
          data-name="15.1.4"
          className="cls-7"
          x="690.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.3"
          data-name="15.1.3"
          className="cls-7"
          x="690.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.2"
          data-name="15.1.2"
          className="cls-7"
          x="690.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.1"
          data-name="15.1.1"
          className="cls-7"
          x="690.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_15.1.6"
          data-name="15.1.6"
          className="cls-7"
          x="690.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.5"
          data-name="15.2.5"
          className="cls-7"
          x="700.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.4"
          data-name="15.2.4"
          className="cls-7"
          x="700.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.3"
          data-name="15.2.3"
          className="cls-7"
          x="700.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.2"
          data-name="15.2.2"
          className="cls-7"
          x="700.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.1"
          data-name="15.2.1"
          className="cls-7"
          x="700.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_15.2.6"
          data-name="15.2.6"
          className="cls-7"
          x="700.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.5"
          data-name="16.1.5"
          className="cls-7"
          x="711.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.4"
          data-name="16.1.4"
          className="cls-7"
          x="711.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.3"
          data-name="16.1.3"
          className="cls-7"
          x="711.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.2"
          data-name="16.1.2"
          className="cls-7"
          x="711.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.1"
          data-name="16.1.1"
          className="cls-7"
          x="711.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_16.1.6"
          data-name="16.1.6"
          className="cls-7"
          x="711.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.5"
          data-name="16.2.5"
          className="cls-7"
          x="721.3"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.4"
          data-name="16.2.4"
          className="cls-7"
          x="721.3"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.3"
          data-name="16.2.3"
          className="cls-7"
          x="721.3"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.2"
          data-name="16.2.2"
          className="cls-7"
          x="721.3"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.1"
          data-name="16.2.1"
          className="cls-7"
          x="721.3"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_16.2.6"
          data-name="16.2.6"
          className="cls-7"
          x="721.3"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.5"
          data-name="17.1.5"
          className="cls-7"
          x="732.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.4"
          data-name="17.1.4"
          className="cls-7"
          x="732.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.3"
          data-name="17.1.3"
          className="cls-7"
          x="732.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.2"
          data-name="17.1.2"
          className="cls-7"
          x="732.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.1"
          data-name="17.1.1"
          className="cls-7"
          x="732.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_17.1.6"
          data-name="17.1.6"
          className="cls-7"
          x="732.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.5"
          data-name="17.2.5"
          className="cls-7"
          x="742.2"
          y="1258.6"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.4"
          data-name="17.2.4"
          className="cls-7"
          x="742.2"
          y="1302.7"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.3"
          data-name="17.2.3"
          className="cls-7"
          x="742.2"
          y="1347.7"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.2"
          data-name="17.2.2"
          className="cls-7"
          x="742.2"
          y="1391.9"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.1"
          data-name="17.2.1"
          className="cls-7"
          x="742.2"
          y="1436.9"
          width="10"
          height="45"
        />
        <rect
          id="_17.2.6"
          data-name="17.2.6"
          className="cls-7"
          x="742.2"
          y="1213.6"
          width="10"
          height="45"
        />
        <rect
          id="_9.2.28"
          data-name="9.2.28"
          className="cls-7"
          x="448.6"
          y="753"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.27"
          data-name="9.2.27"
          className="cls-7"
          x="448.6"
          y="785.8"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.26"
          data-name="9.2.26"
          className="cls-7"
          x="448.6"
          y="818.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.25"
          data-name="9.2.25"
          className="cls-7"
          x="448.6"
          y="851.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.24"
          data-name="9.2.24"
          className="cls-7"
          x="448.6"
          y="884.5"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.23"
          data-name="9.2.23"
          className="cls-7"
          x="448.6"
          y="917.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.22"
          data-name="9.2.22"
          className="cls-7"
          x="448.6"
          y="950.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.21"
          data-name="9.2.21"
          className="cls-7"
          x="448.6"
          y="983.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.20"
          data-name="9.2.20"
          className="cls-7"
          x="448.6"
          y="1016.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.19"
          data-name="9.2.19"
          className="cls-7"
          x="448.6"
          y="1049.3"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.18"
          data-name="9.2.18"
          className="cls-7"
          x="448.6"
          y="1082.3"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.17"
          data-name="9.2.17"
          className="cls-7"
          x="448.6"
          y="1114.9"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.16"
          data-name="9.2.16"
          className="cls-7"
          x="448.6"
          y="1147.9"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.15"
          data-name="9.2.15"
          className="cls-7"
          x="448.6"
          y="1180.8"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.14"
          data-name="9.2.14"
          className="cls-7"
          x="448.6"
          y="1213.8"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.13"
          data-name="9.2.13"
          className="cls-7"
          x="448.6"
          y="1246.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.12"
          data-name="9.2.12"
          className="cls-7"
          x="448.6"
          y="1279.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.11"
          data-name="9.2.11"
          className="cls-7"
          x="448.6"
          y="1312.6"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.10"
          data-name="9.2.10"
          className="cls-7"
          x="448.6"
          y="1345.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.9"
          data-name="9.2.9"
          className="cls-7"
          x="448.6"
          y="1378.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.8"
          data-name="9.2.8"
          className="cls-7"
          x="448.6"
          y="1411.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.2.7"
          data-name="9.2.7"
          className="cls-7"
          x="448.6"
          y="1444.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.26"
          data-name="9.1.26"
          className="cls-7"
          x="438.6"
          y="818.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.25"
          data-name="9.1.25"
          className="cls-7"
          x="438.6"
          y="851.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.24"
          data-name="9.1.24"
          className="cls-7"
          x="438.6"
          y="884.5"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.23"
          data-name="9.1.23"
          className="cls-7"
          x="438.6"
          y="917.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.22"
          data-name="9.1.22"
          className="cls-7"
          x="438.6"
          y="950.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.21"
          data-name="9.1.21"
          className="cls-7"
          x="438.6"
          y="983.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.20"
          data-name="9.1.20"
          className="cls-7"
          x="438.6"
          y="1016.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.19"
          data-name="9.1.19"
          className="cls-7"
          x="438.6"
          y="1049.3"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.18"
          data-name="9.1.18"
          className="cls-7"
          x="438.6"
          y="1082.3"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.17"
          data-name="9.1.17"
          className="cls-7"
          x="438.6"
          y="1114.9"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.16"
          data-name="9.1.16"
          className="cls-7"
          x="438.6"
          y="1147.9"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.15"
          data-name="9.1.15"
          className="cls-7"
          x="438.6"
          y="1180.8"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.14"
          data-name="9.1.14"
          className="cls-7"
          x="438.6"
          y="1213.8"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.13"
          data-name="9.1.13"
          className="cls-7"
          x="438.6"
          y="1246.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.12"
          data-name="9.1.12"
          className="cls-7"
          x="438.6"
          y="1279.7"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.11"
          data-name="9.1.11"
          className="cls-7"
          x="438.6"
          y="1312.6"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.10"
          data-name="9.1.10"
          className="cls-7"
          x="438.6"
          y="1345.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.9"
          data-name="9.1.9"
          className="cls-7"
          x="438.6"
          y="1378.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.8"
          data-name="9.1.8"
          className="cls-7"
          x="438.6"
          y="1411.4"
          width="10"
          height="32"
        />
        <rect
          id="_9.1.7"
          data-name="9.1.7"
          className="cls-7"
          x="438.6"
          y="1444.4"
          width="10"
          height="32"
        />
        <rect
          id="_1"
          data-name="1"
          className="cls-7"
          x="186.5"
          y="1509.2"
          width="10"
          height="35"
        />
        <rect
          id="_2"
          data-name="2"
          className="cls-7"
          x="185.7"
          y="1384.9"
          width="10"
          height="35"
        />
        <rect
          id="_3"
          data-name="3"
          className="cls-7"
          x="181.6"
          y="1251.7"
          width="10"
          height="35"
        />
        <rect
          id="_4"
          data-name="4"
          className="cls-7"
          x="181.6"
          y="1126.8"
          width="10"
          height="35"
        />
        <rect
          id="_5"
          data-name="5"
          className="cls-7"
          x="181.6"
          y="995.1"
          width="10"
          height="35"
        />
        <path
          id="_9.2.6"
          data-name="9.2.6"
          className="cls-7"
          d="M458.6,1477.3v22c0,5.5-4.5,10-10,10h-20v-8h20v-24h10Z"
        />
        <rect
          id="_9.2.5"
          data-name="9.2.5"
          className="cls-7"
          x="408.6"
          y="1501.3"
          width="20"
          height="8"
        />
        <path
          id="_9.1.6"
          data-name="9.1.6"
          className="cls-7"
          d="M448.6,1477.3v24h-20v-8c5.5,0,10-4.5,10-10v-6h10Z"
        />
        <rect
          id="_9.1.5"
          data-name="9.1.5"
          className="cls-7"
          x="408.6"
          y="1493.3"
          width="20"
          height="8"
        />
        <rect
          id="_9.2.3"
          data-name="9.2.3"
          className="cls-7"
          x="364.2"
          y="1477.3"
          width="28.2"
          height="8"
        />
        <rect
          id="_9.1.3"
          data-name="9.1.3"
          className="cls-7"
          x="364.2"
          y="1469.3"
          width="28.2"
          height="8"
        />
        <path
          id="_9.2.2"
          data-name="9.2.2"
          className="cls-7"
          d="M364.2,1477.3v8c-4,0-7.3,2.9-7.9,6.7v5.3h-8v-20h15.9Z"
        />
        <path
          id="_9.1.2"
          data-name="9.1.2"
          className="cls-7"
          d="M364.2,1469.3v8h-16.1v20h-8v-20h-.1c0-4.4,2.9-8,6.5-8h17.7Z"
        />
        <path
          id="_9.2.1"
          data-name="9.2.1"
          className="cls-7"
          d="M348.2,1497.3h8v12c0,4.4-3.6,8-8,8h0v-20Z"
        />
        <path
          id="_9.1.1."
          data-name="9.1.1."
          className="cls-7"
          d="M340,1497.3h8v20h0c-4.4,0-8-3.6-8-8v-12Z"
        />
        <rect
          id="_6.1"
          data-name="6.1"
          className="cls-7"
          x="197.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.2"
          data-name="6.2"
          className="cls-7"
          x="209.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.3"
          data-name="6.3"
          className="cls-7"
          x="221.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.4"
          data-name="6.4"
          className="cls-7"
          x="233.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.5"
          data-name="6.5"
          className="cls-7"
          x="245.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.6"
          data-name="6.6"
          className="cls-7"
          x="257.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.7"
          data-name="6.7"
          className="cls-7"
          x="269.7"
          y="867.9"
          width="12"
          height="12"
        />
        <rect
          id="_6.8"
          data-name="6.8"
          className="cls-7"
          x="281.7"
          y="867.9"
          width="12"
          height="12"
        />
        <path
          id="_6.9"
          data-name="6.9"
          className="cls-7"
          d="M304,876.3c-3.8,3.8-8.6,3.6-9.4,3.6v-12h12.9c0,.9-.2,5-3.5,8.4Z"
        />
        <rect
          id="_6.10"
          data-name="6.10"
          className="cls-7"
          x="295.5"
          y="855.1"
          width="12"
          height="12"
        />
        <rect
          id="_6.11"
          data-name="6.11"
          className="cls-7"
          x="295.5"
          y="843.1"
          width="12"
          height="12"
        />
        <rect
          id="_6.12"
          data-name="6.12"
          className="cls-7"
          x="295.5"
          y="831.1"
          width="12"
          height="12"
        />
        <rect
          id="_6.13"
          data-name="6.13"
          className="cls-7"
          x="307.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.14"
          data-name="6.14"
          className="cls-7"
          x="314.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.15"
          data-name="6.15"
          className="cls-7"
          x="321.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.16"
          data-name="6.16"
          className="cls-7"
          x="328.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.17"
          data-name="6.17"
          className="cls-7"
          x="335.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.19"
          data-name="6.19"
          className="cls-7"
          x="342.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.20"
          data-name="6.20"
          className="cls-7"
          x="349.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.21"
          data-name="6.21"
          className="cls-7"
          x="356.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.22"
          data-name="6.22"
          className="cls-7"
          x="363.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.23"
          data-name="6.23"
          className="cls-7"
          x="370.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.24"
          data-name="6.24"
          className="cls-7"
          x="377.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.25"
          data-name="6.25"
          className="cls-7"
          x="384.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_6.26"
          data-name="6.26"
          className="cls-7"
          x="391.7"
          y="818.4"
          width="7"
          height="12"
        />
        <rect
          id="_7.2.1"
          data-name="7.2.1"
          className="cls-7"
          x="355"
          y="968.1"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.1.1"
          data-name="7.1.1"
          className="cls-7"
          x="355"
          y="978.1"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.1.2"
          data-name="7.1.2"
          className="cls-7"
          x="384.9"
          y="978.1"
          width="30"
          height="9.8"
        />
        <rect
          id="_7.2.3"
          data-name="7.2.3"
          className="cls-7"
          x="415.1"
          y="988.1"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.1.3"
          data-name="7.1.3"
          className="cls-7"
          x="405.1"
          y="988.1"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.1.4"
          data-name="7.1.4"
          className="cls-7"
          x="405.1"
          y="1018.1"
          width="9.8"
          height="30"
        />
        <rect
          id="_7.1.5"
          data-name="7.1.5"
          className="cls-7"
          x="375.2"
          y="1038.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.2.5"
          data-name="7.2.5"
          className="cls-7"
          x="375.2"
          y="1048.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.2.7"
          data-name="7.2.7"
          className="cls-7"
          x="335.4"
          y="1048.2"
          width="9.8"
          height="30"
        />
        <rect
          id="_7.2.8"
          data-name="7.2.8"
          className="cls-7"
          x="335.4"
          y="1078.2"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.1.8"
          data-name="7.1.8"
          className="cls-7"
          x="325.4"
          y="1078.2"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.2.9"
          data-name="7.2.9"
          className="cls-7"
          x="335.4"
          y="1108.1"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.1.9"
          data-name="7.1.9"
          className="cls-7"
          x="325.4"
          y="1108.1"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.2.10"
          data-name="7.2.10"
          className="cls-16"
          x="325.4"
          y="1148.2"
          width="29.9"
          height="9.8"
          transform="translate(-813.8 1489.6) rotate(-89.8)"
        />
        <rect
          id="_7.1.10"
          data-name="7.1.10"
          className="cls-7"
          x="325.5"
          y="1138.1"
          width="9.8"
          height="29.9"
        />
        <rect
          id="_7.2.11"
          data-name="7.2.11"
          className="cls-7"
          x="335.5"
          y="1168.1"
          width="30"
          height="9.8"
        />
        <rect
          id="_7.2.12"
          data-name="7.2.12"
          className="cls-7"
          x="365.5"
          y="1168.1"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.1.12"
          data-name="7.1.12"
          className="cls-7"
          x="365.5"
          y="1177.9"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.1.6"
          data-name="7.1.6"
          className="cls-7"
          x="345.2"
          y="1038.2"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_7.2.6"
          data-name="7.2.6"
          className="cls-7"
          x="345.2"
          y="1048.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.2.2"
          data-name="8.2.2"
          className="cls-7"
          x="342.3"
          y="1524.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.1.2"
          data-name="8.1.2"
          className="cls-7"
          x="342.3"
          y="1534.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.2.3"
          data-name="8.2.3"
          className="cls-7"
          x="387.8"
          y="1524.2"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.1.3"
          data-name="8.1.3"
          className="cls-7"
          x="387.8"
          y="1534.2"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.2.4"
          data-name="8.2.4"
          className="cls-7"
          x="417.7"
          y="1524.1"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.1.4"
          data-name="8.1.4"
          className="cls-7"
          x="417.7"
          y="1534.1"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.2.1"
          data-name="8.2.1"
          className="cls-7"
          x="312.4"
          y="1524.3"
          width="29.9"
          height="9.8"
        />
        <rect
          id="_8.1.1"
          data-name="8.1.1"
          className="cls-7"
          x="312.4"
          y="1534.3"
          width="29.9"
          height="9.8"
        />
        <path
          id="_9.1.4"
          data-name="9.1.4"
          className="cls-7"
          d="M408.4,1477.3v24h-8v-24h-8v-8h8c2.2,0,4.2.9,5.7,2.3,1.4,1.5,2.3,3.5,2.3,5.7Z"
        />
        <path
          id="_9.2.4"
          data-name="9.2.4"
          className="cls-7"
          d="M408.4,1501.1v8h-8c-2.2,0-4.2-.9-5.7-2.3-1.4-1.4-2.3-3.4-2.3-5.7v-24h8v24h8Z"
        />
        <path
          id="_7.2.2"
          data-name="7.2.2"
          className="cls-7"
          d="M415.1,968.1h-30.2v9.8h30v9.8h10v-9.8c0-5.4-4.4-9.8-9.8-9.8Z"
        />
        <path
          id="_7.2.4"
          data-name="7.2.4"
          className="cls-7"
          d="M415.1,1018.1v30.2h-10v9.8h10c5.4,0,9.8-5.9,9.8-13.1v-26.9h-9.8Z"
        />
        <path
          id="_7.1.7"
          data-name="7.1.7"
          className="cls-7"
          d="M335.2,1038.2c-5.4,0-9.8,4.3-9.8,9.8v30.2h9.8v-30.2h10v-9.8h-10Z"
        />
        <path
          id="_7.1.11"
          data-name="7.1.11"
          className="cls-7"
          d="M335.5,1177.8v-9.8h-10v9.8c0,5.4,4.4,9.8,9.8,9.8h30.2v-9.8h-30Z"
        />
        <rect
          id="_10.1"
          data-name="10.1"
          className="cls-7"
          x="525.7"
          y="741.2"
          width="10"
          height="32"
        />
        <rect
          id="_10.2"
          data-name="10.2"
          className="cls-7"
          x="525.7"
          y="774"
          width="10"
          height="32"
        />
        <rect
          id="_0.3"
          data-name="0.3"
          className="cls-8"
          x="525.7"
          y="852.1"
          width="10"
          height="32"
        />
        <rect
          id="_0.4"
          data-name="0.4"
          className="cls-8"
          x="525.7"
          y="885.1"
          width="10"
          height="32"
        />
        <rect
          id="_0.5"
          data-name="0.5"
          className="cls-8"
          x="525.7"
          y="917.8"
          width="10"
          height="32"
        />
        <rect
          id="_10.6"
          data-name="10.6"
          className="cls-7"
          x="525.7"
          y="950.8"
          width="10"
          height="32"
        />
        <rect
          id="_0.7"
          data-name="0.7"
          className="cls-8"
          x="525.7"
          y="983.8"
          width="10"
          height="32"
        />
        <rect
          id="_0.8"
          data-name="0.8"
          className="cls-8"
          x="525.7"
          y="1016.8"
          width="10"
          height="32"
        />
        <rect
          id="_10.9"
          data-name="10.9"
          className="cls-7"
          x="525.7"
          y="1049.8"
          width="10"
          height="32"
        />
        <rect
          id="_0.10"
          data-name="0.10"
          className="cls-8"
          x="525.7"
          y="1082.7"
          width="10"
          height="32"
        />
        <rect
          id="_10.11"
          data-name="10.11"
          className="cls-7"
          x="525.7"
          y="1115.7"
          width="10"
          height="32"
        />
        <rect
          id="_10.12"
          data-name="10.12"
          className="cls-7"
          x="525.7"
          y="1148.2"
          width="10"
          height="32"
        />
        <rect
          id="_0.13"
          data-name="0.13"
          className="cls-8"
          x="525.7"
          y="1181.2"
          width="10"
          height="32"
        />
        <rect
          id="_0.14"
          data-name="0.14"
          className="cls-8"
          x="525.7"
          y="1214.2"
          width="10"
          height="32"
        />
        <rect
          id="_0.15"
          data-name="0.15"
          className="cls-8"
          x="525.7"
          y="1247.2"
          width="10"
          height="32"
        />
        <rect
          id="_0.16"
          data-name="0.16"
          className="cls-8"
          x="525.7"
          y="1280"
          width="10"
          height="32"
        />
        <rect
          id="_0.17"
          data-name="0.17"
          className="cls-8"
          x="525.7"
          y="1313"
          width="10"
          height="32"
        />
        <rect
          id="_0.18"
          data-name="0.18"
          className="cls-8"
          x="525.7"
          y="1345.9"
          width="10"
          height="32"
        />
        <rect
          id="_0.19"
          data-name="0.19"
          className="cls-8"
          x="525.7"
          y="1378.8"
          width="10"
          height="32"
        />
        <rect
          id="_0.20"
          data-name="0.20"
          className="cls-8"
          x="525.7"
          y="1411.8"
          width="10"
          height="32"
        />
        <rect
          id="_0.21"
          data-name="0.21"
          className="cls-8"
          x="525.7"
          y="1486.7"
          width="10"
          height="32"
        />
        <rect
          id="_0.22"
          data-name="0.22"
          className="cls-8"
          x="525.7"
          y="1519.7"
          width="10"
          height="32"
        />
      </g>
      <g className="_2fl_walls_doors" data-name="2fl_walls_doors">
        <g id="_2fl_mainlift" data-name="2fl_mainlift">
          <rect
            id="_2fl_mainlift_walls"
            data-name="2fl_mainlift_walls"
            className="cls-19"
            x="454.7"
            y="622"
            width="86"
            height="125"
          />
          <g id="_2fl_mainlift_door" data-name="2fl_mainlift_door">
            <rect className="cls-9" x="477.7" y="742" width="40" height="10" />
            <line className="cls-10" x1="477.7" y1="752" x2="517.7" y2="742" />
          </g>
        </g>
        <line
          id="_2fl_artspace_wall"
          data-name="2fl_artspace_wall"
          className="cls-19"
          x1="261.9"
          y1="567"
          x2="261.9"
          y2="619.4"
        />
        <rect
          id="_2fl_2lift_walls"
          data-name="2fl_2lift_walls"
          className="cls-12"
          x="722.7"
          y="1661.8"
          width="81"
          height="106"
        />
        <g id="_2fl_mainstairs_door" data-name="2fl_mainstairs_door">
          <rect className="cls-9" x="552.6" y="742" width="40" height="10" />
          <line className="cls-10" x1="552.6" y1="752" x2="592.6" y2="742" />
        </g>
        <rect
          id="_2fl_toilet_pass"
          data-name="2fl_toilet_pass"
          className="cls-4"
          x="545.9"
          y="593.3"
          width="70"
          height="9.9"
        />
        <g id="_2fl_int_pillars" data-name="2fl_int_pillars">
          <rect
            id="c1ad3"
            className="cls-1"
            x="250.4"
            y="653.6"
            width="15"
            height="15"
          />
          <rect
            id="c1ad1"
            className="cls-1"
            x="372.2"
            y="653.6"
            width="15"
            height="15"
          />
        </g>
        <g id="_2fl_stairs" data-name="2fl_stairs">
          <g id="stairs2-2" data-name="stairs2">
            <rect
              className="cls-8"
              x="881.9"
              y="1856.3"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1868.7"
              x2="881.9"
              y2="1868.7"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1880.7"
              x2="881.9"
              y2="1880.7"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1893.1"
              x2="881.9"
              y2="1893.1"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1904.2"
              x2="881.9"
              y2="1904.2"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1915.7"
              x2="881.9"
              y2="1915.7"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1928.6"
              x2="881.9"
              y2="1928.6"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1939.8"
              x2="881.9"
              y2="1939.8"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1952.2"
              x2="881.9"
              y2="1952.2"
            />
            <line
              className="cls-8"
              x1="939.3"
              y1="1964.9"
              x2="881.9"
              y2="1964.9"
            />
            <rect
              className="cls-8"
              x="814.4"
              y="1856.3"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1868.7"
              x2="814.4"
              y2="1868.7"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1880.7"
              x2="814.4"
              y2="1880.7"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1893.1"
              x2="814.4"
              y2="1893.1"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1904.2"
              x2="814.4"
              y2="1904.2"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1915.7"
              x2="814.4"
              y2="1915.7"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1928.6"
              x2="814.4"
              y2="1928.6"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1939.8"
              x2="814.4"
              y2="1939.8"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1952.2"
              x2="814.4"
              y2="1952.2"
            />
            <line
              className="cls-8"
              x1="871.8"
              y1="1964.9"
              x2="814.4"
              y2="1964.9"
            />
          </g>
          <g id="stairs1-3" data-name="stairs1">
            <rect
              className="cls-8"
              x="614.4"
              y="605.1"
              width="121.2"
              height="57.5"
            />
            <line
              className="cls-8"
              x1="626.8"
              y1="605.1"
              x2="626.8"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="638.9"
              y1="605.1"
              x2="638.9"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="651.2"
              y1="605.1"
              x2="651.2"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="662.3"
              y1="605.1"
              x2="662.3"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="673.8"
              y1="605.1"
              x2="673.8"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="686.7"
              y1="605.1"
              x2="686.7"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="697.9"
              y1="605.1"
              x2="697.9"
              y2="662.5"
            />
            <line
              className="cls-8"
              x1="710.4"
              y1="605.1"
              x2="710.4"
              y2="662.5"
            />
            <line className="cls-8" x1="723" y1="605.1" x2="723" y2="662.5" />
            <rect
              className="cls-8"
              x="614.4"
              y="672.6"
              width="121.2"
              height="57.5"
            />
            <line className="cls-8" x1="626.8" y1="672.6" x2="626.8" y2="730" />
            <line className="cls-8" x1="638.9" y1="672.6" x2="638.9" y2="730" />
            <line className="cls-8" x1="651.2" y1="672.6" x2="651.2" y2="730" />
            <line className="cls-8" x1="662.3" y1="672.6" x2="662.3" y2="730" />
            <line className="cls-8" x1="673.8" y1="672.6" x2="673.8" y2="730" />
            <line className="cls-8" x1="686.7" y1="672.6" x2="686.7" y2="730" />
            <line className="cls-8" x1="697.9" y1="672.6" x2="697.9" y2="730" />
            <line className="cls-8" x1="710.4" y1="672.6" x2="710.4" y2="730" />
            <line className="cls-8" x1="723" y1="672.6" x2="723" y2="730" />
          </g>
        </g>
      </g>
      <g className="_2fl_bookstands" data-name="2fl_bookstands">
        <rect
          id="_1.1.1"
          data-name="1.1.1"
          className="cls-7"
          x="647.7"
          y="752"
          width="12"
          height="50"
        />
        <rect
          id="_1.1.2"
          data-name="1.1.2"
          className="cls-7"
          x="647.7"
          y="802"
          width="12"
          height="50"
        />
        <rect
          id="_1.1.3"
          data-name="1.1.3"
          className="cls-7"
          x="647.7"
          y="852"
          width="12"
          height="50"
        />
        <rect
          id="_1.1.4"
          data-name="1.1.4"
          className="cls-7"
          x="647.7"
          y="902"
          width="12"
          height="50"
        />
        <rect
          id="_1.1.5"
          data-name="1.1.5"
          className="cls-7"
          x="647.7"
          y="952"
          width="12"
          height="50"
        />
        <path
          id="_1.1.6"
          data-name="1.1.6"
          className="cls-7"
          d="M647.7,1002h24.1v24.1h0c-13.3,0-24.1-10.8-24.1-24.1Z"
        />
        <rect
          id="_1.1.7"
          data-name="1.1.7"
          className="cls-7"
          x="671.7"
          y="1013.8"
          width="35"
          height="12"
        />
        <rect
          id="_1.1.8"
          data-name="1.1.8"
          className="cls-7"
          x="706.7"
          y="1013.8"
          width="35"
          height="12"
        />
        <rect
          id="_1.1.9"
          data-name="1.1.9"
          className="cls-7"
          x="741.7"
          y="1013.8"
          width="35"
          height="12"
        />
        <rect
          id="_1.2.1"
          data-name="1.2.1"
          className="cls-7"
          x="659.7"
          y="752"
          width="12"
          height="50"
        />
        <rect
          id="_1.2.2"
          data-name="1.2.2"
          className="cls-7"
          x="659.7"
          y="802"
          width="12"
          height="50"
        />
        <rect
          id="_1.2.3"
          data-name="1.2.3"
          className="cls-7"
          x="659.7"
          y="852"
          width="12"
          height="50"
        />
        <rect
          id="_1.2.4"
          data-name="1.2.4"
          className="cls-7"
          x="659.7"
          y="902"
          width="12"
          height="50"
        />
        <rect
          id="_1.2.5"
          data-name="1.2.5"
          className="cls-7"
          x="659.7"
          y="952"
          width="12"
          height="50"
        />
        <rect
          id="_1.2.6"
          data-name="1.2.6"
          className="cls-7"
          x="671.7"
          y="1002"
          width="35"
          height="12"
        />
        <rect
          id="_1.2.7"
          data-name="1.2.7"
          className="cls-7"
          x="706.7"
          y="1002"
          width="35"
          height="12"
        />
        <rect
          id="_1.2.8"
          data-name="1.2.8"
          className="cls-7"
          x="741.7"
          y="1002"
          width="35"
          height="12"
        />
        <rect
          id="_1.3"
          data-name="1.3"
          className="cls-7"
          x="707"
          y="751.1"
          width="35"
          height="12"
        />
        <rect
          id="_1.4"
          data-name="1.4"
          className="cls-7"
          x="741.4"
          y="751.1"
          width="35"
          height="12"
        />
        <rect
          id="_1.5"
          data-name="1.5"
          className="cls-7"
          x="780.5"
          y="796"
          width="12"
          height="35"
        />
        <rect
          id="_1.6"
          data-name="1.6"
          className="cls-7"
          x="780.4"
          y="830.1"
          width="12"
          height="35"
        />
        <rect
          id="_1.7"
          data-name="1.7"
          className="cls-7"
          x="741.4"
          y="870"
          width="35"
          height="12"
        />
        <rect
          id="_1.8"
          data-name="1.8"
          className="cls-7"
          x="741.4"
          y="881.8"
          width="35"
          height="12"
        />
        <rect
          id="_1.9"
          data-name="1.9"
          className="cls-7"
          x="741.4"
          y="931"
          width="35"
          height="12"
        />
        <rect
          id="_1.10"
          data-name="1.10"
          className="cls-7"
          x="741.4"
          y="942.8"
          width="35"
          height="12"
        />
        <rect
          id="_2.1.1"
          data-name="2.1.1"
          className="cls-7"
          x="637.6"
          y="1063.9"
          width="35"
          height="12"
        />
        <rect
          id="_2.1.2"
          data-name="2.1.2"
          className="cls-7"
          x="672.6"
          y="1063.9"
          width="35"
          height="12"
        />
        <rect
          id="_2.1.3"
          data-name="2.1.3"
          className="cls-7"
          x="707.6"
          y="1063.9"
          width="35"
          height="12"
        />
        <rect
          id="_2.1.4"
          data-name="2.1.4"
          className="cls-7"
          x="742"
          y="1064"
          width="35"
          height="12"
        />
        <rect
          id="_2.2.1"
          data-name="2.2.1"
          className="cls-7"
          x="637.7"
          y="1075.7"
          width="35"
          height="12"
        />
        <rect
          id="_2.2.2"
          data-name="2.2.2"
          className="cls-7"
          x="672.7"
          y="1075.7"
          width="35"
          height="12"
        />
        <rect
          id="_2.2.3"
          data-name="2.2.3"
          className="cls-7"
          x="707.7"
          y="1075.7"
          width="35"
          height="12"
        />
        <rect
          id="_2.2.4"
          data-name="2.2.4"
          className="cls-7"
          x="742"
          y="1075.8"
          width="35"
          height="12"
        />
        <rect
          id="_3.1.1"
          data-name="3.1.1"
          className="cls-7"
          x="636.8"
          y="1110.9"
          width="35"
          height="12"
        />
        <rect
          id="_3.1.2"
          data-name="3.1.2"
          className="cls-7"
          x="671.8"
          y="1110.9"
          width="35"
          height="12"
        />
        <rect
          id="_3.1.3"
          data-name="3.1.3"
          className="cls-7"
          x="706.8"
          y="1110.9"
          width="35"
          height="12"
        />
        <rect
          id="_3.2.1"
          data-name="3.2.1"
          className="cls-7"
          x="636.8"
          y="1122.7"
          width="35"
          height="12"
        />
        <rect
          id="_3.2.2"
          data-name="3.2.2"
          className="cls-7"
          x="671.8"
          y="1122.7"
          width="35"
          height="12"
        />
        <rect
          id="_3.2.3"
          data-name="3.2.3"
          className="cls-7"
          x="706.8"
          y="1122.7"
          width="35"
          height="12"
        />
        <rect
          id="_4.1.1"
          data-name="4.1.1"
          className="cls-7"
          x="637.6"
          y="1158.6"
          width="35"
          height="12"
        />
        <rect
          id="_4.1.2"
          data-name="4.1.2"
          className="cls-7"
          x="672.6"
          y="1158.6"
          width="35"
          height="12"
        />
        <rect
          id="_4.1.3"
          data-name="4.1.3"
          className="cls-7"
          x="707.6"
          y="1158.6"
          width="35"
          height="12"
        />
        <rect
          id="_4.2.1"
          data-name="4.2.1"
          className="cls-7"
          x="637.6"
          y="1170.4"
          width="35"
          height="12"
        />
        <rect
          id="_4.2.2"
          data-name="4.2.2"
          className="cls-7"
          x="672.6"
          y="1170.4"
          width="35"
          height="12"
        />
        <rect
          id="_4.2.3"
          data-name="4.2.3"
          className="cls-7"
          x="707.6"
          y="1170.4"
          width="35"
          height="12"
        />
        <rect
          id="_5.1.1"
          data-name="5.1.1"
          className="cls-7"
          x="637.6"
          y="1202.7"
          width="35"
          height="12"
        />
        <rect
          id="_5.1.2"
          data-name="5.1.2"
          className="cls-7"
          x="672.6"
          y="1202.7"
          width="35"
          height="12"
        />
        <rect
          id="_5.1.3"
          data-name="5.1.3"
          className="cls-7"
          x="707.6"
          y="1202.7"
          width="35"
          height="12"
        />
        <rect
          id="_5.2.1"
          data-name="5.2.1"
          className="cls-7"
          x="637.6"
          y="1214.5"
          width="35"
          height="12"
        />
        <rect
          id="_5.2.2"
          data-name="5.2.2"
          className="cls-7"
          x="672.6"
          y="1214.5"
          width="35"
          height="12"
        />
        <rect
          id="_5.2.3"
          data-name="5.2.3"
          className="cls-7"
          x="707.6"
          y="1214.5"
          width="35"
          height="12"
        />
        <rect
          id="_6.1.1"
          data-name="6.1.1"
          className="cls-7"
          x="637.7"
          y="1253.3"
          width="35"
          height="12"
        />
        <rect
          id="_6.1.2"
          data-name="6.1.2"
          className="cls-7"
          x="672.7"
          y="1253.3"
          width="35"
          height="12"
        />
        <rect
          id="_6.1.3"
          data-name="6.1.3"
          className="cls-7"
          x="707.7"
          y="1253.3"
          width="35"
          height="12"
        />
        <rect
          id="_6.2.1"
          data-name="6.2.1"
          className="cls-7"
          x="637.7"
          y="1265.1"
          width="35"
          height="12"
        />
        <rect
          id="_6.2.2"
          data-name="6.2.2"
          className="cls-7"
          x="672.7"
          y="1265.1"
          width="35"
          height="12"
        />
        <rect
          id="_6.2.3"
          data-name="6.2.3"
          className="cls-7"
          x="707.7"
          y="1265.1"
          width="35"
          height="12"
        />
        <rect
          id="_7.1.1-2"
          data-name="7.1.1"
          className="cls-7"
          x="637.6"
          y="1308.3"
          width="35"
          height="12"
        />
        <rect
          id="_7.1.2-2"
          data-name="7.1.2"
          className="cls-7"
          x="672.6"
          y="1308.3"
          width="35"
          height="12"
        />
        <rect
          id="_7.1.3-2"
          data-name="7.1.3"
          className="cls-7"
          x="707.6"
          y="1308.3"
          width="35"
          height="12"
        />
        <rect
          id="_7.2.1-2"
          data-name="7.2.1"
          className="cls-7"
          x="637.6"
          y="1320.1"
          width="35"
          height="12"
        />
        <rect
          id="_7.2.2-2"
          data-name="7.2.2"
          className="cls-7"
          x="672.6"
          y="1320.1"
          width="35"
          height="12"
        />
        <rect
          id="_7.2.3-2"
          data-name="7.2.3"
          className="cls-7"
          x="707.6"
          y="1320.1"
          width="35"
          height="12"
        />
        <rect
          id="_8.1.1-2"
          data-name="8.1.1"
          className="cls-7"
          x="636.7"
          y="1361"
          width="35"
          height="12"
        />
        <rect
          id="_8.1.2-2"
          data-name="8.1.2"
          className="cls-7"
          x="671.7"
          y="1361"
          width="35"
          height="12"
        />
        <rect
          id="_8.1.3-2"
          data-name="8.1.3"
          className="cls-7"
          x="706.7"
          y="1361"
          width="35"
          height="12"
        />
        <rect
          id="_8.2.1-2"
          data-name="8.2.1"
          className="cls-7"
          x="636.7"
          y="1372.8"
          width="35"
          height="12"
        />
        <rect
          id="_8.2.2-2"
          data-name="8.2.2"
          className="cls-7"
          x="671.7"
          y="1372.8"
          width="35"
          height="12"
        />
        <rect
          id="_8.2.3-2"
          data-name="8.2.3"
          className="cls-7"
          x="706.7"
          y="1372.8"
          width="35"
          height="12"
        />
        <rect
          id="_9.1.1"
          data-name="9.1.1"
          className="cls-7"
          x="621.7"
          y="1408.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.1.2-2"
          data-name="9.1.2"
          className="cls-7"
          x="652"
          y="1408.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.1.3-2"
          data-name="9.1.3"
          className="cls-7"
          x="682"
          y="1408.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.1.4-2"
          data-name="9.1.4"
          className="cls-7"
          x="712"
          y="1408.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.2.1-2"
          data-name="9.2.1"
          className="cls-7"
          x="621.7"
          y="1420.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.2.2-2"
          data-name="9.2.2"
          className="cls-7"
          x="652"
          y="1420.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.2.3-2"
          data-name="9.2.3"
          className="cls-7"
          x="682"
          y="1420.1"
          width="30"
          height="12"
        />
        <rect
          id="_9.2.4-2"
          data-name="9.2.4"
          className="cls-7"
          x="712"
          y="1420.1"
          width="30"
          height="12"
        />
        <rect
          id="_10.1.1"
          data-name="10.1.1"
          className="cls-7"
          x="637.6"
          y="1460.1"
          width="35"
          height="12"
        />
        <rect
          id="_10.1.2"
          data-name="10.1.2"
          className="cls-7"
          x="672.6"
          y="1460.1"
          width="35"
          height="12"
        />
        <rect
          id="_10.1.3"
          data-name="10.1.3"
          className="cls-7"
          x="707.6"
          y="1460.1"
          width="35"
          height="12"
        />
        <rect
          id="_10.2.1"
          data-name="10.2.1"
          className="cls-7"
          x="637.6"
          y="1471.9"
          width="35"
          height="12"
        />
        <rect
          id="_10.2.2"
          data-name="10.2.2"
          className="cls-7"
          x="672.6"
          y="1471.9"
          width="35"
          height="12"
        />
        <rect
          id="_10.2.3"
          data-name="10.2.3"
          className="cls-7"
          x="707.6"
          y="1471.9"
          width="35"
          height="12"
        />
        <rect
          id="_11"
          data-name="11"
          className="cls-7"
          x="770.5"
          y="1503.9"
          width="12"
          height="30"
        />
        <rect
          id="_26.1.1"
          data-name="26.1.1"
          className="cls-7"
          x="461.9"
          y="752"
          width="12"
          height="35"
        />
        <rect
          id="_26.1.2"
          data-name="26.1.2"
          className="cls-7"
          x="461.9"
          y="787"
          width="12"
          height="35"
        />
        <rect
          id="_26.1.3"
          data-name="26.1.3"
          className="cls-7"
          x="461.9"
          y="822"
          width="12"
          height="35"
        />
        <rect
          id="_26.1.4"
          data-name="26.1.4"
          className="cls-7"
          x="461.9"
          y="857"
          width="12"
          height="35"
        />
        <path
          id="_26.1.5"
          data-name="26.1.5"
          className="cls-7"
          d="M449.8,916.1h0v-24.1h24.1c0,13.3-10.8,24.1-24.1,24.1Z"
        />
        <rect
          id="_26.1.6"
          data-name="26.1.6"
          className="cls-7"
          x="414.5"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.7"
          data-name="26.1.7"
          className="cls-7"
          x="379.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.8"
          data-name="26.1.8"
          className="cls-7"
          x="344.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.9"
          data-name="26.1.9"
          className="cls-7"
          x="309.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.10"
          data-name="26.1.10"
          className="cls-7"
          x="274.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.11"
          data-name="26.1.11"
          className="cls-7"
          x="239.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.12"
          data-name="26.1.12"
          className="cls-7"
          x="204.7"
          y="904"
          width="35"
          height="12"
        />
        <rect
          id="_26.1.13"
          data-name="26.1.13"
          className="cls-7"
          x="182.7"
          y="904"
          width="22"
          height="12"
        />
        <rect
          id="_26.2.1"
          data-name="26.2.1"
          className="cls-7"
          x="449.9"
          y="752"
          width="12"
          height="35"
        />
        <rect
          id="_26.2.2"
          data-name="26.2.2"
          className="cls-7"
          x="449.9"
          y="787"
          width="12"
          height="35"
        />
        <rect
          id="_26.2.3"
          data-name="26.2.3"
          className="cls-7"
          x="449.9"
          y="822"
          width="12"
          height="35"
        />
        <rect
          id="_26.2.4"
          data-name="26.2.4"
          className="cls-7"
          x="449.9"
          y="857"
          width="12"
          height="35"
        />
        <rect
          id="_26.2.5"
          data-name="26.2.5"
          className="cls-7"
          x="414.5"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.6"
          data-name="26.2.6"
          className="cls-7"
          x="379.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.7"
          data-name="26.2.7"
          className="cls-7"
          x="344.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.8"
          data-name="26.2.8"
          className="cls-7"
          x="309.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.9"
          data-name="26.2.9"
          className="cls-7"
          x="274.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.10"
          data-name="26.2.10"
          className="cls-7"
          x="239.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.11"
          data-name="26.2.11"
          className="cls-7"
          x="204.7"
          y="892"
          width="35"
          height="12"
        />
        <rect
          id="_26.2.12"
          data-name="26.2.12"
          className="cls-7"
          x="182.7"
          y="892"
          width="22"
          height="12"
        />
        <rect
          id="_26.3"
          data-name="26.3"
          className="cls-7"
          x="437.3"
          y="695.2"
          width="12"
          height="56"
        />
        <rect
          id="_26.4"
          data-name="26.4"
          className="cls-7"
          x="437.3"
          y="639.2"
          width="12"
          height="56"
        />
        <rect
          id="_26.5"
          data-name="26.5"
          className="cls-7"
          x="412.1"
          y="626.7"
          width="25"
          height="12"
        />
        <rect
          id="_26.6"
          data-name="26.6"
          className="cls-7"
          x="412.1"
          y="601.7"
          width="12"
          height="25"
        />
        <rect
          id="_26.7"
          data-name="26.7"
          className="cls-7"
          x="412.1"
          y="576.7"
          width="12"
          height="25"
        />
        <rect
          id="_26.8"
          data-name="26.8"
          className="cls-7"
          x="412.1"
          y="551.7"
          width="12"
          height="25"
        />
        <rect
          id="_26.9"
          data-name="26.9"
          className="cls-15"
          x="302.8"
          y="563.5"
          width="25"
          height="12"
          transform="translate(-68.9 44) rotate(-7.2)"
        />
        <rect
          id="_26.10"
          data-name="26.10"
          className="cls-15"
          x="277.5"
          y="566.7"
          width="25"
          height="12"
          transform="translate(-69.5 40.9) rotate(-7.2)"
        />
        <rect
          id="_26.11"
          data-name="26.11"
          className="cls-7"
          x="266.6"
          y="580.9"
          width="12"
          height="38"
        />
        <rect
          id="_26.12"
          data-name="26.12"
          className="cls-7"
          x="254"
          y="619.5"
          width="25"
          height="12"
        />
        <rect
          id="_23.1.5-2"
          data-name="23.1.5"
          className="cls-7"
          x="387.9"
          y="1132.2"
          width="38"
          height="12"
        />
        <rect
          id="_23.1.3-2"
          data-name="23.1.3"
          className="cls-7"
          x="463.9"
          y="1132.2"
          width="38"
          height="12"
        />
        <rect
          id="_23.1.2-2"
          data-name="23.1.2"
          className="cls-7"
          x="501.9"
          y="1132.2"
          width="38"
          height="12"
        />
        <rect
          id="_23.1.1-2"
          data-name="23.1.1"
          className="cls-7"
          x="539.9"
          y="1132.2"
          width="38"
          height="12"
        />
        <rect
          id="_23.2.1-2"
          data-name="23.2.1"
          className="cls-7"
          x="539.9"
          y="1144.4"
          width="38"
          height="12"
        />
        <rect
          id="_23.2.2-2"
          data-name="23.2.2"
          className="cls-7"
          x="501.9"
          y="1144.4"
          width="38"
          height="12"
        />
        <rect
          id="_23.2.3-2"
          data-name="23.2.3"
          className="cls-7"
          x="463.9"
          y="1144.4"
          width="38"
          height="12"
        />
        <rect
          id="_23.2.5-2"
          data-name="23.2.5"
          className="cls-7"
          x="387.9"
          y="1144.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.1.1-2"
          data-name="24.1.1"
          className="cls-7"
          x="539.2"
          y="1066.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.1.2-2"
          data-name="24.1.2"
          className="cls-7"
          x="501.2"
          y="1066.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.1.3-2"
          data-name="24.1.3"
          className="cls-7"
          x="463.2"
          y="1066.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.1.4-2"
          data-name="24.1.4"
          className="cls-7"
          x="425.2"
          y="1066.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.1.5-2"
          data-name="24.1.5"
          className="cls-7"
          x="387.2"
          y="1066.4"
          width="38"
          height="12"
        />
        <rect
          id="_24.2.1-2"
          data-name="24.2.1"
          className="cls-7"
          x="539.2"
          y="1078.6"
          width="38"
          height="12"
        />
        <rect
          id="_24.2.2-2"
          data-name="24.2.2"
          className="cls-7"
          x="501.2"
          y="1078.6"
          width="38"
          height="12"
        />
        <rect
          id="_24.2.3-2"
          data-name="24.2.3"
          className="cls-7"
          x="463.2"
          y="1078.6"
          width="38"
          height="12"
        />
        <rect
          id="_24.2.4-2"
          data-name="24.2.4"
          className="cls-7"
          x="425.2"
          y="1078.6"
          width="38"
          height="12"
        />
        <rect
          id="_24.2.5-2"
          data-name="24.2.5"
          className="cls-7"
          x="387.2"
          y="1078.6"
          width="38"
          height="12"
        />
        <rect
          id="_25.1.1"
          data-name="25.1.1"
          className="cls-7"
          x="539.2"
          y="1004"
          width="38"
          height="12"
        />
        <rect
          id="_25.1.2"
          data-name="25.1.2"
          className="cls-7"
          x="501.2"
          y="1004"
          width="38"
          height="12"
        />
        <rect
          id="_25.1.3"
          data-name="25.1.3"
          className="cls-7"
          x="463.2"
          y="1004"
          width="38"
          height="12"
        />
        <rect
          id="_25.1.4"
          data-name="25.1.4"
          className="cls-7"
          x="425.2"
          y="1004"
          width="38"
          height="12"
        />
        <rect
          id="_25.1.5"
          data-name="25.1.5"
          className="cls-7"
          x="387.2"
          y="1004"
          width="38"
          height="12"
        />
        <rect
          id="_25.2.1"
          data-name="25.2.1"
          className="cls-7"
          x="539.2"
          y="1016.2"
          width="38"
          height="12"
        />
        <rect
          id="_25.2.2"
          data-name="25.2.2"
          className="cls-7"
          x="501.2"
          y="1016.2"
          width="38"
          height="12"
        />
        <rect
          id="_25.2.3"
          data-name="25.2.3"
          className="cls-7"
          x="463.2"
          y="1016.2"
          width="38"
          height="12"
        />
        <rect
          id="_25.2.4"
          data-name="25.2.4"
          className="cls-7"
          x="425.2"
          y="1016.2"
          width="38"
          height="12"
        />
        <rect
          id="_25.2.5"
          data-name="25.2.5"
          className="cls-7"
          x="387.2"
          y="1016.2"
          width="38"
          height="12"
        />
        <rect
          id="_21.1.4-2"
          data-name="21.1.4"
          className="cls-7"
          x="387.2"
          y="1320.3"
          width="38"
          height="12"
        />
        <rect
          id="_21.1.3-2"
          data-name="21.1.3"
          className="cls-7"
          x="425.2"
          y="1320.3"
          width="38"
          height="12"
        />
        <rect
          id="_21.2.4-2"
          data-name="21.2.4"
          className="cls-7"
          x="387.2"
          y="1332.5"
          width="38"
          height="12"
        />
        <rect
          id="_20.1.4-2"
          data-name="20.1.4"
          className="cls-7"
          x="464"
          y="1387.4"
          width="38"
          height="12"
        />
        <rect
          id="_20.1.5-2"
          data-name="20.1.5"
          className="cls-7"
          x="426"
          y="1387.4"
          width="38"
          height="12"
        />
        <rect
          id="_20.1.6-2"
          data-name="20.1.6"
          className="cls-7"
          x="388"
          y="1387.4"
          width="38"
          height="12"
        />
        <rect
          id="_21.2.3-2"
          data-name="21.2.3"
          className="cls-7"
          x="425.2"
          y="1332.5"
          width="38"
          height="12"
        />
        <rect
          id="_20.2.4-2"
          data-name="20.2.4"
          className="cls-7"
          x="463.9"
          y="1399.6"
          width="38"
          height="12"
        />
        <rect
          id="_20.2.5-2"
          data-name="20.2.5"
          className="cls-7"
          x="425.9"
          y="1399.6"
          width="38"
          height="12"
        />
        <rect
          id="_20.2.6-2"
          data-name="20.2.6"
          className="cls-7"
          x="387.9"
          y="1399.6"
          width="38"
          height="12"
        />
        <rect
          id="_19.1.4-2"
          data-name="19.1.4"
          className="cls-7"
          x="387.2"
          y="1454.5"
          width="38"
          height="12"
        />
        <rect
          id="_19.1.3-2"
          data-name="19.1.3"
          className="cls-7"
          x="463.2"
          y="1454.5"
          width="38"
          height="12"
        />
        <rect
          id="_19.1.2-2"
          data-name="19.1.2"
          className="cls-7"
          x="501.2"
          y="1454.5"
          width="38"
          height="12"
        />
        <rect
          id="_19.1.1-2"
          data-name="19.1.1"
          className="cls-7"
          x="539.2"
          y="1454.5"
          width="38"
          height="12"
        />
        <rect
          id="_19.2.4-2"
          data-name="19.2.4"
          className="cls-7"
          x="387.2"
          y="1466.7"
          width="38"
          height="12"
        />
        <rect
          id="_19.2.3-2"
          data-name="19.2.3"
          className="cls-7"
          x="463.2"
          y="1466.7"
          width="38"
          height="12"
        />
        <rect
          id="_19.2.2-2"
          data-name="19.2.2"
          className="cls-7"
          x="501.2"
          y="1466.7"
          width="38"
          height="12"
        />
        <rect
          id="_19.2.1-2"
          data-name="19.2.1"
          className="cls-7"
          x="539.2"
          y="1466.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.1.5-2"
          data-name="18.1.5"
          className="cls-7"
          x="387.2"
          y="1513.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.1.4-2"
          data-name="18.1.4"
          className="cls-7"
          x="425.2"
          y="1513.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.1.3-2"
          data-name="18.1.3"
          className="cls-7"
          x="463.2"
          y="1513.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.1.2-2"
          data-name="18.1.2"
          className="cls-7"
          x="501.2"
          y="1513.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.1.1-2"
          data-name="18.1.1"
          className="cls-7"
          x="539.2"
          y="1513.7"
          width="38"
          height="12"
        />
        <rect
          id="_18.2.5-2"
          data-name="18.2.5"
          className="cls-7"
          x="387.2"
          y="1525.9"
          width="38"
          height="12"
        />
        <rect
          id="_18.2.4-2"
          data-name="18.2.4"
          className="cls-7"
          x="425.2"
          y="1525.9"
          width="38"
          height="12"
        />
        <rect
          id="_18.2.3-2"
          data-name="18.2.3"
          className="cls-7"
          x="463.2"
          y="1525.9"
          width="38"
          height="12"
        />
        <rect
          id="_18.2.2-2"
          data-name="18.2.2"
          className="cls-7"
          x="501.2"
          y="1525.9"
          width="38"
          height="12"
        />
        <rect
          id="_18.2.1-2"
          data-name="18.2.1"
          className="cls-7"
          x="539.2"
          y="1525.9"
          width="38"
          height="12"
        />
        <rect
          id="_17.1.5-2"
          data-name="17.1.5"
          className="cls-7"
          x="387.2"
          y="1580.2"
          width="38"
          height="12"
        />
        <rect
          id="_17.1.4-2"
          data-name="17.1.4"
          className="cls-7"
          x="425.2"
          y="1580.2"
          width="38"
          height="12"
        />
        <rect
          id="_17.1.3-2"
          data-name="17.1.3"
          className="cls-7"
          x="463.2"
          y="1580.2"
          width="38"
          height="12"
        />
        <rect
          id="_17.1.2-2"
          data-name="17.1.2"
          className="cls-7"
          x="501.2"
          y="1580.2"
          width="38"
          height="12"
        />
        <rect
          id="_17.1.1-2"
          data-name="17.1.1"
          className="cls-7"
          x="539.2"
          y="1580.2"
          width="38"
          height="12"
        />
        <rect
          id="_17.2.5-2"
          data-name="17.2.5"
          className="cls-7"
          x="387.2"
          y="1592.4"
          width="38"
          height="12"
        />
        <rect
          id="_17.2.4-2"
          data-name="17.2.4"
          className="cls-7"
          x="425.2"
          y="1592.4"
          width="38"
          height="12"
        />
        <rect
          id="_17.2.3-2"
          data-name="17.2.3"
          className="cls-7"
          x="463.2"
          y="1592.4"
          width="38"
          height="12"
        />
        <rect
          id="_17.2.2-2"
          data-name="17.2.2"
          className="cls-7"
          x="501.2"
          y="1592.4"
          width="38"
          height="12"
        />
        <rect
          id="_17.2.1-2"
          data-name="17.2.1"
          className="cls-7"
          x="539.2"
          y="1592.4"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.5-2"
          data-name="14.1.5"
          className="cls-7"
          x="387.9"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.4-2"
          data-name="14.1.4"
          className="cls-7"
          x="425.9"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.3-2"
          data-name="14.1.3"
          className="cls-7"
          x="463.9"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.2-2"
          data-name="14.1.2"
          className="cls-7"
          x="501.9"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.1-2"
          data-name="14.1.1"
          className="cls-7"
          x="539.9"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.5-2"
          data-name="14.2.5"
          className="cls-7"
          x="387.9"
          y="1775.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.4-2"
          data-name="14.2.4"
          className="cls-7"
          x="425.9"
          y="1775.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.3-2"
          data-name="14.2.3"
          className="cls-7"
          x="463.9"
          y="1775.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.2-2"
          data-name="14.2.2"
          className="cls-7"
          x="501.9"
          y="1775.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.1-2"
          data-name="14.2.1"
          className="cls-7"
          x="539.9"
          y="1775.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.9"
          data-name="14.1.9"
          className="cls-7"
          x="235.2"
          y="1763.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.8"
          data-name="14.1.8"
          className="cls-7"
          x="273.2"
          y="1763.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.7"
          data-name="14.1.7"
          className="cls-7"
          x="311.2"
          y="1763.8"
          width="38"
          height="12"
        />
        <rect
          id="_14.1.6-2"
          data-name="14.1.6"
          className="cls-7"
          x="349.2"
          y="1763.6"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.9"
          data-name="14.2.9"
          className="cls-7"
          x="235.2"
          y="1776"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.8"
          data-name="14.2.8"
          className="cls-7"
          x="273.2"
          y="1776"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.7"
          data-name="14.2.7"
          className="cls-7"
          x="311.2"
          y="1776"
          width="38"
          height="12"
        />
        <rect
          id="_14.2.6-2"
          data-name="14.2.6"
          className="cls-7"
          x="349.2"
          y="1776"
          width="23"
          height="12"
        />
        <rect
          id="_17.1.6-2"
          data-name="17.1.6"
          className="cls-7"
          x="368.2"
          y="1580.2"
          width="19"
          height="12"
        />
        <rect
          id="_17.2.6-2"
          data-name="17.2.6"
          className="cls-7"
          x="368.2"
          y="1592.4"
          width="19"
          height="12"
        />
        <rect
          id="_16.1.5-2"
          data-name="16.1.5"
          className="cls-7"
          x="387.2"
          y="1641.9"
          width="38"
          height="12"
        />
        <rect
          id="_16.1.4-2"
          data-name="16.1.4"
          className="cls-7"
          x="425.2"
          y="1641.9"
          width="38"
          height="12"
        />
        <rect
          id="_16.1.3-2"
          data-name="16.1.3"
          className="cls-7"
          x="463.2"
          y="1641.9"
          width="38"
          height="12"
        />
        <rect
          id="_16.1.2-2"
          data-name="16.1.2"
          className="cls-7"
          x="501.2"
          y="1641.9"
          width="38"
          height="12"
        />
        <rect
          id="_16.1.1-2"
          data-name="16.1.1"
          className="cls-7"
          x="539.2"
          y="1641.9"
          width="38"
          height="12"
        />
        <rect
          id="_16.2.5-2"
          data-name="16.2.5"
          className="cls-7"
          x="387.2"
          y="1654.1"
          width="38"
          height="12"
        />
        <rect
          id="_16.2.4-2"
          data-name="16.2.4"
          className="cls-7"
          x="425.2"
          y="1654.1"
          width="38"
          height="12"
        />
        <rect
          id="_16.2.3-2"
          data-name="16.2.3"
          className="cls-7"
          x="463.2"
          y="1654.1"
          width="38"
          height="12"
        />
        <rect
          id="_16.2.2-2"
          data-name="16.2.2"
          className="cls-7"
          x="501.2"
          y="1654.1"
          width="38"
          height="12"
        />
        <rect
          id="_16.2.1-2"
          data-name="16.2.1"
          className="cls-7"
          x="539.2"
          y="1654.1"
          width="38"
          height="12"
        />
        <rect
          id="_16.5"
          data-name="16.5"
          className="cls-7"
          x="360.2"
          y="1644.1"
          width="12"
          height="19"
        />
        <rect
          id="_16.3"
          data-name="16.3"
          className="cls-7"
          x="592.6"
          y="1644.1"
          width="12"
          height="19"
        />
        <rect
          id="_15.1.6-2"
          data-name="15.1.6"
          className="cls-7"
          x="360.6"
          y="1696.6"
          width="38"
          height="12"
        />
        <rect
          id="_15.1.5-2"
          data-name="15.1.5"
          className="cls-7"
          x="398.6"
          y="1696.6"
          width="38"
          height="12"
        />
        <rect
          id="_15.1.4-2"
          data-name="15.1.4"
          className="cls-7"
          x="436.6"
          y="1696.6"
          width="38"
          height="12"
        />
        <rect
          id="_15.1.3-2"
          data-name="15.1.3"
          className="cls-7"
          x="474.6"
          y="1696.6"
          width="38"
          height="12"
        />
        <rect
          id="_15.1.2-2"
          data-name="15.1.2"
          className="cls-7"
          x="512.6"
          y="1696.6"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.6-2"
          data-name="15.2.6"
          className="cls-7"
          x="360.6"
          y="1708.8"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.5-2"
          data-name="15.2.5"
          className="cls-7"
          x="398.6"
          y="1708.8"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.4-2"
          data-name="15.2.4"
          className="cls-7"
          x="436.6"
          y="1708.8"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.3-2"
          data-name="15.2.3"
          className="cls-7"
          x="474.6"
          y="1708.8"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.2-2"
          data-name="15.2.2"
          className="cls-7"
          x="512.6"
          y="1708.8"
          width="38"
          height="12"
        />
        <rect
          id="_15.1.1-2"
          data-name="15.1.1"
          className="cls-7"
          x="550.6"
          y="1696.7"
          width="38"
          height="12"
        />
        <rect
          id="_15.2.1-2"
          data-name="15.2.1"
          className="cls-7"
          x="550.7"
          y="1708.9"
          width="38"
          height="12"
        />
        <rect
          id="c2"
          className="cls-7"
          x="588.7"
          y="1696.5"
          width="25"
          height="25"
        />
        <rect
          id="c1"
          className="cls-7"
          x="572.6"
          y="852"
          width="25"
          height="25"
        />
        <rect
          id="_0.1"
          data-name="0.1"
          className="cls-5"
          x="425.2"
          y="1190.7"
          width="38"
          height="24"
        />
        <rect
          id="_22.1.1-2"
          data-name="22.1.1"
          className="cls-7"
          x="539.2"
          y="1190.5"
          width="38"
          height="12"
        />
        <rect
          id="_22.1.2-2"
          data-name="22.1.2"
          className="cls-7"
          x="501.2"
          y="1190.5"
          width="38"
          height="12"
        />
        <rect
          id="_22.1.3-2"
          data-name="22.1.3"
          className="cls-7"
          x="463.2"
          y="1190.5"
          width="38"
          height="12"
        />
        <rect
          id="_22.1.4-2"
          data-name="22.1.4"
          className="cls-7"
          x="387.2"
          y="1190.5"
          width="38"
          height="12"
        />
        <rect
          id="_23.1.4-2"
          data-name="23.1.4"
          className="cls-7"
          x="425.9"
          y="1132.3"
          width="38"
          height="12"
        />
        <rect
          id="_22.2.1-2"
          data-name="22.2.1"
          className="cls-7"
          x="539.2"
          y="1202.7"
          width="38"
          height="12"
        />
        <rect
          id="_22.2.2-2"
          data-name="22.2.2"
          className="cls-7"
          x="501.2"
          y="1202.7"
          width="38"
          height="12"
        />
        <rect
          id="_22.2.3-2"
          data-name="22.2.3"
          className="cls-7"
          x="463.2"
          y="1202.7"
          width="38"
          height="12"
        />
        <rect
          id="_22.2.4-2"
          data-name="22.2.4"
          className="cls-7"
          x="387.2"
          y="1202.7"
          width="38"
          height="12"
        />
        <rect
          id="_23.2.4-2"
          data-name="23.2.4"
          className="cls-7"
          x="425.9"
          y="1144.6"
          width="38"
          height="12"
        />
        <rect
          id="_24.3"
          data-name="24.3"
          className="cls-7"
          x="375.2"
          y="1065.9"
          width="12"
          height="25"
        />
        <rect
          id="_0.3-2"
          data-name="0.3"
          className="cls-5"
          x="425.2"
          y="1454.7"
          width="38"
          height="24"
        />
        <rect
          id="_0.2"
          data-name="0.2"
          className="cls-5"
          x="488.4"
          y="1258.1"
          width="66"
          height="24"
        />
        <rect
          id="_13.3"
          data-name="13.3"
          className="cls-13"
          x="345"
          y="2012.7"
          width="45"
          height="12"
          transform="translate(-62.8 12.5) rotate(-1.8)"
        />
        <rect
          id="_13.1"
          data-name="13.1"
          className="cls-7"
          x="725.1"
          y="1774"
          width="60"
          height="12"
        />
        <rect
          id="_12.7"
          data-name="12.7"
          className="cls-7"
          x="705.1"
          y="1716.1"
          width="12"
          height="30"
        />
        <rect
          id="_12.6"
          data-name="12.6"
          className="cls-7"
          x="705.1"
          y="1686.1"
          width="12"
          height="30"
        />
        <rect
          id="_12.5"
          data-name="12.5"
          className="cls-7"
          x="705.1"
          y="1656.1"
          width="12"
          height="30"
        />
        <path
          id="_12.4"
          data-name="12.4"
          className="cls-7"
          d="M717.1,1656.1h-12c0-6.6,5.4-12,12-12h0v12Z"
        />
        <path
          id="_12.8"
          data-name="12.8"
          className="cls-7"
          d="M717.1,1758.1h0c-6.6,0-12-5.4-12-12h12v12Z"
        />
        <rect
          id="_12.3"
          data-name="12.3"
          className="cls-7"
          x="717.1"
          y="1644.1"
          width="20"
          height="12"
        />
        <rect
          id="_12.2"
          data-name="12.2"
          className="cls-7"
          x="737.1"
          y="1644.1"
          width="20"
          height="12"
        />
        <rect
          id="_12.1"
          data-name="12.1"
          className="cls-7"
          x="757.1"
          y="1644.1"
          width="20"
          height="12"
        />
        <rect
          id="_13.2"
          data-name="13.2"
          className="cls-18"
          x="541.2"
          y="1989.2"
          width="45"
          height="12"
          transform="translate(-272.2 97.9) rotate(-8)"
        />
        <rect
          id="_20.1.1-2"
          data-name="20.1.1"
          className="cls-7"
          x="526.1"
          y="1325.1"
          width="38"
          height="12"
        />
        <path
          id="_20.1.2-2"
          data-name="20.1.2"
          className="cls-7"
          d="M525.9,1349.1h-24c0-13.3,10.7-24,24-24h0v24Z"
        />
        <rect
          id="_20.1.3-2"
          data-name="20.1.3"
          className="cls-7"
          x="501.9"
          y="1349.4"
          width="12"
          height="38"
        />
        <rect
          id="_20.2.1-2"
          data-name="20.2.1"
          className="cls-7"
          x="526.2"
          y="1337.3"
          width="38"
          height="12"
        />
        <rect
          id="_20.2.2-2"
          data-name="20.2.2"
          className="cls-7"
          x="514.2"
          y="1349.4"
          width="12"
          height="38"
        />
        <path
          id="_20.2.3-2"
          data-name="20.2.3"
          className="cls-7"
          d="M502.2,1411.4h0v-24h24c0,13.2-10.8,24-24,24Z"
        />
        <path
          id="_21.1.1"
          data-name="21.1.1"
          className="cls-7"
          d="M487.9,1282.1h-24c0-13.3,10.7-24,24-24h0v24Z"
        />
        <rect
          id="_21.1.2-2"
          data-name="21.1.2"
          className="cls-7"
          x="463.9"
          y="1282.1"
          width="12"
          height="38"
        />
        <rect
          id="_21.1.5-2"
          data-name="21.1.5"
          className="cls-7"
          x="387.9"
          y="1259"
          width="38"
          height="12"
        />
        <rect
          id="_21.2.1-2"
          data-name="21.2.1"
          className="cls-7"
          x="476.2"
          y="1282.1"
          width="12"
          height="38"
        />
        <path
          id="_21.2.2-2"
          data-name="21.2.2"
          className="cls-7"
          d="M464.2,1344.8h0v-24h24c0,13.2-10.8,24-24,24Z"
        />
        <rect
          id="_21.2.5-2"
          data-name="21.2.5"
          className="cls-7"
          x="387.9"
          y="1271.2"
          width="38"
          height="12"
        />
      </g>
      <g className="_3fl_walls_doors" data-name="3fl_walls_doors">
        <g id="_3fl_18_room" data-name="3fl_18_room">
          <line className="cls-19" x1="304" y1="747" x2="304" y2="554.8" />
          <polygon
            id="_3fl_18_room-2"
            data-name="3fl_18_room"
            className="cls-19"
            points="454.7 747 153.3 747 153.3 573.1 429.6 538.6 429.6 622 454.7 622 454.7 747"
          />
          <g id="_3fl_18_1door" data-name="3fl_18_1door">
            <rect className="cls-9" x="387.2" y="742" width="40" height="10" />
            <line className="cls-10" x1="387.2" y1="742" x2="427.2" y2="752" />
          </g>
          <g id="_3fl_18_2door" data-name="3fl_18_2door">
            <rect className="cls-9" x="227.7" y="742" width="40" height="10" />
            <line className="cls-10" x1="227.7" y1="742" x2="267.7" y2="752" />
          </g>
        </g>
        <g id="_3fl_oit" data-name="3fl_oit">
          <line
            className="cls-19"
            x1="384.2"
            y1="1523.5"
            x2="440"
            y2="1523.5"
          />
          <polygon
            id="_3fl_oit_room"
            data-name="3fl_oit_room"
            className="cls-19"
            points="382.1 2041.8 163.4 2061.1 158.2 1523.5 384.2 1523.5 384.2 1656.4 437.4 1656.4 437.4 1775.7 382.1 1775.7 382.1 2041.8"
          />
          <g id="_3fl_oit_door" data-name="3fl_oit_door">
            <rect
              className="cls-9"
              x="432.4"
              y="1698.5"
              width="10"
              height="40"
            />
            <line
              className="cls-10"
              x1="442.4"
              y1="1698.5"
              x2="432.4"
              y2="1738.5"
            />
          </g>
        </g>
        <g id="_3fl_2lift_room" data-name="3fl_2lift_room">
          <rect
            id="_3fl_2lift_walls"
            data-name="3fl_2lift_walls"
            className="cls-19"
            x="700"
            y="1671.4"
            width="105.9"
            height="94.2"
          />
          <g id="_3fl_2lift_door" data-name="3fl_2lift_door">
            <rect className="cls-9" x="695" y="1698.5" width="10" height="40" />
            <line
              className="cls-10"
              x1="705"
              y1="1698.5"
              x2="695"
              y2="1738.5"
            />
          </g>
        </g>
        <g id="_3fl_orik" data-name="3fl_orik">
          <rect
            id="_3fl_orik_walls"
            data-name="3fl_orik_walls"
            className="cls-19"
            x="552.8"
            y="1282.3"
            width="251.6"
            height="389.1"
          />
          <g id="_3fl_orik_door" data-name="3fl_orik_door">
            <rect
              className="cls-9"
              x="645.2"
              y="1666.4"
              width="40"
              height="10"
            />
            <line
              className="cls-10"
              x1="645.2"
              y1="1666.4"
              x2="685.2"
              y2="1676.4"
            />
          </g>
        </g>
        <g id="_3fl_mainlift" data-name="3fl_mainlift">
          <rect
            id="_3fl_mainlift_walls"
            data-name="3fl_mainlift_walls"
            className="cls-19"
            x="454.7"
            y="622"
            width="86"
            height="125"
          />
          <g id="_3fl_mainlift_door" data-name="3fl_mainlift_door">
            <rect className="cls-9" x="477.7" y="742" width="40" height="10" />
            <line className="cls-10" x1="477.7" y1="742" x2="517.7" y2="752" />
          </g>
        </g>
        <rect
          id="_3fl_toilet_pass"
          data-name="3fl_toilet_pass"
          className="cls-4"
          x="545.9"
          y="593.3"
          width="70"
          height="9.9"
        />
        <g id="_3fl_mainstairs_door" data-name="3fl_mainstairs_door">
          <rect className="cls-9" x="552.6" y="742" width="40" height="10" />
          <line className="cls-10" x1="552.6" y1="752" x2="592.6" y2="742" />
        </g>
        <g id="_3fl_stairs" data-name="3fl_stairs">
          <g id="stairs2-3" data-name="stairs2">
            <rect
              className="cls-8"
              x="882"
              y="1856.2"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1868.6"
              x2="882"
              y2="1868.6"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1880.6"
              x2="882"
              y2="1880.6"
            />
            <line className="cls-8" x1="939.4" y1="1893" x2="882" y2="1893" />
            <line
              className="cls-8"
              x1="939.4"
              y1="1904.1"
              x2="882"
              y2="1904.1"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1915.6"
              x2="882"
              y2="1915.6"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1928.5"
              x2="882"
              y2="1928.5"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1939.7"
              x2="882"
              y2="1939.7"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1952.1"
              x2="882"
              y2="1952.1"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1964.8"
              x2="882"
              y2="1964.8"
            />
            <rect
              className="cls-8"
              x="814.5"
              y="1856.2"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1868.6"
              x2="814.5"
              y2="1868.6"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1880.6"
              x2="814.5"
              y2="1880.6"
            />
            <line className="cls-8" x1="871.9" y1="1893" x2="814.5" y2="1893" />
            <line
              className="cls-8"
              x1="871.9"
              y1="1904.1"
              x2="814.5"
              y2="1904.1"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1915.6"
              x2="814.5"
              y2="1915.6"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1928.5"
              x2="814.5"
              y2="1928.5"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1939.7"
              x2="814.5"
              y2="1939.7"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1952.1"
              x2="814.5"
              y2="1952.1"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1964.8"
              x2="814.5"
              y2="1964.8"
            />
          </g>
          <g id="stairs1-4" data-name="stairs1">
            <rect
              className="cls-8"
              x="614.5"
              y="605"
              width="121.2"
              height="57.5"
            />
            <line className="cls-8" x1="626.9" y1="605" x2="626.9" y2="662.4" />
            <line className="cls-8" x1="639" y1="605" x2="639" y2="662.4" />
            <line className="cls-8" x1="651.3" y1="605" x2="651.3" y2="662.4" />
            <line className="cls-8" x1="662.4" y1="605" x2="662.4" y2="662.4" />
            <line className="cls-8" x1="673.9" y1="605" x2="673.9" y2="662.4" />
            <line className="cls-8" x1="686.8" y1="605" x2="686.8" y2="662.4" />
            <line className="cls-8" x1="698" y1="605" x2="698" y2="662.4" />
            <line className="cls-8" x1="710.5" y1="605" x2="710.5" y2="662.4" />
            <line className="cls-8" x1="723.1" y1="605" x2="723.1" y2="662.4" />
            <rect
              className="cls-8"
              x="614.5"
              y="672.5"
              width="121.2"
              height="57.5"
            />
            <line
              className="cls-8"
              x1="626.9"
              y1="672.5"
              x2="626.9"
              y2="729.9"
            />
            <line className="cls-8" x1="639" y1="672.5" x2="639" y2="729.9" />
            <line
              className="cls-8"
              x1="651.3"
              y1="672.5"
              x2="651.3"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="662.4"
              y1="672.5"
              x2="662.4"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="673.9"
              y1="672.5"
              x2="673.9"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="686.8"
              y1="672.5"
              x2="686.8"
              y2="729.9"
            />
            <line className="cls-8" x1="698" y1="672.5" x2="698" y2="729.9" />
            <line
              className="cls-8"
              x1="710.5"
              y1="672.5"
              x2="710.5"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="723.1"
              y1="672.5"
              x2="723.1"
              y2="729.9"
            />
          </g>
        </g>
      </g>
      <g className="_3fl_bookstands" data-name="3fl_bookstands">
        <rect
          id="_1.1"
          data-name="1.1"
          className="cls-7"
          x="557.7"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_1.2"
          data-name="1.2"
          className="cls-7"
          x="592.7"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_1.3-2"
          data-name="1.3"
          className="cls-7"
          x="627.5"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_1.4-2"
          data-name="1.4"
          className="cls-7"
          x="662.5"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_1.5-2"
          data-name="1.5"
          className="cls-7"
          x="697.5"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_1.6-2"
          data-name="1.6"
          className="cls-7"
          x="732.5"
          y="817.9"
          width="35"
          height="10"
        />
        <rect
          id="_2.1"
          data-name="2.1"
          className="cls-7"
          x="557.7"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_2.2"
          data-name="2.2"
          className="cls-7"
          x="592.7"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_2.3"
          data-name="2.3"
          className="cls-7"
          x="627.5"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_2.4"
          data-name="2.4"
          className="cls-7"
          x="662.5"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_2.5"
          data-name="2.5"
          className="cls-7"
          x="697.5"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_2.6"
          data-name="2.6"
          className="cls-7"
          x="732.5"
          y="827.2"
          width="35"
          height="10"
        />
        <rect
          id="_3.1"
          data-name="3.1"
          className="cls-7"
          x="542.4"
          y="874.8"
          width="35"
          height="10"
        />
        <rect
          id="_3.2"
          data-name="3.2"
          className="cls-7"
          x="592.7"
          y="875.1"
          width="35"
          height="10"
        />
        <rect
          id="_3.3"
          data-name="3.3"
          className="cls-7"
          x="627.5"
          y="875.1"
          width="35"
          height="10"
        />
        <rect
          id="_3.4"
          data-name="3.4"
          className="cls-7"
          x="662.5"
          y="875.1"
          width="35"
          height="10"
        />
        <rect
          id="_3.5"
          data-name="3.5"
          className="cls-7"
          x="697.5"
          y="875.1"
          width="35"
          height="10"
        />
        <rect
          id="_3.6"
          data-name="3.6"
          className="cls-7"
          x="732.5"
          y="875.1"
          width="35"
          height="10"
        />
        <rect
          id="_4.1"
          data-name="4.1"
          className="cls-7"
          x="542.4"
          y="884"
          width="35"
          height="10"
        />
        <rect
          id="_4.2"
          data-name="4.2"
          className="cls-7"
          x="592.7"
          y="884.4"
          width="35"
          height="10"
        />
        <rect
          id="_4.3"
          data-name="4.3"
          className="cls-7"
          x="627.5"
          y="884.4"
          width="35"
          height="10"
        />
        <rect
          id="_4.4"
          data-name="4.4"
          className="cls-7"
          x="662.5"
          y="884.4"
          width="35"
          height="10"
        />
        <rect
          id="_4.5"
          data-name="4.5"
          className="cls-7"
          x="697.5"
          y="884.4"
          width="35"
          height="10"
        />
        <rect
          id="_4.6"
          data-name="4.6"
          className="cls-7"
          x="732.5"
          y="884.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.1"
          data-name="5.1"
          className="cls-7"
          x="522.4"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.2"
          data-name="5.2"
          className="cls-7"
          x="557.4"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.3"
          data-name="5.3"
          className="cls-7"
          x="592.4"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.4"
          data-name="5.4"
          className="cls-7"
          x="627.3"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.5"
          data-name="5.5"
          className="cls-7"
          x="662.3"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.6"
          data-name="5.6"
          className="cls-7"
          x="697.3"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_5.7"
          data-name="5.7"
          className="cls-7"
          x="732.3"
          y="917.4"
          width="35"
          height="10"
        />
        <rect
          id="_6.1-2"
          data-name="6.1"
          className="cls-7"
          x="522.4"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.2-2"
          data-name="6.2"
          className="cls-7"
          x="557.4"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.3-2"
          data-name="6.3"
          className="cls-7"
          x="592.4"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.4-2"
          data-name="6.4"
          className="cls-7"
          x="627.3"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.5-2"
          data-name="6.5"
          className="cls-7"
          x="662.3"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.6-2"
          data-name="6.6"
          className="cls-7"
          x="697.3"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_6.7-2"
          data-name="6.7"
          className="cls-7"
          x="732.3"
          y="926.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.1"
          data-name="7.1"
          className="cls-7"
          x="522.5"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.2"
          data-name="7.2"
          className="cls-7"
          x="557.5"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.3"
          data-name="7.3"
          className="cls-7"
          x="592.5"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.4"
          data-name="7.4"
          className="cls-7"
          x="627.4"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.5"
          data-name="7.5"
          className="cls-7"
          x="662.4"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.6"
          data-name="7.6"
          className="cls-7"
          x="697.4"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.7"
          data-name="7.7"
          className="cls-7"
          x="732.4"
          y="959.7"
          width="35"
          height="10"
        />
        <rect
          id="_8.1"
          data-name="8.1"
          className="cls-7"
          x="522.5"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.2"
          data-name="8.2"
          className="cls-7"
          x="557.5"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.3"
          data-name="8.3"
          className="cls-7"
          x="592.5"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.4"
          data-name="8.4"
          className="cls-7"
          x="627.4"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.5"
          data-name="8.5"
          className="cls-7"
          x="662.4"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.6"
          data-name="8.6"
          className="cls-7"
          x="697.4"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_8.7"
          data-name="8.7"
          className="cls-7"
          x="732.4"
          y="968.9"
          width="35"
          height="10"
        />
        <rect
          id="_9.1"
          data-name="9.1"
          className="cls-7"
          x="542.4"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_9.2"
          data-name="9.2"
          className="cls-7"
          x="592.5"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_9.3"
          data-name="9.3"
          className="cls-7"
          x="627.4"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_9.4"
          data-name="9.4"
          className="cls-7"
          x="662.4"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_9.5"
          data-name="9.5"
          className="cls-7"
          x="697.4"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_9.6"
          data-name="9.6"
          className="cls-7"
          x="732.4"
          y="1005.1"
          width="35"
          height="10"
        />
        <rect
          id="_10.1-2"
          data-name="10.1"
          className="cls-7"
          x="542.4"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_10.2-2"
          data-name="10.2"
          className="cls-7"
          x="592.5"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_10.3"
          data-name="10.3"
          className="cls-7"
          x="627.4"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_10.4"
          data-name="10.4"
          className="cls-7"
          x="662.4"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_10.5"
          data-name="10.5"
          className="cls-7"
          x="697.4"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_10.6-2"
          data-name="10.6"
          className="cls-7"
          x="732.4"
          y="1014.3"
          width="35"
          height="10"
        />
        <rect
          id="_11.1"
          data-name="11.1"
          className="cls-7"
          x="522.6"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.2"
          data-name="11.2"
          className="cls-7"
          x="557.6"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.3"
          data-name="11.3"
          className="cls-7"
          x="592.6"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.4"
          data-name="11.4"
          className="cls-7"
          x="627.5"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.5"
          data-name="11.5"
          className="cls-7"
          x="662.5"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.6"
          data-name="11.6"
          className="cls-7"
          x="697.5"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_11.7"
          data-name="11.7"
          className="cls-7"
          x="732.5"
          y="1057.7"
          width="35"
          height="10"
        />
        <rect
          id="_12.1-2"
          data-name="12.1"
          className="cls-7"
          x="522.6"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.2-2"
          data-name="12.2"
          className="cls-7"
          x="557.6"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.3-2"
          data-name="12.3"
          className="cls-7"
          x="592.6"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.4-2"
          data-name="12.4"
          className="cls-7"
          x="627.5"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.5-2"
          data-name="12.5"
          className="cls-7"
          x="662.5"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.6-2"
          data-name="12.6"
          className="cls-7"
          x="697.5"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_12.7-2"
          data-name="12.7"
          className="cls-7"
          x="732.5"
          y="1067"
          width="35"
          height="10"
        />
        <rect
          id="_13.1-2"
          data-name="13.1"
          className="cls-7"
          x="522.7"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.2-2"
          data-name="13.2"
          className="cls-7"
          x="557.7"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.3-2"
          data-name="13.3"
          className="cls-7"
          x="592.7"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.4"
          data-name="13.4"
          className="cls-7"
          x="627.5"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.5"
          data-name="13.5"
          className="cls-7"
          x="662.5"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.6"
          data-name="13.6"
          className="cls-7"
          x="697.5"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_13.7"
          data-name="13.7"
          className="cls-7"
          x="732.5"
          y="1096.3"
          width="35"
          height="10"
        />
        <rect
          id="_14.1"
          data-name="14.1"
          className="cls-7"
          x="522.7"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.2"
          data-name="14.2"
          className="cls-7"
          x="557.7"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.3"
          data-name="14.3"
          className="cls-7"
          x="592.7"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.4"
          data-name="14.4"
          className="cls-7"
          x="627.5"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.5"
          data-name="14.5"
          className="cls-7"
          x="662.5"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.6"
          data-name="14.6"
          className="cls-7"
          x="697.5"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_14.7"
          data-name="14.7"
          className="cls-7"
          x="732.5"
          y="1105.6"
          width="35"
          height="10"
        />
        <rect
          id="_15.1"
          data-name="15.1"
          className="cls-7"
          x="542.4"
          y="1135.4"
          width="35"
          height="10"
        />
        <rect
          id="_15.2"
          data-name="15.2"
          className="cls-7"
          x="592.7"
          y="1135"
          width="35"
          height="10"
        />
        <rect
          id="_15.3"
          data-name="15.3"
          className="cls-7"
          x="627.5"
          y="1135"
          width="35"
          height="10"
        />
        <rect
          id="_15.4"
          data-name="15.4"
          className="cls-7"
          x="662.5"
          y="1135"
          width="35"
          height="10"
        />
        <rect
          id="_15.5"
          data-name="15.5"
          className="cls-7"
          x="697.5"
          y="1135"
          width="35"
          height="10"
        />
        <rect
          id="_15.6"
          data-name="15.6"
          className="cls-7"
          x="732.5"
          y="1135"
          width="35"
          height="10"
        />
        <rect
          id="_16.1"
          data-name="16.1"
          className="cls-7"
          x="542.4"
          y="1144.6"
          width="35"
          height="10"
        />
        <rect
          id="_16.2"
          data-name="16.2"
          className="cls-7"
          x="592.7"
          y="1144.3"
          width="35"
          height="10"
        />
        <rect
          id="_16.3-2"
          data-name="16.3"
          className="cls-7"
          x="627.5"
          y="1144.3"
          width="35"
          height="10"
        />
        <rect
          id="_16.4"
          data-name="16.4"
          className="cls-7"
          x="662.5"
          y="1144.3"
          width="35"
          height="10"
        />
        <rect
          id="_16.5-2"
          data-name="16.5"
          className="cls-7"
          x="697.5"
          y="1144.3"
          width="35"
          height="10"
        />
        <rect
          id="_16.6"
          data-name="16.6"
          className="cls-7"
          x="732.5"
          y="1144.3"
          width="35"
          height="10"
        />
        <rect
          id="_17.1"
          data-name="17.1"
          className="cls-7"
          x="522.6"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.2"
          data-name="17.2"
          className="cls-7"
          x="557.6"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.3"
          data-name="17.3"
          className="cls-7"
          x="592.6"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.4"
          data-name="17.4"
          className="cls-7"
          x="627.5"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.5"
          data-name="17.5"
          className="cls-7"
          x="662.5"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.6"
          data-name="17.6"
          className="cls-7"
          x="697.5"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_17.7"
          data-name="17.7"
          className="cls-7"
          x="732.5"
          y="1183"
          width="35"
          height="10"
        />
        <rect
          id="_18.1"
          data-name="18.1"
          className="cls-7"
          x="522.6"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.2"
          data-name="18.2"
          className="cls-7"
          x="557.6"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.3"
          data-name="18.3"
          className="cls-7"
          x="592.6"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.4"
          data-name="18.4"
          className="cls-7"
          x="627.5"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.5"
          data-name="18.5"
          className="cls-7"
          x="662.5"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.6"
          data-name="18.6"
          className="cls-7"
          x="697.5"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_18.7"
          data-name="18.7"
          className="cls-7"
          x="732.5"
          y="1192.3"
          width="35"
          height="10"
        />
        <rect
          id="_19.1"
          data-name="19.1"
          className="cls-7"
          x="522.7"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.2"
          data-name="19.2"
          className="cls-7"
          x="557.7"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.3"
          data-name="19.3"
          className="cls-7"
          x="592.7"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.4"
          data-name="19.4"
          className="cls-7"
          x="627.6"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.5"
          data-name="19.5"
          className="cls-7"
          x="662.6"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.6"
          data-name="19.6"
          className="cls-7"
          x="697.6"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_19.7"
          data-name="19.7"
          className="cls-7"
          x="732.6"
          y="1219.4"
          width="35"
          height="10"
        />
        <rect
          id="_20.1"
          data-name="20.1"
          className="cls-7"
          x="522.7"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.2"
          data-name="20.2"
          className="cls-7"
          x="557.7"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.3"
          data-name="20.3"
          className="cls-7"
          x="592.7"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.4"
          data-name="20.4"
          className="cls-7"
          x="627.6"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.5"
          data-name="20.5"
          className="cls-7"
          x="662.6"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.6"
          data-name="20.6"
          className="cls-7"
          x="697.6"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_20.7"
          data-name="20.7"
          className="cls-7"
          x="732.6"
          y="1228.7"
          width="35"
          height="10"
        />
        <rect
          id="_21.1"
          data-name="21.1"
          className="cls-7"
          x="542.4"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_21.2"
          data-name="21.2"
          className="cls-7"
          x="592.6"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_21.3"
          data-name="21.3"
          className="cls-7"
          x="627.6"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_21.4"
          data-name="21.4"
          className="cls-7"
          x="662.6"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_21.5"
          data-name="21.5"
          className="cls-7"
          x="697.6"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_21.6"
          data-name="21.6"
          className="cls-7"
          x="732.6"
          y="1267.2"
          width="35"
          height="10"
        />
        <rect
          id="_22.1"
          data-name="22.1"
          className="cls-7"
          x="537.4"
          y="1278.7"
          width="10"
          height="40"
        />
        <rect
          id="_22.2"
          data-name="22.2"
          className="cls-7"
          x="537.4"
          y="1318.7"
          width="10"
          height="40"
        />
        <rect
          id="_22.3"
          data-name="22.3"
          className="cls-7"
          x="537.4"
          y="1358.7"
          width="10"
          height="40"
        />
        <rect
          id="_22.4"
          data-name="22.4"
          className="cls-7"
          x="537.4"
          y="1398.7"
          width="10"
          height="40"
        />
        <rect
          id="_22.5"
          data-name="22.5"
          className="cls-7"
          x="537.4"
          y="1438.7"
          width="10"
          height="40"
        />
        <rect
          id="_23.1"
          data-name="23.1"
          className="cls-7"
          x="537.4"
          y="1556.4"
          width="10"
          height="40"
        />
        <rect
          id="_23.2"
          data-name="23.2"
          className="cls-7"
          x="537.4"
          y="1596.4"
          width="10"
          height="40"
        />
        <rect
          id="_23.3"
          data-name="23.3"
          className="cls-7"
          x="537.4"
          y="1636.4"
          width="10"
          height="40"
        />
        <rect
          id="_24.1"
          data-name="24.1"
          className="cls-7"
          x="705"
          y="1770.7"
          width="40"
          height="10"
        />
        <rect
          id="_24.2"
          data-name="24.2"
          className="cls-7"
          x="745"
          y="1770.7"
          width="40"
          height="10"
        />
        <rect
          id="_25.1"
          data-name="25.1"
          className="cls-7"
          x="387.2"
          y="1971.9"
          width="10"
          height="40"
        />
        <rect
          id="_25.2"
          data-name="25.2"
          className="cls-7"
          x="387.2"
          y="1931.9"
          width="10"
          height="40"
        />
        <rect
          id="_25.3"
          data-name="25.3"
          className="cls-7"
          x="387.2"
          y="1891.9"
          width="10"
          height="40"
        />
        <rect
          id="_25.4"
          data-name="25.4"
          className="cls-7"
          x="387.2"
          y="1851.9"
          width="10"
          height="40"
        />
        <rect
          id="_25.5"
          data-name="25.5"
          className="cls-7"
          x="387.2"
          y="1811.9"
          width="10"
          height="40"
        />
        <rect
          id="_26.1"
          data-name="26.1"
          className="cls-7"
          x="672.9"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.2"
          data-name="26.2"
          className="cls-7"
          x="632.9"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.3-2"
          data-name="26.3"
          className="cls-7"
          x="592.9"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.4-2"
          data-name="26.4"
          className="cls-7"
          x="537.4"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.5-2"
          data-name="26.5"
          className="cls-7"
          x="497.4"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.6-2"
          data-name="26.6"
          className="cls-7"
          x="457.4"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_26.7-2"
          data-name="26.7"
          className="cls-7"
          x="417.4"
          y="1903.7"
          width="40"
          height="10"
        />
        <rect
          id="_27.1-2"
          data-name="27.1"
          className="cls-7"
          x="679.9"
          y="1893.8"
          width="35"
          height="10"
        />
        <rect
          id="_27.2-2"
          data-name="27.2"
          className="cls-7"
          x="644.9"
          y="1893.7"
          width="35"
          height="10"
        />
        <rect
          id="_27.3-2"
          data-name="27.3"
          className="cls-7"
          x="609.9"
          y="1893.8"
          width="35"
          height="10"
        />
        <rect
          id="_27.4-2"
          data-name="27.4"
          className="cls-7"
          x="574.9"
          y="1893.8"
          width="35"
          height="10"
        />
        <rect
          id="_27.5-2"
          data-name="27.5"
          className="cls-7"
          x="539.9"
          y="1893.8"
          width="35"
          height="10"
        />
        <rect
          id="_27.6"
          data-name="27.6"
          className="cls-7"
          x="504.9"
          y="1893.7"
          width="35"
          height="10"
        />
        <rect
          id="_27.7"
          data-name="27.7"
          className="cls-7"
          x="469.9"
          y="1893.7"
          width="35"
          height="10"
        />
        <rect
          id="_27.8"
          data-name="27.8"
          className="cls-7"
          x="434.9"
          y="1893.7"
          width="35"
          height="10"
        />
        <rect
          id="_27.9"
          data-name="27.9"
          className="cls-7"
          x="399.9"
          y="1893.8"
          width="35"
          height="10"
        />
        <rect
          id="_28.1"
          data-name="28.1"
          className="cls-7"
          x="619.9"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_28.2"
          data-name="28.2"
          className="cls-7"
          x="619.9"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_28.3"
          data-name="28.3"
          className="cls-7"
          x="619.9"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_29.1"
          data-name="29.1"
          className="cls-7"
          x="610.1"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_29.2"
          data-name="29.2"
          className="cls-7"
          x="610.1"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_29.3"
          data-name="29.3"
          className="cls-7"
          x="610.1"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_30.1"
          data-name="30.1"
          className="cls-7"
          x="553.8"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_30.2"
          data-name="30.2"
          className="cls-7"
          x="553.8"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_30.3"
          data-name="30.3"
          className="cls-7"
          x="553.8"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_31.1"
          data-name="31.1"
          className="cls-7"
          x="544"
          y="1747.4"
          width="10"
          height="35"
        />
        <rect
          id="_31.2"
          data-name="31.2"
          className="cls-7"
          x="544"
          y="1782.4"
          width="10"
          height="35"
        />
        <rect
          id="_31.3"
          data-name="31.3"
          className="cls-7"
          x="544"
          y="1817.4"
          width="10"
          height="35"
        />
        <rect
          id="_32.1"
          data-name="32.1"
          className="cls-7"
          x="517.4"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_32.2"
          data-name="32.2"
          className="cls-7"
          x="517.4"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_32.3"
          data-name="32.3"
          className="cls-7"
          x="517.4"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_33.1"
          data-name="33.1"
          className="cls-7"
          x="507.6"
          y="1747.4"
          width="10"
          height="35"
        />
        <rect
          id="_33.2"
          data-name="33.2"
          className="cls-7"
          x="507.6"
          y="1782.4"
          width="10"
          height="35"
        />
        <rect
          id="_33.3"
          data-name="33.3"
          className="cls-7"
          x="507.6"
          y="1817.4"
          width="10"
          height="35"
        />
        <rect
          id="_34.1"
          data-name="34.1"
          className="cls-7"
          x="481"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_34.2"
          data-name="34.2"
          className="cls-7"
          x="481"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_34.3"
          data-name="34.3"
          className="cls-7"
          x="481"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_35.1"
          data-name="35.1"
          className="cls-7"
          x="471.2"
          y="1747.5"
          width="10"
          height="35"
        />
        <rect
          id="_35.2"
          data-name="35.2"
          className="cls-7"
          x="471.2"
          y="1782.5"
          width="10"
          height="35"
        />
        <rect
          id="_35.3"
          data-name="35.3"
          className="cls-7"
          x="471.2"
          y="1817.5"
          width="10"
          height="35"
        />
        <rect
          id="_36.1"
          data-name="36.1"
          className="cls-7"
          x="442.2"
          y="1744.5"
          width="10"
          height="35"
        />
        <rect
          id="_36.2"
          data-name="36.2"
          className="cls-7"
          x="442.2"
          y="1779.5"
          width="10"
          height="35"
        />
        <rect
          id="_36.3"
          data-name="36.3"
          className="cls-7"
          x="442.2"
          y="1814.5"
          width="10"
          height="35"
        />
        <rect
          id="_36.4"
          data-name="36.4"
          className="cls-7"
          x="442.2"
          y="1849.5"
          width="10"
          height="35"
        />
        <rect
          id="_37.1"
          data-name="37.1"
          className="cls-7"
          x="389.9"
          y="1531"
          width="10"
          height="40"
        />
        <rect
          id="_37.2"
          data-name="37.2"
          className="cls-7"
          x="389.9"
          y="1571"
          width="10"
          height="40"
        />
        <rect
          id="_37.3"
          data-name="37.3"
          className="cls-7"
          x="389.9"
          y="1611"
          width="10"
          height="40"
        />
        <rect
          id="_38.1"
          data-name="38.1"
          className="cls-7"
          x="396.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.2"
          data-name="38.2"
          className="cls-7"
          x="361.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.3"
          data-name="38.3"
          className="cls-7"
          x="326.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.4"
          data-name="38.4"
          className="cls-7"
          x="291.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.5"
          data-name="38.5"
          className="cls-7"
          x="256.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.6"
          data-name="38.6"
          className="cls-7"
          x="221.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_38.7"
          data-name="38.7"
          className="cls-7"
          x="186.2"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_39.1"
          data-name="39.1"
          className="cls-7"
          x="412.2"
          y="1478.9"
          width="35"
          height="10"
        />
        <rect
          id="_39.2"
          data-name="39.2"
          className="cls-7"
          x="377.2"
          y="1478.9"
          width="35"
          height="10"
        />
        <rect
          id="_39.3"
          data-name="39.3"
          className="cls-7"
          x="342.2"
          y="1478.9"
          width="35"
          height="10"
        />
        <rect
          id="_39.4"
          data-name="39.4"
          className="cls-7"
          x="307.2"
          y="1478.9"
          width="35"
          height="10"
        />
        <rect
          id="_40.1"
          data-name="40.1"
          className="cls-7"
          x="412.2"
          y="1469.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.2"
          data-name="40.2"
          className="cls-7"
          x="377.2"
          y="1469.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.3"
          data-name="40.3"
          className="cls-7"
          x="342.2"
          y="1469.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.4"
          data-name="40.4"
          className="cls-7"
          x="307.2"
          y="1469.5"
          width="35"
          height="10"
        />
        <rect
          id="_41.1"
          data-name="41.1"
          className="cls-7"
          x="412.2"
          y="1434.8"
          width="35"
          height="10"
        />
        <rect
          id="_41.2"
          data-name="41.2"
          className="cls-7"
          x="377.2"
          y="1434.8"
          width="35"
          height="10"
        />
        <rect
          id="_41.3"
          data-name="41.3"
          className="cls-7"
          x="342.2"
          y="1434.8"
          width="35"
          height="10"
        />
        <rect
          id="_41.4"
          data-name="41.4"
          className="cls-7"
          x="307.2"
          y="1434.8"
          width="35"
          height="10"
        />
        <rect
          id="_42.1"
          data-name="42.1"
          className="cls-7"
          x="412.2"
          y="1425.5"
          width="35"
          height="10"
        />
        <rect
          id="_42.2"
          data-name="42.2"
          className="cls-7"
          x="377.2"
          y="1425.5"
          width="35"
          height="10"
        />
        <rect
          id="_42.3"
          data-name="42.3"
          className="cls-7"
          x="342.2"
          y="1425.5"
          width="35"
          height="10"
        />
        <rect
          id="_42.4"
          data-name="42.4"
          className="cls-7"
          x="307.2"
          y="1425.5"
          width="35"
          height="10"
        />
        <rect
          id="_43.1"
          data-name="43.1"
          className="cls-7"
          x="413.7"
          y="1368.7"
          width="35"
          height="10"
        />
        <rect
          id="_43.2"
          data-name="43.2"
          className="cls-7"
          x="378.7"
          y="1368.7"
          width="35"
          height="10"
        />
        <rect
          id="_43.3"
          data-name="43.3"
          className="cls-7"
          x="343.7"
          y="1368.7"
          width="35"
          height="10"
        />
        <rect
          id="_43.4"
          data-name="43.4"
          className="cls-7"
          x="308.7"
          y="1368.7"
          width="35"
          height="10"
        />
        <rect
          id="_44.1"
          data-name="44.1"
          className="cls-7"
          x="413.7"
          y="1359.4"
          width="35"
          height="10"
        />
        <rect
          id="_44.2"
          data-name="44.2"
          className="cls-7"
          x="378.7"
          y="1359.4"
          width="35"
          height="10"
        />
        <rect
          id="_44.3"
          data-name="44.3"
          className="cls-7"
          x="343.7"
          y="1359.4"
          width="35"
          height="10"
        />
        <rect
          id="_44.4"
          data-name="44.4"
          className="cls-7"
          x="308.7"
          y="1359.4"
          width="35"
          height="10"
        />
        <rect
          id="_45.1"
          data-name="45.1"
          className="cls-7"
          x="335.9"
          y="1320.9"
          width="35"
          height="10"
        />
        <rect
          id="_45.2"
          data-name="45.2"
          className="cls-7"
          x="300.9"
          y="1320.9"
          width="35"
          height="10"
        />
        <rect
          id="_45.3"
          data-name="45.3"
          className="cls-7"
          x="265.9"
          y="1320.9"
          width="35"
          height="10"
        />
        <rect
          id="_46.1"
          data-name="46.1"
          className="cls-7"
          x="335.9"
          y="1311.6"
          width="35"
          height="10"
        />
        <rect
          id="_46.2"
          data-name="46.2"
          className="cls-7"
          x="300.9"
          y="1311.6"
          width="35"
          height="10"
        />
        <rect
          id="_46.3"
          data-name="46.3"
          className="cls-7"
          x="265.9"
          y="1311.6"
          width="35"
          height="10"
        />
        <rect
          id="_47.1"
          data-name="47.1"
          className="cls-7"
          x="337.2"
          y="1272.3"
          width="35"
          height="10"
        />
        <rect
          id="_47.2"
          data-name="47.2"
          className="cls-7"
          x="302.2"
          y="1272.3"
          width="35"
          height="10"
        />
        <rect
          id="_47.3"
          data-name="47.3"
          className="cls-7"
          x="267.2"
          y="1272.3"
          width="35"
          height="10"
        />
        <rect
          id="_48.1"
          data-name="48.1"
          className="cls-7"
          x="337.2"
          y="1262.9"
          width="35"
          height="10"
        />
        <rect
          id="_48.2"
          data-name="48.2"
          className="cls-7"
          x="302.2"
          y="1262.9"
          width="35"
          height="10"
        />
        <rect
          id="_48.3"
          data-name="48.3"
          className="cls-7"
          x="267.2"
          y="1262.9"
          width="35"
          height="10"
        />
        <rect
          id="_49.1"
          data-name="49.1"
          className="cls-7"
          x="413.7"
          y="1207.3"
          width="35"
          height="10"
        />
        <rect
          id="_49.2"
          data-name="49.2"
          className="cls-7"
          x="378.7"
          y="1207.3"
          width="35"
          height="10"
        />
        <rect
          id="_49.3"
          data-name="49.3"
          className="cls-7"
          x="343.7"
          y="1207.3"
          width="35"
          height="10"
        />
        <rect
          id="_49.4"
          data-name="49.4"
          className="cls-7"
          x="308.7"
          y="1207.3"
          width="35"
          height="10"
        />
        <rect
          id="_50.1"
          data-name="50.1"
          className="cls-7"
          x="413.7"
          y="1198"
          width="35"
          height="10"
        />
        <rect
          id="_50.2"
          data-name="50.2"
          className="cls-7"
          x="378.7"
          y="1198"
          width="35"
          height="10"
        />
        <rect
          id="_50.3"
          data-name="50.3"
          className="cls-7"
          x="343.7"
          y="1198"
          width="35"
          height="10"
        />
        <rect
          id="_50.4"
          data-name="50.4"
          className="cls-7"
          x="308.7"
          y="1198"
          width="35"
          height="10"
        />
        <rect
          id="_51.1"
          data-name="51.1"
          className="cls-7"
          x="337.2"
          y="1146.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.2"
          data-name="51.2"
          className="cls-7"
          x="302.2"
          y="1146.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.3"
          data-name="51.3"
          className="cls-7"
          x="267.2"
          y="1146.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.4"
          data-name="51.4"
          className="cls-7"
          x="232.2"
          y="1146.8"
          width="35"
          height="10"
        />
        <rect
          id="_52.1"
          data-name="52.1"
          className="cls-7"
          x="337.2"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_52.2"
          data-name="52.2"
          className="cls-7"
          x="302.2"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_52.3"
          data-name="52.3"
          className="cls-7"
          x="267.2"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_52.4"
          data-name="52.4"
          className="cls-7"
          x="232.2"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_53.1"
          data-name="53.1"
          className="cls-7"
          x="414.7"
          y="1099.4"
          width="35"
          height="10"
        />
        <rect
          id="_53.2"
          data-name="53.2"
          className="cls-7"
          x="379.7"
          y="1099.4"
          width="35"
          height="10"
        />
        <rect
          id="_53.3"
          data-name="53.3"
          className="cls-7"
          x="344.7"
          y="1099.4"
          width="35"
          height="10"
        />
        <rect
          id="_53.4"
          data-name="53.4"
          className="cls-7"
          x="309.7"
          y="1099.4"
          width="35"
          height="10"
        />
        <rect
          id="_54.1"
          data-name="54.1"
          className="cls-7"
          x="414.7"
          y="1090.1"
          width="35"
          height="10"
        />
        <rect
          id="_54.2"
          data-name="54.2"
          className="cls-7"
          x="379.7"
          y="1090.1"
          width="35"
          height="10"
        />
        <rect
          id="_54.3"
          data-name="54.3"
          className="cls-7"
          x="344.7"
          y="1090.1"
          width="35"
          height="10"
        />
        <rect
          id="_54.4"
          data-name="54.4"
          className="cls-7"
          x="309.7"
          y="1090.1"
          width="35"
          height="10"
        />
        <rect
          id="_55.1"
          data-name="55.1"
          className="cls-7"
          x="414.7"
          y="1058.5"
          width="35"
          height="10"
        />
        <rect
          id="_55.2"
          data-name="55.2"
          className="cls-7"
          x="379.7"
          y="1058.5"
          width="35"
          height="10"
        />
        <rect
          id="_55.3"
          data-name="55.3"
          className="cls-7"
          x="344.7"
          y="1058.5"
          width="35"
          height="10"
        />
        <rect
          id="_55.4"
          data-name="55.4"
          className="cls-7"
          x="309.7"
          y="1058.5"
          width="35"
          height="10"
        />
        <rect
          id="_56.1"
          data-name="56.1"
          className="cls-7"
          x="414.7"
          y="1049.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.2"
          data-name="56.2"
          className="cls-7"
          x="379.7"
          y="1049.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.3"
          data-name="56.3"
          className="cls-7"
          x="344.7"
          y="1049.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.4"
          data-name="56.4"
          className="cls-7"
          x="309.7"
          y="1049.2"
          width="35"
          height="10"
        />
        <rect
          id="_57.1"
          data-name="57.1"
          className="cls-7"
          x="337.2"
          y="1017.6"
          width="35"
          height="10"
        />
        <rect
          id="_57.2"
          data-name="57.2"
          className="cls-7"
          x="302.2"
          y="1017.6"
          width="35"
          height="10"
        />
        <rect
          id="_57.3"
          data-name="57.3"
          className="cls-7"
          x="267.2"
          y="1017.6"
          width="35"
          height="10"
        />
        <rect
          id="_57.4"
          data-name="57.4"
          className="cls-7"
          x="232.2"
          y="1017.6"
          width="35"
          height="10"
        />
        <rect
          id="_58.1"
          data-name="58.1"
          className="cls-7"
          x="337.2"
          y="1008.3"
          width="35"
          height="10"
        />
        <rect
          id="_58.2"
          data-name="58.2"
          className="cls-7"
          x="302.2"
          y="1008.3"
          width="35"
          height="10"
        />
        <rect
          id="_58.3"
          data-name="58.3"
          className="cls-7"
          x="267.2"
          y="1008.3"
          width="35"
          height="10"
        />
        <rect
          id="_58.4"
          data-name="58.4"
          className="cls-7"
          x="232.2"
          y="1008.3"
          width="35"
          height="10"
        />
        <rect
          id="_59.1"
          data-name="59.1"
          className="cls-7"
          x="414.7"
          y="969.3"
          width="35"
          height="10"
        />
        <rect
          id="_59.2"
          data-name="59.2"
          className="cls-7"
          x="379.7"
          y="969.3"
          width="35"
          height="10"
        />
        <rect
          id="_59.3"
          data-name="59.3"
          className="cls-7"
          x="344.7"
          y="969.3"
          width="35"
          height="10"
        />
        <rect
          id="_59.4"
          data-name="59.4"
          className="cls-7"
          x="309.7"
          y="969.3"
          width="35"
          height="10"
        />
        <rect
          id="_60.1"
          data-name="60.1"
          className="cls-7"
          x="414.7"
          y="959.9"
          width="35"
          height="10"
        />
        <rect
          id="_60.2"
          data-name="60.2"
          className="cls-7"
          x="379.7"
          y="959.9"
          width="35"
          height="10"
        />
        <rect
          id="_60.3"
          data-name="60.3"
          className="cls-7"
          x="344.7"
          y="959.9"
          width="35"
          height="10"
        />
        <rect
          id="_60.4"
          data-name="60.4"
          className="cls-7"
          x="309.7"
          y="959.9"
          width="35"
          height="10"
        />
        <rect
          id="_61.1"
          data-name="61.1"
          className="cls-7"
          x="414.7"
          y="927.1"
          width="35"
          height="10"
        />
        <rect
          id="_61.2"
          data-name="61.2"
          className="cls-7"
          x="379.7"
          y="927.1"
          width="35"
          height="10"
        />
        <rect
          id="_61.3"
          data-name="61.3"
          className="cls-7"
          x="344.7"
          y="927.1"
          width="35"
          height="10"
        />
        <rect
          id="_61.4"
          data-name="61.4"
          className="cls-7"
          x="309.7"
          y="927.1"
          width="35"
          height="10"
        />
        <rect
          id="_62.1"
          data-name="62.1"
          className="cls-7"
          x="414.7"
          y="917.8"
          width="35"
          height="10"
        />
        <rect
          id="_62.2"
          data-name="62.2"
          className="cls-7"
          x="379.7"
          y="917.8"
          width="35"
          height="10"
        />
        <rect
          id="_62.3"
          data-name="62.3"
          className="cls-7"
          x="344.7"
          y="917.8"
          width="35"
          height="10"
        />
        <rect
          id="_62.4"
          data-name="62.4"
          className="cls-7"
          x="309.7"
          y="917.8"
          width="35"
          height="10"
        />
        <rect
          id="_63.1"
          data-name="63.1"
          className="cls-7"
          x="337.2"
          y="867.5"
          width="35"
          height="10"
        />
        <rect
          id="_63.2"
          data-name="63.2"
          className="cls-7"
          x="302.2"
          y="867.5"
          width="35"
          height="10"
        />
        <rect
          id="_63.3"
          data-name="63.3"
          className="cls-7"
          x="267.2"
          y="867.5"
          width="35"
          height="10"
        />
        <rect
          id="_64.1"
          data-name="64.1"
          className="cls-7"
          x="337.2"
          y="858.2"
          width="35"
          height="10"
        />
        <rect
          id="_64.2"
          data-name="64.2"
          className="cls-7"
          x="302.2"
          y="858.2"
          width="35"
          height="10"
        />
        <rect
          id="_64.3"
          data-name="64.3"
          className="cls-7"
          x="267.2"
          y="858.2"
          width="35"
          height="10"
        />
        <rect
          id="_65.1"
          data-name="65.1"
          className="cls-7"
          x="412"
          y="822.9"
          width="35"
          height="10"
        />
        <rect
          id="_65.2"
          data-name="65.2"
          className="cls-7"
          x="377"
          y="822.9"
          width="35"
          height="10"
        />
        <rect
          id="_65.3"
          data-name="65.3"
          className="cls-7"
          x="342"
          y="823"
          width="35"
          height="10"
        />
        <rect
          id="_65.4"
          data-name="65.4"
          className="cls-7"
          x="307"
          y="823"
          width="35"
          height="10"
        />
        <rect
          id="_65.5"
          data-name="65.5"
          className="cls-7"
          x="272"
          y="823"
          width="35"
          height="10"
        />
        <rect
          id="_66.1"
          data-name="66.1"
          className="cls-7"
          x="412"
          y="813.6"
          width="35"
          height="10"
        />
        <rect
          id="_66.2"
          data-name="66.2"
          className="cls-7"
          x="377"
          y="813.6"
          width="35"
          height="10"
        />
        <rect
          id="_66.3"
          data-name="66.3"
          className="cls-7"
          x="342"
          y="813.7"
          width="35"
          height="10"
        />
        <rect
          id="_66.4"
          data-name="66.4"
          className="cls-7"
          x="307"
          y="813.7"
          width="35"
          height="10"
        />
        <rect
          id="_66.5"
          data-name="66.5"
          className="cls-7"
          x="272"
          y="813.7"
          width="35"
          height="10"
        />
        <rect
          id="_67"
          data-name="67"
          className="cls-7"
          x="439.7"
          y="752.1"
          width="30"
          height="10"
        />
        <rect
          id="_68.1"
          data-name="68.1"
          className="cls-7"
          x="342.2"
          y="752.1"
          width="30"
          height="10"
        />
        <rect
          id="_68.2"
          data-name="68.2"
          className="cls-7"
          x="312.2"
          y="752.1"
          width="30"
          height="10"
        />
        <rect
          id="_68.3"
          data-name="68.3"
          className="cls-7"
          x="282.2"
          y="752.1"
          width="30"
          height="10"
        />
        <rect
          id="_69.1"
          data-name="69.1"
          className="cls-7"
          x="339.5"
          y="732"
          width="30"
          height="10"
        />
        <rect
          id="_69.2"
          data-name="69.2"
          className="cls-7"
          x="309.5"
          y="732"
          width="30"
          height="10"
        />
        <rect
          id="_70.1"
          data-name="70.1"
          className="cls-7"
          x="339.5"
          y="702.6"
          width="30"
          height="10"
        />
        <rect
          id="_70.2"
          data-name="70.2"
          className="cls-7"
          x="309.5"
          y="702.6"
          width="30"
          height="10"
        />
        <rect
          id="_71.1"
          data-name="71.1"
          className="cls-7"
          x="339.5"
          y="692.6"
          width="30"
          height="10"
        />
        <rect
          id="_71.2"
          data-name="71.2"
          className="cls-7"
          x="309.5"
          y="692.6"
          width="30"
          height="10"
        />
        <rect
          id="_72.1"
          data-name="72.1"
          className="cls-7"
          x="339.5"
          y="662.6"
          width="30"
          height="10"
        />
        <rect
          id="_72.2"
          data-name="72.2"
          className="cls-7"
          x="309.5"
          y="662.6"
          width="30"
          height="10"
        />
        <rect
          id="_73.1"
          data-name="73.1"
          className="cls-7"
          x="339.5"
          y="652.6"
          width="30"
          height="10"
        />
        <rect
          id="_73.2"
          data-name="73.2"
          className="cls-7"
          x="309.5"
          y="652.6"
          width="30"
          height="10"
        />
        <rect
          id="_74.1"
          data-name="74.1"
          className="cls-7"
          x="339.5"
          y="618.4"
          width="30"
          height="10"
        />
        <rect
          id="_74.2"
          data-name="74.2"
          className="cls-7"
          x="309.5"
          y="618.4"
          width="30"
          height="10"
        />
        <rect
          id="_75.1"
          data-name="75.1"
          className="cls-7"
          x="339.5"
          y="608.4"
          width="30"
          height="10"
        />
        <rect
          id="_75.2"
          data-name="75.2"
          className="cls-7"
          x="309.5"
          y="608.4"
          width="30"
          height="10"
        />
        <rect
          id="_76.1"
          data-name="76.1"
          className="cls-17"
          x="339.3"
          y="558.6"
          width="30"
          height="10"
          transform="translate(-75 54.8) rotate(-8)"
        />
        <rect
          id="_76.2"
          data-name="76.2"
          className="cls-17"
          x="309.6"
          y="562.7"
          width="30"
          height="10"
          transform="translate(-75.9 50.7) rotate(-8)"
        />
      </g>
      <g className="_4fl_walls_doors" data-name="4fl_walls_doors">
        <rect
          id="_4fl_toilet_pass"
          data-name="4fl_toilet_pass"
          className="cls-4"
          x="545.9"
          y="593.3"
          width="70"
          height="9.9"
        />
        <g id="_4fl_mainstairs_door" data-name="4fl_mainstairs_door">
          <rect className="cls-9" x="552.6" y="742" width="40" height="10" />
          <line className="cls-10" x1="552.6" y1="752" x2="592.6" y2="742" />
        </g>
        <g id="_4fl_2lift_room" data-name="4fl_2lift_room">
          <rect
            id="_4fl_2lift_walls"
            data-name="4fl_2lift_walls"
            className="cls-19"
            x="700"
            y="1671.4"
            width="105.9"
            height="94.2"
          />
          <g id="_4fl_2lift_door" data-name="4fl_2lift_door">
            <rect className="cls-9" x="695" y="1698.5" width="10" height="40" />
            <line
              className="cls-10"
              x1="705"
              y1="1698.5"
              x2="695"
              y2="1738.5"
            />
          </g>
        </g>
        <g id="_4fl_mainlift" data-name="4fl_mainlift">
          <rect
            id="_4fl_mainlift_walls"
            data-name="4fl_mainlift_walls"
            className="cls-19"
            x="454.7"
            y="622"
            width="86"
            height="125"
          />
          <g id="_4fl_mainlift_door" data-name="4fl_mainlift_door">
            <rect className="cls-9" x="477.7" y="742" width="40" height="10" />
            <line className="cls-10" x1="477.7" y1="742" x2="517.7" y2="752" />
          </g>
        </g>
        <g id="_4fl_restoration" data-name="4fl_restoration">
          <polygon
            id="_4fl_restoration_walls"
            data-name="4fl_restoration_walls"
            className="cls-19"
            points="429.6 622 429.6 539.9 153.3 575.6 153.3 873.2 154.8 873.2 154.8 1131.9 332 1131.9 332 873.2 418.1 873.2 418.1 747 454.7 747 454.7 622 429.6 622"
          />
          <g id="_4fl_restoration_door" data-name="4fl_restoration_door">
            <rect className="cls-9" x="413.1" y="796" width="10" height="40" />
            <line className="cls-10" x1="423.1" y1="836" x2="413.1" y2="796" />
          </g>
        </g>
        <g id="_4fl_stairs" data-name="4fl_stairs">
          <g id="stairs2-4" data-name="stairs2">
            <rect
              className="cls-8"
              x="882"
              y="1856.2"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1868.6"
              x2="882"
              y2="1868.6"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1880.6"
              x2="882"
              y2="1880.6"
            />
            <line className="cls-8" x1="939.4" y1="1893" x2="882" y2="1893" />
            <line
              className="cls-8"
              x1="939.4"
              y1="1904.1"
              x2="882"
              y2="1904.1"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1915.6"
              x2="882"
              y2="1915.6"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1928.5"
              x2="882"
              y2="1928.5"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1939.7"
              x2="882"
              y2="1939.7"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1952.1"
              x2="882"
              y2="1952.1"
            />
            <line
              className="cls-8"
              x1="939.4"
              y1="1964.8"
              x2="882"
              y2="1964.8"
            />
            <rect
              className="cls-8"
              x="814.5"
              y="1856.2"
              width="57.4"
              height="121.2"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1868.6"
              x2="814.5"
              y2="1868.6"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1880.6"
              x2="814.5"
              y2="1880.6"
            />
            <line className="cls-8" x1="871.9" y1="1893" x2="814.5" y2="1893" />
            <line
              className="cls-8"
              x1="871.9"
              y1="1904.1"
              x2="814.5"
              y2="1904.1"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1915.6"
              x2="814.5"
              y2="1915.6"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1928.5"
              x2="814.5"
              y2="1928.5"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1939.7"
              x2="814.5"
              y2="1939.7"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1952.1"
              x2="814.5"
              y2="1952.1"
            />
            <line
              className="cls-8"
              x1="871.9"
              y1="1964.8"
              x2="814.5"
              y2="1964.8"
            />
          </g>
          <g id="stairs1-5" data-name="stairs1">
            <rect
              className="cls-8"
              x="614.5"
              y="605"
              width="121.2"
              height="57.5"
            />
            <line className="cls-8" x1="626.9" y1="605" x2="626.9" y2="662.4" />
            <line className="cls-8" x1="639" y1="605" x2="639" y2="662.4" />
            <line className="cls-8" x1="651.3" y1="605" x2="651.3" y2="662.4" />
            <line className="cls-8" x1="662.4" y1="605" x2="662.4" y2="662.4" />
            <line className="cls-8" x1="673.9" y1="605" x2="673.9" y2="662.4" />
            <line className="cls-8" x1="686.8" y1="605" x2="686.8" y2="662.4" />
            <line className="cls-8" x1="698" y1="605" x2="698" y2="662.4" />
            <line className="cls-8" x1="710.5" y1="605" x2="710.5" y2="662.4" />
            <line className="cls-8" x1="723.1" y1="605" x2="723.1" y2="662.4" />
            <rect
              className="cls-8"
              x="614.5"
              y="672.5"
              width="121.2"
              height="57.5"
            />
            <line
              className="cls-8"
              x1="626.9"
              y1="672.5"
              x2="626.9"
              y2="729.9"
            />
            <line className="cls-8" x1="639" y1="672.5" x2="639" y2="729.9" />
            <line
              className="cls-8"
              x1="651.3"
              y1="672.5"
              x2="651.3"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="662.4"
              y1="672.5"
              x2="662.4"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="673.9"
              y1="672.5"
              x2="673.9"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="686.8"
              y1="672.5"
              x2="686.8"
              y2="729.9"
            />
            <line className="cls-8" x1="698" y1="672.5" x2="698" y2="729.9" />
            <line
              className="cls-8"
              x1="710.5"
              y1="672.5"
              x2="710.5"
              y2="729.9"
            />
            <line
              className="cls-8"
              x1="723.1"
              y1="672.5"
              x2="723.1"
              y2="729.9"
            />
          </g>
        </g>
      </g>
      <g className="_4fl_bookstands" data-name="4fl_bookstands">
        <rect
          id="_1.1.1-2"
          data-name="1.1.1"
          className="cls-7"
          x="422.2"
          y="868.2"
          width="35"
          height="10"
        />
        <rect
          id="_1.2.1-2"
          data-name="1.2.1"
          className="cls-7"
          x="422.2"
          y="878.6"
          width="35"
          height="10"
        />
        <rect
          id="_1.2.2-2"
          data-name="1.2.2"
          className="cls-7"
          x="387.2"
          y="878.6"
          width="35"
          height="10"
        />
        <rect
          id="_1.2.3-2"
          data-name="1.2.3"
          className="cls-7"
          x="337.2"
          y="878.6"
          width="35"
          height="10"
        />
        <rect
          id="_2.1-2"
          data-name="2.1"
          className="cls-7"
          x="442.5"
          y="915.7"
          width="35"
          height="10"
        />
        <rect
          id="_2.2-2"
          data-name="2.2"
          className="cls-7"
          x="407.5"
          y="915.8"
          width="35"
          height="10"
        />
        <rect
          id="_2.3-2"
          data-name="2.3"
          className="cls-7"
          x="372.5"
          y="915.9"
          width="35"
          height="10"
        />
        <rect
          id="_2.4-2"
          data-name="2.4"
          className="cls-7"
          x="337.5"
          y="915.9"
          width="35"
          height="10"
        />
        <rect
          id="_3.1-2"
          data-name="3.1"
          className="cls-7"
          x="442.5"
          y="926.1"
          width="35"
          height="10"
        />
        <rect
          id="_3.2-2"
          data-name="3.2"
          className="cls-7"
          x="407.5"
          y="926.2"
          width="35"
          height="10"
        />
        <rect
          id="_3.3-2"
          data-name="3.3"
          className="cls-7"
          x="372.5"
          y="926.3"
          width="35"
          height="10"
        />
        <rect
          id="_3.4-2"
          data-name="3.4"
          className="cls-7"
          x="337.5"
          y="926.3"
          width="35"
          height="10"
        />
        <rect
          id="_4.1-2"
          data-name="4.1"
          className="cls-7"
          x="442.5"
          y="961.7"
          width="35"
          height="10"
        />
        <rect
          id="_4.2-2"
          data-name="4.2"
          className="cls-7"
          x="407.5"
          y="961.7"
          width="35"
          height="10"
        />
        <rect
          id="_4.3-2"
          data-name="4.3"
          className="cls-7"
          x="372.5"
          y="961.7"
          width="35"
          height="10"
        />
        <rect
          id="_4.4-2"
          data-name="4.4"
          className="cls-7"
          x="337.5"
          y="961.7"
          width="35"
          height="10"
        />
        <rect
          id="_5.1-2"
          data-name="5.1"
          className="cls-7"
          x="442.5"
          y="972"
          width="35"
          height="10"
        />
        <rect
          id="_5.2-2"
          data-name="5.2"
          className="cls-7"
          x="407.5"
          y="972"
          width="35"
          height="10"
        />
        <rect
          id="_5.3-2"
          data-name="5.3"
          className="cls-7"
          x="372.5"
          y="972.1"
          width="35"
          height="10"
        />
        <rect
          id="_5.4-2"
          data-name="5.4"
          className="cls-7"
          x="337.5"
          y="972.1"
          width="35"
          height="10"
        />
        <rect
          id="_6.1-3"
          data-name="6.1"
          className="cls-7"
          x="457.2"
          y="1007.5"
          width="35"
          height="10"
        />
        <rect
          id="_6.2-3"
          data-name="6.2"
          className="cls-7"
          x="422.2"
          y="1007.5"
          width="35"
          height="10"
        />
        <rect
          id="_6.3-3"
          data-name="6.3"
          className="cls-7"
          x="387.2"
          y="1007.6"
          width="35"
          height="10"
        />
        <rect
          id="_6.4-3"
          data-name="6.4"
          className="cls-7"
          x="337.5"
          y="1007.7"
          width="35"
          height="10"
        />
        <rect
          id="_7.1-2"
          data-name="7.1"
          className="cls-7"
          x="457.2"
          y="1017.8"
          width="35"
          height="10"
        />
        <rect
          id="_7.2-2"
          data-name="7.2"
          className="cls-7"
          x="422.2"
          y="1017.8"
          width="35"
          height="10"
        />
        <rect
          id="_7.3-2"
          data-name="7.3"
          className="cls-7"
          x="387.2"
          y="1017.9"
          width="35"
          height="10"
        />
        <rect
          id="_7.4-2"
          data-name="7.4"
          className="cls-7"
          x="337.5"
          y="1018"
          width="35"
          height="10"
        />
        <rect
          id="_8.1-2"
          data-name="8.1"
          className="cls-7"
          x="442.5"
          y="1053.5"
          width="35"
          height="10"
        />
        <rect
          id="_8.2-2"
          data-name="8.2"
          className="cls-7"
          x="407.5"
          y="1053.5"
          width="35"
          height="10"
        />
        <rect
          id="_8.3-2"
          data-name="8.3"
          className="cls-7"
          x="372.5"
          y="1053.6"
          width="35"
          height="10"
        />
        <rect
          id="_8.4-2"
          data-name="8.4"
          className="cls-7"
          x="337.5"
          y="1053.6"
          width="35"
          height="10"
        />
        <rect
          id="_9.1-2"
          data-name="9.1"
          className="cls-7"
          x="442.5"
          y="1063.9"
          width="35"
          height="10"
        />
        <rect
          id="_9.2-2"
          data-name="9.2"
          className="cls-7"
          x="407.5"
          y="1063.9"
          width="35"
          height="10"
        />
        <rect
          id="_9.3-2"
          data-name="9.3"
          className="cls-7"
          x="372.5"
          y="1063.9"
          width="35"
          height="10"
        />
        <rect
          id="_9.4-2"
          data-name="9.4"
          className="cls-7"
          x="337.5"
          y="1063.9"
          width="35"
          height="10"
        />
        <rect
          id="_10.1-3"
          data-name="10.1"
          className="cls-7"
          x="442.5"
          y="1093.4"
          width="35"
          height="10"
        />
        <rect
          id="_10.2-3"
          data-name="10.2"
          className="cls-7"
          x="407.5"
          y="1093.4"
          width="35"
          height="10"
        />
        <rect
          id="_10.3-2"
          data-name="10.3"
          className="cls-7"
          x="372.5"
          y="1093.5"
          width="35"
          height="10"
        />
        <rect
          id="_10.4-2"
          data-name="10.4"
          className="cls-7"
          x="337.5"
          y="1093.5"
          width="35"
          height="10"
        />
        <rect
          id="_11.1-2"
          data-name="11.1"
          className="cls-7"
          x="442.5"
          y="1103.8"
          width="35"
          height="10"
        />
        <rect
          id="_11.2-2"
          data-name="11.2"
          className="cls-7"
          x="407.5"
          y="1103.8"
          width="35"
          height="10"
        />
        <rect
          id="_11.3-2"
          data-name="11.3"
          className="cls-7"
          x="372.5"
          y="1103.9"
          width="35"
          height="10"
        />
        <rect
          id="_11.4-2"
          data-name="11.4"
          className="cls-7"
          x="337.5"
          y="1103.9"
          width="35"
          height="10"
        />
        <rect
          id="_12.1-3"
          data-name="12.1"
          className="cls-7"
          x="457.2"
          y="1137.2"
          width="35"
          height="10"
        />
        <rect
          id="_12.2-3"
          data-name="12.2"
          className="cls-7"
          x="422.2"
          y="1137.2"
          width="35"
          height="10"
        />
        <rect
          id="_12.3-3"
          data-name="12.3"
          className="cls-7"
          x="387.2"
          y="1137.2"
          width="35"
          height="10"
        />
        <rect
          id="_12.4-3"
          data-name="12.4"
          className="cls-7"
          x="336.8"
          y="1137.3"
          width="35"
          height="10"
        />
        <rect
          id="_12.5-3"
          data-name="12.5"
          className="cls-7"
          x="301.8"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_12.6-3"
          data-name="12.6"
          className="cls-7"
          x="266.8"
          y="1137.5"
          width="35"
          height="10"
        />
        <rect
          id="_12.7-3"
          data-name="12.7"
          className="cls-7"
          x="231.8"
          y="1137.6"
          width="35"
          height="10"
        />
        <rect
          id="_13.1-3"
          data-name="13.1"
          className="cls-7"
          x="457.2"
          y="1147.5"
          width="35"
          height="10"
        />
        <rect
          id="_13.2-3"
          data-name="13.2"
          className="cls-7"
          x="422.2"
          y="1147.5"
          width="35"
          height="10"
        />
        <rect
          id="_13.3-3"
          data-name="13.3"
          className="cls-7"
          x="387.2"
          y="1147.6"
          width="35"
          height="10"
        />
        <rect
          id="_13.4-2"
          data-name="13.4"
          className="cls-7"
          x="336.8"
          y="1147.7"
          width="35"
          height="10"
        />
        <rect
          id="_13.5-2"
          data-name="13.5"
          className="cls-7"
          x="301.8"
          y="1147.8"
          width="35"
          height="10"
        />
        <rect
          id="_13.6-2"
          data-name="13.6"
          className="cls-7"
          x="266.8"
          y="1147.8"
          width="35"
          height="10"
        />
        <rect
          id="_13.7-2"
          data-name="13.7"
          className="cls-7"
          x="231.8"
          y="1147.9"
          width="35"
          height="10"
        />
        <rect
          id="_14.1-2"
          data-name="14.1"
          className="cls-7"
          x="457.2"
          y="1188"
          width="35"
          height="10"
        />
        <rect
          id="_14.2-2"
          data-name="14.2"
          className="cls-7"
          x="422.2"
          y="1188"
          width="35"
          height="10"
        />
        <rect
          id="_14.3-2"
          data-name="14.3"
          className="cls-7"
          x="387.2"
          y="1188"
          width="35"
          height="10"
        />
        <rect
          id="_14.4-2"
          data-name="14.4"
          className="cls-7"
          x="352.2"
          y="1188"
          width="35"
          height="10"
        />
        <rect
          id="_14.5-2"
          data-name="14.5"
          className="cls-7"
          x="317.2"
          y="1188.2"
          width="35"
          height="10"
        />
        <rect
          id="_14.6-2"
          data-name="14.6"
          className="cls-7"
          x="282.2"
          y="1188.2"
          width="35"
          height="10"
        />
        <rect
          id="_14.7-2"
          data-name="14.7"
          className="cls-7"
          x="247.2"
          y="1188.3"
          width="35"
          height="10"
        />
        <rect
          id="_15.1-2"
          data-name="15.1"
          className="cls-7"
          x="457.2"
          y="1198.3"
          width="35"
          height="10"
        />
        <rect
          id="_15.2-2"
          data-name="15.2"
          className="cls-7"
          x="422.2"
          y="1198.3"
          width="35"
          height="10"
        />
        <rect
          id="_15.3-2"
          data-name="15.3"
          className="cls-7"
          x="387.2"
          y="1198.3"
          width="35"
          height="10"
        />
        <rect
          id="_15.4-2"
          data-name="15.4"
          className="cls-7"
          x="352.2"
          y="1198.4"
          width="35"
          height="10"
        />
        <rect
          id="_15.5-2"
          data-name="15.5"
          className="cls-7"
          x="317.2"
          y="1198.5"
          width="35"
          height="10"
        />
        <rect
          id="_15.6-2"
          data-name="15.6"
          className="cls-7"
          x="282.2"
          y="1198.5"
          width="35"
          height="10"
        />
        <rect
          id="_15.7"
          data-name="15.7"
          className="cls-7"
          x="247.2"
          y="1198.6"
          width="35"
          height="10"
        />
        <rect
          id="_16.1-2"
          data-name="16.1"
          className="cls-7"
          x="457.2"
          y="1228"
          width="35"
          height="10"
        />
        <rect
          id="_16.2-2"
          data-name="16.2"
          className="cls-7"
          x="422.2"
          y="1228"
          width="35"
          height="10"
        />
        <rect
          id="_16.3-3"
          data-name="16.3"
          className="cls-7"
          x="387.2"
          y="1228"
          width="35"
          height="10"
        />
        <rect
          id="_16.4-2"
          data-name="16.4"
          className="cls-7"
          x="352.2"
          y="1228.1"
          width="35"
          height="10"
        />
        <rect
          id="_16.5-3"
          data-name="16.5"
          className="cls-7"
          x="317.2"
          y="1228.2"
          width="35"
          height="10"
        />
        <rect
          id="_16.6-2"
          data-name="16.6"
          className="cls-7"
          x="282.2"
          y="1228.2"
          width="35"
          height="10"
        />
        <rect
          id="_16.7"
          data-name="16.7"
          className="cls-7"
          x="247.2"
          y="1228.3"
          width="35"
          height="10"
        />
        <rect
          id="_17.1-2"
          data-name="17.1"
          className="cls-7"
          x="457.2"
          y="1238.4"
          width="35"
          height="10"
        />
        <rect
          id="_17.2-2"
          data-name="17.2"
          className="cls-7"
          x="422.2"
          y="1238.4"
          width="35"
          height="10"
        />
        <rect
          id="_17.3-2"
          data-name="17.3"
          className="cls-7"
          x="387.2"
          y="1238.3"
          width="35"
          height="10"
        />
        <rect
          id="_17.4-2"
          data-name="17.4"
          className="cls-7"
          x="352.2"
          y="1238.4"
          width="35"
          height="10"
        />
        <rect
          id="_17.5-2"
          data-name="17.5"
          className="cls-7"
          x="317.2"
          y="1238.5"
          width="35"
          height="10"
        />
        <rect
          id="_17.6-2"
          data-name="17.6"
          className="cls-7"
          x="282.2"
          y="1238.5"
          width="35"
          height="10"
        />
        <rect
          id="_17.7-2"
          data-name="17.7"
          className="cls-7"
          x="247.2"
          y="1238.6"
          width="35"
          height="10"
        />
        <rect
          id="_18.1-2"
          data-name="18.1"
          className="cls-7"
          x="457.2"
          y="1278.1"
          width="35"
          height="10"
        />
        <rect
          id="_18.2-2"
          data-name="18.2"
          className="cls-7"
          x="422.2"
          y="1278.1"
          width="35"
          height="10"
        />
        <rect
          id="_18.3-2"
          data-name="18.3"
          className="cls-7"
          x="387.2"
          y="1278.1"
          width="35"
          height="10"
        />
        <rect
          id="_18.4-2"
          data-name="18.4"
          className="cls-7"
          x="337.5"
          y="1278.4"
          width="35"
          height="10"
        />
        <rect
          id="_18.5-2"
          data-name="18.5"
          className="cls-7"
          x="302.5"
          y="1278.6"
          width="35"
          height="10"
        />
        <rect
          id="_18.6-2"
          data-name="18.6"
          className="cls-7"
          x="267.5"
          y="1278.6"
          width="35"
          height="10"
        />
        <rect
          id="_18.7-2"
          data-name="18.7"
          className="cls-7"
          x="232.5"
          y="1278.7"
          width="35"
          height="10"
        />
        <rect
          id="_19.1-2"
          data-name="19.1"
          className="cls-7"
          x="457.2"
          y="1288.5"
          width="35"
          height="10"
        />
        <rect
          id="_19.2-2"
          data-name="19.2"
          className="cls-7"
          x="422.2"
          y="1288.5"
          width="35"
          height="10"
        />
        <rect
          id="_19.3-2"
          data-name="19.3"
          className="cls-7"
          x="387.2"
          y="1288.5"
          width="35"
          height="10"
        />
        <rect
          id="_19.4-2"
          data-name="19.4"
          className="cls-7"
          x="337.5"
          y="1288.8"
          width="35"
          height="10"
        />
        <rect
          id="_19.5-2"
          data-name="19.5"
          className="cls-7"
          x="302.5"
          y="1288.9"
          width="35"
          height="10"
        />
        <rect
          id="_19.6-2"
          data-name="19.6"
          className="cls-7"
          x="267.5"
          y="1288.9"
          width="35"
          height="10"
        />
        <rect
          id="_19.7-2"
          data-name="19.7"
          className="cls-7"
          x="232.5"
          y="1289"
          width="35"
          height="10"
        />
        <rect
          id="_20.1-2"
          data-name="20.1"
          className="cls-7"
          x="457.2"
          y="1317.6"
          width="35"
          height="10"
        />
        <rect
          id="_20.2-2"
          data-name="20.2"
          className="cls-7"
          x="422.2"
          y="1317.6"
          width="35"
          height="10"
        />
        <rect
          id="_20.3-2"
          data-name="20.3"
          className="cls-7"
          x="387.2"
          y="1317.6"
          width="35"
          height="10"
        />
        <rect
          id="_20.4-2"
          data-name="20.4"
          className="cls-7"
          x="352.2"
          y="1317.6"
          width="35"
          height="10"
        />
        <rect
          id="_20.5-2"
          data-name="20.5"
          className="cls-7"
          x="317.2"
          y="1317.8"
          width="35"
          height="10"
        />
        <rect
          id="_20.6-2"
          data-name="20.6"
          className="cls-7"
          x="282.2"
          y="1317.8"
          width="35"
          height="10"
        />
        <rect
          id="_20.7-2"
          data-name="20.7"
          className="cls-7"
          x="247.2"
          y="1317.9"
          width="35"
          height="10"
        />
        <rect
          id="_21.1-2"
          data-name="21.1"
          className="cls-7"
          x="457.2"
          y="1327.9"
          width="35"
          height="10"
        />
        <rect
          id="_21.2-2"
          data-name="21.2"
          className="cls-7"
          x="422.2"
          y="1327.9"
          width="35"
          height="10"
        />
        <rect
          id="_21.3-2"
          data-name="21.3"
          className="cls-7"
          x="387.2"
          y="1327.9"
          width="35"
          height="10"
        />
        <rect
          id="_21.4-2"
          data-name="21.4"
          className="cls-7"
          x="352.2"
          y="1328"
          width="35"
          height="10"
        />
        <rect
          id="_21.5-2"
          data-name="21.5"
          className="cls-7"
          x="317.2"
          y="1328.1"
          width="35"
          height="10"
        />
        <rect
          id="_21.6-2"
          data-name="21.6"
          className="cls-7"
          x="282.2"
          y="1328.1"
          width="35"
          height="10"
        />
        <rect
          id="_21.7"
          data-name="21.7"
          className="cls-7"
          x="247.2"
          y="1328.2"
          width="35"
          height="10"
        />
        <rect
          id="_22.1-2"
          data-name="22.1"
          className="cls-7"
          x="457.2"
          y="1353.3"
          width="35"
          height="10"
        />
        <rect
          id="_22.3-2"
          data-name="22.3"
          className="cls-7"
          x="387.2"
          y="1353.3"
          width="35"
          height="10"
        />
        <rect
          id="_22.2-2"
          data-name="22.2"
          className="cls-7"
          x="422.2"
          y="1353.3"
          width="35"
          height="10"
        />
        <rect
          id="_22.4-2"
          data-name="22.4"
          className="cls-7"
          x="352.2"
          y="1353.4"
          width="35"
          height="10"
        />
        <rect
          id="_22.5-2"
          data-name="22.5"
          className="cls-7"
          x="317.2"
          y="1353.5"
          width="35"
          height="10"
        />
        <rect
          id="_22.6"
          data-name="22.6"
          className="cls-7"
          x="282.2"
          y="1353.5"
          width="35"
          height="10"
        />
        <rect
          id="_22.7"
          data-name="22.7"
          className="cls-7"
          x="247.2"
          y="1353.6"
          width="35"
          height="10"
        />
        <rect
          id="_23.1-2"
          data-name="23.1"
          className="cls-7"
          x="457.2"
          y="1363.7"
          width="35"
          height="10"
        />
        <rect
          id="_23.2-2"
          data-name="23.2"
          className="cls-7"
          x="422.2"
          y="1363.7"
          width="35"
          height="10"
        />
        <rect
          id="_23.3-2"
          data-name="23.3"
          className="cls-7"
          x="387.2"
          y="1363.7"
          width="35"
          height="10"
        />
        <rect
          id="_23.4"
          data-name="23.4"
          className="cls-7"
          x="352.2"
          y="1363.7"
          width="35"
          height="10"
        />
        <rect
          id="_23.5"
          data-name="23.5"
          className="cls-7"
          x="317.2"
          y="1363.9"
          width="35"
          height="10"
        />
        <rect
          id="_23.6"
          data-name="23.6"
          className="cls-7"
          x="282.2"
          y="1363.9"
          width="35"
          height="10"
        />
        <rect
          id="_23.7"
          data-name="23.7"
          className="cls-7"
          x="247.2"
          y="1364"
          width="35"
          height="10"
        />
        <rect
          id="_24.1-2"
          data-name="24.1"
          className="cls-7"
          x="457.2"
          y="1407.2"
          width="35"
          height="10"
        />
        <rect
          id="_24.2-2"
          data-name="24.2"
          className="cls-7"
          x="422.2"
          y="1407.2"
          width="35"
          height="10"
        />
        <rect
          id="_24.3-2"
          data-name="24.3"
          className="cls-7"
          x="387.2"
          y="1407.2"
          width="35"
          height="10"
        />
        <rect
          id="_24.4"
          data-name="24.4"
          className="cls-7"
          x="337.5"
          y="1407.5"
          width="35"
          height="10"
        />
        <rect
          id="_24.5"
          data-name="24.5"
          className="cls-7"
          x="302.5"
          y="1407.6"
          width="35"
          height="10"
        />
        <rect
          id="_24.6"
          data-name="24.6"
          className="cls-7"
          x="267.5"
          y="1407.6"
          width="35"
          height="10"
        />
        <rect
          id="_24.7"
          data-name="24.7"
          className="cls-7"
          x="232.5"
          y="1407.7"
          width="35"
          height="10"
        />
        <rect
          id="_25.1-2"
          data-name="25.1"
          className="cls-7"
          x="457.2"
          y="1417.6"
          width="35"
          height="10"
        />
        <rect
          id="_25.2-2"
          data-name="25.2"
          className="cls-7"
          x="422.2"
          y="1417.6"
          width="35"
          height="10"
        />
        <rect
          id="_25.3-2"
          data-name="25.3"
          className="cls-7"
          x="387.2"
          y="1417.6"
          width="35"
          height="10"
        />
        <rect
          id="_25.4-2"
          data-name="25.4"
          className="cls-7"
          x="337.5"
          y="1417.8"
          width="35"
          height="10"
        />
        <rect
          id="_25.5-2"
          data-name="25.5"
          className="cls-7"
          x="302.5"
          y="1418"
          width="35"
          height="10"
        />
        <rect
          id="_25.6"
          data-name="25.6"
          className="cls-7"
          x="267.5"
          y="1418"
          width="35"
          height="10"
        />
        <rect
          id="_25.7"
          data-name="25.7"
          className="cls-7"
          x="232.5"
          y="1418"
          width="35"
          height="10"
        />
        <rect
          id="_26.1-2"
          data-name="26.1"
          className="cls-7"
          x="457.2"
          y="1446"
          width="35"
          height="10"
        />
        <rect
          id="_26.2-2"
          data-name="26.2"
          className="cls-7"
          x="422.2"
          y="1446"
          width="35"
          height="10"
        />
        <rect
          id="_26.3-3"
          data-name="26.3"
          className="cls-7"
          x="387.2"
          y="1446"
          width="35"
          height="10"
        />
        <rect
          id="_26.4-3"
          data-name="26.4"
          className="cls-7"
          x="352.2"
          y="1446.1"
          width="35"
          height="10"
        />
        <rect
          id="_26.5-3"
          data-name="26.5"
          className="cls-7"
          x="317.2"
          y="1446.2"
          width="35"
          height="10"
        />
        <rect
          id="_26.6-3"
          data-name="26.6"
          className="cls-7"
          x="282.2"
          y="1446.2"
          width="35"
          height="10"
        />
        <rect
          id="_26.7-3"
          data-name="26.7"
          className="cls-7"
          x="247.2"
          y="1446.3"
          width="35"
          height="10"
        />
        <rect
          id="_27.1-3"
          data-name="27.1"
          className="cls-7"
          x="457.2"
          y="1456.4"
          width="35"
          height="10"
        />
        <rect
          id="_27.2-3"
          data-name="27.2"
          className="cls-7"
          x="422.2"
          y="1456.4"
          width="35"
          height="10"
        />
        <rect
          id="_27.3-3"
          data-name="27.3"
          className="cls-7"
          x="387.2"
          y="1456.4"
          width="35"
          height="10"
        />
        <rect
          id="_27.4-3"
          data-name="27.4"
          className="cls-7"
          x="352.2"
          y="1456.4"
          width="35"
          height="10"
        />
        <rect
          id="_27.5-3"
          data-name="27.5"
          className="cls-7"
          x="317.2"
          y="1456.6"
          width="35"
          height="10"
        />
        <rect
          id="_27.6-2"
          data-name="27.6"
          className="cls-7"
          x="282.2"
          y="1456.6"
          width="35"
          height="10"
        />
        <rect
          id="_27.7-2"
          data-name="27.7"
          className="cls-7"
          x="247.2"
          y="1456.6"
          width="35"
          height="10"
        />
        <rect
          id="_28.1-2"
          data-name="28.1"
          className="cls-7"
          x="457.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.2-2"
          data-name="28.2"
          className="cls-7"
          x="422.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.3-2"
          data-name="28.3"
          className="cls-7"
          x="387.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.4"
          data-name="28.4"
          className="cls-7"
          x="352.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.5"
          data-name="28.5"
          className="cls-7"
          x="317.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.6"
          data-name="28.6"
          className="cls-7"
          x="282.2"
          y="1485.2"
          width="35"
          height="10"
        />
        <rect
          id="_28.7"
          data-name="28.7"
          className="cls-7"
          x="247.2"
          y="1485.3"
          width="35"
          height="10"
        />
        <rect
          id="_29.1-2"
          data-name="29.1"
          className="cls-7"
          x="457.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.2-2"
          data-name="29.2"
          className="cls-7"
          x="422.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.3-2"
          data-name="29.3"
          className="cls-7"
          x="387.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.4"
          data-name="29.4"
          className="cls-7"
          x="352.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.5"
          data-name="29.5"
          className="cls-7"
          x="317.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.6"
          data-name="29.6"
          className="cls-7"
          x="282.2"
          y="1495.5"
          width="35"
          height="10"
        />
        <rect
          id="_29.7"
          data-name="29.7"
          className="cls-7"
          x="247.2"
          y="1495.6"
          width="35"
          height="10"
        />
        <rect
          id="_30.1-2"
          data-name="30.1"
          className="cls-7"
          x="457.2"
          y="1534.7"
          width="35"
          height="10"
        />
        <rect
          id="_30.2-2"
          data-name="30.2"
          className="cls-7"
          x="422.2"
          y="1534.7"
          width="35"
          height="10"
        />
        <rect
          id="_30.3-2"
          data-name="30.3"
          className="cls-7"
          x="387.2"
          y="1534.7"
          width="35"
          height="10"
        />
        <rect
          id="_30.4"
          data-name="30.4"
          className="cls-7"
          x="337.5"
          y="1534.7"
          width="35"
          height="10"
        />
        <rect
          id="_30.5"
          data-name="30.5"
          className="cls-7"
          x="302.5"
          y="1534.9"
          width="35"
          height="10"
        />
        <rect
          id="_30.6"
          data-name="30.6"
          className="cls-7"
          x="267.5"
          y="1534.9"
          width="35"
          height="10"
        />
        <rect
          id="_30.7"
          data-name="30.7"
          className="cls-7"
          x="232.5"
          y="1535"
          width="35"
          height="10"
        />
        <rect
          id="_31.1-2"
          data-name="31.1"
          className="cls-7"
          x="457.2"
          y="1545.1"
          width="35"
          height="10"
        />
        <rect
          id="_31.2-2"
          data-name="31.2"
          className="cls-7"
          x="422.2"
          y="1545.1"
          width="35"
          height="10"
        />
        <rect
          id="_31.3-2"
          data-name="31.3"
          className="cls-7"
          x="387.2"
          y="1545"
          width="35"
          height="10"
        />
        <rect
          id="_31.4"
          data-name="31.4"
          className="cls-7"
          x="337.5"
          y="1545.1"
          width="35"
          height="10"
        />
        <rect
          id="_31.5"
          data-name="31.5"
          className="cls-7"
          x="302.5"
          y="1545.2"
          width="35"
          height="10"
        />
        <rect
          id="_31.6"
          data-name="31.6"
          className="cls-7"
          x="267.5"
          y="1545.2"
          width="35"
          height="10"
        />
        <rect
          id="_31.7"
          data-name="31.7"
          className="cls-7"
          x="232.5"
          y="1545.3"
          width="35"
          height="10"
        />
        <rect
          id="_32.1-2"
          data-name="32.1"
          className="cls-7"
          x="457.2"
          y="1574.3"
          width="35"
          height="10"
        />
        <rect
          id="_32.2-2"
          data-name="32.2"
          className="cls-7"
          x="422.2"
          y="1574.3"
          width="35"
          height="10"
        />
        <rect
          id="_32.3-2"
          data-name="32.3"
          className="cls-7"
          x="387.2"
          y="1574.3"
          width="35"
          height="10"
        />
        <rect
          id="_32.4"
          data-name="32.4"
          className="cls-7"
          x="352.2"
          y="1574.4"
          width="35"
          height="10"
        />
        <rect
          id="_32.5"
          data-name="32.5"
          className="cls-7"
          x="317.2"
          y="1574.5"
          width="35"
          height="10"
        />
        <rect
          id="_32.6"
          data-name="32.6"
          className="cls-7"
          x="282.2"
          y="1574.5"
          width="35"
          height="10"
        />
        <rect
          id="_32.7"
          data-name="32.7"
          className="cls-7"
          x="247.2"
          y="1574.6"
          width="35"
          height="10"
        />
        <rect
          id="_33.1-2"
          data-name="33.1"
          className="cls-7"
          x="457.2"
          y="1584.7"
          width="35"
          height="10"
        />
        <rect
          id="_33.2-2"
          data-name="33.2"
          className="cls-7"
          x="422.2"
          y="1584.7"
          width="35"
          height="10"
        />
        <rect
          id="_33.3-2"
          data-name="33.3"
          className="cls-7"
          x="387.2"
          y="1584.7"
          width="35"
          height="10"
        />
        <rect
          id="_33.4"
          data-name="33.4"
          className="cls-7"
          x="352.2"
          y="1584.7"
          width="35"
          height="10"
        />
        <rect
          id="_33.5"
          data-name="33.5"
          className="cls-7"
          x="317.2"
          y="1584.9"
          width="35"
          height="10"
        />
        <rect
          id="_33.6"
          data-name="33.6"
          className="cls-7"
          x="282.2"
          y="1584.9"
          width="35"
          height="10"
        />
        <rect
          id="_33.7"
          data-name="33.7"
          className="cls-7"
          x="247.2"
          y="1585"
          width="35"
          height="10"
        />
        <rect
          id="_34.1-2"
          data-name="34.1"
          className="cls-7"
          x="457.2"
          y="1609.2"
          width="35"
          height="10"
        />
        <rect
          id="_34.2-2"
          data-name="34.2"
          className="cls-7"
          x="422.2"
          y="1609.2"
          width="35"
          height="10"
        />
        <rect
          id="_34.3-2"
          data-name="34.3"
          className="cls-7"
          x="387.2"
          y="1609.1"
          width="35"
          height="10"
        />
        <rect
          id="_34.4"
          data-name="34.4"
          className="cls-7"
          x="352.2"
          y="1609.2"
          width="35"
          height="10"
        />
        <rect
          id="_34.5"
          data-name="34.5"
          className="cls-7"
          x="317.2"
          y="1609.3"
          width="35"
          height="10"
        />
        <rect
          id="_34.6"
          data-name="34.6"
          className="cls-7"
          x="282.2"
          y="1609.3"
          width="35"
          height="10"
        />
        <rect
          id="_34.7"
          data-name="34.7"
          className="cls-7"
          x="247.2"
          y="1609.4"
          width="35"
          height="10"
        />
        <rect
          id="_35.1-2"
          data-name="35.1"
          className="cls-7"
          x="457.2"
          y="1619.5"
          width="35"
          height="10"
        />
        <rect
          id="_35.2-2"
          data-name="35.2"
          className="cls-7"
          x="422.2"
          y="1619.5"
          width="35"
          height="10"
        />
        <rect
          id="_35.3-2"
          data-name="35.3"
          className="cls-7"
          x="387.2"
          y="1619.5"
          width="35"
          height="10"
        />
        <rect
          id="_35.4"
          data-name="35.4"
          className="cls-7"
          x="352.2"
          y="1619.6"
          width="35"
          height="10"
        />
        <rect
          id="_35.5"
          data-name="35.5"
          className="cls-7"
          x="317.2"
          y="1619.7"
          width="35"
          height="10"
        />
        <rect
          id="_35.6"
          data-name="35.6"
          className="cls-7"
          x="282.2"
          y="1619.7"
          width="35"
          height="10"
        />
        <rect
          id="_35.7"
          data-name="35.7"
          className="cls-7"
          x="247.2"
          y="1619.8"
          width="35"
          height="10"
        />
        <rect
          id="_36.1-2"
          data-name="36.1"
          className="cls-7"
          x="457.2"
          y="1661.9"
          width="35"
          height="10"
        />
        <rect
          id="_36.2-2"
          data-name="36.2"
          className="cls-7"
          x="422.2"
          y="1661.9"
          width="35"
          height="10"
        />
        <rect
          id="_36.3-2"
          data-name="36.3"
          className="cls-7"
          x="387.2"
          y="1661.9"
          width="35"
          height="10"
        />
        <rect
          id="_36.4-2"
          data-name="36.4"
          className="cls-7"
          x="337.5"
          y="1662.2"
          width="35"
          height="10"
        />
        <rect
          id="_36.5"
          data-name="36.5"
          className="cls-7"
          x="302.5"
          y="1662.3"
          width="35"
          height="10"
        />
        <rect
          id="_36.6"
          data-name="36.6"
          className="cls-7"
          x="267.5"
          y="1662.3"
          width="35"
          height="10"
        />
        <rect
          id="_36.7"
          data-name="36.7"
          className="cls-7"
          x="232.5"
          y="1662.4"
          width="35"
          height="10"
        />
        <rect
          id="_37.1-2"
          data-name="37.1"
          className="cls-7"
          x="457.2"
          y="1672.3"
          width="35"
          height="10"
        />
        <rect
          id="_37.2-2"
          data-name="37.2"
          className="cls-7"
          x="422.2"
          y="1672.3"
          width="35"
          height="10"
        />
        <rect
          id="_37.3-2"
          data-name="37.3"
          className="cls-7"
          x="387.2"
          y="1672.3"
          width="35"
          height="10"
        />
        <rect
          id="_37.4"
          data-name="37.4"
          className="cls-7"
          x="337.5"
          y="1672.5"
          width="35"
          height="10"
        />
        <rect
          id="_37.5"
          data-name="37.5"
          className="cls-7"
          x="302.5"
          y="1672.7"
          width="35"
          height="10"
        />
        <rect
          id="_37.6"
          data-name="37.6"
          className="cls-7"
          x="267.5"
          y="1672.7"
          width="35"
          height="10"
        />
        <rect
          id="_37.7"
          data-name="37.7"
          className="cls-7"
          x="232.5"
          y="1672.7"
          width="35"
          height="10"
        />
        <rect
          id="_38.1-2"
          data-name="38.1"
          className="cls-7"
          x="457.2"
          y="1702.6"
          width="35"
          height="10"
        />
        <rect
          id="_38.2-2"
          data-name="38.2"
          className="cls-7"
          x="422.2"
          y="1702.6"
          width="35"
          height="10"
        />
        <rect
          id="_38.3-2"
          data-name="38.3"
          className="cls-7"
          x="387.2"
          y="1702.6"
          width="35"
          height="10"
        />
        <rect
          id="_38.4-2"
          data-name="38.4"
          className="cls-7"
          x="352.2"
          y="1702.7"
          width="35"
          height="10"
        />
        <rect
          id="_38.5-2"
          data-name="38.5"
          className="cls-7"
          x="317.2"
          y="1702.8"
          width="35"
          height="10"
        />
        <rect
          id="_38.6-2"
          data-name="38.6"
          className="cls-7"
          x="282.2"
          y="1702.8"
          width="35"
          height="10"
        />
        <rect
          id="_38.7-2"
          data-name="38.7"
          className="cls-7"
          x="247.2"
          y="1702.9"
          width="35"
          height="10"
        />
        <rect
          id="_39.1-2"
          data-name="39.1"
          className="cls-7"
          x="457.2"
          y="1713"
          width="35"
          height="10"
        />
        <rect
          id="_39.2-2"
          data-name="39.2"
          className="cls-7"
          x="422.2"
          y="1713"
          width="35"
          height="10"
        />
        <rect
          id="_39.3-2"
          data-name="39.3"
          className="cls-7"
          x="387.2"
          y="1712.9"
          width="35"
          height="10"
        />
        <rect
          id="_39.4-2"
          data-name="39.4"
          className="cls-7"
          x="352.2"
          y="1713"
          width="35"
          height="10"
        />
        <rect
          id="_39.5"
          data-name="39.5"
          className="cls-7"
          x="317.2"
          y="1713.1"
          width="35"
          height="10"
        />
        <rect
          id="_39.6"
          data-name="39.6"
          className="cls-7"
          x="282.2"
          y="1713.1"
          width="35"
          height="10"
        />
        <rect
          id="_39.7"
          data-name="39.7"
          className="cls-7"
          x="247.2"
          y="1713.2"
          width="35"
          height="10"
        />
        <rect
          id="_40.1-2"
          data-name="40.1"
          className="cls-7"
          x="457.2"
          y="1738.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.2-2"
          data-name="40.2"
          className="cls-7"
          x="422.2"
          y="1738.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.3-2"
          data-name="40.3"
          className="cls-7"
          x="387.2"
          y="1738.5"
          width="35"
          height="10"
        />
        <rect
          id="_40.4-2"
          data-name="40.4"
          className="cls-7"
          x="352.2"
          y="1738.6"
          width="35"
          height="10"
        />
        <rect
          id="_40.5"
          data-name="40.5"
          className="cls-7"
          x="317.2"
          y="1738.7"
          width="35"
          height="10"
        />
        <rect
          id="_40.6"
          data-name="40.6"
          className="cls-7"
          x="282.2"
          y="1738.7"
          width="35"
          height="10"
        />
        <rect
          id="_40.7"
          data-name="40.7"
          className="cls-7"
          x="247.2"
          y="1738.8"
          width="35"
          height="10"
        />
        <rect
          id="_41.1-2"
          data-name="41.1"
          className="cls-7"
          x="457.2"
          y="1748.9"
          width="35"
          height="10"
        />
        <rect
          id="_41.2-2"
          data-name="41.2"
          className="cls-7"
          x="422.2"
          y="1748.9"
          width="35"
          height="10"
        />
        <rect
          id="_41.3-2"
          data-name="41.3"
          className="cls-7"
          x="387.2"
          y="1748.9"
          width="35"
          height="10"
        />
        <rect
          id="_41.4-2"
          data-name="41.4"
          className="cls-7"
          x="352.2"
          y="1748.9"
          width="35"
          height="10"
        />
        <rect
          id="_41.5"
          data-name="41.5"
          className="cls-7"
          x="317.2"
          y="1749.1"
          width="35"
          height="10"
        />
        <rect
          id="_41.6"
          data-name="41.6"
          className="cls-7"
          x="282.2"
          y="1749.1"
          width="35"
          height="10"
        />
        <rect
          id="_41.7"
          data-name="41.7"
          className="cls-7"
          x="247.2"
          y="1749.1"
          width="35"
          height="10"
        />
        <rect
          id="_42.1-2"
          data-name="42.1"
          className="cls-7"
          x="457.2"
          y="1791.1"
          width="35"
          height="10"
        />
        <rect
          id="_42.2-2"
          data-name="42.2"
          className="cls-7"
          x="422.2"
          y="1791.1"
          width="35"
          height="10"
        />
        <rect
          id="_42.3-2"
          data-name="42.3"
          className="cls-7"
          x="387.2"
          y="1791.1"
          width="35"
          height="10"
        />
        <rect
          id="_42.4-2"
          data-name="42.4"
          className="cls-7"
          x="337.5"
          y="1790.8"
          width="35"
          height="10"
        />
        <rect
          id="_42.5"
          data-name="42.5"
          className="cls-7"
          x="302.5"
          y="1790.9"
          width="35"
          height="10"
        />
        <rect
          id="_42.6"
          data-name="42.6"
          className="cls-7"
          x="267.5"
          y="1790.9"
          width="35"
          height="10"
        />
        <rect
          id="_42.7"
          data-name="42.7"
          className="cls-7"
          x="232.5"
          y="1791"
          width="35"
          height="10"
        />
        <rect
          id="_43.1-2"
          data-name="43.1"
          className="cls-7"
          x="457.2"
          y="1801.4"
          width="35"
          height="10"
        />
        <rect
          id="_43.2-2"
          data-name="43.2"
          className="cls-7"
          x="422.2"
          y="1801.4"
          width="35"
          height="10"
        />
        <rect
          id="_43.3-2"
          data-name="43.3"
          className="cls-7"
          x="387.2"
          y="1801.4"
          width="35"
          height="10"
        />
        <rect
          id="_43.4-2"
          data-name="43.4"
          className="cls-7"
          x="337.5"
          y="1801.1"
          width="35"
          height="10"
        />
        <rect
          id="_43.5"
          data-name="43.5"
          className="cls-7"
          x="302.5"
          y="1801.3"
          width="35"
          height="10"
        />
        <rect
          id="_43.6"
          data-name="43.6"
          className="cls-7"
          x="267.5"
          y="1801.3"
          width="35"
          height="10"
        />
        <rect
          id="_43.7"
          data-name="43.7"
          className="cls-7"
          x="232.5"
          y="1801.3"
          width="35"
          height="10"
        />
        <rect
          id="_44.1-2"
          data-name="44.1"
          className="cls-7"
          x="457.2"
          y="1829"
          width="35"
          height="10"
        />
        <rect
          id="_44.2-2"
          data-name="44.2"
          className="cls-7"
          x="422.2"
          y="1829"
          width="35"
          height="10"
        />
        <rect
          id="_44.3-2"
          data-name="44.3"
          className="cls-7"
          x="387.2"
          y="1829"
          width="35"
          height="10"
        />
        <rect
          id="_44.4-2"
          data-name="44.4"
          className="cls-7"
          x="352.2"
          y="1829"
          width="35"
          height="10"
        />
        <rect
          id="_44.5"
          data-name="44.5"
          className="cls-7"
          x="317.2"
          y="1829.2"
          width="35"
          height="10"
        />
        <rect
          id="_44.6"
          data-name="44.6"
          className="cls-7"
          x="282.2"
          y="1829.2"
          width="35"
          height="10"
        />
        <rect
          id="_44.7"
          data-name="44.7"
          className="cls-7"
          x="247.2"
          y="1829.3"
          width="35"
          height="10"
        />
        <rect
          id="_45.1-2"
          data-name="45.1"
          className="cls-7"
          x="457.2"
          y="1839.4"
          width="35"
          height="10"
        />
        <rect
          id="_45.2-2"
          data-name="45.2"
          className="cls-7"
          x="422.2"
          y="1839.4"
          width="35"
          height="10"
        />
        <rect
          id="_45.3-2"
          data-name="45.3"
          className="cls-7"
          x="387.2"
          y="1839.3"
          width="35"
          height="10"
        />
        <rect
          id="_45.4"
          data-name="45.4"
          className="cls-7"
          x="352.2"
          y="1839.4"
          width="35"
          height="10"
        />
        <rect
          id="_45.5"
          data-name="45.5"
          className="cls-7"
          x="317.2"
          y="1839.5"
          width="35"
          height="10"
        />
        <rect
          id="_45.6"
          data-name="45.6"
          className="cls-7"
          x="282.2"
          y="1839.5"
          width="35"
          height="10"
        />
        <rect
          id="_45.7"
          data-name="45.7"
          className="cls-7"
          x="247.2"
          y="1839.6"
          width="35"
          height="10"
        />
        <rect
          id="_46.1-2"
          data-name="46.1"
          className="cls-7"
          x="457.2"
          y="1870.4"
          width="35"
          height="10"
        />
        <rect
          id="_46.2-2"
          data-name="46.2"
          className="cls-7"
          x="422.2"
          y="1870.4"
          width="35"
          height="10"
        />
        <rect
          id="_46.3-2"
          data-name="46.3"
          className="cls-7"
          x="387.2"
          y="1870.3"
          width="35"
          height="10"
        />
        <rect
          id="_46.4"
          data-name="46.4"
          className="cls-7"
          x="352.2"
          y="1870.4"
          width="35"
          height="10"
        />
        <rect
          id="_46.5"
          data-name="46.5"
          className="cls-7"
          x="317.2"
          y="1870.5"
          width="35"
          height="10"
        />
        <rect
          id="_46.6"
          data-name="46.6"
          className="cls-7"
          x="282.2"
          y="1870.5"
          width="35"
          height="10"
        />
        <rect
          id="_46.7"
          data-name="46.7"
          className="cls-7"
          x="247.2"
          y="1870.6"
          width="35"
          height="10"
        />
        <rect
          id="_47.1-2"
          data-name="47.1"
          className="cls-7"
          x="457.2"
          y="1880.7"
          width="35"
          height="10"
        />
        <rect
          id="_47.2-2"
          data-name="47.2"
          className="cls-7"
          x="422.2"
          y="1880.7"
          width="35"
          height="10"
        />
        <rect
          id="_47.3-2"
          data-name="47.3"
          className="cls-7"
          x="387.2"
          y="1880.7"
          width="35"
          height="10"
        />
        <rect
          id="_47.4"
          data-name="47.4"
          className="cls-7"
          x="352.2"
          y="1880.8"
          width="35"
          height="10"
        />
        <rect
          id="_47.5"
          data-name="47.5"
          className="cls-7"
          x="317.2"
          y="1880.9"
          width="35"
          height="10"
        />
        <rect
          id="_47.6"
          data-name="47.6"
          className="cls-7"
          x="282.2"
          y="1880.9"
          width="35"
          height="10"
        />
        <rect
          id="_47.7"
          data-name="47.7"
          className="cls-7"
          x="247.2"
          y="1881"
          width="35"
          height="10"
        />
        <rect
          id="_48.1-2"
          data-name="48.1"
          className="cls-7"
          x="457.2"
          y="1905.4"
          width="35"
          height="10"
        />
        <rect
          id="_48.2-2"
          data-name="48.2"
          className="cls-7"
          x="422.2"
          y="1905.4"
          width="35"
          height="10"
        />
        <rect
          id="_48.3-2"
          data-name="48.3"
          className="cls-7"
          x="387.2"
          y="1905.4"
          width="35"
          height="10"
        />
        <rect
          id="_48.4"
          data-name="48.4"
          className="cls-7"
          x="337.5"
          y="1905.3"
          width="35"
          height="10"
        />
        <rect
          id="_48.5"
          data-name="48.5"
          className="cls-7"
          x="302.5"
          y="1905.3"
          width="35"
          height="10"
        />
        <rect
          id="_48.6"
          data-name="48.6"
          className="cls-7"
          x="267.5"
          y="1905.3"
          width="35"
          height="10"
        />
        <rect
          id="_48.7"
          data-name="48.7"
          className="cls-7"
          x="232.5"
          y="1905.3"
          width="35"
          height="10"
        />
        <rect
          id="_49.1-2"
          data-name="49.1"
          className="cls-7"
          x="457.2"
          y="1915.8"
          width="35"
          height="10"
        />
        <rect
          id="_49.2-2"
          data-name="49.2"
          className="cls-7"
          x="422.2"
          y="1915.8"
          width="35"
          height="10"
        />
        <rect
          id="_49.3-2"
          data-name="49.3"
          className="cls-7"
          x="387.2"
          y="1915.8"
          width="35"
          height="10"
        />
        <rect
          id="_49.4-2"
          data-name="49.4"
          className="cls-7"
          x="337.5"
          y="1915.6"
          width="35"
          height="10"
        />
        <rect
          id="_49.5"
          data-name="49.5"
          className="cls-7"
          x="302.5"
          y="1915.6"
          width="35"
          height="10"
        />
        <rect
          id="_49.6"
          data-name="49.6"
          className="cls-7"
          x="267.5"
          y="1915.6"
          width="35"
          height="10"
        />
        <rect
          id="_49.7"
          data-name="49.7"
          className="cls-7"
          x="232.5"
          y="1915.7"
          width="35"
          height="10"
        />
        <rect
          id="_50.1-2"
          data-name="50.1"
          className="cls-7"
          x="372.5"
          y="1954.5"
          width="35"
          height="10"
        />
        <rect
          id="_50.2-2"
          data-name="50.2"
          className="cls-7"
          x="337.5"
          y="1954.5"
          width="35"
          height="10"
        />
        <rect
          id="_50.3-2"
          data-name="50.3"
          className="cls-7"
          x="302.5"
          y="1954.5"
          width="35"
          height="10"
        />
        <rect
          id="_50.4-2"
          data-name="50.4"
          className="cls-7"
          x="267.5"
          y="1954.5"
          width="35"
          height="10"
        />
        <rect
          id="_50.5"
          data-name="50.5"
          className="cls-7"
          x="232.5"
          y="1954.6"
          width="35"
          height="10"
        />
        <rect
          id="_51.1-2"
          data-name="51.1"
          className="cls-5"
          x="372.5"
          y="1964.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.2-2"
          data-name="51.2"
          className="cls-5"
          x="337.2"
          y="1964.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.3-2"
          data-name="51.3"
          className="cls-5"
          x="302.5"
          y="1964.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.4-2"
          data-name="51.4"
          className="cls-5"
          x="267.5"
          y="1964.8"
          width="35"
          height="10"
        />
        <rect
          id="_51.5"
          data-name="51.5"
          className="cls-5"
          x="232.5"
          y="1964.9"
          width="35"
          height="10"
        />
        <rect
          id="_52.1-2"
          data-name="52.1"
          className="cls-7"
          x="567.3"
          y="1881.2"
          width="35"
          height="10"
        />
        <rect
          id="_52.2-2"
          data-name="52.2"
          className="cls-7"
          x="602.3"
          y="1881.1"
          width="35"
          height="10"
        />
        <rect
          id="_52.3-2"
          data-name="52.3"
          className="cls-7"
          x="637.3"
          y="1881.1"
          width="35"
          height="10"
        />
        <rect
          id="_52.4-2"
          data-name="52.4"
          className="cls-7"
          x="672.3"
          y="1881.1"
          width="35"
          height="10"
        />
        <rect
          id="_53.1-2"
          data-name="53.1"
          className="cls-7"
          x="567.3"
          y="1870.8"
          width="35"
          height="10"
        />
        <rect
          id="_53.2-2"
          data-name="53.2"
          className="cls-7"
          x="602.3"
          y="1870.8"
          width="35"
          height="10"
        />
        <rect
          id="_53.3-2"
          data-name="53.3"
          className="cls-7"
          x="637.3"
          y="1870.8"
          width="35"
          height="10"
        />
        <rect
          id="_53.4-2"
          data-name="53.4"
          className="cls-7"
          x="672.3"
          y="1870.8"
          width="35"
          height="10"
        />
        <rect
          id="_54.1-2"
          data-name="54.1"
          className="cls-7"
          x="567.5"
          y="1567.7"
          width="10"
          height="35"
        />
        <rect
          id="_54.2-2"
          data-name="54.2"
          className="cls-7"
          x="567.5"
          y="1532.7"
          width="10"
          height="35"
        />
        <rect
          id="_54.3-2"
          data-name="54.3"
          className="cls-7"
          x="567.5"
          y="1497.7"
          width="10"
          height="35"
        />
        <rect
          id="_54.4-2"
          data-name="54.4"
          className="cls-7"
          x="567.6"
          y="1429.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.5"
          data-name="54.5"
          className="cls-7"
          x="567.6"
          y="1394.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.6"
          data-name="54.6"
          className="cls-7"
          x="567.6"
          y="1359.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.7"
          data-name="54.7"
          className="cls-7"
          x="567.6"
          y="1324.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.8"
          data-name="54.8"
          className="cls-7"
          x="567.5"
          y="1289.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.9"
          data-name="54.9"
          className="cls-7"
          x="567.5"
          y="1254.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.10"
          data-name="54.10"
          className="cls-7"
          x="567.5"
          y="1219.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.11"
          data-name="54.11"
          className="cls-7"
          x="567.5"
          y="1184.8"
          width="10"
          height="35"
        />
        <rect
          id="_54.12"
          data-name="54.12"
          className="cls-7"
          x="567.6"
          y="1123"
          width="10"
          height="35"
        />
        <rect
          id="_54.13"
          data-name="54.13"
          className="cls-7"
          x="567.6"
          y="1088"
          width="10"
          height="35"
        />
        <rect
          id="_54.14"
          data-name="54.14"
          className="cls-7"
          x="567.6"
          y="1053"
          width="10"
          height="35"
        />
        <rect
          id="_54.15"
          data-name="54.15"
          className="cls-7"
          x="567.6"
          y="1018"
          width="10"
          height="35"
        />
        <rect
          id="_55.1-2"
          data-name="55.1"
          className="cls-7"
          x="577.5"
          y="1592.7"
          width="35"
          height="10"
        />
        <rect
          id="_55.2-2"
          data-name="55.2"
          className="cls-7"
          x="612.5"
          y="1592.6"
          width="35"
          height="10"
        />
        <rect
          id="_55.3-2"
          data-name="55.3"
          className="cls-7"
          x="647.5"
          y="1592.5"
          width="35"
          height="10"
        />
        <rect
          id="_55.4-2"
          data-name="55.4"
          className="cls-7"
          x="682.5"
          y="1592.6"
          width="35"
          height="10"
        />
        <rect
          id="_55.5"
          data-name="55.5"
          className="cls-7"
          x="717.5"
          y="1592.6"
          width="35"
          height="10"
        />
        <rect
          id="_56.1-2"
          data-name="56.1"
          className="cls-7"
          x="577.5"
          y="1582.4"
          width="35"
          height="10"
        />
        <rect
          id="_56.2-2"
          data-name="56.2"
          className="cls-7"
          x="612.5"
          y="1582.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.3-2"
          data-name="56.3"
          className="cls-7"
          x="647.5"
          y="1582.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.4-2"
          data-name="56.4"
          className="cls-7"
          x="682.5"
          y="1582.2"
          width="35"
          height="10"
        />
        <rect
          id="_56.5"
          data-name="56.5"
          className="cls-7"
          x="717.5"
          y="1582.2"
          width="35"
          height="10"
        />
        <rect
          id="_57.1-2"
          data-name="57.1"
          className="cls-7"
          x="577.5"
          y="1557.2"
          width="35"
          height="10"
        />
        <rect
          id="_57.2-2"
          data-name="57.2"
          className="cls-7"
          x="612.5"
          y="1557.1"
          width="35"
          height="10"
        />
        <rect
          id="_57.3-2"
          data-name="57.3"
          className="cls-7"
          x="647.5"
          y="1557"
          width="35"
          height="10"
        />
        <rect
          id="_57.4-2"
          data-name="57.4"
          className="cls-7"
          x="682.5"
          y="1557"
          width="35"
          height="10"
        />
        <rect
          id="_57.5"
          data-name="57.5"
          className="cls-7"
          x="717.5"
          y="1557"
          width="35"
          height="10"
        />
        <rect
          id="_58.1-2"
          data-name="58.1"
          className="cls-7"
          x="577.5"
          y="1546.8"
          width="35"
          height="10"
        />
        <rect
          id="_58.2-2"
          data-name="58.2"
          className="cls-7"
          x="612.5"
          y="1546.7"
          width="35"
          height="10"
        />
        <rect
          id="_58.3-2"
          data-name="58.3"
          className="cls-7"
          x="647.5"
          y="1546.6"
          width="35"
          height="10"
        />
        <rect
          id="_58.4-2"
          data-name="58.4"
          className="cls-7"
          x="682.5"
          y="1546.7"
          width="35"
          height="10"
        />
        <rect
          id="_58.5"
          data-name="58.5"
          className="cls-7"
          x="717.5"
          y="1546.7"
          width="35"
          height="10"
        />
        <rect
          id="_59.1-2"
          data-name="59.1"
          className="cls-7"
          x="578"
          y="1508.3"
          width="35"
          height="10"
        />
        <rect
          id="_59.2-2"
          data-name="59.2"
          className="cls-7"
          x="613"
          y="1508.2"
          width="35"
          height="10"
        />
        <rect
          id="_59.3-2"
          data-name="59.3"
          className="cls-7"
          x="648"
          y="1508.1"
          width="35"
          height="10"
        />
        <rect
          id="_59.4-2"
          data-name="59.4"
          className="cls-7"
          x="683"
          y="1508.1"
          width="35"
          height="10"
        />
        <rect
          id="_59.5"
          data-name="59.5"
          className="cls-7"
          x="718"
          y="1508.1"
          width="35"
          height="10"
        />
        <rect
          id="_60.1-2"
          data-name="60.1"
          className="cls-7"
          x="578"
          y="1497.9"
          width="35"
          height="10"
        />
        <rect
          id="_60.2-2"
          data-name="60.2"
          className="cls-7"
          x="613"
          y="1497.8"
          width="35"
          height="10"
        />
        <rect
          id="_60.3-2"
          data-name="60.3"
          className="cls-7"
          x="648"
          y="1497.7"
          width="35"
          height="10"
        />
        <rect
          id="_60.4-2"
          data-name="60.4"
          className="cls-7"
          x="683"
          y="1497.8"
          width="35"
          height="10"
        />
        <rect
          id="_60.5"
          data-name="60.5"
          className="cls-7"
          x="718"
          y="1497.8"
          width="35"
          height="10"
        />
        <rect
          id="_61.1-2"
          data-name="61.1"
          className="cls-7"
          x="577.5"
          y="1454.8"
          width="35"
          height="10"
        />
        <rect
          id="_61.2-2"
          data-name="61.2"
          className="cls-7"
          x="612.5"
          y="1454.6"
          width="35"
          height="10"
        />
        <rect
          id="_61.3-2"
          data-name="61.3"
          className="cls-7"
          x="647.5"
          y="1454.6"
          width="35"
          height="10"
        />
        <rect
          id="_61.4-2"
          data-name="61.4"
          className="cls-7"
          x="682.5"
          y="1454.6"
          width="35"
          height="10"
        />
        <rect
          id="_61.5"
          data-name="61.5"
          className="cls-7"
          x="717.5"
          y="1454.6"
          width="35"
          height="10"
        />
        <rect
          id="_62.1-2"
          data-name="62.1"
          className="cls-7"
          x="577.5"
          y="1444.4"
          width="35"
          height="10"
        />
        <rect
          id="_62.2-2"
          data-name="62.2"
          className="cls-7"
          x="612.5"
          y="1444.3"
          width="35"
          height="10"
        />
        <rect
          id="_62.3-2"
          data-name="62.3"
          className="cls-7"
          x="647.5"
          y="1444.2"
          width="35"
          height="10"
        />
        <rect
          id="_62.4-2"
          data-name="62.4"
          className="cls-7"
          x="682.5"
          y="1444.2"
          width="35"
          height="10"
        />
        <rect
          id="_62.5"
          data-name="62.5"
          className="cls-7"
          x="717.5"
          y="1444.2"
          width="35"
          height="10"
        />
        <rect
          id="_63.1-2"
          data-name="63.1"
          className="cls-7"
          x="577.6"
          y="1421.6"
          width="35"
          height="10"
        />
        <rect
          id="_63.2-2"
          data-name="63.2"
          className="cls-7"
          x="612.6"
          y="1421.5"
          width="35"
          height="10"
        />
        <rect
          id="_63.3-2"
          data-name="63.3"
          className="cls-7"
          x="647.6"
          y="1421.5"
          width="35"
          height="10"
        />
        <rect
          id="_63.4"
          data-name="63.4"
          className="cls-7"
          x="682.6"
          y="1421.5"
          width="35"
          height="10"
        />
        <rect
          id="_63.5"
          data-name="63.5"
          className="cls-7"
          x="717.6"
          y="1421.5"
          width="35"
          height="10"
        />
        <rect
          id="_64.1-2"
          data-name="64.1"
          className="cls-7"
          x="577.6"
          y="1411.3"
          width="35"
          height="10"
        />
        <rect
          id="_64.2-2"
          data-name="64.2"
          className="cls-7"
          x="612.6"
          y="1411.2"
          width="35"
          height="10"
        />
        <rect
          id="_64.3-2"
          data-name="64.3"
          className="cls-7"
          x="647.6"
          y="1411.1"
          width="35"
          height="10"
        />
        <rect
          id="_64.4"
          data-name="64.4"
          className="cls-7"
          x="682.6"
          y="1411.1"
          width="35"
          height="10"
        />
        <rect
          id="_64.5"
          data-name="64.5"
          className="cls-7"
          x="717.6"
          y="1411.1"
          width="35"
          height="10"
        />
        <rect
          id="_65.1-2"
          data-name="65.1"
          className="cls-7"
          x="577.6"
          y="1375.3"
          width="35"
          height="10"
        />
        <rect
          id="_65.2-2"
          data-name="65.2"
          className="cls-7"
          x="612.6"
          y="1375.2"
          width="35"
          height="10"
        />
        <rect
          id="_65.3-2"
          data-name="65.3"
          className="cls-7"
          x="647.6"
          y="1375.1"
          width="35"
          height="10"
        />
        <rect
          id="_65.4-2"
          data-name="65.4"
          className="cls-7"
          x="682.6"
          y="1375.1"
          width="35"
          height="10"
        />
        <rect
          id="_65.5-2"
          data-name="65.5"
          className="cls-7"
          x="717.6"
          y="1375.1"
          width="35"
          height="10"
        />
        <rect
          id="_66.1-2"
          data-name="66.1"
          className="cls-7"
          x="577.6"
          y="1364.9"
          width="35"
          height="10"
        />
        <rect
          id="_66.2-2"
          data-name="66.2"
          className="cls-7"
          x="612.6"
          y="1364.8"
          width="35"
          height="10"
        />
        <rect
          id="_66.3-2"
          data-name="66.3"
          className="cls-7"
          x="647.6"
          y="1364.7"
          width="35"
          height="10"
        />
        <rect
          id="_66.4-2"
          data-name="66.4"
          className="cls-7"
          x="682.6"
          y="1364.8"
          width="35"
          height="10"
        />
        <rect
          id="_66.5-2"
          data-name="66.5"
          className="cls-7"
          x="717.6"
          y="1364.8"
          width="35"
          height="10"
        />
        <rect
          id="_67.1"
          data-name="67.1"
          className="cls-7"
          x="577.6"
          y="1335.3"
          width="35"
          height="10"
        />
        <rect
          id="_67.2"
          data-name="67.2"
          className="cls-7"
          x="612.6"
          y="1335.2"
          width="35"
          height="10"
        />
        <rect
          id="_67.3"
          data-name="67.3"
          className="cls-7"
          x="647.6"
          y="1335.1"
          width="35"
          height="10"
        />
        <rect
          id="_67.4"
          data-name="67.4"
          className="cls-7"
          x="682.6"
          y="1335.2"
          width="35"
          height="10"
        />
        <rect
          id="_67.5"
          data-name="67.5"
          className="cls-7"
          x="717.6"
          y="1335.2"
          width="35"
          height="10"
        />
        <rect
          id="_68.1-2"
          data-name="68.1"
          className="cls-7"
          x="577.6"
          y="1325"
          width="35"
          height="10"
        />
        <rect
          id="_68.2-2"
          data-name="68.2"
          className="cls-7"
          x="612.6"
          y="1324.8"
          width="35"
          height="10"
        />
        <rect
          id="_68.3-2"
          data-name="68.3"
          className="cls-7"
          x="647.6"
          y="1324.8"
          width="35"
          height="10"
        />
        <rect
          id="_68.4"
          data-name="68.4"
          className="cls-7"
          x="682.6"
          y="1324.8"
          width="35"
          height="10"
        />
        <rect
          id="_68.5"
          data-name="68.5"
          className="cls-7"
          x="717.6"
          y="1324.8"
          width="35"
          height="10"
        />
        <rect
          id="_69.1-2"
          data-name="69.1"
          className="cls-7"
          x="577.6"
          y="1300.3"
          width="35"
          height="10"
        />
        <rect
          id="_69.2-2"
          data-name="69.2"
          className="cls-7"
          x="612.6"
          y="1300.3"
          width="35"
          height="10"
        />
        <rect
          id="_69.3"
          data-name="69.3"
          className="cls-7"
          x="647.6"
          y="1300.1"
          width="35"
          height="10"
        />
        <rect
          id="_69.4"
          data-name="69.4"
          className="cls-7"
          x="682.6"
          y="1300.2"
          width="35"
          height="10"
        />
        <rect
          id="_69.5"
          data-name="69.5"
          className="cls-7"
          x="717.6"
          y="1300.2"
          width="35"
          height="10"
        />
        <rect
          id="_70.1-2"
          data-name="70.1"
          className="cls-7"
          x="577.6"
          y="1290"
          width="35"
          height="10"
        />
        <rect
          id="_70.2-2"
          data-name="70.2"
          className="cls-7"
          x="612.6"
          y="1289.9"
          width="35"
          height="10"
        />
        <rect
          id="_70.3"
          data-name="70.3"
          className="cls-7"
          x="647.6"
          y="1289.8"
          width="35"
          height="10"
        />
        <rect
          id="_70.4"
          data-name="70.4"
          className="cls-7"
          x="682.6"
          y="1289.8"
          width="35"
          height="10"
        />
        <rect
          id="_70.5"
          data-name="70.5"
          className="cls-7"
          x="717.6"
          y="1289.8"
          width="35"
          height="10"
        />
        <rect
          id="_71.1-2"
          data-name="71.1"
          className="cls-7"
          x="577.6"
          y="1241.6"
          width="35"
          height="10"
        />
        <rect
          id="_71.2-2"
          data-name="71.2"
          className="cls-7"
          x="612.6"
          y="1241.5"
          width="35"
          height="10"
        />
        <rect
          id="_71.3"
          data-name="71.3"
          className="cls-7"
          x="647.6"
          y="1241.4"
          width="35"
          height="10"
        />
        <rect
          id="_71.4"
          data-name="71.4"
          className="cls-7"
          x="682.6"
          y="1241.4"
          width="35"
          height="10"
        />
        <rect
          id="_71.5"
          data-name="71.5"
          className="cls-7"
          x="717.6"
          y="1241.4"
          width="35"
          height="10"
        />
        <rect
          id="_72.1-2"
          data-name="72.1"
          className="cls-7"
          x="577.6"
          y="1231.3"
          width="35"
          height="10"
        />
        <rect
          id="_72.2-2"
          data-name="72.2"
          className="cls-7"
          x="612.6"
          y="1231.1"
          width="35"
          height="10"
        />
        <rect
          id="_72.3"
          data-name="72.3"
          className="cls-7"
          x="647.6"
          y="1231.1"
          width="35"
          height="10"
        />
        <rect
          id="_72.4"
          data-name="72.4"
          className="cls-7"
          x="682.6"
          y="1231.1"
          width="35"
          height="10"
        />
        <rect
          id="_72.5"
          data-name="72.5"
          className="cls-7"
          x="717.6"
          y="1231.1"
          width="35"
          height="10"
        />
        <rect
          id="_73.1-2"
          data-name="73.1"
          className="cls-7"
          x="577.6"
          y="1195.3"
          width="35"
          height="10"
        />
        <rect
          id="_73.2-2"
          data-name="73.2"
          className="cls-7"
          x="612.6"
          y="1195.2"
          width="35"
          height="10"
        />
        <rect
          id="_73.3"
          data-name="73.3"
          className="cls-7"
          x="647.6"
          y="1195.1"
          width="35"
          height="10"
        />
        <rect
          id="_73.4"
          data-name="73.4"
          className="cls-7"
          x="682.6"
          y="1195.2"
          width="35"
          height="10"
        />
        <rect
          id="_73.5"
          data-name="73.5"
          className="cls-7"
          x="717.6"
          y="1195.2"
          width="35"
          height="10"
        />
        <rect
          id="_74.1-2"
          data-name="74.1"
          className="cls-7"
          x="577.6"
          y="1185"
          width="35"
          height="10"
        />
        <rect
          id="_74.2-2"
          data-name="74.2"
          className="cls-7"
          x="612.6"
          y="1184.8"
          width="35"
          height="10"
        />
        <rect
          id="_74.3"
          data-name="74.3"
          className="cls-7"
          x="647.6"
          y="1184.8"
          width="35"
          height="10"
        />
        <rect
          id="_74.4"
          data-name="74.4"
          className="cls-7"
          x="682.6"
          y="1184.8"
          width="35"
          height="10"
        />
        <rect
          id="_74.5"
          data-name="74.5"
          className="cls-7"
          x="717.6"
          y="1184.8"
          width="35"
          height="10"
        />
        <rect
          id="_75.1-2"
          data-name="75.1"
          className="cls-7"
          x="577.6"
          y="1152.1"
          width="35"
          height="10"
        />
        <rect
          id="_75.2-2"
          data-name="75.2"
          className="cls-7"
          x="612.6"
          y="1152.1"
          width="35"
          height="10"
        />
        <rect
          id="_75.3"
          data-name="75.3"
          className="cls-7"
          x="647.6"
          y="1152.1"
          width="35"
          height="10"
        />
        <rect
          id="_75.4"
          data-name="75.4"
          className="cls-7"
          x="682.6"
          y="1152.1"
          width="35"
          height="10"
        />
        <rect
          id="_75.5"
          data-name="75.5"
          className="cls-7"
          x="717.6"
          y="1152.1"
          width="35"
          height="10"
        />
        <rect
          id="_76.1-2"
          data-name="76.1"
          className="cls-7"
          x="612.6"
          y="1141.8"
          width="35"
          height="10"
        />
        <rect
          id="_76.2-2"
          data-name="76.2"
          className="cls-7"
          x="647.6"
          y="1141.7"
          width="35"
          height="10"
        />
        <rect
          id="_76.3"
          data-name="76.3"
          className="cls-7"
          x="682.6"
          y="1141.7"
          width="35"
          height="10"
        />
        <rect
          id="_76.4"
          data-name="76.4"
          className="cls-7"
          x="717.6"
          y="1141.7"
          width="35"
          height="10"
        />
        <rect
          id="_77.1"
          data-name="77.1"
          className="cls-7"
          x="577.6"
          y="1088.3"
          width="35"
          height="10"
        />
        <rect
          id="_77.2"
          data-name="77.2"
          className="cls-7"
          x="612.6"
          y="1088.2"
          width="35"
          height="10"
        />
        <rect
          id="_77.3"
          data-name="77.3"
          className="cls-7"
          x="647.6"
          y="1088.1"
          width="35"
          height="10"
        />
        <rect
          id="_77.4"
          data-name="77.4"
          className="cls-7"
          x="682.6"
          y="1088.1"
          width="35"
          height="10"
        />
        <rect
          id="_77.5"
          data-name="77.5"
          className="cls-7"
          x="717.6"
          y="1088.1"
          width="35"
          height="10"
        />
        <rect
          id="_78.1"
          data-name="78.1"
          className="cls-7"
          x="577.6"
          y="1077.9"
          width="35"
          height="10"
        />
        <rect
          id="_78.2"
          data-name="78.2"
          className="cls-7"
          x="612.6"
          y="1077.8"
          width="35"
          height="10"
        />
        <rect
          id="_78.3"
          data-name="78.3"
          className="cls-7"
          x="682.6"
          y="1077.8"
          width="35"
          height="10"
        />
        <rect
          id="_78.4"
          data-name="78.4"
          className="cls-7"
          x="717.6"
          y="1077.8"
          width="35"
          height="10"
        />
        <rect
          id="_78.5"
          data-name="78.5"
          className="cls-7"
          x="647.6"
          y="1077.7"
          width="35"
          height="10"
        />
        <rect
          id="_79"
          data-name="79"
          className="cls-7"
          x="577.6"
          y="1022.6"
          width="10"
          height="35"
        />
        <rect
          id="_80.1"
          data-name="80.1"
          className="cls-7"
          x="578.1"
          y="893"
          width="10"
          height="35"
        />
        <rect
          id="_80.2"
          data-name="80.2"
          className="cls-7"
          x="588.1"
          y="893"
          width="10"
          height="35"
        />
        <rect
          id="_81.1"
          data-name="81.1"
          className="cls-7"
          x="578.1"
          y="842.1"
          width="10"
          height="35"
        />
        <rect
          id="_81.2"
          data-name="81.2"
          className="cls-7"
          x="588.1"
          y="842.1"
          width="10"
          height="35"
        />
      </g>
    </svg>
  );
};
