import React, { useEffect } from "react";
import TableUsers from "../../components/TableUsers";
import { useWindowDimensions } from "../../../../helpers";
import MobileUsers from "../../components/MobileUsers";

const Members = ({
  members,
  history,
  getMembers,
  setMembers,
  initialMembers,
}) => {
  const { width } = useWindowDimensions();
  useEffect(() => {
    getMembers();

    return () => setMembers(initialMembers);
  }, []);

  return members.loaded ? (
    width > 430 ? (
      <TableUsers data={members.data} history={history} />
    ) : (
      <MobileUsers data={members.data} history={history} />
    )
  ) : null;
};

export default Members;
