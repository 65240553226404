export default [
  // {
  //   point_id: "CUNB_SOSED",
  //   point_name: "ПРД «День соседа»",
  //   point_accounting: true,
  //   point_consultations: true,
  //   point_excursions: false,
  //   point_visuals: false,
  //   point_department: ["ОБИО", "ОИТ"],
  // },
  // {
  //   point_id: "CUNB_MOBIUS",
  //   point_name: "Мобиус",
  //   point_accounting: true,
  //   point_consultations: true,
  //   point_excursions: false,
  //   point_visuals: false,
  //   point_department: ["ОБИО", "ОИТ"],
  // },
  {
    point_id: "CUNB_ANG",
    point_name: "ТЦ Ангара",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_VODN",
    point_name: "ТЦ Водный",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_HUD",
    point_name: "Художественный",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_POCHT",
    point_name: "Большая Почтовая",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ИБО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_SOFIA",
    point_name: "РЦ София",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_KUZ",
    point_name: "Кузьминки",
    point_accounting: true,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОБИО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_ENT",
    point_name: "Главное Здание",
    point_accounting: false,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОПЧПФ", "ОКФ", "ОБИО", "ИБО", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_ORIK_IN",
    point_name: "ОРИК (Кафедра отдела)",
    point_accounting: false,
    point_consultations: true,
    point_excursions: true,
    point_visuals: true,
    point_department: ["ОРИК", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_ORIK_OUT",
    point_name: "ОРИК (Кафедра общая)",
    point_accounting: false,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОРИК", "ОИТ", "Дирекция"],
  },
  {
    point_id: "CUNB_OUBF",
    point_name: "ОУБФ",
    point_accounting: false,
    point_consultations: true,
    point_excursions: false,
    point_visuals: false,
    point_department: ["ОУБФ", "ОИТ", "Дирекция"],
  },
];
