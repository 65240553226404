import React, { useEffect } from "react";
import styled from "styled-components";
import Section from "../Section";
import Icon from "../../../../nekrasovka-ui/Icon";

export default ({ floor, handleBookcase, clickedFloor, setClickedFloor }) => {
  const handleImage = () => setClickedFloor(floor);

  // const parseFloorsId = () => {
  //   const floor = {};
  //
  //   [1, 2, 3, 4].forEach((item) => {
  //     const all = document.querySelectorAll(`._${item}fl_bookstands rect`);
  //     const obj = {};
  //
  //     Array.from(all).forEach((element) => {
  //       const id = element.id.split(".")[0].replace("_", "");
  //       const x = element.getAttribute("x");
  //       const y = element.getAttribute("y");
  //
  //       obj[id] = { ...obj[id], [element.id]: { x, y } };
  //
  //       return obj;
  //     });
  //
  //     floor[item] = obj;
  //   });
  //
  //   console.log("❗", floor);
  // };
  //
  // useEffect(() => {
  //   parseFloorsId();
  // }, []);

  return (
    <Container isFloor={clickedFloor === floor}>
      <div>
        <span>Этаж {floor}</span>
        {clickedFloor === floor && (
          <div onClick={() => setClickedFloor(null)}>
            <Icon icon="closeModal" height="20" width="20" fill="red" />
          </div>
        )}
      </div>
      <StyledDiv onClick={handleImage} floor={floor}>
        <Icon icon="floors" />
      </StyledDiv>
    </Container>
  );
};

const StyledDiv = styled.div`
  > svg {
    ._1fl_walls_doors,
    ._1fl_bookstands,
    ._2fl_walls_doors,
    ._2fl_bookstands,
    ._3fl_walls_doors,
    ._3fl_bookstands,
    ._4fl_walls_doors,
    ._4fl_bookstands {
      display: none;
    }

    [data-name="${({ floor }) => floor}fl_walls_doors"],
    [data-name="${({ floor }) => floor}fl_bookstands"] {
      display: block;
    }

    .cls-1,
    .cls-2,
    .cls-3 {
      fill: #000;
    }

    .cls-1,
    .cls-4 {
      stroke-width: 0;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18,
    .cls-19 {
      stroke: #000;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-14,
    .cls-19 {
      stroke-miterlimit: 10;
    }

    .cls-5,
    .cls-9,
    .cls-4 {
      fill: #fff;
    }

    .cls-2,
    .cls-19 {
      stroke-width: 10px;
    }

    .cls-6 {
      stroke-width: 20px;
    }

    .cls-6,
    .cls-8,
    .cls-10,
    .cls-12,
    .cls-14,
    .cls-19 {
      fill: none;
    }

    .cls-7,
    .cls-13,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18 {
      fill: #b3b3b3;
    }

    .cls-9,
    .cls-10 {
      stroke-width: 0.5px;
    }

    .cls-11 {
      fill: gray;
    }

    .cls-12 {
      stroke-width: 11px;
    }

    .cls-3 {
      font-size: 42px;
      isolation: isolate;
    }

    .cls-13 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-14 {
      stroke-width: 5px;
    }

    .cls-15 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-16 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-17 {
      stroke-miterlimit: 10;
    }

    .cls-17,
    .cls-18 {
      stroke-width: 1px;
    }

    .cls-18 {
      stroke-miterlimit: 10;
    }
  }
`;

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 15px;
  background-color: rgb(237, 238, 233);
  box-sizing: border-box;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    > div {
      position: absolute;
      z-index: 111;
      right: 15px;
      top: 15px;
    }
  }

  > div:nth-child(2) {
    svg {
      ${({ isFloor }) =>
        isFloor
          ? `
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    background-color: rgb(237, 238, 233);
 `
          : "width: 100%;"};
    }
  }
`;
