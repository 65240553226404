import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  height: fit-content;

  @media (min-width: 769px) {
    padding: 20px 15px 20px 30px;
    margin-right: 30px;
  }

  @media (max-width: 768px) and (min-width: 701px) {
    padding: 20px 15px;
    margin-right: 15px;
  }

  @media (min-width: 701px) {
    max-width: 100%;
    min-height: 100vh;

    border-bottom: 1px solid rgb(196, 196, 196);
    border-top: 1px solid rgb(196, 196, 196);

    border-right: 1px;
    border-right-style: solid;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right-color: rgb(196, 196, 196);
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

const TableTitle = styled.div`
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    margin-left: 15px;
    padding-top: 20px;
  }
`;

const Table = styled.div`
  :nth-child(1),
  :nth-child(2) {
    > div:nth-child(2) {
      overflow-x: scroll;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  :nth-child(1) {
    tbody tr {
      cursor: pointer;
    }

    th:nth-child(n + 2) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: center;
    }
  }

  :nth-child(2) {
    th:nth-child(n + 3) {
      text-align: left;
    }

    td:nth-child(-n + 2) {
      text-align: center;
    }
  }

  table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 10px;
    }

    tbody {
      tr {
        :not(:last-child) {
          border-bottom: 1px solid #d8d8d8;
        }

        td:nth-child(3) {
          white-space: nowrap;
        }
      }
    }

    thead {
      tr {
        th {
          font-weight: 500;
          background: #d8d8d8;
          white-space: nowrap;
        }
      }
    }
  }
`;

const TableThSort = styled.th`
  cursor: pointer;
  position: relative;

  :after {
    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    display: inline-block;
    content: "\\2193";
    margin-left: 10px;
    transform: rotate(${({ isRotate }) => (isRotate ? "180deg" : "0")});
  }
`;

const TableUserImage = styled.td`
  img {
    object-fit: cover;
    width: 48px;
    height: 48px;
    border-radius: 5px;
  }
`;

const EmailLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const TableUsers = ({ data, history }) => {
  const [items, setItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [colNumber, setColNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState("asc");

  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const perPage = 10;
  const totalPages = Math.ceil(data.length / perPage);

  useEffect(() => {
    getSortedItems(1, "asc");
  }, []);

  const getSortedItems = (col, order) => {
    const temp = sortItems(col, order);

    setSortedItems(temp);
    if (!!currentPage) setCurrentPage(0);
    if (!hasMore) setHasMore(true);

    const newItems = temp.slice(0, perPage);

    setItems(newItems);
  };

  const sortItems = (col, order) => {
    return [...data].sort((a, b) => {
      const nameA = getName(col, a);
      const nameB = getName(col, b);

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else return nameB.localeCompare(nameA);
    });
  };

  const getName = (col, init) => {
    const objectName = {
      1: init["name"],
      2: init["department"]["login"],
    };

    return objectName[col];
  };

  const getItems = (page) => {
    if (hasMore) {
      const start = page * perPage;
      const newItems = sortedItems.slice(start, start + perPage);

      if (page === totalPages) {
        setHasMore(false);
      }

      setItems([...items, ...newItems]);
    }
  };

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const sHeight = document.documentElement.scrollHeight;
    const cHeight = document.documentElement.clientHeight;

    if (scrollTop + cHeight >= sHeight) {
      const page = currentPage + 1;

      setCurrentPage(page);
      getItems(page);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [items]);

  return (
    <Container>
      <Table>
        <TableTitle>Коллеги</TableTitle>
        <div>
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Аватар</th>
                <TableThSort
                  isActive={colNumber === 1}
                  isRotate={sortOrder !== "asc"}
                  onClick={() => {
                    let order = "asc";
                    let col = 1;

                    if (colNumber === col) {
                      order = sortOrder === "asc" ? "desc" : "asc";
                    } else setColNumber(col);

                    getSortedItems(col, order);
                    setSortOrder(order);
                  }}
                >
                  ФИО
                </TableThSort>
                <th>О себе</th>
                <th>Почта (НЕК)</th>
                <TableThSort
                  isActive={colNumber === 2}
                  isRotate={sortOrder !== "asc"}
                  onClick={() => {
                    let order = "asc";
                    let col = 2;

                    if (colNumber === col) {
                      order = sortOrder === "asc" ? "desc" : "asc";
                    } else setColNumber(col);

                    setSortOrder(order);
                    getSortedItems(col, order);
                  }}
                >
                  Аббревиатура отдела
                </TableThSort>
              </tr>
            </thead>
            <tbody>
              {items.map(
                ({ id, login, name, status, email, department }, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => history.push(`/staff/users/${login}`)}
                    >
                      <td>{index + 1}</td>
                      <TableUserImage>
                        <img
                          src={`https://filin.mail.ru/pic?from=ph&email=${email}&width=180&height=180`}
                          alt="аватар"
                        />
                      </TableUserImage>
                      <td>{name}</td>
                      <td>{status}</td>
                      <td>
                        <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
                      </td>
                      <td>{department.login}</td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
      </Table>
    </Container>
  );
};

export default TableUsers;
