import React from "react";

export default (props) => (
  <svg
    {...props}
    viewBox="0 0 36 66"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Canvas" transform="translate(-1213 -794)">
      <g id="Vector">
        <path
          id="path0_fill"
          transform="matrix(6.94379e-17 -1 1 5.39964e-17 1213 860)"
          fillRule="evenodd"
          d="M 0 1.875L 1.85393 0L 33 32.3077L 64.1461 0L 66 1.875L 33 36L 0 1.875Z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);
