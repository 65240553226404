import * as types from "./actionTypes";

const initialState = {
  collection_id: "excursions",
  excursionsData: [],
  isExcursionsGet: false,
  isExcursionsUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EXCURSIONS_FETCH_SUCCESS:
      return {
        ...state,
        excursionsData: action.payload,
        isExcursionsGet: true,
      };
    case types.EXCURSIONS_UPDATE:
      return {
        ...state,
        isExcursionsGet: false,
        isExcursionsUpdate: false,
      };

    case types.EXCURSIONS_UPDATE_SUCCESS:
      return {
        ...state,
        isExcursionsUpdate: action.payload,
      };
    case types.EXCURSIONS_RESET:
      return initialState;

    default:
      return state;
  }
};
