import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Button from "../../../../nekrasovka-ui/Button";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: fit-content;

  @media (min-width: 701px) {
    padding: 20px 15px 20px 30px;
    border-bottom: 1px solid rgb(196, 196, 196);
    border-top: 1px solid rgb(196, 196, 196);

    border-right: 1px;
    border-right-style: solid;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right-color: rgb(196, 196, 196);
    width: 436px;
  }

  @media (max-width: 700px) {
    padding: 20px 0 20px;
    margin: 0 15px;

    :not(:last-child) {
      border-bottom: 1px solid rgb(196, 196, 196);
    }
  }

  a {
    :hover {
      text-decoration: underline;
    }
  }
`;

const StaffStatus = styled.div`
  display: grid;
  column-gap: 15px;
  grid-template-columns: 1fr auto;

  textarea {
    min-height: 20px;
    width: 100%;
    line-height: 20px;
    font-size: 14px;
    background: initial;
    border: none;
    overflow: hidden;
    padding: 0;
    color: rgb(34, 34, 34);
  }

  > div:nth-child(2) {
    display: flex;

    img {
      border-radius: 5px;
      object-fit: cover;
      ${({ isDisabled }) => !isDisabled && "cursor: pointer;"}
    }
  }

  @media (min-width: 701px) {
    > div:nth-child(2) {
      width: 96px;
      height: 96px;
    }
  }

  @media (max-width: 700px) {
    > div:nth-child(2) {
      width: 67px;
      height: 67px;
    }
  }
`;

const StaffInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 14px;

  * {
    font-size: 14px !important;
  }

  ul {
    margin-top: 5px;
    padding-left: 0;

    li {
      list-style: none;

      div {
        display: flex;

        span {
          margin-right: 5px;
        }
      }

      :not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;

const StaffInfoDepartment = styled.div`
  > span {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const StaffInfoTitle = styled.span`
  color: rgb(119, 119, 119);
`;

const StaffName = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > span {
    font-weight: 500;
  }
`;

const StaffButtons = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`;

const Card = ({
  handleCancel = null,
  handleSave = null,
  handleChange = null,
  isUpdated = false,
  isDisabled = true,
  handleCard = null,
  state,
  status,
}) => {
  const statusRef = useRef();

  const textareaAdjust = (el) => {
    el.style.height = "auto";
    el.style.height = el.scrollHeight + "px";
  };

  const onImageClick = () => {
    return isDisabled
      ? null
      : window
          .open(
            `https://e.mail.ru/inbox/?authid=lslwiif9.cr&back=1%2C1&dwhsplit=s10273.b1ss12743s&from=login&x-login-auth=1&afterReload=1&x-email=${state.email}`,
            "_blank",
          )
          .focus();
  };

  useEffect(() => {
    if (statusRef.current) textareaAdjust(statusRef.current);
  }, [statusRef.current, status]);

  return (
    <Container>
      <StaffStatus isDisabled={isDisabled}>
        <StaffName>
          <span onClick={handleCard}>{state.name}</span>
          <textarea
            disabled={isDisabled}
            value={status}
            placeholder="О себе"
            name="status"
            ref={statusRef}
            onChange={handleChange}
          >
            {status}
          </textarea>
        </StaffName>
        <div>
          <img
            src={`https://filin.mail.ru/pic?from=ph&email=${state.email}&width=180&height=180`}
            alt="аватар"
            onClick={onImageClick}
          />
        </div>
      </StaffStatus>
      <StaffInfo>
        <StaffInfoDepartment>
          <StaffInfoTitle>Отдел:</StaffInfoTitle>{" "}
          <Link to={`/staff/departments/${state.department.login}`}>
            {state.department.name} ({state.department.login})
          </Link>
        </StaffInfoDepartment>
        <div>
          <StaffInfoTitle>Почта (НЕК): </StaffInfoTitle>
          <a href={`mailto:${state.email}`}>{state.email}</a>
        </div>
      </StaffInfo>
      {isUpdated && (
        <StaffButtons>
          <Button name="Сохранить" onClick={handleSave} />
          <Button name="Отмена" onClick={handleCancel} />
        </StaffButtons>
      )}
    </Container>
  );
};

export default Card;
