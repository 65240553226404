import React from "react";
import styled from "styled-components";
import Button from "../Button";

export default ({
  buttons,
  groupName,
  activeButton,
  handleButton,
  buttonStyles,
  className,
  isDisabled,
  tooltip,
}) => {
  return (
    <Container className={className}>
      {Array.isArray(buttons)
        ? buttons.map((button, id) => {
            const isActive = Array.isArray(activeButton)
              ? activeButton.includes(id)
              : activeButton === id;

            const data = {};

            if (!!tooltip) data.btnTooltip = tooltip[id];

            return (
              <Button
                key={id}
                isActive={isActive}
                isDisabled={isDisabled}
                onClick={() => handleButton(groupName, id)}
                name={button}
                buttonStyles={buttonStyles}
                {...data}
              />
            );
          })
        : Object.keys(buttons).map((id) => {
            const isActive = Array.isArray(activeButton)
              ? activeButton.includes(id)
              : activeButton === id;

            const data = {};

            if (!!tooltip) data.btnTooltip = tooltip[id];

            return (
              <Button
                key={id}
                isActive={isActive}
                isDisabled={isDisabled}
                onClick={() => handleButton(groupName, id)}
                name={buttons[id]}
                buttonStyles={buttonStyles}
                {...data}
              />
            );
          })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  @media (min-width: 769px) {
    width: fit-content;

    > :not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }

    > :last-child {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
    }

    > :first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    > :not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
    }

    > :last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    > :first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
`;
