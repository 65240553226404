import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled.div`
  ${({ linkStyle }) => linkStyle};
`;

export default ({ children, to, isExternal, className, linkStyle }) => {
  const params = isExternal
    ? { href: to, target: "_blank", rel: "noopener noreferrer", as: "a" }
    : { to: to, as: Link };

  return (
    <StyledLink className={className} {...params} linkStyle={linkStyle}>
      {children}
    </StyledLink>
  );
};
