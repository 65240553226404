import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import "./skin-blue.css";
import "./AdminLTE.css";
import locale from "date-fns/locale/ru";
import { Helmet } from "react-helmet";
import format from "date-fns/format";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";

import Place from "../../components/Place";
import { scrollToIf } from "../../helpers";
import Link from "../../nekrasovka-ui/Link";
import { setPoint, resetPoint, getPoints } from "../../reducers/points/actions";
import {
  getAccounting,
  resetAccounting,
} from "../../reducers/accounting/actions";
import {
  getConsultations,
  getConsultationsTypicalResponses,
  resetConsultations,
} from "../../reducers/consultations/actions";
import {
  getExcursions,
  resetExcursions,
} from "../../reducers/excursions/actions";
import { getVisuals, resetVisuals } from "../../reducers/visuals/actions";

import { footerLinks } from "../../database/footerLinks";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import LibrarianNews from "../../components/LibrarianNews";
import LibrarianSettings from "../../components/LibrarianSettings";
import Message from "../../components/Message";
import Icon from "../../nekrasovka-ui/Icon";
import { KeycloakContext } from "react-keycloak-nekrasovka";
import Organisation from "../../components/Organizations";

const Librarian = ({
  getPoints,
  resetPoint,
  setPoint,
  point,
  points,
  name,
  user,
  today,
  getAccounting,
  resetAccounting,
  getConsultations,
  getConsultationsTypicalResponses,
  resetConsultations,
  getExcursions,
  resetExcursions,
  getVisuals,
  resetVisuals,
}) => {
  const newDate = new Date();
  const minDate = new Date("2022-01-01");
  const { onExit } = useContext(KeycloakContext);
  const [date, setDate] = useState(newDate);
  const [isCalendar, setIsCalendar] = useState(false);
  const [isContentModal, setIsContentModal] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [isOrganisation, setIsOrganisation] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(newDate, "dd.MM.yyyy", { locale }),
  );

  const getByParams = (fDate) => {
    if (point.point_accounting) {
      resetAccounting();
      getAccounting(fDate);
    }

    if (point.point_excursions) {
      resetExcursions();
      getExcursions(fDate);
    }

    if (point.point_visuals) {
      resetVisuals();
      getVisuals(fDate);
    }

    if (point.point_consultations) {
      resetConsultations();
      getConsultations(fDate);

      if (fDate === today) getConsultationsTypicalResponses(today);
    }
  };

  const handlePointDataByDate = (d) => {
    const sDate = format(d, "dd.MM.yyyy", { locale });
    const fDate = format(d, "yyyy-MM-dd", { locale });

    getByParams(fDate);

    setDate(d);
    setIsCalendar(false);
    setIsToday(today === fDate);
    setSelectedDate(sDate);
  };

  const handlePointDataBySettings = () => {
    const fDate = format(date, "yyyy-MM-dd", { locale });

    getByParams(fDate);
  };

  const toggleBar = () => {
    const toggleEl = document.querySelector(".skin-blue.sidebar-mini");
    const isMobile = window.innerWidth <= 768;
    const token = isMobile ? "sidebar-open" : "sidebar-collapse";

    toggleEl.classList.toggle(token);
  };

  const toggleMenu = ({ currentTarget }) => {
    currentTarget.parentNode.classList.toggle("active");
  };

  const handlePoint = (pointId) => {
    const isMobile = window.innerWidth <= 768;

    if (isOrganisation) {
      setIsOrganisation(false);
    }

    setPoint(pointId);

    if (isMobile) {
      const toggleEl = document.querySelector(".skin-blue.sidebar-mini");
      toggleEl.classList.toggle("sidebar-open");
    }
  };

  const handleLibrarianNews = () => {
    if (isOrganisation) {
      setIsOrganisation(false);
    }

    resetPoint();
  };

  const handleMessage = () => {
    setIsMessage(true);
  };

  const handleOrganisation = () => {
    setIsOrganisation(true);
  };

  useEffect(() => {
    const sDate = format(newDate, "dd.MM.yyyy", { locale });

    setDate(newDate);
    setIsCalendar(false);
    setIsToday(true);
    setSelectedDate(sDate);
  }, [point.point_id]);

  useEffect(() => {
    scrollToIf("top");
    getPoints();

    return () => resetPoint();
  }, []);

  return (
    <LibrarianBody className="skin-blue sidebar-mini">
      <Helmet>
        <meta name="theme-color" content="#367fa9" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>
      <div className="wrapper">
        <header className="main-header">
          <a className="librarian-body_link logo" href="/">
            <span className="logo-mini">
              <b>N</b>AC
            </span>
            <span className="logo-lg">
              <b>Nekrasovka</b>AC
            </span>
          </a>
          <nav className="navbar navbar-static-top" role="navigation">
            <a className="sidebar-toggle" onClick={toggleBar}>
              <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-static-top_settings">
              <LibrarianHeaderUser
                onClick={() => setIsContentModal(!isContentModal)}
              >
                {name}
              </LibrarianHeaderUser>
              <div onClick={onExit}>
                <Icon icon="exit" fill="rgb(255, 255, 255)" />
              </div>
            </div>
            <LibrarianSettings
              isContentModal={isContentModal}
              setIsContentModal={setIsContentModal}
              handlePointDataBySettings={handlePointDataBySettings}
            />
          </nav>
        </header>
        <aside className="main-sidebar">
          <section className="sidebar">
            <ul className="sidebar-menu tree" data-widget="tree">
              <li className="active">
                <a onClick={toggleMenu}>
                  <i className="fa fa-book" aria-hidden="true"></i>
                  <span>Книги учета и консультаций</span>
                </a>
                <ul className="treeview-menu">
                  {points.map(({ point_name, point_id }, pointId) => {
                    return (
                      <PlaceLink
                        key={pointId}
                        isPlaceLink={point.point_id === point_id}
                      >
                        <a key={pointId} onClick={() => handlePoint(pointId)}>
                          {point_name}
                        </a>
                      </PlaceLink>
                    );
                  })}
                </ul>
              </li>
              <li>
                <a onClick={handleOrganisation}>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                  <span>Учёт методических консультаций</span>
                </a>
              </li>
              <li>
                <a onClick={toggleMenu}>
                  <i className="fa fa-link" aria-hidden="true"></i>
                  <span>Полезные ссылки</span>
                </a>
                <ul className="treeview-menu">
                  {footerLinks.map(({ href, isExternal, name }, linkId) => (
                    <li key={linkId}>
                      <Link to={href} isExternal={isExternal}>
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a onClick={handleLibrarianNews}>
                  <i className="fa fa-podcast" aria-hidden="true"></i>
                  <span>Новости приложения</span>
                </a>
              </li>
              <li>
                <a onClick={handleMessage}>
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  <span>Обратная связь</span>
                </a>
              </li>
            </ul>
          </section>
        </aside>
        <div className="content-wrapper">
          {isMessage && (
            <Message
              setIsMessage={setIsMessage}
              name={name}
              user={user}
              today={today}
            />
          )}
          {isOrganisation ? (
            <section className="content container-fluid">
              <Organisation />
            </section>
          ) : point.isPoint ? (
            <Fragment>
              <LibrarianPointHeader className="content-header">
                <h1>{point.point_name}</h1>
                <LibrarianPointCalendarDate
                  onClick={() => setIsCalendar(!isCalendar)}
                >
                  {selectedDate}
                  <i className="fa fa-calendar" aria-hidden="true" />
                </LibrarianPointCalendarDate>
                <ContentModal
                  contentModalStyle={`top: 50px; right: 15px;`}
                  isContentModal={isCalendar}
                  setIsContentModal={setIsCalendar}
                >
                  <LibrarianPointCalendar>
                    <Calendar
                      selectRange={false}
                      onChange={handlePointDataByDate}
                      value={date}
                      maxDate={newDate}
                      minDate={minDate}
                      locale="ru-RU"
                    />
                  </LibrarianPointCalendar>
                </ContentModal>
              </LibrarianPointHeader>
              <section className="content container-fluid">
                <Place
                  point_accounting={point.point_accounting}
                  point_consultations={point.point_consultations}
                  point_excursions={point.point_excursions}
                  point_visuals={point.point_visuals}
                  isToday={isToday}
                />
              </section>
            </Fragment>
          ) : (
            <LibrarianNews />
          )}
        </div>
      </div>
    </LibrarianBody>
  );
};

const mapStateToProps = ({
  points: { isPoints, points, point },
  allowance: { name, today, user },
}) => ({
  isPoints,
  point,
  points,
  name,
  user,
  today,
});

export default connect(mapStateToProps, {
  setPoint,
  resetPoint,
  getPoints,
  getAccounting,
  resetAccounting,
  getConsultations,
  getConsultationsTypicalResponses,
  resetConsultations,
  getExcursions,
  resetExcursions,
  getVisuals,
  resetVisuals,
})(Librarian);

const LibrarianHeaderUser = styled.div`
  display: flex;
  height: 50px;
  padding: 15px 0 15px 15px;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;

const LibrarianBody = styled.div`
  height: auto;
  min-height: 100%;
  position: relative;

  * {
    box-sizing: border-box;
  }

  button {
    font-size: 14px;
  }

  h1,
  h4 {
    margin: 0;
    font-weight: 500;
  }

  .librarian-body_link {
    position: relative;
    z-index: 10;
  }

  .fa {
    width: auto !important;
  }

  .navbar-static-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding-right: 15px;
  }

  .navbar-static-top_settings {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }

  .navbar-static-top_settings :last-child {
    cursor: pointer;
  }

  .content-wrapper {
    min-height: 100vh;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .wrapper {
    height: auto;
    min-height: 100%;
    position: relative;
    background-color: #222d32;
  }

  &.sidebar-collapse {
    .sidebar-menu > li:hover {
      .treeview-menu {
        padding: 7.5px 0;

        > li > a {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      > a > span {
        padding: 15px !important;
      }
    }
  }

  .sidebar-menu {
    cursor: pointer;

    .fa {
      margin-right: 15px;
    }

    > li {
      > a {
        padding: 15px;
        line-height: 1;
      }
    }
  }

  .treeview-menu {
    white-space: normal;
    padding: 7.5px 0;

    > li > a {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
    }
  }

  .sidebar-toggle {
    justify-content: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .main-sidebar {
    min-height: auto;
    z-index: 0;
  }

  .main-header {
    .logo {
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .container-fluid {
      padding: 15px 0 0;
    }
  }
`;

const LibrarianPointCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: calc(100vw - 30px);
    }
  }
`;

const LibrarianPointCalendarDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-size: 16px;
  line-height: 1;

  .fa {
    margin-left: 10px;
  }
`;

const LibrarianPointHeader = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;

  h1 {
    line-height: 1;
    font-size: 16px;
  }
`;

const PlaceLink = styled.li`
  cursor: pointer;
  ${({ isPlaceLink }) => isPlaceLink && "a {color: #fff !important;}"}
`;
