import * as types from "./actionTypes";

const initialState = {
  points: [],
  isPoints: false,
  point: { isPoint: false, point_id: "", point_name: "" },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.POINTS_GET_SUCCESS:
      return {
        ...state,
        points: action.payload.points,
        isPoints: true,
      };
    case types.POINT_SET:
      return {
        ...state,
        point: {
          isPoint: true,
          ...state.points[action.payload],
        },
      };
    case types.POINT_RESET:
      return {
        ...state,
        point: initialState.point,
      };

    default:
      return state;
  }
};
