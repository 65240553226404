import React, { useEffect } from "react";
import styled from "styled-components";
import Applications from "../../components/Applications";

const Container = styled.div`
  @media (max-width: 768px) {
    display: flex;
    row-gap: 10px;
  }

  @media (min-width: 701px) {
    padding: 0 30px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

const ServicesApplications = styled(Applications)`
  @media (min-width: 769px) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }
`;

const TableTitle = styled.div`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin-left: 15px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
    padding-top: 20px;
  }
`;

const Services = ({
  dragOver,
  dragEnd,
  dragEnter,
  dragStart,
  services,
  setServices,
  getServices,
  initialServices,
  isDisabled,
  handleServices,
  className,
}) => {
  useEffect(() => {
    getServices();

    return () => setServices(initialServices);
  }, []);

  return (
    <Container className={className}>
      <TableTitle>Сервисы</TableTitle>
      {services.loaded && (
        <ServicesApplications
          pages={services.data}
          dragOver={dragOver}
          dragEnd={dragEnd}
          dragEnter={dragEnter}
          dragStart={dragStart}
          isDescription={true}
          isFavorites={true}
          isDisabled={isDisabled}
          handleFavorites={handleServices}
        />
      )}
    </Container>
  );
};

export default Services;
