import * as types from "./actionTypes";

const initialState = {
  visualData: {
    id: null,
    location: "",
    reader: "",
    type: "",
    count: null,
    date_time: "",
    comment: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.VISUAL_SET:
      return {
        ...state,
        visualData: action.payload,
      };
    case types.VISUAL_GET_SUCCESS:
      return {
        ...state,
        visualData: action.payload,
      };
    case types.VISUAL_RESET:
      return {
        visualData: {
          id: null,
          location: "",
          reader: "",
          type: "",
          count: null,
          date_time: "",
          comment: "",
        },
      };

    default:
      return state;
  }
};
