import * as types from "./actionTypes";

export const getPoints = () => ({
  type: types.POINTS_GET,
});

export const getPointsSuccess = (payload) => ({
  type: types.POINTS_GET_SUCCESS,
  payload,
});

export const setPoint = (payload) => ({
  type: types.POINT_SET,
  payload,
});

export const resetPoint = () => ({
  type: types.POINT_RESET,
});
