import * as types from "./actionTypes";

export const getVisuals = (date_time) => ({
  type: types.VISUALS_FETCH,
  date_time,
});

export const getVisualsSuccess = (payload) => ({
  type: types.VISUALS_FETCH_SUCCESS,
  payload,
});

export const updateVisuals = () => ({
  type: types.VISUALS_UPDATE,
});

export const updateVisualsSuccess = (payload) => ({
  type: types.VISUALS_UPDATE_SUCCESS,
  payload,
});

export const resetVisuals = () => ({
  type: types.VISUALS_RESET,
});
