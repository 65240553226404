import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Image from "../Image";
import Assignment from "./Assignment";
import Icon from "../../../../nekrasovka-ui/Icon";
import axios from "axios";
import jwt from "jwt-decode";
import { connect } from "react-redux";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

const initialState = {
  shelves: [],
  bookcase: {
    floor_id: "",
    point_id: "",
    image: "",
    x: 0,
    y: 0,
    names: {},
    map: [],
  },
};

const Bookcase = ({ checkTokenExpiredYesUpdate }) => {
  const history = useHistory();
  const { bookcase_id } = useParams();
  const fileInputRef = createRef();
  const today = format(new Date(), "dd.MM.yyyy", { locale });

  const [comment, setComment] = useState("");
  const [state, setState] = useState(initialState);
  const [isAssignment, setAssignment] = useState(false);
  const [isBookcase, setBookcase] = useState(false);

  const TOKEN_KEY = localStorage.getItem("TOKEN_KEY");
  const { preferred_username } = jwt(TOKEN_KEY);

  const isAdmin = ["vdascal", "dmatveev"].includes(preferred_username);

  const createTicket = async () => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/bookcase/ticket/create`;
    const method = "POST";
    const options = {
      method,
      data: {
        bookcase_id,
        user: preferred_username,
        date: today,
        point_id: "CUNB_ENT",
        floor_id: state.bookcase.floor_id,
      },
      url,
      headers,
    };

    return axios(options);
  };

  const handleShelf = async (id) => {
    const shelves = [];
    let shelf = undefined;

    const ticket = state.tickets.find((n) => n.user === preferred_username);
    state.tickets.forEach((m) => {
      m.shelves.forEach((n) => {
        if (n.shelf_id === id) {
          shelf = n;
        }
      });
    });

    if (!!ticket) {
      shelves.push({
        id,
        is_done: !!shelf ? !shelf.is_done : true,
        ticket_id: ticket.id,
      });
    } else {
      const ct = await createTicket();

      shelves.push({
        id,
        is_done: !!shelf ? !shelf.is_done : true,
        ticket_id: ct.data[0].id,
      });
    }

    axios
      .post(`${process.env.REACT_APP_API}/bookcase/ticket/shelf`, { shelves })
      .then(() => getBookcase());
  };

  const handleAssignment = () => {
    setAssignment(!isAssignment);
  };

  const handleImage = () => {
    fileInputRef.current.click();
  };

  const getBookcase = () => {
    axios
      .post(`${process.env.REACT_APP_API}/bookcase/get`, { id: bookcase_id })
      .then((resp) => {
        resp.data.bookcase.names = JSON.parse(resp.data.bookcase.names);
        resp.data.bookcase.map = JSON.parse(resp.data.bookcase.map);

        resp.data.bookcase.map.forEach((n) => {
          const el = document.getElementById(`${n}`);
          el.classList.add("active");
        });

        setState(resp.data);
      });
  };

  const handleFile = async (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/bookcases/image/upload`,
      formData,
    );

    setState({
      ...state,
      bookcase: {
        ...state.bookcase,
        image: response.data.image,
      },
    });
  };

  useEffect(() => {
    getBookcase();
  }, [bookcase_id]);

  return (
    <Container>
      <Navigation>
        <div>
          <BookcaseButton onClick={() => history.push("/")}>
            Интранет
          </BookcaseButton>
          <BookcaseButton onClick={() => history.push("/backoffice/dedusting")}>
            Обеспыливание
          </BookcaseButton>
          <span>{bookcase_id}</span>
        </div>
        {isAdmin && (
          <BookcaseButton isActive={isAssignment} onClick={handleAssignment}>
            Назначить
          </BookcaseButton>
        )}
      </Navigation>
      <MiddleBlock>
        <BookcaseImage>
          <div onClick={handleImage}>
            {state.bookcase.image ? (
              <Image
                href={`${process.env.REACT_APP_URL}/${state.bookcase.image}`}
              />
            ) : (
              "Картинка"
            )}
          </div>
          <input
            ref={fileInputRef}
            hidden
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={handleFile}
          />
        </BookcaseImage>
        <BookcaseImage>
          <StyledDiv
            floor={state.bookcase.floor_id}
            isImage={isBookcase}
            onClick={() => !isBookcase && setBookcase(true)}
          >
            <Icon icon="floors" />
          </StyledDiv>
          {isBookcase && (
            <div onClick={() => setBookcase(false)}>
              <Icon icon="closeModal" height="20" width="20" />
            </div>
          )}
        </BookcaseImage>
        <Comment>
          <textarea
            rows="5"
            disabled={!isAssignment}
            value={comment || state.comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </Comment>
      </MiddleBlock>
      {isAssignment ? (
        <Assignment data={state} getBookcase={getBookcase} />
      ) : !!state.shelves.length ? (
        <CreationMiddle nColumns={state.bookcase.x}>
          {[...Array(state.bookcase.x)].map((_, titleId) => {
            return (
              <TitleInput key={titleId}>
                <input
                  disabled={true}
                  type="text"
                  id={`title_${titleId}`}
                  value={state.bookcase.names[titleId] || ""}
                />
              </TitleInput>
            );
          })}
          {state.shelves.map((shelf, shelfId) => {
            let isDone = false;
            let name = "";
            let date = "";

            state.tickets.forEach((m) => {
              m.shelves.forEach((n) => {
                if (n.shelf_id === shelf.id) {
                  name = m.name;
                  isDone = n.is_done;
                  date = m.date;
                }
              });
            });

            return (
              <Shelf
                key={shelfId}
                isEmpty={shelf.is_empty}
                isDone={isDone}
                onClick={() => handleShelf(shelf.id)}
              >
                <>
                  <span>{name}</span>
                  <span>{date}</span>
                </>
              </Shelf>
            );
          })}
        </CreationMiddle>
      ) : null}
    </Container>
  );
};

const mapStateToProps = ({ allowance: { checkTokenExpiredYesUpdate } }) => ({
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps, null)(Bookcase);

const Container = styled.div``;

const Shelf = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  column-gap: 5px;
  padding: 15px;
  font-size: 11px;

  height: 50px;
  background-color: ${({ isDone, isEmpty }) =>
    isEmpty ? "initial" : isDone ? "green" : "red"};

  > span {
    padding: 2px 5px;
    background-color: #edeee9;
  }
`;

const Input = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 1;

  input {
    padding: 10px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
    color: #222222;
    background-color: #ffffff;

    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
  }
`;

const TitleInput = styled(Input)`
  input {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
  }
`;

const CreationMiddle = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ nColumns }) => nColumns}, 330px);
  gap: 1px;

  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 769px) {
    justify-content: center;
  }
`;

const MiddleBlock = styled.div`
  padding: 15px;

  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    min-height: 130px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > div {
      min-height: 100px;
    }

    > div:nth-child(1n) {
      flex-basis: calc(50% - 5px);
    }

    > div:last-child {
      flex-basis: 100%;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 130px;

  > svg {
    ._1fl_walls_doors,
    ._1fl_bookstands,
    ._2fl_walls_doors,
    ._2fl_bookstands,
    ._3fl_walls_doors,
    ._3fl_bookstands,
    ._4fl_walls_doors,
    ._4fl_bookstands {
      display: none;
    }

    [data-name="${({ floor }) => floor}fl_bookstands"] > .active {
      fill: red;
    }

    [data-name="${({ floor }) => floor}fl_walls_doors"],
    [data-name="${({ floor }) => floor}fl_bookstands"] {
      display: block;
    }

    .cls-1,
    .cls-2,
    .cls-3 {
      fill: #000;
    }

    .cls-1,
    .cls-4 {
      stroke-width: 0;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18,
    .cls-19 {
      stroke: #000;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-14,
    .cls-19 {
      stroke-miterlimit: 10;
    }

    .cls-5,
    .cls-9,
    .cls-4 {
      fill: #fff;
    }

    .cls-2,
    .cls-19 {
      stroke-width: 10px;
    }

    .cls-6 {
      stroke-width: 20px;
    }

    .cls-6,
    .cls-8,
    .cls-10,
    .cls-12,
    .cls-14,
    .cls-19 {
      fill: none;
    }

    .cls-7,
    .cls-13,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18 {
      fill: #b3b3b3;
    }

    .cls-9,
    .cls-10 {
      stroke-width: 0.5px;
    }

    .cls-11 {
      fill: gray;
    }

    .cls-12 {
      stroke-width: 11px;
    }

    .cls-3 {
      font-size: 42px;
      isolation: isolate;
    }

    .cls-13 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-14 {
      stroke-width: 5px;
    }

    .cls-15 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-16 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-17 {
      stroke-miterlimit: 10;
    }

    .cls-17,
    .cls-18 {
      stroke-width: 1px;
    }

    .cls-18 {
      stroke-miterlimit: 10;
    }

    ${({ isImage }) =>
      isImage &&
      `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    background-color: #edeee9;
 `}
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: ${({ isActive }) => (isActive ? "#DDDBCFFF" : "initial")};
  color: ${({ isActive }) => (isActive ? "#777777FF" : "#222222")};
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  :not(:disabled) {
    cursor: pointer;
  }
`;

const Comment = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    padding: 10px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
    height: 100%;

    :disabled {
      opacity: 0.7;
    }
  }

  textarea {
    resize: none;
  }

  @media (min-width: 769px) {
    row-gap: 15px;

    textarea {
      border-radius: 0;
    }
  }

  @media (max-width: 768px) {
    row-gap: 10px;
  }
`;

const BookcaseImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid #c4c4c4;

  > div:nth-child(2) {
    position: absolute;
    z-index: 11;
    right: 15px;
    top: 15px;
  }

  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const Navigation = styled.div`
  display: flex;
  row-gap: 20px;

  padding: 15px;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 10px;

    > span {
      font-size: 13px;
    }
  }

  @media (min-width: 769px) {
    align-items: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;

    > button {
      width: fit-content;
    }
  }
`;
