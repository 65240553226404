import React from "react";

export default (props) => {
  return (
    <svg
      width={props.width || "35"}
      height={props.height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34.7826" height="34.9091" fill="#c4c4c4" />
      <line
        x1="9.53369"
        y1="24.6711"
        x2="23.7636"
        y2="10.6173"
        stroke="black"
        strokeWidth="3"
      />
      <line
        x1="10.3289"
        y1="10.5337"
        x2="24.3827"
        y2="24.7636"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  );
};
