import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Calendar from "react-calendar";
import styled from "styled-components";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import { parse } from "date-fns";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import Button from "../../nekrasovka-ui/Button";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import Image from "../Image";
import { AlertContext } from "../../../../nekrasovka-ui/Alert/AlertProvider";
import jwt from "jwt-decode";
import { connect } from "react-redux";

const User = ({ checkTokenExpiredYesUpdate }) => {
  const { user_id } = useParams();
  const { dispatch } = useContext(AlertContext);
  const today = new Date();

  const [user, setUser] = useState({});
  const [bookcases, setBookcases] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [isCalendar, setCalendar] = useState(false);
  const [isBookcase, setBookcase] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedBookcase, setSelectedBookcase] = useState("");
  const [selectedCard, setSelectedCard] = useState("");
  const [bookcasesButtons, setBookcasesButtons] = useState([]);

  const TOKEN_KEY = localStorage.getItem("TOKEN_KEY");
  const { preferred_username } = jwt(TOKEN_KEY);

  const isAdmin = ["vdascal", "dmatveev"].includes(preferred_username);

  const getUser = () => {
    axios
      .post(`${process.env.REACT_APP_API}/bookcases/user/get`, {
        user_id,
      })
      .then((response) => {
        setUser({ ...response.data.user, tickets: response.data.tickets });
        setLoaded(true);
      });
  };

  const getBookcases = () => {
    const buttonsNames = [];
    const options = { where: { bookcases: {} } };

    axios
      .post(`${process.env.REACT_APP_API}/bookcases/get`, { ...options })
      .then((response) => {
        const data = response.data.map((a) => {
          buttonsNames.push(
            `Главное здание, ${a.bookcase.floor_id} этаж, ${a.bookcase.id} ряд`,
          );

          return {
            names: a.bookcase.names,
            floor: a.bookcase.floor_id,
            bookcase_id: a.bookcase.id,
            image: a.bookcase.image,
          };
        });

        setBookcasesButtons(buttonsNames);
        setBookcases(data);
      });
  };

  const deleteTicket = async (id) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/bookcase/ticket/delete`;
    const method = "POST";
    const data = { id };
    const options = { method, data, url, headers };

    axios(options).then((response) => {
      if (!!response.data) {
        setUser({
          ...user,
          tickets: user.tickets.filter((m) => m.id !== id),
        });
      }
    });
  };

  const createTicket = async () => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/bookcase/ticket/create`;
    const method = "POST";
    const options = { method, data: {}, url, headers };

    if (selectedBookcase !== "" || selectedDate !== "") {
      options.data = {
        user: user_id,
        date: selectedDate,
        point_id: "CUNB_ENT",
        bookcase_id: "",
        floor_id: "",
      };

      if (selectedBookcase !== "") {
        options.data.bookcase_id = bookcases[selectedBookcase].bookcase_id;
        options.data.floor_id = bookcases[selectedBookcase].floor;
      }

      axios(options).then((response) => {
        if (!!response.data[1]) {
          setUser({ ...user, tickets: [...user.tickets, response.data[0]] });
        }

        setSelectedBookcase("");
        setSelectedDate("");
      });
    }
  };

  const updateTicket = () => {
    const ticket = user.tickets.find((f) => f.id === selectedCard);

    const options = {
      id: selectedCard,
      ticket: {
        user: user_id,
        date: selectedDate === "" ? ticket.date : selectedDate,
        bookcase_id:
          selectedBookcase === ""
            ? ticket.bookcase_id
            : bookcases[selectedBookcase].bookcase_id,
        floor_id:
          selectedBookcase === ""
            ? ticket.floor_id
            : bookcases[selectedBookcase].floor,
        point_id: "CUNB_ENT",
      },
    };

    const isTicket = !!user.tickets.find(
      (f) =>
        f.date === options.ticket.date &&
        f.bookcase_id === options.ticket.bookcase_id,
    );

    if (isTicket) {
      dispatch({
        type: "ALERT_ON",
        text: "Такой тикет уже существует!",
        name: "warning",
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/bookcase/ticket/update`, {
          ...options,
        })
        .then((response) => {
          const tickets = user.tickets.map((m) =>
            m.id === selectedCard ? response.data : m,
          );

          setUser({
            ...user,
            tickets,
          });

          setSelectedCard("");
          setSelectedDate("");
          setSelectedBookcase("");
        });
    }
  };

  const handleDate = (d) => {
    const date = format(d, "dd.MM.yyyy", { locale });

    setSelectedDate(date === selectedDate ? "" : date);
    setCalendar(false);
  };

  const handleBookcase = (groupName, id) => {
    setSelectedBookcase(id === selectedBookcase ? "" : id);
    setBookcase(false);
  };

  const handleClear = async () => {
    setSelectedBookcase("");
    setSelectedDate("");
  };

  useEffect(() => {
    getUser();
  }, [user_id]);

  useEffect(() => {
    getBookcases();
  }, []);

  const calendarValue = !!selectedDate
    ? parse(selectedDate, "d.MM.yyyy", new Date()).toString()
    : selectedDate;

  return (
    isLoaded && (
      <Container>
        <Navigation>
          <div>
            <BookcaseButton as={Link} to="/">
              Интранет
            </BookcaseButton>
            <BookcaseButton as={isAdmin && Link} to="/backoffice/dedusting">
              Обеспыливание
            </BookcaseButton>
            <span>{user.name}</span>
          </div>
        </Navigation>
        {isAdmin && (
          <>
            <AssignmentContainer>
              <Section>
                Назначить ряд
                <Input>
                  <div onClick={() => setBookcase(!isBookcase)}>
                    {bookcasesButtons[selectedBookcase]}
                  </div>
                  <Modal
                    isContentModal={isBookcase}
                    setContentModal={setBookcase}
                  >
                    <ModalButtonsGroup
                      groupName="bookcases"
                      buttons={bookcasesButtons}
                      activeButton={selectedBookcase}
                      handleButton={handleBookcase}
                    />
                  </Modal>
                </Input>
              </Section>
              <Section>
                Назначить дату
                <Input>
                  <div onClick={() => setCalendar(!isCalendar)}>
                    {selectedDate}
                  </div>
                  <Modal
                    isContentModal={isCalendar}
                    setContentModal={setCalendar}
                  >
                    <ModalCalendar>
                      <Calendar
                        value={calendarValue}
                        minDate={today}
                        locale="ru-RU"
                        selectRange={false}
                        onChange={handleDate}
                      />
                    </ModalCalendar>
                  </Modal>
                </Input>
              </Section>
            </AssignmentContainer>
            <CreationFooter>
              <Button
                isDisabled={selectedBookcase === "" && selectedDate === ""}
                name={!!selectedCard ? "Сохранить" : "Создать новую задачу"}
                onClick={!!selectedCard ? updateTicket : createTicket}
              />
              <Button
                isDisabled={selectedBookcase === "" && selectedDate === ""}
                name="Отменить"
                onClick={handleClear}
              />
            </CreationFooter>
          </>
        )}
        <Section>
          Задачи
          <AssignedCards>
            {user.tickets.map((ticket) => {
              const isBookcase = !!ticket.bookcase_id;
              const isDate = !!ticket.date;
              const isActive = selectedCard === ticket.id;
              const point = "Главное здание";
              let linked = "";

              if (isBookcase && isDate) {
                linked = {
                  href: `/backoffice/dedusting/bookcases/${ticket.bookcase_id}`,
                  as: "a",
                  target: "_blank",
                };
              }

              return (
                <Card
                  key={ticket.id}
                  isActive={isActive}
                  onClick={() => {
                    (!isBookcase || !isDate) &&
                      setSelectedCard(isActive ? "" : ticket.id);
                  }}
                >
                  <CardDebusting {...linked}>
                    <div>
                      <div>
                        <CardRow>
                          <span>{point}</span>
                        </CardRow>
                        <CardRow>
                          <span>
                            {ticket.floor_id ? `${ticket.floor_id} этаж` : "-"}
                          </span>
                        </CardRow>
                      </div>
                      <div>
                        <CardRow>
                          <span>Ряд №</span>
                          <span>
                            {ticket.bookcase_id ? ticket.bookcase_id : "-"}
                          </span>
                        </CardRow>
                        <CardRow>
                          <span>Полок</span>
                          <span>
                            {ticket.shelves.count ? ticket.shelves.count : "-"}
                          </span>
                        </CardRow>
                        <CardRow>
                          <span>Обеспылено</span>
                          <span>
                            {ticket.shelves.count
                              ? `${ticket.shelves.percent}%`
                              : "-"}
                          </span>
                        </CardRow>
                      </div>
                      <div>
                        <CardRow>
                          <span>Дата обеспыливания</span>
                          <span>{ticket.date ? ticket.date : "-"}</span>
                        </CardRow>
                      </div>
                    </div>
                    <div>
                      {isBookcase ? (
                        !!ticket.image ? (
                          <Image
                            href={`${process.env.REACT_APP_IMAGE}${ticket.image}`}
                          />
                        ) : (
                          "Нет изображения"
                        )
                      ) : null}
                    </div>
                  </CardDebusting>
                  {isAdmin && (
                    <Button
                      name="Удалить задачу"
                      onClick={() => deleteTicket(ticket.id)}
                    />
                  )}
                </Card>
              );
            })}
          </AssignedCards>
        </Section>
      </Container>
    )
  );
};

const mapStateToProps = ({ allowance: { checkTokenExpiredYesUpdate } }) => ({
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps)(User);

const CardDebusting = styled.div`
  :first-child {
    display: grid;
    grid-template-columns: 1fr 130px;

    > div {
      :first-child {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        > div {
          :nth-child(1) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-weight: 500;
          }

          :nth-child(2) {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            font-weight: 500;

            > div {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
          }

          :nth-child(3) {
            > div {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              font-weight: 500;
            }
          }
        }
      }

      :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #777777;
      }
    }
  }
`;

const CardRow = styled.div`
  span {
    :nth-child(2) {
      color: #777777;
    }
  }
`;

const Card = styled.div`
  * {
    font-size: 14px;
  }

  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 15px;
  background-color: ${({ isActive }) => (isActive ? "#DDDBCFFF" : "#f7f7f7")};
  border-radius: 5px;

  color: #222222;
  cursor: pointer;

  > button {
    margin-left: auto;
    margin-top: 30px;
  }
`;

const AssignedCards = styled.div`
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 10px;

    > span {
      font-size: 13px;
    }
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: ${({ isActive }) => (isActive ? "#DDDBCFFF" : "initial")};
  color: ${({ isActive }) => (isActive ? "#777777FF" : "#222222")};
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  :not(:disabled) {
    cursor: pointer;
  }
`;

const AssignmentContainer = styled.div`
  display: flex;

  @media (min-width: 769px) {
    column-gap: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const ModalButtonsGroup = styled(ButtonsGroup)`
  width: 100%;

  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 15px;
`;

const CreationFooter = styled.div`
  display: flex;
  column-gap: 10px;
  max-width: 390px;

  > button {
    width: 100%;
    white-space: nowrap;
  }

  @media (min-width: 769px) {
    max-width: calc(50% / 2 - 10px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 10px;
  }
`;

const Input = styled.div`
  position: relative;
  font-size: 14px;
  width: 300px;

  > div:nth-child(1),
  input {
    padding: 10px;
    min-height: 42px;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const Modal = styled(ContentModal)`
  left: 0;
  right: 0;
  margin-top: 5px;

  > div {
    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ModalCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: 100%;
    }
  }
`;
