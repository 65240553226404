import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../Card";

const MobileUsersContainer = styled.div``;

const TableTitle = styled.div`
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    margin-left: 15px;
    padding-top: 20px;
  }
`;

const MobileUsers = ({ data, history }) => {
  const [items, setItems] = useState([]);
  const [sortedItems, setSortedItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const perPage = 10;
  const totalPages = Math.ceil(data.length / perPage);

  const getSortedItems = (col, order) => {
    const temp = sortItems(col, order);

    setSortedItems(temp);
    if (!!currentPage) setCurrentPage(0);
    if (!hasMore) setHasMore(true);

    const newItems = temp.slice(0, perPage);

    setItems(newItems);
  };

  const sortItems = (col, order) => {
    return [...data].sort((a, b) => {
      const nameA = getName(col, a);
      const nameB = getName(col, b);

      if (order === "asc") {
        return nameA.localeCompare(nameB);
      } else return nameB.localeCompare(nameA);
    });
  };

  const getName = (col, init) => {
    const objectName = {
      1: init["name"],
      2: init["department"]["login"],
    };

    return objectName[col];
  };

  const getItems = (page) => {
    if (hasMore) {
      const start = page * perPage;
      const newItems = sortedItems.slice(start, start + perPage);

      if (page === totalPages) {
        setHasMore(false);
      }

      setItems([...items, ...newItems]);
    }
  };

  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const sHeight = document.documentElement.scrollHeight;
    const cHeight = document.documentElement.clientHeight;

    if (scrollTop + cHeight >= sHeight) {
      const page = currentPage + 1;

      setCurrentPage(page);
      getItems(page);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [items]);

  useEffect(() => {
    getSortedItems(1, "asc");
  }, [data]);

  return (
    <MobileUsersContainer>
      <TableTitle>Коллеги</TableTitle>
      <div>
        {items.map((item) => {
          return (
            <Card
              state={item}
              status={item.status}
              key={item.id}
              handleCard={() => history.push(`/staff/users/${item.login}`)}
            />
          );
        })}
      </div>
    </MobileUsersContainer>
  );
};

export default MobileUsers;
