import React, { Fragment, useEffect } from "react";
import TableUsers from "../../components/TableUsers";
import { useWindowDimensions } from "../../../../helpers";
import MobileDepartments from "../../components/MobileDepartments";
import MobileUsers from "../../components/MobileUsers";
import TableDepartments from "../../components/TableDepartments";

const Departments = ({
  departments,
  members,
  history,
  department_id,
  initialDepartments,
  setDepartments,
  getDepartments,
  setMembers,
  initialMembers,
}) => {
  const { width } = useWindowDimensions();

  const handleDepartment = (department) => {
    const name = !!department ? department.login : "";
    history.replace(`/staff/departments/${name}`);
  };

  useEffect(() => {
    getDepartments();

    return () => {
      setDepartments(initialDepartments);
      setMembers(initialMembers);
    };
  }, [department_id]);

  return (
    <Fragment>
      {width > 430 ? (
        <Fragment>
          {departments.loaded && (
            <TableDepartments
              data={departments.data}
              handleDepartment={handleDepartment}
            />
          )}
          {members.loaded && (
            <TableUsers data={members.data} history={history} />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {departments.loaded && (
            <MobileDepartments
              data={departments.data}
              department_id={department_id}
              handleDepartment={handleDepartment}
            />
          )}
          {members.loaded && (
            <MobileUsers data={members.data} history={history} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Departments;
