import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../../nekrasovka-ui/Icon";
import styled from "styled-components";
import ContentModal from "../../../../nekrasovka-ui/ContentModal";
import { Link } from "react-router-dom";

const Container = styled.div`
  flex-direction: column;
  row-gap: 10px;

  > div {
    position: relative;

    &:nth-child(1) {
      font-size: 14px;
      color: rgb(119, 119, 119);

      @media (max-width: 700px) {
        display: none;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
      padding: 10px 18px;
      background: rgb(255, 255, 255);

      border-radius: 5px;
      column-gap: 15px;

      @media (min-width: 701px) {
        max-width: fit-content;
      }

      input {
        font-size: 14px;
        font-weight: normal;
        color: rgb(34, 34, 34);
        margin: 0;
        padding: 0;
        border: none;
        outline: none;

        @media (min-width: 701px) {
          width: ${({ inputWidth }) => inputWidth}px;

          ::placeholder {
            color: transparent;
          }
        }

        @media (max-width: 700px) {
          width: 100%;
        }
      }

      > div {
        display: flex;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  @media (min-width: 769px) {
    padding: 0 30px;
  }

  @media (min-width: 701px) {
    > div:nth-child(2) {
      border: 1px solid rgb(196, 196, 196);
    }
  }
`;

const SearchModal = styled(ContentModal)`
  bottom: 0;
  border-radius: 5px;

  > div {
    display: flex;
    flex-direction: column;
    padding: 20px 18px;
    border-radius: 5px;

    > div > div {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      h5 {
        margin: 0;
      }

      a,
      h5 {
        font-size: 14px;
      }
    }

    ::after {
      right: 0;
      left: 30px;
    }
  }

  @media (min-width: 701px) {
    width: 407px;
  }

  @media (max-width: 700px) {
    box-shadow: none;
  }
`;

const Search = ({
  isModal,
  search,
  query = "",
  handleSearch = null,
  setIsModal,
  className,
}) => {
  const searchRef = useRef();
  const [width, setWidth] = useState(338);

  useEffect(() => {
    if (searchRef.current) {
      const queryLength = (query.length + 1) * 8;

      if (queryLength > 338) {
        setWidth(queryLength);
      } else setWidth(338);
    }
  }, [query]);

  return (
    <Container inputWidth={width} className={className}>
      <div>Поиск по всем разделам</div>
      <div>
        <input
          ref={searchRef}
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Поиск по всем разделам"
        />
        {!!query ? (
          <div onClick={handleSearch}>
            <Icon
              icon="closeModal"
              width={16}
              height={16}
              fill="rgb(119, 119, 119)"
            />
          </div>
        ) : (
          <Icon
            icon="search"
            width={16}
            height={16}
            fill="rgb(119, 119, 119)"
          />
        )}
      </div>
      <SearchModal isContentModal={isModal} setIsContentModal={setIsModal}>
        <div>
          {!!search.members.length && (
            <div>
              <h5>Коллеги</h5>
              {search.members.map((item, index) => {
                return (
                  <Link to={`/staff/users/${item.login}`} key={index}>
                    {item.name}
                  </Link>
                );
              })}
            </div>
          )}
          {!!search.departments.length && (
            <div>
              <h5>Отделы</h5>
              {search.departments.map((item, index) => {
                return (
                  <Link to={`/staff/departments/${item.login}`} key={index}>
                    {item.name}
                  </Link>
                );
              })}
            </div>
          )}
          {!!search.services.length && (
            <div>
              <h5>Сервисы</h5>
              {search.services.map((item, index) => {
                return (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    {item.name}
                  </a>
                );
              })}
            </div>
          )}
          {!!search.websites.length && (
            <div>
              <h5>Сайты</h5>
              {search.websites.map((item, index) => {
                return (
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                  >
                    {item.name}
                  </a>
                );
              })}
            </div>
          )}
        </div>
      </SearchModal>
    </Container>
  );
};

export default Search;
