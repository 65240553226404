import React, { Fragment, useEffect, useState } from "react";
import Icon from "../../nekrasovka-ui/Icon";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import Visual from "../Visual";
import {
  getVisuals,
  resetVisuals,
  updateVisuals,
} from "../../reducers/visuals/actions";
import { getVisual } from "../../reducers/visual/actions";
import Modal from "../../nekrasovka-ui/Modal";
import LibrarianTable from "../LibrarianTable";
import Pagination from "../../nekrasovka-ui/Pagination";

const visualColumns = {
  date_time: "Дата выдачи",
  location: "Куда выдано",
  reader: "Кому выдано",
  type: "Вид документов",
  count: "Количество",
  comment: "Комментарий",
};

const Visuals = ({
  isToday,
  point,
  today,
  user,
  visualData,
  visualsData,
  updateVisuals,
  getVisuals,
  resetVisuals,
  getVisual,
  isVisualsUpdate,
  isVisualsGet,
}) => {
  const limit = 10;
  const visualsDataLength = visualsData.length;
  const countPages = Math.ceil(visualsDataLength / limit);
  const [isVisual, setIsVisual] = useState(false);
  const [visualsLoaded, setVisualsLoaded] = useState(0);

  const getPaginated = (num) => setVisualsLoaded((num - 1) * limit);
  const onClose = () => setIsVisual((state) => !state);

  const onSave = () => {
    updateVisuals();
    onClose();
  };

  const handleVisual = (id) => {
    getVisual(id);
    onClose();
  };

  const resetAllInVisuals = () => {
    resetVisuals();
    setVisualsLoaded(0);
    isVisual && setIsVisual(false);
  };

  useEffect(() => {
    getVisuals(today);

    return () => resetAllInVisuals();
  }, [point.point_id]);

  useEffect(() => {
    if (isVisualsUpdate) getVisuals(today);
  }, [isVisualsUpdate]);

  useEffect(() => {
    if (visualsLoaded > 0) setVisualsLoaded(0);
  }, [isVisualsGet]);

  const isUserCanEdit = visualData.user === user && isToday;

  return (
    <Fragment>
      {isVisual && (
        <Modal>
          <VisualsContainer>
            <VisualModalTitle>
              <h4>
                {visualData.id
                  ? `${
                      isUserCanEdit ? "Редактирование" : "Чтение"
                    } выданных МНК`
                  : "Новая выдача МНК"}
              </h4>
              <VisualCloseButton onClick={onClose}>
                <Icon icon="closeModal" width={20} height={20} />
              </VisualCloseButton>
            </VisualModalTitle>
            <Visual
              isUserCanEdit={isUserCanEdit || !visualData.id}
              onSave={onSave}
              onClose={onClose}
              visualColumns={visualColumns}
            />
          </VisualsContainer>
        </Modal>
      )}
      <VisualsContainer>
        <VisualTitle>
          <h4>Учет выданных МНК</h4>
          {isToday && <VisualOpenButton name={"Добавить"} onClick={onClose} />}
        </VisualTitle>
        <LibrarianTable
          isDataGet={isVisualsGet}
          loaded={visualsLoaded}
          rows={visualsData}
          columns={{
            location: "Куда выдано",
            reader: "Кому выдано",
            type: "Вид документов",
            count: "Количество",
          }}
          info={{
            loading: "Получаем выданные материалы некнижных коллекций...",
            get: "Нет выданных материалов некнижных коллекций за этот день...",
          }}
          limit={limit}
          handleRow={handleVisual}
        />
        {countPages > 1 && (
          <Pagination getPaginated={getPaginated} countPages={countPages} />
        )}
      </VisualsContainer>
    </Fragment>
  );
};

const mapStateToProps = ({
  points: { point },
  allowance: { today, user },
  visuals: { visualsData, isVisualsGet, isVisualsUpdate },
  visual: { visualData },
}) => ({
  point,
  today,
  user,
  visualsData,
  visualData,
  isVisualsGet,
  isVisualsUpdate,
});

export default connect(mapStateToProps, {
  updateVisuals,
  getVisuals,
  resetVisuals,
  getVisual,
})(Visuals);

const VisualsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  row-gap: 20px;

  @media (min-width: 769px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

const VisualTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  h4 {
    display: flex;
    padding: 4px 10px 4px 0;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 15px 15px 0;
  }
`;

const VisualModalTitle = styled(VisualTitle)`
  justify-content: space-between;
`;

const VisualOpenButton = styled(Button)`
  padding: 4px 10px;
  font-size: 14px;
`;

const VisualCloseButton = styled.div`
  display: flex;

  svg {
    rect {
      fill: #ecf0f5;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    :hover svg {
      rect {
        fill: #40677e;
      }

      line {
        stroke: #ffffff;
      }
    }
  }
`;
