import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import {
  setUnavailable,
  resetUnavailable,
} from "../../reducers/unavailable/actions";
// import axios from "axios";

const Unavalable = ({
  unavailableData,
  setUnavailable,
  resetUnavailable,
  isDisabled,
  columns,
  reasons,
}) => {
  const [isType, setIsType] = useState(false);
  // const [authorsData, setAuthorsData] = useState([]);
  // const [isAuthor, setIsAuthor] = useState(false);

  const handleSearchAuthor = (name, value) => {
    // if (value.length > 2) {
    //   const url = `${process.env.REACT_APP_ELECTRO_API}/search/books_filters?type=authors&query=${value}`;
    //
    //   setTimeout(async () => {
    //     const { data } = await axios.get(url);
    //
    //     if (!!data.response.length) {
    //       setAuthorsData(data.response.map((item) => item["value"]));
    //       !isAuthor && setIsAuthor(true);
    //     } else !!isAuthor && setIsAuthor(false);
    //   }, 300);
    // } else !!isAuthor && setIsAuthor(false);

    handleUnavalable(name, value);
  };

  // const handleAuthor = (name, value) => {
  //   const temp = unavailableData;
  //   temp[name] = authorsData[value];
  //
  //   setUnavailable({ ...temp });
  //   setIsAuthor(false);
  // };

  const handleUnavalable = (name, value) => {
    const temp = unavailableData;
    temp[name] = value;

    setUnavailable({ ...temp });
  };

  const handleType = (name, value) => {
    handleUnavalable(name, value);
  };

  useEffect(() => {
    return () => resetUnavailable();
  }, []);

  return (
    <>
      <VisualActionReader>
        <div>{columns.author}</div>
        <VisualTypeContainer>
          <input
            disabled={isDisabled}
            value={unavailableData.author || ""}
            onChange={({ target: { value } }) =>
              handleSearchAuthor("author", value)
            }
          />
          {/*<ContentModalType*/}
          {/*  isContentModal={isAuthor}*/}
          {/*  setIsContentModal={setIsAuthor}*/}
          {/*>*/}
          {/*  <UnavailableAuthorButtons*/}
          {/*    groupName="author"*/}
          {/*    handleButton={handleAuthor}*/}
          {/*    buttons={authorsData}*/}
          {/*  />*/}
          {/*</ContentModalType>*/}
        </VisualTypeContainer>
      </VisualActionReader>
      <VisualActionReader>
        <div>
          {columns.title} *<span>(обязательное поле)</span>
        </div>
        <input
          disabled={isDisabled}
          value={unavailableData.title || ""}
          onChange={({ target: { value } }) => handleUnavalable("title", value)}
        />
      </VisualActionReader>
      <VisualAction>
        <div>{columns.code}</div>
        <input
          disabled={isDisabled}
          type="text"
          value={unavailableData.code || ""}
          onChange={({ target: { value } }) => handleUnavalable("code", value)}
        />
      </VisualAction>
      <VisualAction>
        <div>
          {columns.reason} *<span>(обязательное поле)</span>
        </div>
        <VisualTypeContainer
          isDisabled={isDisabled}
          onClick={() => !isDisabled && setIsType(!isType)}
        >
          <input
            type="text"
            value={reasons[unavailableData.reason] || ""}
            readOnly
          />
          <ContentModalType
            isContentModal={isType}
            setIsContentModal={setIsType}
          >
            <VisualTypeButtons
              groupName="reason"
              handleButton={handleType}
              activeButton={unavailableData["reason"]}
              buttons={reasons}
            />
          </ContentModalType>
        </VisualTypeContainer>
      </VisualAction>
      <VisualActionReader>
        <div>{columns.publisher}</div>
        <input
          disabled={isDisabled}
          value={unavailableData.publisher || ""}
          onChange={({ target: { value } }) =>
            handleUnavalable("publisher", value)
          }
        />
      </VisualActionReader>
    </>
  );
};
const mapStateToProps = ({
  unavailable: { unavailableData, reasons, columns },
}) => ({
  unavailableData,
  reasons,
  columns,
});

export default connect(mapStateToProps, {
  setUnavailable,
  resetUnavailable,
})(Unavalable);

const ContentModalType = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div {
    padding: 10px;

    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VisualAction = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    > span {
      opacity: 0.5;
      font-size: 80%;
      margin-left: 5px;
      font-weight: normal;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 2px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  textarea[disabled] {
    resize: none;
  }

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

const VisualActionReader = styled(VisualAction)`
  > :first-child {
    > :nth-child(3) {
      margin-left: 0;
    }
  }
`;

const UnavailableAuthorButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none !important;
    white-space: nowrap;
  }

  @media (min-width: 601px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }
`;

const VisualTypeButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;
