import React from "react";
import { floors } from "../../../../database/dedusting";
import Calendar from "react-calendar";
import styled from "styled-components";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import parse from "date-fns/parse";

const Filters = ({
  handleFilters,
  isFilters,
  selectedFloor,
  setFloor,
  isFloor,
  handleFloor,
  selectedUser,
  setUser,
  handleUser,
  isUser,
  selectedDate,
  setCalendar,
  isCalendar,
  handleDate,
  isDesktop,
  assigned,
}) => {
  const calendarValue = !!selectedDate
    ? parse(selectedDate, "d.MM.yyyy", new Date()).toString()
    : selectedDate;

  return (
    <Container>
      {!isDesktop && (
        <FiltersButton onClick={handleFilters} isActive={isFilters}>
          Фильтры
        </FiltersButton>
      )}
      {isFilters && (
        <div>
          <Section>
            Этаж
            <Input>
              <div onClick={() => setFloor(!isFloor)}>
                <span>
                  {!!selectedFloor || selectedFloor === 0
                    ? selectedFloor + 1
                    : ""}
                </span>
              </div>
              <Modal isContentModal={isFloor} setContentModal={setFloor}>
                <ModalButtonsGroup
                  groupName="floors"
                  buttons={floors}
                  activeButton={selectedFloor}
                  handleButton={handleFloor}
                />
              </Modal>
            </Input>
          </Section>
          <Section>
            Сотрудник
            <InputAssigned>
              <div onClick={() => setUser(!isUser)}>
                {selectedUser.map((m, i) => {
                  return (
                    <span key={i}>
                      {assigned[m]}
                      {i + 1 < selectedUser.length ? ", " : ""}
                    </span>
                  );
                })}
              </div>
              <Modal isContentModal={isUser} setContentModal={setUser}>
                <ModalButtonsGroup
                  groupName="assigned"
                  buttons={assigned}
                  activeButton={selectedUser}
                  handleButton={handleUser}
                />
              </Modal>
            </InputAssigned>
          </Section>
          <Section>
            Дата
            <Input>
              <div onClick={() => setCalendar(!isCalendar)}>{selectedDate}</div>
              <ModalCalendarContainer
                isContentModal={isCalendar}
                setContentModal={setCalendar}
              >
                <ModalCalendar>
                  <Calendar
                    value={calendarValue}
                    locale="ru-RU"
                    selectRange={false}
                    onChange={handleDate}
                  />
                </ModalCalendar>
              </ModalCalendarContainer>
            </Input>
          </Section>
        </div>
      )}
    </Container>
  );
};

export default Filters;

const Container = styled.div`
  padding: 15px;

  > button {
    width: 100%;
    cursor: pointer;

    :hover {
      background-color: rgb(221, 219, 207);
      color: rgb(119, 119, 119);
    }
  }

  > div {
    margin-top: 15px;
  }

  @media (min-width: 451px) {
    > button {
      max-width: 390px;
    }
  }

  @media (min-width: 451px) {
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      > div {
        cursor: pointer;

        :nth-child(1) {
          max-width: 58px;
        }

        :nth-child(2) {
          max-width: 200px;
        }

        :nth-child(3) {
          max-width: 350px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    > div {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
`;

const FiltersButton = styled.button`
  padding: 7px 10px;
  background-color: ${({ isActive }) => (isActive ? "#dddbcf" : "initial")};
  color: ${({ isActive }) => (isActive ? "#777777FF" : "#222222")};
  border-radius: 5px;
  border: 1px solid #c4c4c4;
`;

const Modal = styled(ContentModal)`
  left: 0;
  right: 0;
  margin-top: 5px;

  > div {
    ::after {
      right: 0;
      left: 15px;
    }
  }
`;

const ModalButtonsGroup = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const ModalCalendarContainer = styled(Modal)`
  width: 100%;
`;

const ModalCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: 100%;
    }
  }
`;

const Input = styled.div`
  position: relative;
  font-size: 14px;

  > div:nth-child(1) {
    padding: 10px;
    min-height: 42px;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.6;"};
`;

const InputAssigned = styled(Input)``;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 10px;
  }
`;
