import React from "react";
import styled from "styled-components";
import Icon from "../../../../nekrasovka-ui/Icon";

const Sorting = ({ sorting, view, handleSorting, handleView, isDesktop }) => {
  return (
    <Container>
      {/*<div>*/}
      {/*  <SortingButtonColored*/}
      {/*    isActive={sorting === "1"}*/}
      {/*    onClick={() => handleSorting("1")}*/}
      {/*  />*/}
      {/*  <SortingButtonColored*/}
      {/*    isActive={sorting === "2"}*/}
      {/*    onClick={() => handleSorting("2")}*/}
      {/*  />*/}
      {/*</div>*/}
      {!isDesktop && (
        <div>
          <SortingButtonIcon
            isActive={view === "1"}
            onClick={() => handleView("1")}
          >
            <Icon icon="filtersList" height="20" width="20" />
          </SortingButtonIcon>
          <SortingButtonIcon
            isActive={view === "2"}
            onClick={() => handleView("2")}
          >
            <Icon icon="filtersMozaic" height="20" width="20" />
          </SortingButtonIcon>
          <SortingButtonIcon
            isActive={view === "3"}
            onClick={() => handleView("3")}
          >
            <Icon icon="calendarPlaner" height="20" width="20" />
          </SortingButtonIcon>
        </div>
      )}
    </Container>
  );
};

export default Sorting;

const SortingButtonColored = styled.div`
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border-radius: 2px;

  :nth-child(1) {
    background: #0080002b;
  }

  :nth-child(2) {
    background: #ff00001f;
  }

  ${({ isActive }) => isActive && "border: 1px solid #222222;"};
`;

const SortingButtonIcon = styled.div`
  fill: ${({ isActive }) => (isActive ? "#222222" : "#C4C4C4")};

  svg {
    border-radius: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  padding: 0 15px 15px;
  justify-content: space-between;

  > div {
    display: flex;
    column-gap: 10px;
  }
`;
