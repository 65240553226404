import * as types from "./actionTypes";

const initialState = {
  unavailableData: {
    id: null,
    author: "",
    title: "",
    code: "",
    reason: null,
    publisher: "",
    name: "",
  },
  reasons: {
    1: "нет в фонде",
    2: "на руках",
    3: "у должника",
    4: "занят единственный экз.",
    5: "нет на полке",
    6: "только чит. зал",
  },
  columns: {
    author: "Автор книги",
    title: "Название",
    code: "Шифр",
    reason: "Причина отказа",
    publisher: "Издательство",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UNAVAILABLE_SET:
      return {
        ...state,
        unavailableData: action.payload,
      };
    case types.UNAVAILABLE_RESET:
      return {
        ...state,
        unavailableData: {
          id: null,
          author: "",
          title: "",
          code: "",
          reason: null,
          publisher: "",
          name: "",
        },
      };

    default:
      return state;
  }
};
