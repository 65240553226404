import React from "react";

export default () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M8 22c0-5.6 0-8.4 1.09-10.54a10 10 0 0 1 4.37-4.37C15.6 6 18.4 6 24 6c5.6 0 8.4 0 10.54 1.09a10 10 0 0 1 4.37 4.37C40 13.6 40 16.4 40 22c0 5.6 0 8.4-1.09 10.54a10.001 10.001 0 0 1-4.37 4.37C32.4 38 29.6 38 24 38c-5.6 0-8.4 0-10.54-1.09a10 10 0 0 1-4.37-4.37C8 30.4 8 27.6 8 22z"
          fill="#F33"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix values="0 0 0 0 0.6 0 0 0 0 0.12 0 0 0 0 0.12 0 0 0 0.32 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
