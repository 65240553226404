import React from "react";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import styled from "styled-components";
import { connect } from "react-redux";
import { setSettings } from "../../reducers/settings/actions";
import Separator from "../../nekrasovka-ui/Separator";

const LibrarianSettings = ({
  handlePointDataBySettings,
  settings,
  setSettings,
  isContentModal,
  setIsContentModal,
}) => {
  return (
    <ContentModal
      contentModalStyle={`right: 15px; @media (min-width: 769px) {top: 50px;} @media (max-width: 768px) {top: 100px;}`}
      isContentModal={isContentModal}
      setIsContentModal={setIsContentModal}
    >
      <ContentModalSettings>
        <h4>Настройки</h4>
        <Separator />
        <div>
          {Object.keys(settings).map((set) => {
            const { title, active } = settings[set];
            const actionActiveStyle = active
              ? {
                  backgroundColor: "#40677e",
                }
              : null;

            return (
              <div key={set}>
                <span>{title}</span>
                <span
                  style={actionActiveStyle}
                  onClick={() => {
                    setSettings({
                      [set]: {
                        ...settings[set],
                        active: !settings[set].active,
                      },
                    });

                    handlePointDataBySettings();
                  }}
                ></span>
              </div>
            );
          })}
        </div>
      </ContentModalSettings>
    </ContentModal>
  );
};

const mapStateToProps = ({ settings }) => ({
  settings,
});

export default connect(mapStateToProps, {
  setSettings,
})(LibrarianSettings);

const ContentModalSettings = styled.div`
  row-gap: 15px;
  padding: 15px;
  min-height: 150px;
  color: #222222;

  &,
  > div {
    display: flex;
    flex-direction: column;
  }

  > div {
    row-gap: 10px;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 5px;

      > span {
        font-size: 14px;

        :nth-child(2) {
          cursor: pointer;

          border: 1px solid #c4c4c4;
          border-radius: 2px;

          @media (hover: hover) {
            :hover {
              border-color: #40677e;
              color: #40677e;
            }
          }
        }
      }
    }
  }

  @media (min-width: 769px) {
    width: 250px;

    > div {
      > div {
        > span {
          :nth-child(2) {
            height: 12px;
            width: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: calc(100vw - 30px);

    > div {
      > div {
        > span {
          :nth-child(2) {
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
`;
