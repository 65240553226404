import React from "react";

export default (props) => {
  return (
    <svg
      width={props.width || "32"}
      height={props.height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.8843 11.629L17.3246 0.474736C16.9398 0.158245 16.4699 0 16 0C15.5302 0 15.0603 0.158245 14.6755 0.474736L10.5469 3.87093V3.2205C10.5469 2.64479 10.0802 2.17804 9.50447 2.17804H4.50414C3.92842 2.17804 3.46168 2.64479 3.46168 3.2205V9.69924L1.1158 11.629C0.43605 12.1881 0.180648 13.1141 0.477679 13.9426C0.735583 14.6622 1.36189 15.1737 2.0987 15.2959V29.9151C2.0987 31.0666 3.03219 32 4.18362 32H11.3726C12.5241 32 13.4575 31.0666 13.4575 29.9151V24.6004C13.4575 24.2309 13.6172 24.0107 13.6916 23.9543H18.3084C18.3829 24.0107 18.5425 24.2309 18.5425 24.6004V29.9151C18.5425 31.0666 19.4759 32 20.6274 32H27.8164C28.9679 32 29.9013 31.0666 29.9013 29.9151V15.296C30.6382 15.1739 31.2645 14.6622 31.5224 13.9427C31.8195 13.1141 31.5641 12.1882 30.8843 11.629ZM5.5466 4.26303H8.46201V5.58605L5.5466 7.98426V4.26303ZM27.8165 13.2392V29.9151H20.6274V24.6004C20.6274 23.0921 19.6063 21.8695 18.3467 21.8695H13.6535C12.3938 21.8695 11.3727 23.0922 11.3727 24.6004V29.9151H4.18362V13.2392H2.44028L16 2.08492L29.5598 13.2392H27.8165Z"
        fill={props.fill || "black"}
      />
    </svg>
  );
};
