export const footerLinks = [
  {
    name: "Инструкции по работе на мобильных точках",
    href: "https://team.nekrasovka.ru/confluence/x/V4ALAw",
    isExternal: true,
  },
  {
    name: "Справка по учету статистики на внестационаре",
    href: "https://team.nekrasovka.ru/confluence/x/uACLAw",
    isExternal: true,
  },
  { name: "ОПАК", href: "http://opac.nekrasovka.ru/", isExternal: true },
  { name: "Маркклауд", href: "https://lib.mos.ru/", isExternal: true },
  {
    name: "Регистрация в ЛитРес",
    href: "https://litres.nekrasovka.ru/",
    isExternal: true,
  },
  {
    name: "Норман",
    href: "http://helper.nekrasovka.ru/",
    isExternal: true,
  },
  {
    name: "Заявка в glpi",
    href: "mailto:glpi@nekrasovka.ru",
    isExternal: true,
  },
  {
    name: "Статистика",
    href: "https://datastudio.google.com/reporting/7ab2ee70-3a1c-46a4-acff-c4914c3d984a",
    isExternal: true,
  },
];
