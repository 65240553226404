import React, { Fragment, useEffect, useState } from "react";
import Icon from "../../nekrasovka-ui/Icon";
import Pagination from "../../nekrasovka-ui/Pagination";
import { connect } from "react-redux";

import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import Excursion from "../Excursion";
import {
  getExcursions,
  resetExcursions,
  updateExcursions,
} from "../../reducers/excursions/actions";
import { getExcursion } from "../../reducers/excursion/actions";
import Modal from "../../nekrasovka-ui/Modal";
import LibrarianTable from "../LibrarianTable";

const excursionColumns = {
  topic: "Тема экскурсии",
  date_time: "Дата экскурсии",
  participants: "Экскурсанты",
  comment: "Комментарий",
};

const Excursions = ({
  isToday,
  point,
  today,
  user,
  excursionData,
  excursionsData,
  updateExcursions,
  getExcursions,
  resetExcursions,
  getExcursion,
  isExcursionsUpdate,
  isExcursionsGet,
}) => {
  const limit = 10;
  const excursionsDataLength = excursionsData.length;
  const countPages = Math.ceil(excursionsDataLength / limit);
  const [isExcursion, setIsExcursion] = useState(false);
  const [excursionsLoaded, setExcursionsLoaded] = useState(0);

  const getPaginated = (num) => setExcursionsLoaded((num - 1) * limit);
  const onClose = () => setIsExcursion((state) => !state);

  const onSave = () => {
    updateExcursions();
    onClose();
  };

  const handleExcursion = (id) => {
    getExcursion(id);
    onClose();
  };

  const resetAllInExcursions = () => {
    resetExcursions();
    setExcursionsLoaded(0);
    isExcursion && setIsExcursion(false);
  };

  useEffect(() => {
    getExcursions(today);

    return () => resetAllInExcursions();
  }, [point.point_id]);

  useEffect(() => {
    if (isExcursionsUpdate) getExcursions(today);
  }, [isExcursionsUpdate]);

  useEffect(() => {
    if (excursionsLoaded > 0) setExcursionsLoaded(0);
  }, [isExcursionsGet]);

  const isUserCanEdit = excursionData.user === user && isToday;

  return (
    <Fragment>
      {isExcursion && (
        <Modal>
          <ExcursionsContainer>
            <ExcursionModalTitle>
              <h4>
                {excursionData.id
                  ? `${isUserCanEdit ? "Редактирование" : "Чтение"} экскурсии`
                  : "Новая экскурсия"}
              </h4>
              <ExcursionCloseButton onClick={onClose}>
                <Icon icon="closeModal" width={20} height={20} />
              </ExcursionCloseButton>
            </ExcursionModalTitle>
            <Excursion
              isUserCanEdit={isUserCanEdit || !excursionData.id}
              onSave={onSave}
              onClose={onClose}
              excursionColumns={excursionColumns}
            />
          </ExcursionsContainer>
        </Modal>
      )}
      <ExcursionsContainer>
        <ExcursionTitle isExcursion={isExcursion}>
          <h4>Экскурсии</h4>
          {isToday && (
            <ExcursionOpenButton name={"Добавить"} onClick={onClose} />
          )}
        </ExcursionTitle>
        <LibrarianTable
          isDataGet={isExcursionsGet}
          loaded={excursionsLoaded}
          limit={limit}
          handleRow={handleExcursion}
          rows={excursionsData}
          columns={{
            topic: "Тема экскурсии",
            participants: "Экскурсанты",
          }}
          info={{
            loading: "Получаем экскурсии...",
            get: "Нет экскурсий за этот день...",
          }}
        />
        {countPages > 1 && (
          <Pagination getPaginated={getPaginated} countPages={countPages} />
        )}
      </ExcursionsContainer>
    </Fragment>
  );
};

const mapStateToProps = ({
  points: { point },
  allowance: { today, user },
  excursions: { excursionsData, isExcursionsGet, isExcursionsUpdate },
  excursion: { excursionData },
}) => ({
  point,
  today,
  user,
  excursionsData,
  excursionData,
  isExcursionsGet,
  isExcursionsUpdate,
});

export default connect(mapStateToProps, {
  updateExcursions,
  getExcursions,
  resetExcursions,
  getExcursion,
})(Excursions);

const ExcursionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  row-gap: 20px;

  @media (min-width: 769px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

const ExcursionTitle = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  h4 {
    display: flex;
    padding: 4px 10px 4px 0;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 15px 15px 0;
  }
`;

const ExcursionModalTitle = styled(ExcursionTitle)`
  justify-content: space-between;
`;

const ExcursionOpenButton = styled(Button)`
  padding: 4px 10px;
  font-size: 14px;
`;

const ExcursionCloseButton = styled.div`
  display: flex;

  svg {
    rect {
      fill: #ecf0f5;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    :hover svg {
      rect {
        fill: #40677e;
      }

      line {
        stroke: #ffffff;
      }
    }
  }
`;
