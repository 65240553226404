import React from "react";
import styled from "styled-components";
import Icon from "../../../../nekrasovka-ui/Icon";
import ContentModal from "../../../../nekrasovka-ui/ContentModal";
import ButtonsGroup from "../../../../nekrasovka-ui/ButtonsGroup";

const MobileBooksNotFound = ({
  head,
  data,
  handlePreviousClick,
  handleNextClick,
  currentPage,
  totalPages,
  handleComment,
  verification,
  verificationOpen,
  setVerificationOpen,
  handleVerification,
  handleVerificationClose,
  hiddenHeaders,
  isOUBF,
}) => {
  return (
    <Container id="books_not_found_table">
      {data.map((dataItem, dataIndex) => {
        const titleAndAuthor = `${dataItem.title}${dataItem.author ? ", " + dataItem.author : ""}`;
        const locationHICM = Array.from(
          Object.entries(dataItem.location)
            .filter(([_, value]) => value !== null)
            .map(([_, value]) => value),
        ).join(", ");

        return (
          <Card key={dataIndex}>
            {hiddenHeaders.includes("Дата") && (
              <div>
                <span>{head[0]}</span>
                <span>{dataItem.created_at}</span>
              </div>
            )}
            {hiddenHeaders.includes("Баркод или инвентарный номер") && (
              <div>
                <span>{head[1]}</span>
                <span>{dataItem.barcode}</span>
              </div>
            )}
            {hiddenHeaders.includes("Автор и название") && (
              <div>
                <span>{head[2]}</span>
                <span>{titleAndAuthor}</span>
              </div>
            )}
            {hiddenHeaders.includes("Местонахождение") && (
              <div>
                <span>{head[3]}</span>
                <span>{locationHICM}</span>
              </div>
            )}
            {hiddenHeaders.includes(
              "Email сотрудника ОБИО, который вёл поиск книги",
            ) && (
              <div>
                <span>{head[4]}</span>
                <span>{dataItem.submitter}</span>
              </div>
            )}
            {hiddenHeaders.includes("Статус поиска книги") && (
              <div>
                <span>Статус поиска книги</span>
                <span style={{ fontSize: "11px" }}>
                  (нажмите на ячейку, чтобы выбрать статус)
                </span>
                <VisualAction>
                  <VisualTypeContainer isDisabled={!isOUBF}>
                    <input
                      disabled={!isOUBF}
                      readOnly
                      type="text"
                      value={verification[dataItem.status - 1]}
                      onClick={() => setVerificationOpen(dataIndex)}
                    />
                    <ContentModalType
                      isContentModal={verificationOpen === dataIndex}
                      setIsContentModal={handleVerificationClose}
                    >
                      <VisualTypeButtons
                        groupName={dataIndex}
                        activeButton={`${dataItem.status - 1}`}
                        buttons={verification}
                        handleButton={handleVerification}
                      />
                    </ContentModalType>
                  </VisualTypeContainer>
                </VisualAction>
              </div>
            )}
            {hiddenHeaders.includes(
              "Email сотрудника ОБФ, который обновил статус поиска книги",
            ) && (
              <div>
                <span>{head[6]}</span>
                <span>{dataItem.finder}</span>
              </div>
            )}
            {hiddenHeaders.includes("Комментарии") && (
              <div>
                <span>{head[7]}</span>
                <span style={{ fontSize: "11px" }}>
                  (нажмите на ячейку, чтобы ввести комментарий)
                </span>
                <TableTdInput
                  type="text"
                  value={dataItem.comment}
                  onChange={({ target }) => {
                    handleComment(dataIndex, target.value);
                  }}
                />
              </div>
            )}
          </Card>
        );
      })}
      {!!data.length ? (
        <TablePagination>
          <TablePaginationIconButton
            onClick={handlePreviousClick}
            isDisabled={currentPage === 1}
          >
            <Icon icon="arrowLineLeft" height={30} />
          </TablePaginationIconButton>
          <div>
            <span>Страница</span>
            <span>{currentPage}</span>
          </div>
          <TablePaginationIconButton
            onClick={handleNextClick}
            isDisabled={currentPage === totalPages}
          >
            <Icon icon="arrowLineRight" height={30} />
          </TablePaginationIconButton>
        </TablePagination>
      ) : (
        <NoData>Нет данных</NoData>
      )}
    </Container>
  );
};

export default MobileBooksNotFound;

const NoData = styled.div`
  padding-top: 15px;
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  padding: 0 15px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 20px 15px;
  background-color: white;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    font-size: 14px;

    > span {
      :first-child {
        color: rgb(119, 119, 119);
      }
    }
  }
`;

const TablePagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  > div {
    display: flex;
    align-items: center;
    column-gap: 10px;

    > span {
      :nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: 1px solid grey;
        border-radius: 5px;
        padding: 0 10px;
      }
    }
  }

  @media (hover: hover) {
    svg {
      cursor: pointer;
    }
  }
`;

const TableTdInput = styled.input`
  border: none;
  outline: none;
  background-color: initial;
`;

const TablePaginationIconButton = styled.div`
  ${({ isDisabled }) => isDisabled && "visibility: hidden"};
`;

const VisualAction = styled.div`
  input {
    padding: 10px;
    outline-style: none;
    border: none;
    margin: 0;
    background-color: initial;
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const ContentModalType = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div {
    padding: 0;

    > div {
      border: none;
    }

    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VisualTypeButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;
