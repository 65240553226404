import * as types from "./actionTypes";

const initialState = {
  consultationData: {
    id: null,
    source: "",
    category: "",
    content: "",
    comment: "",
    date_time: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONSULTATION_SET:
      return {
        ...state,
        consultationData: action.payload,
      };
    case types.CONSULTATION_GET_SUCCESS:
      return {
        ...state,
        consultationData: action.payload,
      };
    case types.CONSULTATION_RESET:
      return {
        consultationData: {
          id: null,
          source: "",
          category: "",
          content: "",
          comment: "",
          date_time: "",
        },
      };

    default:
      return state;
  }
};
