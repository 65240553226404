import React from "react";

export default (props) => {
  return (
    <svg
      width={props.width || "35"}
      height={props.height || "35"}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="34.7826" height="34.9091" fill="#c4c4c4" />
      <line x1="7" y1="17.5" x2="27" y2="17.5" stroke="black" strokeWidth="3" />
      <line x1="17.5" y1="8" x2="17.5" y2="28" stroke="black" strokeWidth="3" />
    </svg>
  );
};
