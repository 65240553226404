import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import Calendar from "react-calendar";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";

export default ({
  buttons,
  checkOrganization,
  onClose,
  organizationData,
  handleSetOrganization,
  checkButtonActive,
  resetOrganization,
}) => {
  const today = new Date();
  const date_time = today;

  const [isDuration, setIsDuration] = useState(false);
  const [isFormat, setFormat] = useState(false);
  const [isFormatInput, setFormatInput] = useState(false);
  const [isOrganization, setOrganization] = useState(false);
  const [isOrganizationInput, setOrganizationInput] = useState(false);
  const [isDepartment, setIsDepartment] = useState(false);
  const [date, setDate] = useState(date_time);
  const [isCalendar, setIsCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(date_time, "dd.MM.yyyy", { locale })
  );

  const handleDate = (d) => {
    const sDate = format(d, "dd.MM.yyyy", { locale });
    const fDate = format(d, "yyyy-MM-dd HH:mm:ss", { locale });

    handleSetOrganization("date_time", fDate);

    setDate(d);
    setIsCalendar(false);
    setSelectedDate(sDate);
  };

  const changeInput = (name, value) => {
    const last = buttons[name].slice(-1)[0];

    if (buttons[name].indexOf(value) >= 0) {
      return value;
    } else return !!value || value === null || value === undefined ? last : "";
  };

  const handleFormat = (name, value) => {
    const last = buttons[name].slice(-1)[0];
    let temp = value;

    if (last === buttons[name][value]) {
      setFormatInput(true);
      temp = null;
    } else {
      setFormatInput(false);
    }

    handleSetOrganization(name, temp);
  };

  const handleOrganization = (name, value) => {
    const last = buttons[name].slice(-1)[0];
    let temp = value;

    if (last === buttons[name][value]) {
      setOrganizationInput(true);
      temp = null;
    } else {
      setOrganizationInput(false);
    }

    handleSetOrganization(name, temp);
  };

  useEffect(() => {
    return () => resetOrganization();
  }, []);

  return (
    <ConsultationContainer>
      <ConsultationAction>
        <div>
          Какое подразделение оказало методическую консультацию *
          <span>(обязательное поле)</span>
        </div>
        <VisualTypeContainer onClick={() => setIsDepartment((state) => !state)}>
          <input
            type="text"
            value={organizationData.department || ""}
            readOnly
          />
          <ContentModalType
            isContentModal={isDepartment}
            setIsContentModal={setIsDepartment}
          >
            <VisualTypeButtons
              groupName="department"
              handleButton={handleSetOrganization}
              activeButton={checkButtonActive("department")}
              buttons={buttons.department}
            />
          </ContentModalType>
        </VisualTypeContainer>
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Была ли оказана консультация *<span>(обязательное поле)</span>
        </div>
        <ConsultationButtons>
          <ButtonsGroup
            groupName={"status"}
            handleButton={handleSetOrganization}
            activeButton={checkButtonActive("status")}
            buttons={buttons.status}
            buttonStyles={
              "border: none; border-radius: 0; @media (max-width: 768px) {width: 100%;}; @media (min-width: 769px) {padding: 4px 10px;}"
            }
          />
        </ConsultationButtons>
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Дата консультации *<span>(обязательное поле)</span>
        </div>
        <ExcursionCalendarContainer onClick={() => setIsCalendar(!isCalendar)}>
          {selectedDate}
          <ContentModal
            contentModalStyle={contentModalStyle}
            isContentModal={isCalendar}
            setIsContentModal={setIsCalendar}
          >
            <ExcursionCalendar>
              <Calendar
                selectRange={false}
                onChange={handleDate}
                value={date}
                maxDate={today}
                locale="ru-RU"
              />
            </ExcursionCalendar>
          </ContentModal>
          <i className="fa fa-calendar" aria-hidden="true" />
        </ExcursionCalendarContainer>
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Сколько сотрудников подразделения было задействовано в ответе на
          запрос о консультации *<span>(обязательное поле)</span>
        </div>
        <input
          type="number"
          max="40"
          value={organizationData.participants}
          onChange={({ target }) =>
            handleSetOrganization("participants", +target.value)
          }
        />
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Как была оказана консультация *<span>(обязательное поле)</span>
        </div>
        <VisualTypeContainer onClick={() => setFormat((state) => !state)}>
          <input
            type="text"
            value={changeInput("format", organizationData.format)}
            readOnly
          />
          <ContentModalType
            isContentModal={isFormat}
            setIsContentModal={setFormat}
          >
            <VisualTypeButtons
              groupName="format"
              handleButton={handleFormat}
              activeButton={checkButtonActive("format")}
              buttons={buttons.format}
            />
          </ContentModalType>
        </VisualTypeContainer>
        {isFormatInput && (
          <input
            type="text"
            value={organizationData.format}
            onChange={({ target }) =>
              handleSetOrganization("format", target.value)
            }
          />
        )}
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Какой организации была оказана консультация *
          <span>(обязательное поле)</span>
        </div>
        <VisualTypeContainer onClick={() => setOrganization((state) => !state)}>
          <input
            type="text"
            value={changeInput("organization", organizationData.organization)}
            readOnly
          />
          <ContentModalType
            isContentModal={isOrganization}
            setIsContentModal={setOrganization}
          >
            <VisualTypeButtons
              groupName="organization"
              handleButton={handleOrganization}
              activeButton={checkButtonActive("organization")}
              buttons={buttons.organization}
            />
          </ContentModalType>
        </VisualTypeContainer>
        {isOrganizationInput && (
          <input
            type="text"
            value={organizationData.organization}
            onChange={({ target }) =>
              handleSetOrganization("organization", target.value)
            }
          />
        )}
      </ConsultationAction>
      <ConsultationAction>
        <div>
          Краткая суть вопроса *<span>(обязательное поле)</span>
        </div>
        <textarea
          rows="1"
          value={organizationData.comment}
          onChange={({ target }) =>
            handleSetOrganization("comment", target.value)
          }
        />
      </ConsultationAction>
      <ConsultationAction>
        <div>Длительность консультации</div>
        <VisualTypeContainer onClick={() => setIsDuration((state) => !state)}>
          <input type="text" value={organizationData.duration || ""} readOnly />
          <ContentModalType
            isContentModal={isDuration}
            setIsContentModal={setIsDuration}
          >
            <VisualTypeButtons
              groupName="duration"
              handleButton={handleSetOrganization}
              activeButton={checkButtonActive("duration")}
              buttons={buttons.duration}
            />
          </ContentModalType>
        </VisualTypeContainer>
      </ConsultationAction>
      <ConsultationSubmit>
        <Button
          onClick={checkOrganization}
          name="Сохранить"
          buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
        />
        <Button
          onClick={onClose}
          name="Отмена"
          buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
        />
      </ConsultationSubmit>
    </ConsultationContainer>
  );
};

const contentModalStyle = `
  top: 40px; 
  left: 0; 
  
  > div::after {
    right: 0; 
    left: 15px;
  }
`;

const VisualTypeContainer = styled.div`
  display: flex;
  position: relative;
  font-size: 14px;
  line-height: 1;

  input {
    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  ${({ isDisabled }) => isDisabled && "opacity: 0.7;"}
`;

const VisualTypeButtons = styled(ButtonsGroup)`
  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const ContentModalType = styled(ContentModal)`
  top: 40px;
  left: 0;

  > div {
    padding: 10px;

    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ConsultationButtons = styled.div`
  @media (min-width: 931px) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;

    > input {
      padding: 0 10px;
    }
  }

  @media (max-width: 930px) {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }
`;

const ExcursionCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${({ isDisabled }) =>
    isDisabled ? "opacity: 0.7; cursor: initial;" : "cursor: pointer;"}

  padding: 10px;
  border-radius: 2px;
  border: 1px solid #c4c4c4;

  font-size: 14px;

  .fa {
    margin-left: 10px;
  }
`;

const ExcursionCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: calc(100vw - 30px);
    }
  }
`;

const ConsultationContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &,
  textarea {
    font-size: 14px;
    resize: none;
  }

  @media (min-width: 769px) {
    border: 1px solid #c4c4c4;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 15px 15px;
    border: none;
  }
`;

const ConsultationAction = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    font-weight: 500;

    > span {
      opacity: 0.5;
      font-size: 80%;
      margin-left: 5px;
      font-weight: normal;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 2px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
  }

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

const ConsultationSubmit = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
