import * as types from "./actionTypes";

export const setUnavailable = (payload) => ({
  type: types.UNAVAILABLE_SET,
  payload,
});

export const resetUnavailable = () => ({
  type: types.UNAVAILABLE_RESET,
});
