import { combineReducers } from "redux";

import allowance from "./allowance";
import points from "./points";
import excursions from "./excursions";
import excursion from "./excursion";
import consultations from "./consultations";
import unavailable from "./unavailable";
import visuals from "./visuals";
import visual from "./visual";
import consultation from "./consultation";
import accounting from "./accounting";
import settings from "./settings";
import organization from "./organization";
import organizations from "./organizations";
import progress from "./progress";

export default combineReducers({
  allowance: allowance.reducer,
  points: points.reducer,
  excursions: excursions.reducer,
  excursion: excursion.reducer,
  consultations: consultations.reducer,
  unavailable: unavailable.reducer,
  visuals: visuals.reducer,
  visual: visual.reducer,
  consultation: consultation.reducer,
  accounting: accounting.reducer,
  settings: settings.reducer,
  organization: organization.reducer,
  organizations: organizations.reducer,
  progress: progress.reducer,
});
