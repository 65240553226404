import React from "react";
import Department from "../Department";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  height: fit-content;

  @media (min-width: 769px) {
    padding: 20px 15px 20px 30px;
    margin-right: 30px;
  }

  @media (max-width: 768px) and (min-width: 701px) {
    padding: 20px 15px;
    margin-right: 15px;
  }

  @media (min-width: 701px) {
    max-width: 100%;

    border-bottom: 1px solid rgb(196, 196, 196);
    border-top: 1px solid rgb(196, 196, 196);

    border-right: 1px;
    border-right-style: solid;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-right-color: rgb(196, 196, 196);
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
`;

const Table = styled.div`
  :nth-child(1),
  :nth-child(2) {
    > div:nth-child(2) {
      overflow-x: scroll;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  :nth-child(1) {
    tbody tr {
      cursor: pointer;
    }

    th:nth-child(n + 2) {
      text-align: left;
    }

    td:nth-child(1) {
      text-align: center;
    }
  }

  :nth-child(2) {
    tbody tr {
      cursor: pointer;
    }

    th:nth-child(n + 3) {
      text-align: left;
    }

    td:nth-child(-n + 2) {
      text-align: center;
    }
  }

  table {
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      padding: 10px;
    }

    tbody {
      tr {
        :not(:last-child) {
          border-bottom: 1px solid #d8d8d8;
        }
      }
    }

    thead {
      tr {
        th {
          font-weight: 500;
          background: #d8d8d8;
          white-space: nowrap;
        }
      }
    }
  }
`;

const TableDepartments = ({ data, handleDepartment }) => {
  return (
    <Container>
      <Table>
        <div>
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Название отдела</th>
                <th>Аббревиатура отдела</th>
                <th>Почта (НЕК)</th>
              </tr>
            </thead>
            <tbody>
              {data.map((department, index) => {
                return (
                  <Department
                    index={index}
                    key={department.id}
                    department={department}
                    handleDepartment={handleDepartment}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Table>
    </Container>
  );
};

export default TableDepartments;
