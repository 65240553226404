import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import Button from "../../nekrasovka-ui/Button";
import { connect } from "react-redux";
import styled from "styled-components";
import { resetExcursion, setExcursion } from "../../reducers/excursion/actions";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import Calendar from "react-calendar";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

const Excursion = ({
  excursionData,
  setExcursion,
  resetExcursion,
  isUserCanEdit,
  onSave,
  onClose,
  excursionColumns,
}) => {
  let date_time;
  const today = new Date();

  if (excursionData.date_time) {
    date_time = new Date(excursionData.date_time);
  } else date_time = today;

  const isDisabled = !isUserCanEdit;
  const { dispatch } = useContext(AlertContext);
  const [date, setDate] = useState(date_time);
  const [isCalendar, setIsCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    format(date_time, "dd.MM.yyyy", { locale })
  );

  useEffect(() => {
    return () => resetExcursion();
  }, []);

  const handleExcursion = (name, value) => {
    const temp = excursionData;
    temp[name] = value;

    setExcursion({ ...temp });
  };

  const handleDate = (d) => {
    const sDate = format(d, "dd.MM.yyyy", { locale });
    const fDate = format(d, "yyyy-MM-dd HH:mm:ss", { locale });

    handleExcursion("date_time", fDate);

    setDate(d);
    setIsCalendar(false);
    setSelectedDate(sDate);
  };

  const checkExcursion = () => {
    const { topic, participants } = excursionData;
    const isSourceNotEmpty = !!topic && !!participants;

    if (!excursionData.date_time) {
      const fDate = format(today, "yyyy-MM-dd HH:mm:ss", { locale });

      handleExcursion("date_time", fDate);
    }

    if (isSourceNotEmpty) {
      onSave();
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Вы не заполнили обязательные поля.",
        name: "warning",
      });
    }
  };

  return (
    <ExcursionContainer>
      <ExcursionAction>
        <div>
          {excursionColumns.topic} *<span>(обязательное поле)</span>
        </div>
        <textarea
          disabled={isDisabled}
          value={excursionData.topic || ""}
          rows="10"
          onChange={({ target: { value } }) => handleExcursion("topic", value)}
        />
      </ExcursionAction>
      <ExcursionAction>
        <div>
          {excursionColumns.date_time} *<span>(обязательное поле)</span>
        </div>
        <ExcursionCalendarContainer
          isDisabled={isDisabled}
          onClick={() => isUserCanEdit && setIsCalendar(!isCalendar)}
        >
          {selectedDate}
          <ContentModal
            contentModalStyle={contentModalStyle}
            isContentModal={isCalendar}
            setIsContentModal={setIsCalendar}
          >
            <ExcursionCalendar>
              <Calendar
                selectRange={false}
                onChange={handleDate}
                value={date}
                maxDate={today}
                locale="ru-RU"
              />
            </ExcursionCalendar>
          </ContentModal>
          <i className="fa fa-calendar" aria-hidden="true" />
        </ExcursionCalendarContainer>
      </ExcursionAction>
      <ExcursionAction>
        <div>
          {excursionColumns.participants} *<span>(обязательное поле)</span>
        </div>
        <textarea
          disabled={isDisabled}
          value={excursionData.participants || ""}
          rows="5"
          onChange={({ target: { value } }) =>
            handleExcursion("participants", value)
          }
        />
      </ExcursionAction>
      <ExcursionAction>
        <div>{excursionColumns.comment}</div>
        <textarea
          disabled={isDisabled}
          value={excursionData.comment || ""}
          rows="5"
          onChange={({ target: { value } }) =>
            handleExcursion("comment", value)
          }
        />
      </ExcursionAction>
      {isUserCanEdit && (
        <ExcursionSubmit>
          <Button
            onClick={checkExcursion}
            name="Сохранить"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
          <Button
            onClick={onClose}
            name="Отмена"
            buttonStyles={`@media (max-width: 768px) {width: 100%;}`}
          />
        </ExcursionSubmit>
      )}
    </ExcursionContainer>
  );
};

const mapStateToProps = ({ excursion: { excursionData } }) => ({
  excursionData,
});

export default connect(mapStateToProps, {
  setExcursion,
  resetExcursion,
})(Excursion);

const contentModalStyle = `
  top: 40px; 
  left: 0; 
  
  > div::after {
    right: 0; 
    left: 15px;
  }
`;

const ExcursionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &,
  textarea {
    font-size: 14px;
  }

  @media (min-width: 769px) {
    border: 1px solid #c4c4c4;
    padding: 20px;
  }

  @media (max-width: 768px) {
    padding: 0 15px 15px;
    border: none;
  }
`;

const ExcursionAction = styled.div`
  display: flex;
  flex-direction: column;

  > :first-child {
    font-weight: 500;

    > span {
      opacity: 0.5;
      font-size: 80%;
      margin-left: 5px;
      font-weight: normal;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 2px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
  }

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;

const ExcursionSubmit = styled.div`
  display: flex;
  column-gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExcursionCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${({ isDisabled }) =>
    isDisabled ? "opacity: 0.7; cursor: initial;" : "cursor: pointer;"}

  padding: 10px;
  border-radius: 2px;
  border: 1px solid #c4c4c4;

  font-size: 14px;

  .fa {
    margin-left: 10px;
  }
`;

const ExcursionCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: calc(100vw - 30px);
    }
  }
`;
