import React from "react";

export default () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#a)">
        <g clipPath="url(#b)">
          <path fill="#FFCA38" d="M8 6.484h32v32H8z" />
          <path
            d="M40 32.879v5.605H8v-5.97L22.224 20.66a2.431 2.431 0 0 1 3.113 0L40 32.879Z"
            fill="#FFDC7C"
          />
          <path
            d="m40 13.336-14.386 12.81c-.92.82-2.309.822-3.23.004L8 13.394v-6.91h32v6.852Z"
            fill="url(#c)"
          />
          <path
            d="m40 13.336-14.386 12.81c-.92.82-2.309.822-3.23.004L8 13.394v-6.91h32v6.852Z"
            fill="url(#d)"
            fillOpacity=".5"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="c"
          x1="47.583"
          y1="-13.695"
          x2="21.043"
          y2="21.836"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA3E" />
          <stop offset="1" stopColor="#FC4714" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="46.235"
          y1="3.978"
          x2="24.054"
          y2="24.021"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <clipPath id="b">
          <rect x="8" y="6.484" width="32" height="32" rx="10" fill="#fff" />
        </clipPath>
        <filter
          id="a"
          x="0"
          y=".484"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.752941 0 0 0 0 0.227451 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_660_44604"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_660_44604"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
