import * as types from "./actionTypes";

export const setExcursion = (payload) => ({
  type: types.EXCURSION_SET,
  payload,
});

export const getExcursion = (id) => ({
  type: types.EXCURSION_GET,
  id,
});

export const getExcursionSuccess = (payload) => ({
  type: types.EXCURSION_GET_SUCCESS,
  payload,
});

export const resetExcursion = () => ({
  type: types.EXCURSION_RESET,
});
