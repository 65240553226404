import React, { useState } from "react";
import styled from "styled-components";
import LinkType from "../../../../nekrasovka-ui/Link";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 5px;

  background-color: #dddbcfff;

  @media (hover: hover) {
    border: 1px solid #dddbcfff;
    transition: background-color 0.13s ease-in-out;

    &:hover {
      background-color: #edeee9;
      border: 1px solid #c4c4c4;
      box-shadow: none;

      > :nth-child(2) {
        visibility: visible;
      }
    }
  }
`;

const Page = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  min-height: 67px;
  text-align: left;
  height: 100%;

  @media (min-width: 701px) {
    padding: 20px 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
`;

const PageContent = styled.div`
  display: block;
  height: 100%;
  width: 100%;

  h3 {
    margin-top: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24.89px;
    color: #222222;
  }

  span {
    color: #777777ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
`;

const FavoriteButton = styled.div`
  display: flex;
  visibility: hidden;
  cursor: pointer;

  justify-content: center;
  background-color: rgb(221, 219, 207);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 20px;

  @media (min-width: 701px) {
    position: absolute;
    top: -13px;
    right: -10px;
  }
`;

export default ({
  dragStart = null,
  dragEnter = null,
  dragEnd = null,
  dragOver = null,
  handleFavorites,
  id,
  pageId,
  to,
  name,
  description,
  isExternal,
  isDescription,
  isFavorites,
  isDisabled,
  className,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Container className={className}>
      <LinkType to={to} isExternal={isExternal}>
        <Page
          id={id}
          draggable
          onDragStart={dragStart}
          onDragEnter={dragEnter}
          onDragEnd={dragEnd}
          onDragOver={dragOver}
        >
          <PageContent>
            <h3>{name}</h3>
            {isDescription && <span>{description}</span>}
          </PageContent>
        </Page>
      </LinkType>
      {!isDisabled ? (
        isFavorites ? (
          <FavoriteButton
            onClick={() => handleFavorites(pageId)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {hovered ? <span>&#9733;</span> : <span>&#9734;</span>}
          </FavoriteButton>
        ) : (
          <FavoriteButton
            onClick={() => handleFavorites(pageId)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {hovered ? <span>&#9734;</span> : <span>&#9733;</span>}
          </FavoriteButton>
        )
      ) : null}
    </Container>
  );
};
