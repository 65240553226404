import React from "react";
import { hydrate } from "react-dom";

import "./index.css";
import { Provider } from "react-redux";
import configureStore from "./store";
import rootSaga from "./sagas";
import App from "./containers/App";
import { Helmet } from "react-helmet";

const root = document.getElementById("root");
const state = window.__INITIAL__STATE__;
const store = configureStore(state);
store.runSaga(rootSaga);

hydrate(
  <Provider store={store}>
    <Helmet>
      <meta name="theme-color" content="#dddbcfff" />
    </Helmet>
    <App />
  </Provider>,
  root
);
