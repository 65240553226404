import React from "react";

export default props => (
  <svg {...props} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 25C30 27.7614 27.7614 30 25 30H5C2.23858 30 0 27.7614 0 25V5C0 2.23858 2.23858 0 5 0H25C27.7614 0 30 2.23858 30 5V25ZM20.32 23.565C20.5887 23.7562 20.9338 23.8037 21.2425 23.6862C21.55 23.57 21.7775 23.3062 21.8462 22.9862C22.57 19.5837 24.3275 10.9688 24.9862 7.87375C25.035 7.64 24.9525 7.3975 24.77 7.24125C24.5863 7.08625 24.3312 7.04125 24.105 7.125C20.6112 8.4175 9.85 12.455 5.45125 14.0837C5.1725 14.1862 4.99125 14.455 5 14.75C5.01 15.0437 5.20875 15.3 5.49375 15.3862C7.46625 15.975 10.0562 16.7962 10.0562 16.7962C10.0562 16.7962 11.265 20.4513 11.8962 22.3088C11.975 22.5425 12.1587 22.7263 12.3987 22.7888C12.64 22.8513 12.895 22.7863 13.075 22.6163C14.0887 21.66 15.655 20.1813 15.655 20.1813C15.655 20.1813 18.6313 22.3637 20.32 23.565ZM11.145 16.335L12.5437 20.95L12.855 18.0275C12.855 18.0275 18.26 13.1525 21.3425 10.3725C21.4325 10.2913 21.445 10.155 21.37 10.0588C21.295 9.9625 21.1588 9.94 21.0562 10.005C17.485 12.2862 11.145 16.335 11.145 16.335Z"
      fill={props.fill}
    />
  </svg>
);
