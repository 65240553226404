import React from "react";
import Page from "../Page";
import styled from "styled-components";

const Container = styled.div`
  display: grid;

  @media (max-width: 768px) {
    row-gap: 10px;
  }
`;

const Applications = ({
  dragOver,
  dragEnd,
  dragEnter,
  dragStart,
  pages,
  isDescription,
  isFavorites,
  isDisabled,
  handleFavorites,
  className,
}) => {
  return (
    <Container className={className}>
      {pages.map((page, index) => {
        return (
          <Page
            isFavorites={isFavorites}
            isDisabled={isDisabled}
            id={index}
            pageId={page.id}
            to={page.url}
            key={`card_${page.id}`}
            name={page.name}
            description={page.description}
            isDescription={isDescription}
            isExternal={page.is_external}
            dragStart={dragStart}
            dragEnter={dragEnter}
            dragEnd={dragEnd}
            dragOver={dragOver}
            handleFavorites={handleFavorites}
          />
        );
      })}
    </Container>
  );
};

export default Applications;
