import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: fit-content;

  padding: 20px 0 20px;
  margin: 0 15px;

  :not(:last-child) {
    border-bottom: 1px solid rgb(196, 196, 196);
  }
`;

const StaffInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  span {
    font-size: 14px !important;
  }

  > div {
    :nth-child(1) {
      a {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`;

const StaffInfoTitle = styled.span`
  color: rgb(119, 119, 119);
`;

const MobileDepartment = ({ state }) => {
  return (
    <Container>
      <StaffInfo>
        <div>
          <Link to={`/staff/departments/${state.login}`}>{state.name}</Link>
        </div>
        <div>
          <StaffInfoTitle>Аббревиатура: </StaffInfoTitle>
          <span>{state.login}</span>
        </div>
        <div>
          <StaffInfoTitle>Почта (НЕК): </StaffInfoTitle>
          <a href={`mailto:${state.email}`}>{state.email}</a>
        </div>
      </StaffInfo>
    </Container>
  );
};

export default MobileDepartment;
