import * as types from "./actionTypes";

const initialState = {
  excursionData: {
    id: null,
    topic: "Обзорная экскурсия по фонду",
    participants: "Пользователи библиотеки",
    comment: "",
    date_time: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.EXCURSION_SET:
      return {
        ...state,
        excursionData: action.payload,
      };
    case types.EXCURSION_GET_SUCCESS:
      return {
        ...state,
        excursionData: action.payload,
      };
    case types.EXCURSION_RESET:
      return {
        excursionData: {
          id: null,
          topic: "Обзорная экскурсия по фонду",
          participants: "Пользователи библиотеки",
          comment: "",
          date_time: "",
        },
      };

    default:
      return state;
  }
};
