import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../../../reducers/progress/actions";

const AdministratorPage = ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
}) => {
  const [data, setData] = useState([]);

  const getRequest = async (end, method, data) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/${end}`;
    const options = { method, data, url, headers };

    return axios(options);
  };

  const getData = () => {
    clearProgress();
    startProgress();

    getRequest("admin", "get").then((resp) => {
      setData(resp.data);

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  return <div>AdministratorPage</div>;
};

const mapStateToProps = ({
  allowance: { tokenParsed, checkTokenExpiredYesUpdate },
}) => ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(AdministratorPage);
