import React, { Fragment, useContext, useEffect, useState } from "react";
import LibrarianTable from "../LibrarianTable";
import Pagination from "../../nekrasovka-ui/Pagination";
import styled from "styled-components";
import Organization from "../Organization";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import Modal from "../../nekrasovka-ui/Modal";
import Icon from "../../nekrasovka-ui/Icon";
import { connect } from "react-redux";
import Button from "../../nekrasovka-ui/Button";
import {
  getOrganizations,
  updateOrganizations,
} from "../../reducers/organizations/actions";
import {
  getOrganization,
  resetOrganization,
  setOrganization,
} from "../../reducers/organization/actions";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import Calendar from "react-calendar";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";

const Organizations = ({
  organizationData,
  organizationsData,
  updateOrganizations,
  getOrganization,
  getOrganizations,
  isOrganizationsGet,
  isOrganizationsUpdate,
  resetOrganization,
  setOrganization,
  today,
}) => {
  const [organizationsLoaded, setOrganizationsLoaded] = useState(0);
  const [isOrganization, setIsOrganization] = useState(false);
  const limit = 10;
  const { dispatch } = useContext(AlertContext);
  const isUserTodayCanEdit = true;
  const organizationsDataLength = organizationsData.length;
  const countPages = Math.ceil(organizationsDataLength / limit);
  const [isCalendar, setIsCalendar] = useState(false);
  const newDate = new Date();
  const minDate = new Date("2022-01-01");
  const [date, setDate] = useState(newDate);
  const [selectedDate, setSelectedDate] = useState(
    format(newDate, "dd.MM.yyyy", { locale })
  );

  const buttons = {
    status: ["не оказана", "оказана"],
    format: [
      "очно на территории библиотеки",
      "очно на внешней площадке",
      "по телефону",
      "по электронной почте",
      "официальным письмом",
      "другое (укажите, что именно)",
    ],
    department: [
      "Дирекция",
      "Информационно-библиографический отдел",
      "Отдел библиотечно-информационного обслуживания",
      "Отдел краеведения",
      "Отдел редких изданий и коллекций",
      "Отдел культурно-просветительских проектов и программ",
      "Отдел научно-методической и научно-исследовательской работы",
      "Отдел каталогизации и научной обработки документов",
      "Отдел управления библиотечным фондом",
      "Отдел комплектования фондов",
      "Отдел мониторинга и библиотечной статистики",
      "Отдел пропаганды чтения и продвижения фондов",
      "Отдел информационных технологий",
      "Сектор развития электронной библиотеки",
      "Сектор консервации и реставрации изданий",
      "Эксплуатационно-хозяйственный отдел",
      "Отдел бухгалтерского учета и финансового контроля",
      "Юридический отдел",
      "Отдел контрактной службы",
      "Отдел кадров",
      "Отдел печати и сохранности фондов",
      "Сектор обменно-резервного фонда отдела комплектования фондов",
    ],
    organization: [
      "Департамент культуры города Москвы",
      'ГБУК г. Москвы "Мосразвитие"',
      'ГБУК г. Москвы "ОКЦ ЦАО"',
      'ГБУК г. Москвы "ОКЦ ТиНАО"',
      'ГБУК г. Москвы "ОКЦ ЗелАО"',
      'ГБУК г. Москвы "ОКЦ СЗАО"',
      'ГБУК г. Москвы "ОКЦ ЗАО"',
      'ГБУК г. Москвы "ОКЦ ЮЗАО"',
      'ГБУК г. Москвы "ОКЦ ЮАО"',
      'ГБУК г. Москвы "ОКЦ ЮВАО"',
      'ГБУК г. Москвы "ОКЦ ВАО"',
      'ГБУК г. Москвы "ОКЦ СВАО"',
      'ГБУК г. Москвы "ОКЦ САО"',
      'ГБУК г. Москвы "ЦГДБ"',
      'ГБУК г. Москвы "Дом Лосева"',
      'ГБУК г. Москвы "Библиотека искусств им. А.П. Боголюбова"',
      'ГБУК г. Москвы "Библиотека-читальня им. И.С. Тургенева"',
      'ГБУК г. Москвы "Библиотека-читальня им. А.С.Пушкина"',
      'ГБУК г. Москвы "ЦГДБ им. А. П. Гайдара"',
      'ГБУК г. Москвы "ЦГМБ им. М.А. Светлова"',
      'ГБУК г. Москвы "Дом Гоголя"',
      "другая организация (укажите, какая именно)",
    ],
    duration: [
      "меньше минуты",
      "от 1 до 5 минут",
      "от 5 до 15 минут",
      "меньше 1 часа",
      "от 1 до 3 часов",
      "от 3 до 8 часов",
      "больше 8 часов",
    ],
  };

  const getPaginated = (num) => setOrganizationsLoaded((num - 1) * limit);

  const checkOrganization = () => {
    const { status, comment, format, department, organization } =
      organizationData;

    const isAllNotEmpty =
      status !== "" && !!format && !!department && !!organization && !!comment;

    if (isAllNotEmpty) {
      onSave();
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Вы не заполнили обязательное поле.",
        name: "warning",
      });
    }
  };

  const handleSetOrganization = (name, value) => {
    const temp = organizationData;

    if (["organization", "format"].includes(name)) {
      temp[name] = isNaN(value) ? value : buttons[name][value];
    } else if (
      ["participants", "date_time", "status", "comment"].includes(name)
    ) {
      temp[name] = value;
    } else temp[name] = buttons[name][value];

    setOrganization({ ...temp });
  };

  const onClose = () => {
    setIsOrganization(false);
  };

  const onSave = () => {
    updateOrganizations();
    onClose();
  };

  const handleGetOrganization = (id) => {
    getOrganization(id);
    setIsOrganization(true);
  };

  const checkButtonActive = (name) => {
    if (name === "status") {
      return typeof organizationData[name] === "string"
        ? buttons[name].indexOf(organizationData[name])
        : organizationData[name];
    } else {
      if (buttons[name].indexOf(organizationData[name]) >= 0) {
        return buttons[name].indexOf(organizationData[name]);
      } else {
        if (["organization", "format"].includes(name)) {
          return organizationData[name] === null ||
            organizationData[name] === undefined
            ? buttons[name].length - 1
            : "";
        } else return "";
      }
    }
  };

  const handlePointDataByDate = (d) => {
    const sDate = format(d, "dd.MM.yyyy", { locale });
    const fDate = format(d, "yyyy-MM-dd", { locale });

    getOrganizations(fDate);

    setDate(d);
    setIsCalendar(false);
    setSelectedDate(sDate);
  };

  useEffect(() => {
    if (isOrganizationsUpdate) getOrganizations(today);
  }, [isOrganizationsUpdate]);

  useEffect(() => {
    if (organizationsLoaded > 0) setOrganizationsLoaded(0);
  }, [isOrganizationsGet]);

  useEffect(() => {
    getOrganizations(today);
  }, []);

  return (
    <Fragment>
      {isOrganization && (
        <Modal>
          <OrganizationsContainer>
            <OrganizationModalTitle>
              <h4>
                {organizationData.id
                  ? `${
                      isUserTodayCanEdit ? "Редактирование" : "Чтение"
                    } консультации`
                  : "Новая консультация"}
              </h4>
              <OrganizationCloseButton onClick={onClose}>
                <Icon icon="closeModal" width={20} height={20} />
              </OrganizationCloseButton>
            </OrganizationModalTitle>
            <Organization
              buttons={buttons}
              checkOrganization={checkOrganization}
              onClose={onClose}
              handleSetOrganization={handleSetOrganization}
              organizationData={organizationData}
              resetOrganization={resetOrganization}
              checkButtonActive={checkButtonActive}
            />
          </OrganizationsContainer>
        </Modal>
      )}
      <LibrarianPointHeader>
        <h1>Учёт методических консультаций</h1>
        <LibrarianPointCalendarDate onClick={() => setIsCalendar(!isCalendar)}>
          {selectedDate}
          <i className="fa fa-calendar" aria-hidden="true" />
        </LibrarianPointCalendarDate>
        <ContentModal
          contentModalStyle={`top: 50px; right: 15px;`}
          isContentModal={isCalendar}
          setIsContentModal={setIsCalendar}
        >
          <LibrarianPointCalendar>
            <Calendar
              selectRange={false}
              onChange={handlePointDataByDate}
              value={date}
              maxDate={newDate}
              minDate={minDate}
              locale="ru-RU"
            />
          </LibrarianPointCalendar>
        </ContentModal>
      </LibrarianPointHeader>
      <OrganizationsContainer>
        <OrganizationTitle>
          <p>
            Методическая консультация — это консультационная услуга, включающая
            профессиональный совет специалиста библиотеки и помощь по
            конкретному вопросу, связанному с повседневной практикой
            библиотечной деятельности. Получателем такой услуги может быть
            только другая организация (например, представитель Департамента
            культуры города Москвы, другой библиотеки или Мосразвития).
          </p>
          <p>
            Консультации пользователям библиотеки учитываются в разделе "Книги
            учета и консультаций".
          </p>
          <p>
            Консультацией считается любая ситуация, когда вы предоставляете
            сотрудникам других организаций информацию, помогающую им решить
            интересующий их вопрос или возникшую проблему. При этом
            предполагается, что вы только помогаете информацией или советом, но
            не предпринимаете каких-либо других значимых действий для решения
            вопроса — например, не являетесь посредником между участниками
            процесса.
          </p>
          <p>
            При этом уточнение и проверка информации, которую вы готовитесь
            предоставить коллегам, считается частью методической консультации.
          </p>
          <p>
            Если вы не смогли помочь с решением вопроса, но переадресовали
            вопрос в другое структурное подразделение библиотеки или
            посоветовали обратиться в другую организацию, то это не считается
            оказанной методической консультацией.
          </p>

          <p>
            Если вы не смогли оказать консультацию, но считаете необходимым
            зафиксировать факт обращения за консультацией, то заполните форму,
            но в пункте "Была ли оказана консультация" выберите вариант "не
            оказана". Информация о запросах, оставшихся без содержательного
            ответа, может быть использована при выборе тем для методических
            разработок библиотеки.
          </p>
          <p>
            Если вы оказывали методические консультации другим юридическим
            лицам, пожалуйста, заполните форму ниже, чтобы мы могли учесть вашу
            работу.
          </p>
          <OrganizationButtons>
            <Button onClick={() => setIsOrganization(true)} name="Добавить" />
          </OrganizationButtons>
        </OrganizationTitle>
        <OrganizationsLibrarianTable
          isDataGet={isOrganizationsGet}
          loaded={organizationsLoaded}
          limit={limit}
          handleRow={handleGetOrganization}
          rows={organizationsData.map((item) => {
            const temp = { ...item };
            temp.status = buttons["status"][item.status];

            return temp;
          })}
          columns={{
            department: "Какое подразделение оказало методическую консультацию",
            status: "Была ли оказана консультация",
            date_time: "Дата консультации",
            participants:
              "Сколько сотрудников подразделения было задействовано в ответе на запрос о консультации",
            format: "Как была оказана консультация",
            organization: "Какой организации была оказана консультация",
            comment: "Краткая суть вопроса",
            duration: "Длительность консультации",
          }}
          info={{
            loading: "Получаем методические консультации...",
            get: "Нет методических консультаций за этот день...",
          }}
        />
        {countPages > 1 && (
          <Pagination getPaginated={getPaginated} countPages={countPages} />
        )}
      </OrganizationsContainer>
    </Fragment>
  );
};

const mapStateToProps = ({
  allowance: { today },
  organizations: {
    organizationsData,
    isOrganizationsGet,
    isOrganizationsUpdate,
  },
  organization: { organizationData },
}) => ({
  organizationData,
  organizationsData,
  isOrganizationsGet,
  isOrganizationsUpdate,
  today,
});

export default connect(mapStateToProps, {
  updateOrganizations,
  getOrganization,
  getOrganizations,
  resetOrganization,
  setOrganization,
})(Organizations);

const LibrarianPointCalendar = styled.div`
  .react-calendar {
    display: block;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: calc(100vw - 30px);
    }
  }
`;

const LibrarianPointCalendarDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  font-size: 16px;
  line-height: 1;

  .fa {
    margin-left: 10px;
  }
`;

const LibrarianPointHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 5px;

  h1 {
    line-height: 1;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 0 15px;
  }
`;

const OrganizationButtons = styled.div`
  @media (min-width: 931px) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;

    > input {
      padding: 0 10px;
    }
  }

  @media (max-width: 930px) {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }
`;

const OrganizationCloseButton = styled.div`
  display: flex;

  svg {
    rect {
      fill: #ecf0f5;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    :hover svg {
      rect {
        fill: #40677e;
      }

      line {
        stroke: #ffffff;
      }
    }
  }
`;

const OrganizationTitle = styled.div`
  p {
    :first-child {
      margin-top: 0;
    }

    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 768px) {
    padding: 15px 15px 0;
  }
`;

const OrganizationModalTitle = styled(OrganizationTitle)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  h4 {
    margin: 0;
  }
`;

const OrganizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: #ffffff;

  @media (min-width: 769px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

const OrganizationsLibrarianTable = styled(LibrarianTable)`
  th {
    white-space: normal;
  }
`;
