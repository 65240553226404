import axios from "axios";
import { useState, useEffect } from "react";

const root = document.getElementById("root");

const mapOrder = (array, order, key) => {
  array.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]));
  return array;
};

const scrollToIf = (to, number = 0) => {
  if (window.scrollY === number) return;
  setTimeout(() => window.scrollTo({ [to]: number, behavior: "smooth" }), 100);
};

const addFixed = () => {
  root.classList.add("root-fixed");
};

const delFixed = () => {
  root.classList.remove("root-fixed");
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const postTelegramBot = ({ method, data }) => {
  const url = `https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/${method}`;

  try {
    axios(url, {
      method: "post",
      data: {
        chat_id: process.env.REACT_APP_TELEGRAM_CHAT_ID,
        ...data,
      },
    });
  } catch (error) {
    console.error("❗telegram error: ", error.response.data);
  }
};

const isExpired = (exp) => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }

  const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

function kebabCaseToCamel(str) {
  return str.replace(/(\/-\w)/g, (matches) => matches[1].toUpperCase());
}

const isLoggedIn = () => {
  return typeof window !== "undefined" && localStorage.getItem("token");
};

const useWindowDimensions = () => {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

const getEnding = (number) => {
  const decCache = [];
  const decCases = [2, 0, 1, 1, 1, 2];
  const titles = ["комментарий", "комментария", "комментариев"];

  if (!decCache[number])
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)];
  return titles[decCache[number]];
};

export {
  getExpirationDate,
  kebabCaseToCamel,
  isExpired,
  isJsonString,
  scrollToIf,
  addFixed,
  delFixed,
  postTelegramBot,
  isLoggedIn,
  useWindowDimensions,
  mapOrder,
  getEnding,
};
