import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt from "jwt-decode";

export default ({ rest, children }) => {
  const TOKEN_KEY = localStorage.getItem("TOKEN_KEY");
  const { preferred_username } = jwt(TOKEN_KEY);

  const isAdmin = ["vdascal", "dmatveev"].includes(preferred_username);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/backoffice/dedusting/users/${preferred_username}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
