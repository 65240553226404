import React, { useRef } from "react";
import styled from "styled-components";

const ContentModal = ({ isContentModal, children, className }) => {
  const contentModalRef = useRef(null);

  return (
    <Container
      className={className}
      isContentModal={isContentModal}
      ref={contentModalRef}
    >
      <div>{children}</div>
    </Container>
  );
};

export default ContentModal;

const Container = styled.div`
  display: ${({ isContentModal }) => (isContentModal ? "flex" : "none")};
  z-index: 1;
  border-radius: 5px;

  &,
  > div::after {
    box-shadow: 0 0 7px rgb(0 0 0 / 27%);
    position: absolute;
  }

  > div,
  > div::after {
    background: #ffffff;
  }

  > div {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;

    > div {
      max-height: 300px;
      overflow-x: scroll;
      scroll-behavior: smooth;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    ::after {
      content: "";
      right: calc(250px / 2 - 7.5px);
      top: -7px;
      width: 15px;
      height: 15px;
      z-index: -1;
      transform: rotate(45deg);
    }
  }
`;
