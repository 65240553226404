import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Users = ({ data }) => {
  return (
    <Container>
      {data.map((item, i) => {
        const isTickets = !!item.tickets.length;

        return (
          <User
            key={i}
            as={Link}
            to={`/backoffice/dedusting/users/${item.user.login}`}
          >
            <UserName>{item.user.name}</UserName>
            <UserDetails>
              <div>
                <span>Дата обеспыливания</span>
                <div>
                  <div>
                    <div>
                      {isTickets &&
                        item.tickets.map((m, dateIndex) => {
                          return (
                            <div key={dateIndex}>
                              <span>
                                {!!m.date ? m.date.split(".")[0] : "-"}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span>Ряд</span>
                <div>
                  <div>
                    <div>
                      {isTickets &&
                        item.tickets.map((m, shelfIndex) => {
                          return (
                            <div key={shelfIndex}>
                              <div>
                                <span>№</span>
                                <span>{m.bookcase_id}</span>
                              </div>
                              <div>
                                <span>%</span>
                                <span>{m.percent}</span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </UserDetails>
          </User>
        );
      })}
    </Container>
  );
};

export default Users;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 5px;
  width: 100%;
  padding-top: 15px;
`;

const UserName = styled.div`
  font-weight: 500;
`;

const User = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 15px;
  background-color: #f7f7f7;
  font-size: 13px;
  color: #222222;
  width: 100%;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    > span {
      font-weight: 500;
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5px));
      column-gap: 10px;

      > div {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        > div {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 3px;
            border: 1px solid rgb(221, 219, 207);
            width: 48px;

            > span {
              padding: 2px;
            }

            > div {
              display: flex;
              justify-content: space-between;
              width: 100%;

              span {
                :first-child {
                  display: flex;
                  padding: 2px 3px 2px 2px;
                  width: 17.5px;
                  background-color: rgb(221, 219, 207);
                }

                :last-child {
                  padding: 2px 3px 2px 2px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UserDetailsPlanDate = styled.div`
  width: calc(48px * 2 + 10px) !important;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      background-color: rgb(221, 219, 207);
    }
  }
`;
