import React from "react";
import styled from "styled-components";

const EmailLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const Department = ({ department, index, handleDepartment }) => {
  return (
    <tr onClick={() => handleDepartment(department)}>
      <td>{index + 1}</td>
      <td>{department.name}</td>
      <td>{department.login}</td>
      <td>
        <EmailLink href={`mailto:${department.email}`}>
          {department.email}
        </EmailLink>
      </td>
    </tr>
  );
};

export default Department;
