import React from "react";

export default (props) => (
  <svg
    {...props}
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          d="M17 14.8116L10.6752 8.48532L17 2.16905L14.8116 0L8.48918 6.32014L2.17136 0L0 2.17136L6.32555 8.50464L0 14.8286L2.17136 17L8.51005 10.6698L14.831 17L17 14.8116Z"
          fill={props.fill}
        />
      </g>
    </g>
  </svg>
);
