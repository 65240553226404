import * as types from "./actionTypes";

const initialState = {
  organizationData: {
    id: null,
    status: "",
    format: "",
    department: "",
    organization: "",
    duration: "",
    comment: "",
    date_time: "",
    participants: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ORGANIZATION_SET:
      return {
        ...state,
        organizationData: action.payload,
      };
    case types.ORGANIZATION_GET_SUCCESS:
      return {
        ...state,
        organizationData: action.payload,
      };
    case types.ORGANIZATION_RESET:
      return {
        ...state,
        organizationData: {
          id: null,
          status: "",
          format: "",
          department: "",
          organization: "",
          duration: "",
          comment: "",
          date_time: "",
          participants: "",
        },
      };

    default:
      return state;
  }
};
