import React from "react";
import Floor from "../Floor";
import styled from "styled-components";

const Maps = ({
  clickedFloor,
  setClickedFloor,
  handleBookcase,
  floors,
  selectedFloor,
}) => {
  return (
    <div>
      <Floors isFloor={selectedFloor !== ""}>
        {floors
          .filter((floor) =>
            selectedFloor === "" ? true : floor === `${selectedFloor + 1}`,
          )
          .map((floor) => {
            return (
              <Floor
                key={floor}
                floor={floor}
                handleBookcase={handleBookcase}
                setClickedFloor={setClickedFloor}
                clickedFloor={clickedFloor}
              />
            );
          })}
      </Floors>
    </div>
  );
};

export default Maps;

const ViewTitle = styled.div`
  display: flex;
  height: 20px;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;

  ${({ isHidden }) =>
    isHidden
      ? "fill: #c4c4c4;"
      : "padding: 0 0 0 15px; > span {font-size: 11px;}"};
`;

const Floors = styled.div`
  display: grid;
  grid-template-columns: ${({ isFloor }) => `repeat(${isFloor ? 1 : 2}, 1fr)`};
`;

const FloorsViewTitle = styled(ViewTitle)`
  margin-top: 10px;
`;
