import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getConsultations,
  getConsultationsTypicalResponses,
  resetConsultations,
  updateConsultations,
} from "../../reducers/consultations/actions";

import Consultation from "../Consultation";
import Button from "../../nekrasovka-ui/Button";
import Icon from "../../nekrasovka-ui/Icon";
import {
  getConsultation,
  setConsultation,
  resetConsultation,
} from "../../reducers/consultation/actions";
import Pagination from "../../nekrasovka-ui/Pagination";
import Modal from "../../nekrasovka-ui/Modal";
import LibrarianTable from "../LibrarianTable";
import axios from "axios";
import databasePoints from "../../database/points";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import {
  resetUnavailable,
  setUnavailable,
} from "../../reducers/unavailable/actions";
import { isJsonString } from "../../helpers";

const Consultations = ({
  user,
  point,
  today,
  isToday,
  reasons,
  getConsultation,
  setConsultation,
  getConsultations,
  consultationData,
  resetConsultation,
  consultationsData,
  resetConsultations,
  isConsultationsGet,
  updateConsultations,
  isConsultationsUpdate,
  getConsultationsTypicalResponses,
  consultationsTypicalResponsesData,
  isConsultationsTypicalResponsesGet,
  setUnavailable,
  unavailableData,
}) => {
  const [consultationsLoaded, setConsultationsLoaded] = useState(0);
  const [isConsultation, setIsConsultation] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const { dispatch } = useContext(AlertContext);

  const limit = 10;
  const consultationsDataLength = consultationsData.length;
  const countPages = Math.ceil(consultationsDataLength / limit);
  const isUserTodayCanEdit = consultationData.user === user && isToday;

  const consultationColumns = {
    source: "Источник",
    category: "Тип обращения",
    references: "Справки",
    consultations: "Консультации",
    content: "Содержание",
    comment: "Комментарий",
  };

  const buttons = {
    source:
      point.point_id === "CUNB_MOBIUS"
        ? ["Очная"]
        : [
            point.point_id === "CUNB_OUBF" ? "Очная" : "Кафедра",
            "Телефон",
            "E-mail",
            "Бот",
            "Соцсети",
          ],
    references: ["Тематическая", "Адресная", "Уточняющая", "Фактографическая"],
    consultations: ["Библиографическая", "Ориентирующая", "Техническая"],
  };

  const tooltips = {
    references: [
      "Справка о литературе по определенной теме, например, “Что можно почитать о восстании Пугачева?”, “Есть ли у вас литература о развитии земледелия на Кубани?”. Одной тематической справкой считается перечень библиографической информации по одной теме",
      "Справка о наличии и/или местонахождении издания в фонде, например, “Есть ли у вас книга Роджера Желязны “Знак Хаоса”?”, “Я ищу монографию “Коррупция как глобальная проблема” Андрианова”. Одной адресной справкой считается получение информации об одной библиографической записи, включая описание и местоположение. ",
      "Справка, восполняющая пробелы в информации об издании, названии произведения, имени автора, издательстве и помогающая найти нужную литературу, например, “Помогите вспомнить, как зовут автора “Бхагавад-гиты”?”, “Подскажите название книги, там еще студент топором старушку по голове стукнул”. Одной уточняющей справкой считается получение сведений по одной библиографической записи, независимо от количества уточненных параметров.",
      "Получение сведений о событиях, персонах, физических параметрах чего-либо, характеристиках географических объектов и т.д., например, “Подскажите дату рождения Некрасова”, “Какова численность населения Антарктиды?”, “Где находится главное здание библиотеки?”. Одной фактографической справкой является установление одного факта, независимо от количества использованных источников. ",
    ],
    consultations: [
      "Ответ на запрос пользователя, который позволит ему найти необходимую информацию самостоятельно. Она содержит разъяснения по методике поиска необходимых сведений как в библиографических источниках (каталогах, библиографических пособиях), так и в энциклопедиях, словарях, справочниках, интернет-ресурсах и различных базах данных. Пользователь в этом случае выполняет поиск сам.",
      "Содержит информацию о библиотеке: о режиме работы, порядке и условиях библиотечно-информационного обслуживания; о направлениях деятельности и функциях структурных подразделений библиотеки; о проводимых мероприятиях, а также об услугах, которые предоставляет библиотека, и о ресурсах, которыми она располагает.",
      "Это помощь пользователям в освоении поиска в электронных каталогах, конкретных базах данных, доступных в библиотеке, советы по использованию оборудования и аппаратно-программных средств для осуществления электронного заказа и просмотра электронных документов, по сохранению и переносу информации на другие носители и т.д.",
    ],
  };

  const onSave = () => {
    updateConsultations();
    onClose();
  };

  const handleGetConsultation = (id) => {
    getConsultation(id);
    onClose();
  };

  const handleConsultationWithTypicalResponsesData = async (content) => {
    const department = databasePoints.filter(
      ({ point_id }) => point_id === point.point_id,
    )[0].point_department[0];

    const pointsArray = databasePoints
      .filter(({ point_department }) => point_department.includes(department))
      .map(({ point_id }) => point_id);

    const collection_id = "consultations";
    const data = [{ point: pointsArray, date_time: today, content }];

    const addition = {
      collection_id,
      data,
      max_entries: 1,
      days_offset: 30,
    };

    const url = `${process.env.REACT_APP_TEAM_API}/stats`;
    const headers = {
      "X-Api-Key": `${process.env.REACT_APP_TEAM_KEY}`,
      "Content-Type": `application/json`,
    };

    const source = await axios(url, {
      params: { ...addition, group_field: "source" },
      headers,
      method: "get",
    });

    const category = await axios(url, {
      params: { ...addition, group_field: "category" },
      headers,
      method: "get",
    });

    const response = {
      content,
      source: source.data.data[0].toString(),
      category: category.data.data[0].toString(),
    };

    setConsultation(response);
    updateConsultations();
    resetConsultation();
  };

  const resetAllInConsultations = () => {
    resetConsultations();
    setConsultationsLoaded(0);
    isConsultation && setIsConsultation(false);
  };

  const getPaginated = (num) => setConsultationsLoaded((num - 1) * limit);

  const onClose = () => {
    setIsConsultation((state) => !state);
    isUnavailable && setIsUnavailable(false);
  };

  const handleSetConsultation = (name, e) => {
    const temp = consultationData;

    if (["comment", "content"].includes(name)) {
      const { scrollHeight, offsetHeight } = e.target;

      if (scrollHeight > offsetHeight) {
        e.target.style.height = `${scrollHeight}px`;
      }

      temp[name] = e.target.value;
    }

    if (["source", "references", "consultations"].includes(name)) {
      const isCategoryButtons = ["references", "consultations"].includes(name);
      const dataName = isCategoryButtons ? "category" : name;
      const isActive = buttons[name][e] === consultationData[dataName];
      temp[dataName] = isActive ? "" : buttons[name][e];
    }

    setConsultation({ ...temp });
  };

  const checkConsultation = () => {
    let isAllNotEmpty;
    const { reason, title } = unavailableData;
    const { source } = consultationData;

    if (isUnavailable) {
      isAllNotEmpty = !!reason && !!title && !!source;
    } else isAllNotEmpty = !!source;

    if (isAllNotEmpty) {
      onSave();
    } else {
      dispatch({
        type: "ALERT_ON",
        text: "Вы не заполнили обязательное поле.",
        name: "warning",
      });
    }
  };

  const checkButtonActive = (name) => {
    const isCategoryButtons = ["references", "consultations"].includes(name);
    const dataName = isCategoryButtons ? "category" : name;

    return buttons[name].indexOf(consultationData[dataName]);
  };

  const handleNewConsultation = (name, e) => {
    handleSetConsultation(name, e);
    onClose();
  };

  const handleUnavailable = () => {
    if (isUnavailable) {
      setIsUnavailable(false);
      handleSetConsultation("references", "");
    } else {
      setIsUnavailable(true);
      handleSetConsultation("references", 1);
    }
  };

  useEffect(() => {
    getConsultations(today);
    getConsultationsTypicalResponses(today);

    return () => resetAllInConsultations();
  }, [point.point_id]);

  useEffect(() => {
    if (isConsultationsUpdate) getConsultations(today);
  }, [isConsultationsUpdate]);

  useEffect(() => {
    if (consultationsLoaded > 0) setConsultationsLoaded(0);
  }, [isConsultationsGet]);

  return (
    <Fragment>
      {isConsultation && (
        <Modal>
          <ConsultationsContainer>
            <ConsultationModalTitle>
              <h4>
                {consultationData.id
                  ? `${
                      isUserTodayCanEdit ? "Редактирование" : "Чтение"
                    } справки/консультации`
                  : "Новая справка/консультация"}
              </h4>
              <ConsultationCloseButton onClick={onClose}>
                <Icon icon="closeModal" width={20} height={20} />
              </ConsultationCloseButton>
            </ConsultationModalTitle>
            <Consultation
              buttons={buttons}
              checkButtonActive={checkButtonActive}
              checkConsultation={checkConsultation}
              consultationColumns={consultationColumns}
              consultationData={consultationData}
              handleSetConsultation={handleSetConsultation}
              handleUnavailable={handleUnavailable}
              isToday={isToday}
              isUnavailable={isUnavailable}
              isUserCanEdit={isUserTodayCanEdit || !consultationData.id}
              onClose={onClose}
              tooltips={tooltips}
              setUnavailable={setUnavailable}
              setIsUnavailable={setIsUnavailable}
              resetConsultation={resetConsultation}
            />
          </ConsultationsContainer>
        </Modal>
      )}
      <ConsultationsContainer>
        <ConsultationTitle isConsultation={isConsultation}>
          <h4>Справки и консультации</h4>
          {isToday && (
            <ConsultationButtons>
              <ButtonsGroup
                handleButton={handleNewConsultation}
                buttons={buttons.source}
                groupName={"source"}
                buttonStyles={
                  "border: none; border-radius: 0; @media (max-width: 768px) {width: 100%;}; @media (min-width: 769px) {padding: 4px 10px;}"
                }
              />
            </ConsultationButtons>
          )}
        </ConsultationTitle>
        {isToday ? (
          <ConsultationTypicalResponses>
            <div>Типовые ответы</div>
            <TypicalResponsesButtonsGroup>
              {point.point_id === "CUNB_MOBIUS" ? (
                ["О библиотеке", "Книги в дар библиотеке", "Литрес"].map(
                  (button, i) => (
                    <Button
                      key={i}
                      buttonStyles={"padding: 4px 10px;"}
                      name={button}
                      onClick={() =>
                        handleConsultationWithTypicalResponsesData(button)
                      }
                    />
                  ),
                )
              ) : isConsultationsTypicalResponsesGet ? (
                !!consultationsTypicalResponsesData.length ? (
                  consultationsTypicalResponsesData.map((button, i) => {
                    return button ? (
                      <Button
                        key={i}
                        buttonStyles={"padding: 4px 10px;"}
                        name={button}
                        onClick={() =>
                          handleConsultationWithTypicalResponsesData(button)
                        }
                      />
                    ) : null;
                  })
                ) : (
                  <OnLoadingTypicalResponses>
                    Недостаточно данных для выборки...
                  </OnLoadingTypicalResponses>
                )
              ) : (
                <OnLoadingTypicalResponses>
                  Отбираем варианты ответов...
                </OnLoadingTypicalResponses>
              )}
            </TypicalResponsesButtonsGroup>
          </ConsultationTypicalResponses>
        ) : null}
        <LibrarianTable
          isDataGet={isConsultationsGet}
          loaded={consultationsLoaded}
          limit={limit}
          handleRow={handleGetConsultation}
          rows={consultationsData.map((item) => {
            if (!!item.content && isJsonString(item.content)) {
              const parsed = JSON.parse(item.content);
              const content = `
              ${parsed.name}: ${parsed.author}, ${parsed.title},
              ${parsed.publisher ? parsed.publisher + "," : ""}
              ${parsed.code ? "[" + parsed.code + "]," : ""}
              ${parsed.reason ? reasons[parsed.reason] : ""}
              `;

              return {
                ...item,
                content,
              };
            } else return item;
          })}
          columns={{
            source: "Источник",
            content: "Содержание",
            category: "Тип обращения",
          }}
          info={{
            loading: "Получаем справки и консультации...",
            get: "Нет справок и консультаций за этот день...",
          }}
        />
        {countPages > 1 && (
          <Pagination getPaginated={getPaginated} countPages={countPages} />
        )}
      </ConsultationsContainer>
    </Fragment>
  );
};

const mapStateToProps = ({
  consultations: {
    consultationsData,
    isConsultationsGet,
    isConsultationsUpdate,
    consultationsTypicalResponsesData,
    isConsultationsTypicalResponsesGet,
  },
  consultation: { consultationData },
  points: { point },
  allowance: { today, user },
  unavailable: { unavailableData, reasons },
}) => ({
  user,
  point,
  today,
  consultationData,
  consultationsData,
  isConsultationsGet,
  isConsultationsUpdate,
  consultationsTypicalResponsesData,
  isConsultationsTypicalResponsesGet,
  unavailableData,
  reasons,
});

export default connect(mapStateToProps, {
  getConsultation,
  setConsultation,
  getConsultations,
  resetConsultations,
  resetConsultation,
  updateConsultations,
  getConsultationsTypicalResponses,
  resetUnavailable,
  setUnavailable,
})(Consultations);

// вынести в отдельный компонент

const ConsultationButtons = styled.div`
  @media (min-width: 931px) {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    align-items: center;

    > input {
      padding: 0 10px;
    }
  }

  @media (max-width: 930px) {
    display: flex;
    row-gap: 10px;
    flex-direction: column;
  }
`;

// вынести в отдельный компонент

const ConsultationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  background: #ffffff;

  @media (min-width: 769px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

const ConsultationTitle = styled.div`
  display: flex;
  row-gap: 10px;
  flex-direction: column;

  h4 {
    display: flex;
    padding: 4px 10px 4px 0;
    font-weight: 500;
    margin: 0;
  }

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 15px 15px 0;
  }
`;

const ConsultationTypicalResponses = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  > div:first-child {
    font-size: 14px;
    font-weight: 500;

    @media (max-width: 768px) {
      margin-left: 15px;
      margin-right: 15px;
      width: calc(100% - 30px);
    }
  }
`;

const TypicalResponsesButtonsGroup = styled.div`
  display: flex;
  border: none;
  column-gap: 10px;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 768px) {
    > :first-child {
      margin-left: 15px;
    }

    > :last-child {
      margin-right: 15px;
    }
  }
`;

const OnLoadingTypicalResponses = styled.div`
  display: flex;
  padding: 5px 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const ConsultationModalTitle = styled(ConsultationTitle)`
  justify-content: space-between;
  flex-direction: row;
`;

const ConsultationCloseButton = styled.div`
  display: flex;

  svg {
    rect {
      fill: #ecf0f5;
    }
  }

  @media (hover: hover) {
    cursor: pointer;

    :hover svg {
      rect {
        fill: #40677e;
      }

      line {
        stroke: #ffffff;
      }
    }
  }
`;
