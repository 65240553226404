import * as types from "./actionTypes";

export const updateAccounting = (name, count) => ({
  type: types.UPDATE_ACCOUNTING,
  name,
  count,
});

export const updateAccountingSuccess = (payload) => ({
  type: types.UPDATE_ACCOUNTING_SUCCESS,
  payload,
});

export const getAccounting = (date_time) => ({
  type: types.GET_ACCOUNTING,
  date_time,
});

export const getAccountingSuccess = (payload) => ({
  type: types.GET_ACCOUNTING_SUCCESS,
  payload,
});

export const resetAccounting = () => ({
  type: types.RESET_ACCOUNTING,
});
