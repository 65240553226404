export const librarianNews = {
  "25.08.2023": [
    {
      title:
        "- В «Справки и консультации» добавили «Учёт отказов». При нажатии на соответсвующую кнопку открываются дополнительные поля.",
      image: "/images/снимок_экрана_2023_08_25_в_09_12_25.png",
      date_time: "25.08.2023",
    },
  ],
  "25.07.2023": [
    {
      title:
        "- Добавили возможность выхода из «Книга учета и консультаций» по нажатии на иконку.",
      image: "/images/снимок_экрана_2023_07_25_в_11_25_01.png",
      date_time: "25.07.2023",
    },
  ],
  "19.07.2023": [
    {
      title:
        "- Вместо кнопки «Добавить» кнопки с источниками. По нажатию на источник создаётся справка/консультация с выбранным источником.",
      image: "/images/снимок_экрана_2023_07_19_в_13_18_57.png",
      date_time: "19.07.2023",
    },
  ],
  "21.03.2023": [
    {
      title:
        "- В этом релизе упростили работу с «Типовые ответы». Теперь при нажатии на ответ, сразу добавляется запись в таблицу. Если Ваш ответ не релевантен записанным данным в «Источник, Содержание, Тип обращения», при нажатии на соответсвующую строку в таблице, его можно скорректировать.",
      image: "",
      date_time: "21.03.2023",
    },
  ],
  "15.03.2023": [
    {
      title:
        "- В разделе «Справки и консультации» добавили возможность выбрать «Типовые ответы» (выборка из 5-и повторяющихся ответов за 30 дней). При нажатии на один из них открывается «Новая справка/консультация», аналогично с «Добавить», но с предзаполненным текстовым полем «Содержание».",
      image: "/images/снимок_экрана_2023_03_15_в_16_11_57.png",
      date_time: "15.03.2023",
    },
  ],
  "14.03.2023": [
    {
      title:
        "- Показываем «Книги учёта и консультаций» согласно Вашего отдела.",
      image: "",
      date_time: "14.03.2023",
    },
  ],
  "17.01.2023": [
    {
      title:
        "- Редактирование/чтение теперь открывается поверх остального контента.",
      image: "/images/снимок_экрана_2023_01_17_в_16_32_50.png",
      date_time: "12.01.2023",
    },
  ],
  "13.01.2023": [
    {
      title:
        "- В разделе «Справки и консультации» заменили «Загрузить ещё» на стрелочки с нумерацией страниц. На компьютерах/ноутбуках доступно переключение страниц клавишами «Стрелки».",
      image: "/images/снимок_экрана_2023_01_13_в_14_48_39.png",
      date_time: "12.01.2023",
    },
  ],
  "12.01.2023": [
    {
      title:
        "- Для удобства при создании/редактировании в разделе «Справки и консультации» добавили всплывающие подсказки на кнопки «Тип обращения»",
      image: "/images/снимок_экрана_2023_01_12_в_15_40_48.png",
      date_time: "12.01.2023",
    },
  ],
  "11.01.2023": [
    {
      title:
        "- Добавили возможность редактировать персонально созданные (за текущий день) «Справки и консультации».",
      image: "/images/снимок_экрана_2023_01_11_в_09_39_27.png",
      date_time: "11.01.2023",
    },
    {
      title: "- Добавили возможность открыть «Справки и консультации».",
      image: "/images/снимок_экрана_2023_01_11_в_12_54_21.png",
      date_time: "11.01.2023",
    },
  ],
  "10.01.2023": [
    {
      title: "- Приступили к разработке раздела «Настройки».",
      date_time: "10.01.2023",
    },
    {
      title:
        "В первом релизе добавили фильтр «Общая статистика». [синий квадратик] - получаем все данные, [белый квадратик] - получаем только свои данные.",
      image: "/images/снимок_экрана_2023_01_11_в_11_57_43.png",
      date_time: "10.01.2023",
    },
  ],
  "28.12.2022": [
    {
      title: "- Добавили выпадающие уведомления.",
      image: "/images/снимок_экрана_2022_12_28_в_12_16_07.png",
      date_time: "28.12.2022",
    },
  ],
  "27.12.2022": [
    {
      title: "- Календарь можно закрыть кликнув в любом месте приложения.",
      image: "/images/снимок_экрана_2022_12_28_в_12_15_41.png",
      date_time: "28.12.2022",
    },
    {
      title:
        "- В разделе «Учёт» открыли возможность добавлять в «Посещения» до 100 посещений. Старайтесь, по-возможности добавлять по 1-му посещению за раз.",
      image: "/images/снимок_экрана_2022_12_28_в_12_51_10.png",
      date_time: "28.12.2022",
    },
  ],
  "26.12.2022": [
    {
      title:
        "- Раздел «Справки и консультации» сделали более компактным. И добавили кнопку «Загрузить ещё» для догрузки данных (отсортированы от новых к старым).",
      image: "/images/снимок_экрана_2022_12_28_в_12_35_21.png",
      date_time: "28.12.2022",
    },
  ],
};
