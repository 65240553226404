import * as types from "./actionTypes";

const initialState = {
  collection_id: "visuals",
  visualsData: [],
  isVisualsGet: false,
  isVisualsUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.VISUALS_FETCH_SUCCESS:
      return {
        ...state,
        visualsData: action.payload,
        isVisualsGet: true,
      };
    case types.VISUALS_UPDATE:
      return {
        ...state,
        isVisualsGet: false,
        isVisualsUpdate: false,
      };

    case types.VISUALS_UPDATE_SUCCESS:
      return {
        ...state,
        isVisualsUpdate: action.payload,
      };
    case types.VISUALS_RESET:
      return initialState;

    default:
      return state;
  }
};
