import * as types from "./actionTypes";

const initialState = {
  collection_id: "consultations",
  consultationsData: [],
  consultationsTypicalResponsesData: ["Книги в дар библиотеке"],
  isConsultationsGet: false,
  isConsultationsTypicalResponsesGet: false,
  isConsultationsUpdate: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CONSULTATIONS_FETCH_SUCCESS:
      return {
        ...state,
        consultationsData: action.payload,
        isConsultationsGet: true,
      };
    case types.CONSULTATIONS_TYPICAL_RESPONSES_FETCH_SUCCESS:
      return {
        ...state,
        consultationsTypicalResponsesData: Array.from(
          new Set([
            ...action.payload,
            ...state.consultationsTypicalResponsesData,
          ])
        ),
        isConsultationsTypicalResponsesGet: true,
      };
    case types.CONSULTATIONS_UPDATE:
      return {
        ...state,
        isConsultationsGet: false,
        isConsultationsUpdate: false,
      };

    case types.CONSULTATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        isConsultationsUpdate: action.payload,
      };
    case types.CONSULTATIONS_RESET:
      return initialState;

    default:
      return state;
  }
};
