import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  row-gap: 10px;

  @media (min-width: 769px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Administrator = () => {
  const parts = [
    { id: 1, name: "Сервисы", url: "/admin/services" },
    { id: 2, name: "Сайты", url: "/admin/websites" },
  ];

  return (
    <Container>
      {parts.map((item, i) => (
        <Link key={item.id} to={item.url}>
          {i + 1}. {item.name}
        </Link>
      ))}
    </Container>
  );
};

export default Administrator;
