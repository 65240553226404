import React from "react";
import Image from "../Image";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Bookcases = ({ data, today }) => {
  return (
    <Container>
      {data.map((row, rowKey) => {
        let aTime, bTime;
        let isAlert = false;
        let isToday = false;

        if (!!row.date) {
          aTime = +today.replace(/[.]/g, "");
          bTime = +row.date.replace(/[.]/g, "");

          if (!row.is_done) isAlert = aTime >= bTime;
          if (row.is_done) isToday = aTime === bTime;
        }

        return (
          <Card
            isAlert={isAlert}
            isToday={isToday}
            key={rowKey}
            to={`/backoffice/dedusting/bookcases/${row["number"]}`}
          >
            <CardDebusting>
              <div>
                <div>
                  <CardRow>
                    <span>{row.point}</span>
                  </CardRow>
                  <CardRow>
                    <span>{row["floor"]} этаж</span>
                  </CardRow>
                </div>
                <div>
                  <CardRow>
                    <span>Ряд №</span>
                    <span>{row["number"]}</span>
                  </CardRow>
                  <CardRow>
                    <span>Обеспылено</span>
                    <span>{row["percent"]}%</span>
                  </CardRow>
                </div>
                <div>
                  <span>Дата обеспыливания</span>
                  <div>
                    {!!row.dates.length && (
                      <CardDatesRows>
                        {row.dates.map((m, dateIndex) => {
                          return (
                            <DateRow key={dateIndex}>
                              <span>{!!m ? m.split(".")[0] : "-"}</span>
                            </DateRow>
                          );
                        })}
                      </CardDatesRows>
                    )}
                  </div>
                </div>
              </div>
              <div>
                {!!row.image ? (
                  <Image href={`${process.env.REACT_APP_IMAGE}${row.image}`} />
                ) : (
                  "Нет изображения"
                )}
              </div>
            </CardDebusting>
            <CardUsers>
              <span>Ответственные сотрудники</span>
              <div>
                {!!row["name"] ? (
                  <span>{row["name"]}</span>
                ) : (
                  <span style={{ color: "red" }}>Не назначены</span>
                )}
              </div>
            </CardUsers>
          </Card>
        );
      })}
    </Container>
  );
};

export default Bookcases;

const CardDatesRows = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  gap: 5px;
`;

const DateRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid rgb(221, 219, 207);
  width: 48px;

  > span {
    padding: 2px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 5px;
  padding-top: 15px;
`;

const CardUsers = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 13px;

  > span {
    padding: 0 15px;
    font-weight: 500;
  }

  > div {
    display: flex;
    padding: 0 15px;
    column-gap: 5px;
    overflow-x: scroll;
    scroll-behavior: smooth;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const CardRow = styled.div`
  span {
    :nth-child(2) {
      color: #777777;
    }
  }
`;

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 15px 0;
  background-color: ${({ isAlert, isToday }) =>
    isAlert ? "#ff00001f" : isToday ? "#0080002b" : "#f7f7f7"};
  font-size: 14px;
  color: #222222;
`;

const CardDebusting = styled.div`
  padding: 0 15px;

  :first-child {
    display: grid;
    grid-template-columns: 1fr 130px;

    > div {
      :first-child {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        > div {
          :nth-child(1) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-weight: 500;
          }

          :nth-child(2) {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            > div {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
            }
          }

          :nth-child(3) {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            > span {
              font-weight: 500;
            }

            > div {
              display: flex;
              flex-direction: column;
              row-gap: 5px;

              > div {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
              }
            }
          }
        }
      }

      :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #777777;
      }
    }
  }
`;
