import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import Calendar from "react-calendar";
import NewsCard from "../../components/NewsCard";
import { connect } from "react-redux";
import {
  clearProgress,
  closeProgress,
  finishProgress,
  startProgress,
} from "../../../../reducers/progress/actions";
import Separator from "../../../../nekrasovka-ui/Separator";
import NewsCardExtended from "../../components/NewsCardExtended";

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: 405px 1fr;
    gap: 15px;
    padding: 0 30px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }
`;

const NewsCardsContainer = styled.div`
  @media (min-width: 701px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(405px, 1fr));
    gap: 15px;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    background-color: white;
  }
`;

const VisualCalendar = styled.div`
  .react-calendar {
    display: block;
    padding: 15px 0;
    width: 100%;
    border: none;

    button {
      font-size: 16px;

      :not(.react-calendar__month-view__days__day--weekend) {
        color: #222222;
      }

      :disabled {
        background-color: #f0f0f0;
        opacity: 0.4;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      color: #ffffff !important;
      background-color: #3c8dbc !important;
    }

    .react-calendar__tile--now:not(.react-calendar__tile--active) {
      color: #3c8dbc !important;
      background-color: #ffffff !important;
    }
  }

  .react-calendar__navigation {
    height: 36px;
    margin-bottom: 0;
    position: relative;

    button {
      background: #ffffff;
    }
  }

  .react-calendar__month-view__weekdays abbr {
    text-decoration: none;
  }

  @media (hover: hover) {
    .react-calendar button:hover {
      :not([disabled]) {
        background-color: #40677e !important;
        color: #ffffff !important;
        z-index: 111;
      }
    }
  }

  @media (min-width: 701px) {
    .react-calendar {
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12157);
      border-radius: 5px;
    }
  }

  @media (max-width: 700px) {
    display: none;

    .react-calendar {
      width: 100%;
    }
  }
`;

const TableTitle = styled.div`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    display: ${({ isNewsId }) => (isNewsId ? "none" : "flex")};
    column-gap: 5px;
    font-weight: 500;
    line-height: 19px;
    padding: 20px 15px 0;
  }
`;

const NewsCardSeparator = styled(Separator)`
  @media (min-width: 701px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin: 0 15px;
  }
`;

const News = ({
  handleDate,
  today,
  date,
  getNews,
  setNews,
  initialNews,
  news,
  history,
  news_id,
  preferred_username,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
  initialNewsCard,
  setNewsCard,
  newsCard,
}) => {
  const handleNewsCard = (id) => {
    history.replace(`/staff/news/${id}`);
  };

  const getData = async () => {
    clearProgress();
    startProgress();
    const status = await getNews();

    if (status === 200) {
      finishProgress();
      setTimeout(() => closeProgress(), 300);
    }
  };

  const resetData = () => {
    setNews(initialNews);
    setNewsCard(initialNewsCard);
  };

  useEffect(() => {
    getData();

    return () => resetData();
  }, [news_id]);

  return (
    <Container>
      <TableTitle isNewsId={!!news_id}>
        <span>Новости</span>
      </TableTitle>
      <VisualCalendar>
        <Calendar
          selectRange={false}
          onChange={handleDate}
          value={date}
          maxDate={today}
          locale="ru-RU"
        />
      </VisualCalendar>
      {news.loaded && (
        <NewsCardsContainer>
          {news.data.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <NewsCard card={item} handleNewsCard={handleNewsCard} />
                {news.data.length !== index + 1 && <NewsCardSeparator />}
              </Fragment>
            );
          })}
        </NewsCardsContainer>
      )}
      {newsCard.loaded && (
        <NewsCardExtended
          card={newsCard.data}
          handleNewsCard={handleNewsCard}
          preferred_username={preferred_username}
        />
      )}
    </Container>
  );
};

export default connect(null, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(News);
