import * as types from "./actionTypes";

export const getExcursions = (date_time) => ({
  type: types.EXCURSIONS_FETCH,
  date_time,
});

export const getExcursionsSuccess = (payload) => ({
  type: types.EXCURSIONS_FETCH_SUCCESS,
  payload,
});

export const updateExcursions = () => ({
  type: types.EXCURSIONS_UPDATE,
});

export const updateExcursionsSuccess = (payload) => ({
  type: types.EXCURSIONS_UPDATE_SUCCESS,
  payload,
});

export const resetExcursions = () => ({
  type: types.EXCURSIONS_RESET,
});
