import React, { createRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../nekrasovka-ui/Button";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Image from "../Image";
import Icon from "../../../../nekrasovka-ui/Icon";

const initialState = {
  shelves: [],
  bookcase: {
    floor_id: "",
    point_id: "",
    image: "",
    x: 0,
    y: 0,
    names: {},
  },
};

const Creation = () => {
  const history = useHistory();
  const fileInputRef = createRef();

  const [state, setState] = useState(initialState);
  const [isBookcase, setBookcase] = useState(false);
  const [selectedId, setSelectedId] = useState([]);

  const handleCreate = async () => {
    let params = {
      shelves: state.shelves,
      bookcase: {
        ...state.bookcase,
        point_id: "CUNB_ENT",
        names: JSON.stringify(state.bookcase.names),
        map: JSON.stringify(selectedId),
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/bookcase/create`,
      params,
    );

    history.push(
      `/backoffice/dedusting/bookcases/${response.data.bookcase_id}`,
    );
  };

  const handleFloor = ({ target }) => {
    setState({
      ...state,
      bookcase: {
        ...state.bookcase,
        floor_id: target.value,
      },
    });

    const options = { where: { bookcases: { floor_id: target.value } } };

    axios
      .post(`${process.env.REACT_APP_API}/bookcases/get`, {
        ...options,
      })
      .then((response) => {
        response.data.forEach((f) => {
          const map = JSON.parse(f.bookcase.map);

          map.forEach((n) => {
            const el = document.getElementById(`${n}`);
            el.classList.add("active");
          });
        });
      });
  };

  const handleInput = (x, y) => {
    const shelves = [...Array(x * y)].map(() => {
      return { is_empty: false };
    });

    setState({
      shelves,
      bookcase: { ...state.bookcase, x, y },
    });
  };

  const handleTitle = ({ target }) => {
    const names = { ...state.bookcase.names };
    const id = target.id.split("_")[1];
    names[id] = target.value;

    setState({
      ...state,
      bookcase: {
        ...state.bookcase,
        names,
      },
    });
  };

  const handleShelf = ({ target }) => {
    const shelves = [...state.shelves];
    shelves[target.id].is_empty = !shelves[target.id].is_empty;

    setState({
      ...state,
      shelves,
    });
  };

  const handleClear = () => {
    setState(initialState);
  };

  const handleImage = () => {
    fileInputRef.current.click();
  };

  const handleFile = async (event) => {
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/bookcases/image/upload`,
      formData,
    );

    setState({
      ...state,
      bookcase: {
        ...state.bookcase,
        image: response.data.image,
      },
    });
  };

  const handleBookcase = (e) => {
    if (isBookcase) {
      const id = e.target.id;
      const regex = /^_/;
      const isValidString = regex.test(id);

      if (isValidString) {
        e.target.classList.toggle("active");

        setSelectedId(
          selectedId.includes(id)
            ? selectedId.filter((f) => f !== id)
            : [...selectedId, id],
        );
      }
    } else setBookcase(true);
  };

  const isReadyToSave =
    !!state.bookcase.x && !!state.bookcase.y && !!state.bookcase.floor_id;

  return (
    <Container>
      <Navigation>
        <div>
          <BookcaseButton as={Link} to="/">
            Интранет
          </BookcaseButton>
          <BookcaseButton as={Link} to="/backoffice/dedusting">
            Обеспыливание
          </BookcaseButton>
        </div>
      </Navigation>
      <HeaderBlock>
        <BookcaseImage>
          <div onClick={handleImage}>
            {state.bookcase.image ? (
              <Image
                href={`${process.env.REACT_APP_URL}/${state.bookcase.image}`}
              />
            ) : (
              "Картинка"
            )}
          </div>
          <input
            ref={fileInputRef}
            hidden
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={handleFile}
          />
        </BookcaseImage>
        <BookcaseImage>
          <StyledDiv
            floor={state.bookcase.floor_id}
            isImage={isBookcase}
            onClick={handleBookcase}
          >
            <Icon icon="floors" />
          </StyledDiv>
          {isBookcase && (
            <div onClick={() => setBookcase(false)}>
              <Icon icon="closeModal" height="20" width="20" />
            </div>
          )}
        </BookcaseImage>
      </HeaderBlock>
      <MiddleBlock>
        <HeaderInput>
          <label>Этаж</label>
          <input
            type="text"
            id="floor_id"
            value={state.bookcase.floor_id}
            onChange={handleFloor}
          />
        </HeaderInput>
        <HeaderInput>
          <label>Стеллажей</label>
          <input
            type="text"
            value={state.bookcase.x}
            onChange={({ target }) => {
              handleInput(+target.value, state.bookcase.y);
            }}
          />
        </HeaderInput>
        <HeaderInput>
          <label>Полок</label>
          <input
            type="text"
            value={state.bookcase.y}
            onChange={({ target }) => {
              handleInput(state.bookcase.x, +target.value);
            }}
          />
        </HeaderInput>
      </MiddleBlock>
      {!!state.shelves.length && (
        <CreationMiddle nColumns={state.bookcase.x}>
          {[...Array(state.bookcase.x)].map((_, titleId) => {
            return (
              <TitleInput key={titleId}>
                <input
                  type="text"
                  id={`title_${titleId}`}
                  value={state.bookcase.names[titleId] || ""}
                  onChange={handleTitle}
                />
              </TitleInput>
            );
          })}
          {state.shelves.map((shelf, shelfId) => {
            return (
              <Shelf
                key={shelfId}
                id={`${shelfId}`}
                isEmpty={shelf.is_empty}
                onClick={handleShelf}
              />
            );
          })}
        </CreationMiddle>
      )}
      <CreationFooter>
        <Button
          isDisabled={!isReadyToSave}
          name="Сохранить"
          onClick={handleCreate}
        />
        <Button name="Отменить" onClick={handleClear} />
      </CreationFooter>
    </Container>
  );
};

export default Creation;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 130px;

  > svg {
    ._1fl_walls_doors,
    ._1fl_bookstands,
    ._2fl_walls_doors,
    ._2fl_bookstands,
    ._3fl_walls_doors,
    ._3fl_bookstands,
    ._4fl_walls_doors,
    ._4fl_bookstands {
      display: none;
    }

    [data-name="${({ floor }) => floor}fl_bookstands"] > .active {
      fill: red;
    }

    [data-name="${({ floor }) => floor}fl_walls_doors"],
    [data-name="${({ floor }) => floor}fl_bookstands"] {
      display: block;
    }

    .cls-1,
    .cls-2,
    .cls-3 {
      fill: #000;
    }

    .cls-1,
    .cls-4 {
      stroke-width: 0;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18,
    .cls-19 {
      stroke: #000;
    }

    .cls-5,
    .cls-2,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-14,
    .cls-19 {
      stroke-miterlimit: 10;
    }

    .cls-5,
    .cls-9,
    .cls-4 {
      fill: #fff;
    }

    .cls-2,
    .cls-19 {
      stroke-width: 10px;
    }

    .cls-6 {
      stroke-width: 20px;
    }

    .cls-6,
    .cls-8,
    .cls-10,
    .cls-12,
    .cls-14,
    .cls-19 {
      fill: none;
    }

    .cls-7,
    .cls-13,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18 {
      fill: #b3b3b3;
    }

    .cls-9,
    .cls-10 {
      stroke-width: 0.5px;
    }

    .cls-11 {
      fill: gray;
    }

    .cls-12 {
      stroke-width: 11px;
    }

    .cls-3 {
      font-size: 42px;
      isolation: isolate;
    }

    .cls-13 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-14 {
      stroke-width: 5px;
    }

    .cls-15 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-16 {
      stroke-miterlimit: 10;
      stroke-width: 1px;
    }

    .cls-17 {
      stroke-miterlimit: 10;
    }

    .cls-17,
    .cls-18 {
      stroke-width: 1px;
    }

    .cls-18 {
      stroke-miterlimit: 10;
    }

    ${({ isImage }) =>
      isImage &&
      `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    width: 100vw;
    height: 100vh;
    background-color: #edeee9;
 `}
  }
`;

const Navigation = styled.div`
  display: flex;
  row-gap: 20px;

  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    width: 100%;
    align-items: center;
    column-gap: 10px;

    > span {
      font-size: 13px;
    }
  }
`;

const BookcaseButton = styled.button`
  display: flex;
  justify-content: center;
  padding: 7px 10px;
  background-color: ${({ isActive }) => (isActive ? "#DDDBCFFF" : "initial")};
  color: ${({ isActive }) => (isActive ? "#777777FF" : "#222222")};
  border-radius: 5px;
  border: 1px solid #c4c4c4;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  :not(:disabled) {
    cursor: pointer;
  }
`;

const HeaderBlock = styled.div`
  @media (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    min-height: 130px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    > div {
      min-height: 100px;
    }

    > div:nth-child(1n) {
      flex-basis: calc(50% - 5px);
    }

    > div:last-child {
      flex-basis: 100%;
    }
  }
`;

const BookcaseImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid #c4c4c4;
  cursor: pointer;

  > div:nth-child(2) {
    position: absolute;
    z-index: 11;
    right: 15px;
    top: 15px;
  }
`;

const Shelf = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  column-gap: 5px;
  padding: 15px;
  font-size: 11px;

  height: 50px;
  background-color: ${({ isEmpty }) => (isEmpty ? "initial" : "#C4C4C4FF")};

  > span {
    padding: 2px 5px;
    background-color: #edeee9;
  }
`;

const CreationFooter = styled.div`
  display: flex;
  column-gap: 10px;
  max-width: 390px;

  > button {
    width: 100%;
    white-space: nowrap;
  }

  @media (min-width: 769px) {
    max-width: calc(50% / 2 - 10px);
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const CreationMiddle = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ nColumns }) => nColumns},
    minmax(190px, 1fr)
  );
  gap: 1px;

  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Input = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 1;

  input {
    padding: 10px;
    outline-style: none;
    border: 1px solid #c4c4c4;
    margin: 0;
    color: #222222;
    background-color: #ffffff;

    cursor: ${({ isDisabled }) => (isDisabled ? "initial" : "pointer")};
  }
`;

const TitleInput = styled(Input)`
  input {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
  }
`;

const PointInput = styled.div`
  position: relative;
  font-size: 14px;
  width: 300px;
  cursor: pointer;

  > div:nth-child(1) {
    padding: 10px;
    min-height: 42px;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    border: 1px solid
      ${({ isPointNotSelected }) => (isPointNotSelected ? "red" : "#c4c4c4")};
    border-radius: 5px;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeaderInput = styled(Input)`
  input {
    width: 45px;
    height: 42px;
    text-align: center;

    ::-webkit-inner-spin-button {
      display: none;
    }

    :disabled {
      opacity: 0.5;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 15px;
  touch-action: none;
`;

const MiddleBlock = styled.div`
  display: flex;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;
  }

  @media (min-width: 769px) {
    > div {
      column-gap: 10px;
    }
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    > div {
      display: flex;
      flex-direction: column;
      align-self: end;
      row-gap: 10px;

      input {
        width: 100%;
      }
    }
  }
`;
