import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import Routes from "../../routes";
import { connect } from "react-redux";
import { getAllowance } from "../../reducers/allowance/actions";
import { KeycloakContext } from "react-keycloak-nekrasovka";
import { useLocation } from "react-router";
// import axios from "axios";
// import { postTelegramBot } from "../../helpers";
import Modal from "../../nekrasovka-ui/Modal";
import Section from "../../nekrasovka-ui/Section";
import ProgressBar from "../../components/ProgressBar";

const MainContainer = styled.main`
  ${({ isNotLibrarian }) => isNotLibrarian && "margin: 0 auto 100px;"};
  max-width: ${({ isPathStaff, isNotLibrarian }) =>
    isNotLibrarian && !isPathStaff ? "1218px" : "100%"};
  height: 100%;
`;

const Error = styled(Section)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #edeee9;
  row-gap: 20px;

  h1 {
    margin: 0;
  }

  p {
    font-size: 40px;
  }

  @media (min-width: 769px) {
    span {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    padding: 15px;

    h1 {
      font-size: 1.2em;
    }

    span {
      font-size: 16px;
    }
  }
`;

const errors = {
  status: [429],
  max_retries: 5,
  wait: { milliseconds: 60000, count: 60 },
};

const Main = ({
  getAllowance,
  isAllowanceLoading,
  isNotLibrarian,
  setNotLibrarian,
  show,
}) => {
  const { pathname } = useLocation();
  const [isError, setError] = useState(false);
  // const [count, setCount] = useState(errors.wait.count);
  const kc = useContext(KeycloakContext);

  // axios.interceptors.response.use(
  //   (res) => res,
  //   (err) => {
  //     // const text = `<b>Ошибка: <a href="https://team.nekrasovka.ru">Team Nekrasovka</a></b>\n<i>message: ${err.message}\n</i><i>params: ${err.config.data}\n</i><i>method: ${err.config.method}, url: ${err.config.url}</i>\n<i>agent: ${navigator.userAgent}</i>`;
  //     //
  //     // postTelegramBot({
  //     //   method: "sendMessage",
  //     //   data: { text, parse_mode: "html" },
  //     // });
  //
  //     if (errors.status.includes(err.response.status)) {
  //       const retryCount = (err.config.retryCount || 0) + 1;
  //       err.config.retryCount = retryCount;
  //       setError(true);
  //
  //       if (retryCount <= errors.max_retries) {
  //         return new Promise((resolve, _) => {
  //           setTimeout(async () => {
  //             const token = await kc.checkTokenExpiredYesUpdate();
  //             const TOKEN_KEY = token.TOKEN_KEY;
  //
  //             if (token.refreshed) {
  //               localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
  //             }
  //
  //             err.config.headers = {
  //               Authorization: `Bearer ${TOKEN_KEY}`,
  //               "X-Api-Key": process.env.REACT_APP_TEAM_KEY,
  //               "Content-Type": `application/json`,
  //             };
  //
  //             resolve(axios(err.config));
  //
  //             setError(false);
  //             setCount(errors.wait.count);
  //           }, errors.wait.milliseconds);
  //         });
  //       }
  //     }
  //
  //     return Promise.reject(err);
  //   },
  // );

  // useEffect(() => {
  //   let timer;
  //
  //   if (isError && count > 0) {
  //     timer = setInterval(
  //       () => setCount(count - 1 === 0 ? null : count - 1),
  //       1000,
  //     );
  //   }
  //
  //   return () => clearInterval(timer);
  // }, [count, isError]);

  useEffect(() => {
    setNotLibrarian(!["/kc/callback", "/librarian"].includes(pathname));
  }, [pathname]);

  useEffect(() => {
    if (isAllowanceLoading) return;

    getAllowance({ ...kc });
  }, [isAllowanceLoading]);

  return (
    <>
      {isError && (
        <Modal>
          <Error>
            <h1>Превышен лимит запросов на сервер</h1>
            <span>Повтор запроса автоматически, через</span>
            <p>{count}</p>
          </Error>
        </Modal>
      )}
      {show && <ProgressBar />}
      <MainContainer
        isNotLibrarian={isNotLibrarian}
        isPathStaff={
          pathname.includes("dedusting") ||
          pathname.includes("staff") ||
          pathname.includes("booksnotfound")
        }
      >
        <Routes preferred_username={kc.tokenParsed.preferred_username} />
      </MainContainer>
    </>
  );
};

const mapStateToProps = ({
  progress: { show },
  allowance: { isAllowanceLoading },
}) => ({
  isAllowanceLoading,
  show,
});

export default connect(mapStateToProps, {
  getAllowance,
})(Main);
