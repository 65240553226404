import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { format } from "date-fns";
import api from "./api";
import databasePoints from "../database/points";
import consultations from "../reducers/consultations";
import points from "../reducers/points";
import accounting from "../reducers/accounting";
import locale from "date-fns/locale/ru";
import consultation from "../reducers/consultation";
import excursion from "../reducers/excursion";
import excursions from "../reducers/excursions";
import visuals from "../reducers/visuals";
import visual from "../reducers/visual";
import organizations from "../reducers/organizations";
import organization from "../reducers/organization";

const getAllowance = ({ allowance }) => allowance;
const getConsultation = ({ consultation }) => consultation;
const getOrganization = ({ organization }) => organization;
const getOrganizations = ({ organizations }) => organizations;
const getConsultations = ({ consultations }) => consultations;
const getVisuals = ({ visuals }) => visuals;
const getVisual = ({ visual }) => visual;
const getUnavailable = ({ unavailable }) => unavailable;
const getExcursions = ({ excursions }) => excursions;
const getExcursion = ({ excursion }) => excursion;
const getPoints = ({ points }) => points;
const getSettings = ({ settings }) => settings;

const getToday = () => format(new Date(), "yyyy-MM-dd HH:mm:ss", { locale });

function* fetchPoints() {
  const { department } = yield select(getAllowance);

  const databasePointsByPointDepartment = databasePoints.filter((f) =>
    f.point_department.includes(department),
  );

  yield put(
    points.actions.getPointsSuccess({
      points: databasePointsByPointDepartment,
    }),
  );
}

function* fetchVisual({ id }) {
  const { visualsData } = yield select(getVisuals);
  const visualToEdit = visualsData.filter((item) => item.id === id)[0];

  yield put(visual.actions.getVisualSuccess({ ...visualToEdit }));
}

function* fetchExcursion({ id }) {
  const { excursionsData } = yield select(getExcursions);
  const excursionToEdit = excursionsData.filter((item) => item.id === id)[0];

  yield put(excursion.actions.getExcursionSuccess({ ...excursionToEdit }));
}

function* fetchOrganizations({ date_time }) {
  const { collection_id } = yield select(getOrganizations);
  const data = [{ date_time }];

  const called = yield call(api.getCollection, {
    url: `${process.env.REACT_APP_TEAM_API}/entry`,
    collection_id,
    data,
  });

  yield put(organizations.actions.getOrganizationsSuccess(called.data.data[0]));
}

function* fetchOrganization({ id }) {
  const { organizationsData } = yield select(getOrganizations);

  const organizationToEdit = organizationsData.filter(
    (item) => item.id === id,
  )[0];

  yield put(
    organization.actions.getOrganizationSuccess({ ...organizationToEdit }),
  );
}

function* updateOrganizations() {
  let organizationsResponse = {};
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { organizationData } = yield select(getOrganization);
  const { collection_id } = yield select(getOrganizations);

  let {
    comment,
    department,
    duration,
    format,
    organization,
    participants,
    date_time,
    id,
    status,
  } = organizationData;

  const url = `${process.env.REACT_APP_TEAM_API}/entry`;

  const params = {
    collection_id,
    url,
    checkTokenExpiredYesUpdate,
  };

  if (!!organizationData.id) {
    params.data = [
      {
        id,
        comment,
        department,
        duration,
        format,
        organization,
        participants,
        date_time,
        status,
      },
    ];

    organizationsResponse = yield call(api.patchCollection, params);
  } else {
    const date_time = !!organizationData.date_time
      ? organizationData.date_time
      : getToday();

    params.data = [
      {
        comment,
        department,
        duration,
        format,
        organization,
        participants,
        date_time,
        status: !!organizationData.status,
      },
    ];

    organizationsResponse = yield call(api.putCollection, params);
  }

  yield put(
    organizations.actions.updateOrganizationsSuccess(
      organizationsResponse.data.status,
    ),
  );
}

function* fetchConsultation({ id }) {
  const { consultationsData } = yield select(getConsultations);
  const consultationToEdit = consultationsData.filter(
    (item) => item.id === id,
  )[0];

  yield put(
    consultation.actions.getConsultationSuccess({ ...consultationToEdit }),
  );
}

function* fetchConsultations({ date_time }) {
  const { point } = yield select(getPoints);
  const { user } = yield select(getAllowance);
  const { statistics } = yield select(getSettings);

  const data = [{ point: point.point_id, date_time }];

  if (statistics.active) data[0].user = user;

  const called = yield call(api.getCollection, {
    url: `${process.env.REACT_APP_TEAM_API}/entry`,
    collection_id: "consultations",
    data,
  });

  const response = called.error
    ? []
    : called.data.data[0].sort((a, b) => b.id - a.id);

  yield put(consultations.actions.getConsultationsSuccess(response));
}

function* fetchConsultationsTypicalResponses({ date_time }) {
  const { point } = yield select(getPoints);

  const url = `${process.env.REACT_APP_TEAM_API}/stats`;
  const collection_id = "consultations";
  const addition = {
    days_offset: 30,
    group_field: "content",
    max_entries: 5,
  };

  let resp1, resp2;

  const department = databasePoints.filter(
    ({ point_id }) => point_id === point.point_id,
  )[0].point_department[0];

  const pointId = databasePoints
    .filter(({ point_department }) => point_department.includes(department))
    .map(({ point_id }) => point_id);

  resp1 = yield call(api.getCollection, {
    url,
    collection_id,
    data: [{ date_time, point: pointId }],
    addition,
  });

  resp2 = yield call(api.getCollection, {
    url,
    collection_id,
    data: [{ date_time, point: point.point_id }],
    addition,
  });

  const arr = [...resp1.data.data[0]];

  resp2.data.data[0].forEach((f) => {
    if (!resp1.data.data[0].some((s) => s.toLowerCase() === f.toLowerCase())) {
      arr.push(f);
    }
  });

  yield put(consultations.actions.getConsultationsTypicalResponsesSuccess(arr));
}

function* updateConsultations() {
  let consultationsResponse, unavailablesResponse, unavailableId;
  const { point } = yield select(getPoints);
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { unavailableData } = yield select(getUnavailable);
  let { consultationData } = yield select(getConsultation);

  const url = `${process.env.REACT_APP_TEAM_API}/entry`;

  let { category, comment, content, date_time, source } = consultationData;
  const { author, title, code, reason, publisher } = unavailableData;

  const isUnavailableNotEmpty = !!reason && !!author && !!title;

  if (isUnavailableNotEmpty) {
    const collection_id = "unavailables";
    const params = {
      collection_id,
      url,
      checkTokenExpiredYesUpdate,
    };

    if (!!unavailableData.id) {
      params.data = [
        { id: unavailableData.id, author, title, code, reason, publisher },
      ];
      yield call(api.patchCollection, params);
      unavailableId = unavailableData.id;
    } else {
      const now = new Date();
      const date_time = format(now, "yyyy-MM-dd HH:mm:ss", { locale });

      params.data = [{ date_time, author, title, code, reason, publisher }];
      unavailablesResponse = yield call(api.putCollection, params);
      unavailableId = unavailablesResponse.data["inserted_ids"][0];
    }

    const text = {
      name: "Отказ по изданию",
      author,
      title,
      code,
      reason,
      publisher,
      id: unavailableId,
    };

    content = JSON.stringify(text);
  }

  const collection_id = "consultations";
  const params = {
    collection_id,
    url,
    checkTokenExpiredYesUpdate,
  };

  if (!!consultationData.id) {
    params.data = [
      {
        id: consultationData.id,
        category,
        comment,
        content,
        source,
        point: point.point_id,
        date_time,
      },
    ];

    consultationsResponse = yield call(api.patchCollection, params);
  } else {
    const now = new Date();
    const ndt = format(now, "yyyy-MM-dd HH:mm:ss", { locale });

    params.data = [
      {
        category,
        comment,
        content,
        source,
        point: point.point_id,
        date_time: ndt,
      },
    ];

    consultationsResponse = yield call(api.putCollection, params);
  }

  yield put(
    consultations.actions.updateConsultationsSuccess(
      consultationsResponse.data.status,
    ),
  );
}

function* fetchExcursions({ date_time }) {
  const { point } = yield select(getPoints);
  const { user } = yield select(getAllowance);
  const { statistics } = yield select(getSettings);
  const data = [{ point: point.point_id, date_time }];

  if (statistics.active) data[0].user = user;

  const called = yield call(api.getCollection, {
    url: `${process.env.REACT_APP_TEAM_API}/entry`,
    collection_id: "excursions",
    data,
  });

  const response = called.data.data[0].sort((a, b) => b.id - a.id);

  yield put(excursions.actions.getExcursionsSuccess(response));
}

function* fetchVisuals({ date_time }) {
  const { point } = yield select(getPoints);
  const { user } = yield select(getAllowance);
  const { statistics } = yield select(getSettings);
  const data = [{ point: point.point_id, date_time }];

  if (statistics.active) data[0].user = user;

  const called = yield call(api.getCollection, {
    url: `${process.env.REACT_APP_TEAM_API}/entry`,
    collection_id: "visuals",
    data,
  });

  const response = called.data.data[0].sort((a, b) => b.id - a.id);

  yield put(visuals.actions.getVisualsSuccess(response));
}

function* fetchAccounting({ date_time }) {
  const { point } = yield select(getPoints);
  const { user } = yield select(getAllowance);
  const { statistics } = yield select(getSettings);

  const collections = { book_lending: 0, book_return: 0, reader_visit: 0 };
  const url = `${process.env.REACT_APP_TEAM_API}/entry`;
  const data = [{ point: point.point_id, date_time }];
  const count_only = true;

  if (statistics.active) data[0].user = user;

  for (let collection_id in collections) {
    try {
      const called = yield call(api.getCollection, {
        collection_id,
        url,
        count_only,
        data,
      });

      collections[collection_id] = called.data.data[0].total;
    } catch (e) {
      console.error(e);
    }
  }

  const response = { ...collections };

  yield put(accounting.actions.getAccountingSuccess(response));
}

function* updateVisuals() {
  let data, response;
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { point } = yield select(getPoints);
  const {
    visualData: { comment, count, date_time, id, location, reader, type },
  } = yield select(getVisual);

  const url = `${process.env.REACT_APP_TEAM_API}/entry`;
  const collection_id = "visuals";

  data = {
    comment,
    count,
    id,
    location,
    reader,
    type,
    point: point.point_id,
  };
  const params = {
    collection_id,
    url,
    checkTokenExpiredYesUpdate,
  };

  if (!!id) {
    data = { ...data, date_time };

    params.data = [data];
    response = yield call(api.patchCollection, params);
  } else {
    const now = new Date();
    const ndt = format(now, "yyyy-MM-dd HH:mm:ss", { locale });
    data = { ...data, date_time: ndt };

    params.data = [data];
    response = yield call(api.putCollection, params);
  }

  yield put(visuals.actions.updateVisualsSuccess(response.data.status));
}

function* updateExcursions() {
  let data, response;
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { point } = yield select(getPoints);
  const {
    excursionData: { comment, date_time, id, participants, topic },
  } = yield select(getExcursion);

  const url = `${process.env.REACT_APP_TEAM_API}/entry`;
  const collection_id = "excursions";

  data = { comment, id, participants, topic, point: point.point_id };
  const params = {
    collection_id,
    url,
    checkTokenExpiredYesUpdate,
  };

  if (!!id) {
    data = { ...data, date_time };

    params.data = [data];
    response = yield call(api.patchCollection, params);
  } else {
    const now = new Date();
    const ndt = format(now, "yyyy-MM-dd HH:mm:ss", { locale });
    data = { ...data, date_time: ndt };

    params.data = [data];
    response = yield call(api.putCollection, params);
  }

  yield put(excursions.actions.updateExcursionsSuccess(response.data.status));
}

function* updateAccounting({ name, count }) {
  const { checkTokenExpiredYesUpdate } = yield select(getAllowance);
  const { point } = yield select(getPoints);
  const now = new Date();
  const date_time = format(now, "yyyy-MM-dd HH:mm:ss", { locale });
  const url = `${process.env.REACT_APP_TEAM_API}/entry`;
  const data = [{ point: point.point_id, date_time }];
  const collection_id = name;

  const params = {
    collection_id,
    url,
    data,
    count,
    checkTokenExpiredYesUpdate,
  };

  const response = yield call(api.putCollection, params);
  yield put(accounting.actions.updateAccountingSuccess(response.data.status));
}

function* watchFetchAccounting() {
  yield takeEvery(accounting.types.GET_ACCOUNTING, fetchAccounting);
}

function* watchUpdateAccounting() {
  yield takeEvery(accounting.types.UPDATE_ACCOUNTING, updateAccounting);
}

function* watchFetchConsultations() {
  yield takeEvery(consultations.types.CONSULTATIONS_FETCH, fetchConsultations);
}

function* watchFetchConsultationsTypicalResponses() {
  yield takeEvery(
    consultations.types.CONSULTATIONS_TYPICAL_RESPONSES_FETCH,
    fetchConsultationsTypicalResponses,
  );
}

function* watchUpdateConsultations() {
  yield takeEvery(
    consultations.types.CONSULTATIONS_UPDATE,
    updateConsultations,
  );
}

function* watchFetchConsultation() {
  yield takeEvery(consultation.types.CONSULTATION_GET, fetchConsultation);
}

function* watchFetchOrganizations() {
  yield takeEvery(organizations.types.ORGANIZATIONS_FETCH, fetchOrganizations);
}

function* watchUpdateOrganizations() {
  yield takeEvery(
    organizations.types.ORGANIZATIONS_UPDATE,
    updateOrganizations,
  );
}

function* watchFetchOrganization() {
  yield takeEvery(organization.types.ORGANIZATION_GET, fetchOrganization);
}

function* watchFetchExcursions() {
  yield takeEvery(excursions.types.EXCURSIONS_FETCH, fetchExcursions);
}

function* watchUpdateExcursions() {
  yield takeEvery(excursions.types.EXCURSIONS_UPDATE, updateExcursions);
}

function* watchFetchExcursion() {
  yield takeEvery(excursion.types.EXCURSION_GET, fetchExcursion);
}

function* watchFetchVisuals() {
  yield takeEvery(visuals.types.VISUALS_FETCH, fetchVisuals);
}

function* watchUpdateVisuals() {
  yield takeEvery(visuals.types.VISUALS_UPDATE, updateVisuals);
}

function* watchFetchVisual() {
  yield takeEvery(visual.types.VISUAL_GET, fetchVisual);
}

function* watchFetchPoints() {
  yield takeEvery(points.types.POINTS_GET, fetchPoints);
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPoints),
    fork(watchFetchVisuals),
    fork(watchFetchVisual),
    fork(watchUpdateVisuals),
    fork(watchFetchExcursions),
    fork(watchFetchExcursion),
    fork(watchUpdateExcursions),
    fork(watchFetchConsultations),
    fork(watchFetchConsultationsTypicalResponses),
    fork(watchFetchConsultation),
    fork(watchUpdateConsultations),
    fork(watchFetchOrganization),
    fork(watchFetchOrganizations),
    fork(watchUpdateOrganizations),
    fork(watchFetchAccounting),
    fork(watchUpdateAccounting),
  ]);
}
