import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "axios";

import Navigation from "./components/Navigation";
import Departments from "./containers/Departments";
import Users from "./containers/Users";
import User from "./containers/User";
import Websites from "./containers/Websites";
import News from "./containers/News";
import {
  closeProgress,
  finishProgress,
  startProgress,
  clearProgress,
} from "../../reducers/progress/actions";
import { mapOrder } from "../../helpers";
import format from "date-fns/format";
import locale from "date-fns/locale/ru";
import Services from "./containers/Services";
import { Helmet } from "react-helmet";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const initialUser = {
  data: {
    name: "",
    login: "",
    status: "",
    email: "",
    apps: "",
    department: { login: "", name: "" },
  },
  loaded: false,
};

const initialMembers = {
  data: [],
  loaded: false,
};

const initialDepartments = {
  data: [],
  loaded: false,
};

const initialServices = {
  data: [],
  loaded: false,
};

const initialFavorites = {
  default: {
    id: 26,
    name: "GLPI",
    description:
      "Сервис для техподдержки ОИТ и ЭХО и учета программного обеспечения",
    url: "https://glpi.nekrasovka.ru/",
    roles: "[1, 2]",
    is_external: 1,
  },
  data: [],
  loaded: false,
};

const initialWebsites = {
  data: [],
  loaded: false,
};

const initialNews = {
  data: [],
  loaded: false,
};

const initialIssues = {
  data: [],
  loaded: false,
};

const initialNewsCard = {
  data: {},
  loaded: false,
};

const initialSearch = {
  members: [],
  departments: [],
  services: [],
  websites: [],
};

const Staff = ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
}) => {
  const { dispatch } = useContext(AlertContext);

  const draggedItemIndex = useRef();
  const draggedOverItemIndex = useRef();
  const today = new Date();

  const { pathname } = useLocation();
  const history = useHistory();
  const { user_id, department_id, news_id } = useParams();

  const [query, setQuery] = useState("");
  const [search, setSearch] = useState(initialSearch);
  const [user, setUser] = useState(initialUser);
  const [members, setMembers] = useState(initialMembers);
  const [departments, setDepartments] = useState(initialDepartments);
  const [services, setServices] = useState(initialServices);
  const [favorites, setFavorites] = useState(initialFavorites);
  const [websites, setWebsites] = useState(initialWebsites);
  const [news, setNews] = useState(initialNews);
  const [issues, setIssues] = useState(initialIssues);
  const [newsCard, setNewsCard] = useState(initialNewsCard);
  const [status, setStatus] = useState("");
  const [isUpdated, setUpdated] = useState(false);
  const [isLoaded, setLoaded] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [date, setDate] = useState(null);

  const { preferred_username } = tokenParsed;

  const is = {
    me: preferred_username === user_id,
    departments: pathname.includes("departments"),
    users: pathname.includes("users") && !user_id,
    user: !!user_id && preferred_username !== user_id,
    websites: pathname.includes("websites"),
    news: pathname.includes("news"),
    services: pathname.includes("services"),
  };

  const myPage = `/staff/users/${preferred_username}`;

  const getRequest = async (end, method, data) => {
    let { refreshed, TOKEN_KEY } = await checkTokenExpiredYesUpdate();

    if (refreshed) {
      localStorage.setItem("TOKEN_KEY", TOKEN_KEY);
    } else TOKEN_KEY = localStorage.getItem("TOKEN_KEY");

    const headers = { Authorization: `Bearer ${TOKEN_KEY}` };
    const url = `${process.env.REACT_APP_API}/${end}`;
    const options = { method, data, url, headers };

    return axios(options);
  };

  const getIssues = () => {
    getRequest("jira", "post", { user_id }).then((resp) => {
      setIssues({ data: resp.data.issues, loaded: !!resp.data.issues.length });
    });
  };

  const getInit = async () => {
    getRequest("init", "get").then(({ data }) => {
      setLoaded(data.loaded);
    });
  };

  const getMembers = () => {
    clearProgress();
    startProgress();

    getRequest("users", "post", { user_id }).then((resp) => {
      setMembers({
        data: resp.data,
        loaded: true,
      });

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const getUser = () => {
    getRequest("users", "post", { user_id }).then((resp) => {
      setStatus(resp.data[0].status);
      setUser({
        data: resp.data[0],
        loaded: true,
      });

      setFavorites({
        data: resp.data[0]["favorites"],
        loaded: !!resp.data[0]["favorites"].length,
      });
    });
  };

  const getServices = () => {
    clearProgress();
    startProgress();

    getRequest("services", "post", { user_id: preferred_username }).then(
      (resp) => {
        setServices({
          data: resp.data["services"],
          loaded: !!resp.data["services"].length,
        });

        finishProgress();
        setTimeout(() => closeProgress(), 300);
      },
    );
  };

  const patchSelf = (data) => {
    getRequest("users", "patch", data);
  };

  const patchServices = (id) => {
    getRequest("services", "patch", { id });
  };

  const patchFavorites = (id) => {
    getRequest("favorites", "patch", { id });
  };

  const getDepartments = () => {
    clearProgress();
    startProgress();

    getRequest("departments", "post", { department_id }).then((resp) => {
      setDepartments({ data: resp.data.departments, loaded: true });

      if (!!department_id) {
        setMembers({ data: resp.data.members, sorted: [], loaded: true });
      } else {
        if (members.loaded) setMembers(initialMembers);
      }

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const getNews = async (params) => {
    const resp = await getRequest("news", "post", { ...params, news_id });
    if (!!news_id) {
      setNewsCard({ data: resp.data, loaded: true });
      setNews(initialNews);
    } else {
      setNews({ data: resp.data, loaded: true });
      setNewsCard(initialNewsCard);
    }

    return resp.status;
  };

  const getWebsites = () => {
    clearProgress();

    getRequest("websites", "get").then((resp) => {
      startProgress();
      setWebsites({ data: resp.data, loaded: true });

      finishProgress();
      setTimeout(() => closeProgress(), 300);
    });
  };

  const handleDate = async (d) => {
    const newDate = !!date && d.getDate() === date.getDate() ? null : d;

    if (!!news_id) {
      history.replace(`/staff/news/`);
    }

    setNews(initialNews);

    if (!!newDate) {
      const created = format(d, "yyyy-MM-dd", { locale });
      await getNews({ created });
    } else await getNews();

    setDate(newDate);
  };

  const handleServices = (id) => {
    const newServices = [...services.data];
    const name = newServices.find((item) => item.id === id).name; // eslint-disable-line

    const filteredServices = newServices.filter((service) => service.id !== id);
    setServices({ loaded: !!filteredServices.length, data: filteredServices });

    patchServices(id);
    dispatch({
      type: "ALERT_ON",
      text: `Добавили сервис «${name}» в раздел «Профиль. Избранные сервисы»`,
      name: "warning",
    });
  };

  const handleFavorites = (id) => {
    const newFavorites = [...favorites.data];
    const name = favorites.data.find((item) => item.id === id).name; // eslint-disable-line

    const inFavorites = !!favorites.data.find((item) => item.id === id);

    if (inFavorites) {
      const filteredFavorites = newFavorites.filter((item) => item.id !== id);
      setFavorites({
        ...favorites,
        loaded: !!filteredFavorites.length,
        data: filteredFavorites,
      });
    } else {
      newFavorites.push(id);
      setFavorites({
        ...favorites,
        loaded: !!newFavorites.length,
        data: newFavorites,
      });
    }

    patchFavorites(id);
    dispatch({
      type: "ALERT_ON",
      text: `Перенесли сервис «${name}» в раздел «Сервисы»`,
      name: "warning",
    });
  };

  const handleChange = ({ target }) => {
    setStatus(target.value);
    setUpdated(true);
  };

  const handleSave = () => {
    patchSelf({ ...user, status });
    setUpdated(false);
  };

  const handleCancel = () => {
    setStatus(user.status);
    setUpdated(false);
  };

  const handleSearch = ({ target }) => {
    const inputValue = target.value;

    setQuery(inputValue);

    if (inputValue && inputValue.length > 2) {
      getRequest("search", "post", { query: inputValue }).then((resp) => {
        const isResults =
          resp.data.departments.length ||
          resp.data.members.length ||
          resp.data.websites.length ||
          resp.data.services.length;

        if (isResults) {
          if (!isModal) setIsModal(true);
          setSearch(resp.data);
        }
      });
    } else {
      if (isModal) setIsModal(false);
      setSearch(initialSearch);
    }
  };

  const resetSearch = () => {
    query ? setQuery("") : null;
    setSearch(initialSearch);
    setIsModal(false);
  };

  const dragStart = (e) => {
    draggedItemIndex.current = e.target.id;
  };

  const dragEnter = (e) => {
    draggedOverItemIndex.current = e.currentTarget.id;
  };

  const dragEnd = () => {
    const newData = [...services.data];

    const draggedItemContent = newData[draggedItemIndex.current];
    newData.splice(draggedItemIndex.current, 1);
    newData.splice(draggedOverItemIndex.current, 0, draggedItemContent);

    draggedItemIndex.current = null;
    draggedOverItemIndex.current = null;

    const idArray = newData.map((item) => item.id);
    const serializedIds = JSON.stringify(idArray);

    const newSortedData = mapOrder(newData, idArray, "id");

    setServices({ ...services, data: newSortedData });
    patchSelf({ service_sort_id: serializedIds });
  };

  const dragOver = (e) => e.preventDefault();

  useEffect(() => {
    getInit();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta name="theme-color" content="#edeee9" />
      </Helmet>
      <Container>
        <Navigation
          is={is}
          myPage={myPage}
          resetSearch={resetSearch}
          isModal={isModal}
          query={query}
          search={search}
          handleSearch={handleSearch}
          setIsModal={setIsModal}
          handleDate={handleDate}
          today={today}
          date={date}
        />
        {isLoaded && (
          <Fragment>
            {is.me && (
              <User
                user_id={user_id}
                user={user}
                favorites={favorites}
                status={status}
                initialUser={initialUser}
                isDisabled={false}
                handleCancel={handleCancel}
                handleSave={handleSave}
                handleChange={handleChange}
                isUpdated={isUpdated}
                getUser={getUser}
                setUser={setUser}
                setFavorites={setFavorites}
                initialFavorites={initialFavorites}
                handleFavorites={handleFavorites}
                getNews={getNews}
                setNews={setNews}
                initialNews={initialNews}
                news={news}
                history={history}
                issues={issues}
                getIssues={getIssues}
                setIssues={setIssues}
                initialIssues={initialIssues}
              />
            )}
            {is.departments && (
              <Departments
                departments={departments}
                department_id={department_id}
                history={history}
                members={members}
                initialDepartments={initialDepartments}
                setDepartments={setDepartments}
                getDepartments={getDepartments}
                setMembers={setMembers}
                initialMembers={initialMembers}
              />
            )}
            {is.users && (
              <Users
                members={members}
                history={history}
                initialMembers={initialMembers}
                getMembers={getMembers}
                setMembers={setMembers}
              />
            )}
            {is.user && (
              <User
                user_id={user_id}
                user={user}
                favorites={favorites}
                status={status}
                initialUser={initialUser}
                isDisabled={true}
                handleCancel={null}
                handleSave={null}
                handleChange={null}
                isUpdated={false}
                getUser={getUser}
                setUser={setUser}
                setFavorites={setFavorites}
                initialFavorites={initialFavorites}
                issues={issues}
                getIssues={getIssues}
                setIssues={setIssues}
                initialIssues={initialIssues}
              />
            )}
            {is.websites && (
              <Websites
                getWebsites={getWebsites}
                setWebsites={setWebsites}
                websites={websites}
                initialWebsites={initialWebsites}
              />
            )}
            {is.news && (
              <News
                getNews={getNews}
                setNews={setNews}
                setNewsCard={setNewsCard}
                initialNews={initialNews}
                initialNewsCard={initialNewsCard}
                news={news}
                newsCard={newsCard}
                handleDate={handleDate}
                today={today}
                date={date}
                history={history}
                news_id={news_id}
                preferred_username={preferred_username}
              />
            )}
            {is.services && (
              <Services
                services={services}
                initialServices={initialServices}
                setServices={setServices}
                getServices={getServices}
                dragOver={dragOver}
                dragEnd={dragEnd}
                dragEnter={dragEnter}
                dragStart={dragStart}
                handleServices={handleServices}
                isDisabled={false}
              />
            )}
          </Fragment>
        )}
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({
  allowance: { tokenParsed, checkTokenExpiredYesUpdate },
}) => ({
  tokenParsed,
  checkTokenExpiredYesUpdate,
});

export default connect(mapStateToProps, {
  startProgress,
  finishProgress,
  closeProgress,
  clearProgress,
})(Staff);
