import React, { useContext, useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Button from "../../nekrasovka-ui/Button";
import {
  updateAccounting,
  getAccounting,
  resetAccounting,
} from "../../reducers/accounting/actions";
import { AlertContext } from "../../nekrasovka-ui/Alert/AlertProvider";

const Accounting = ({
  updateAccounting,
  getAccounting,
  resetAccounting,
  today,
  reader_visit,
  book_lending,
  book_return,
  point,
  isAccountingGet,
  isAccountingUpdate,
  isToday,
}) => {
  const { dispatch } = useContext(AlertContext);

  const max = 100;
  const accInitial = {
    reader_visit: { count: 1, title: "Посещения" },
    book_lending: { count: 1, title: "Книговыдача ЧЗ" },
    book_return: { count: 1, title: "Возвраты" },
  };

  const [acc, setAcc] = useState(accInitial);

  useEffect(() => {
    getAccounting(today);

    return () => resetAccounting();
  }, [point.point_id]);

  useEffect(() => {
    if (isAccountingUpdate) getAccounting(today);
  }, [isAccountingUpdate]);

  const handleAccounting = (name) => {
    const count = !acc[name].count ? 1 : acc[name].count;

    updateAccounting(name, count);
    setAcc(accInitial);
  };

  const handleAccountingCount = ({ target }) => {
    const value = Number(target.value);
    let count = value;

    if (value === 0) count = "";
    if (value > max) {
      dispatch({
        type: "ALERT_ON",
        text: `Не больше ${max}`,
        name: "warning",
      });
      count = Math.floor(value / 10);
    }

    setAcc({
      ...acc,
      [target.name]: { ...acc[target.name], count },
    });
  };

  return (
    <AccountingContainer>
      <AccountingHeader>
        <h4>Учёт</h4>
      </AccountingHeader>
      <AccountingList>
        <div>
          <span>Посещения</span>
          <span>Книговыдача ЧЗ</span>
          <span>Возвраты</span>
        </div>
        <div>
          <AccountingListAction>
            <span>
              {isAccountingGet ? (
                reader_visit
              ) : (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </span>
            {isToday && (
              <Fragment>
                <div>
                  <span>+</span>
                  <input
                    disabled={!isToday}
                    onChange={handleAccountingCount}
                    value={acc.reader_visit.count}
                    type="number"
                    name="reader_visit"
                    min="1"
                    max="1000"
                    size="4"
                    placeholder="1"
                  />
                </div>
                <Button
                  isDisabled={!isAccountingGet}
                  name="Добавить"
                  buttonStyles={"padding: 4px 10px; font-size: 14px;"}
                  onClick={() => handleAccounting("reader_visit")}
                />
              </Fragment>
            )}
          </AccountingListAction>
          <AccountingListAction>
            <span>
              {isAccountingGet ? (
                book_lending
              ) : (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </span>
            {isToday && (
              <Fragment>
                <div>
                  <span>+</span>
                  <input
                    onChange={handleAccountingCount}
                    value={acc.book_lending.count}
                    type="number"
                    name="book_lending"
                    min="1"
                    max="1000"
                    size="4"
                    placeholder="1"
                  />
                </div>
                <Button
                  isDisabled={!isAccountingGet}
                  name="Добавить"
                  buttonStyles={"padding: 4px 10px; font-size: 14px;"}
                  onClick={() => handleAccounting("book_lending")}
                />
              </Fragment>
            )}
          </AccountingListAction>
          <AccountingListAction>
            <span>
              {isAccountingGet ? (
                book_return
              ) : (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </span>
            {isToday && (
              <Fragment>
                <div>
                  <span>+</span>
                  <input
                    onChange={handleAccountingCount}
                    value={acc.book_return.count}
                    name="book_return"
                    type="number"
                    min="1"
                    max="1000"
                    size="4"
                    placeholder="1"
                  />
                </div>
                <Button
                  isDisabled={!isAccountingGet}
                  name="Добавить"
                  buttonStyles={"padding: 4px 10px; font-size: 14px;"}
                  onClick={() => handleAccounting("book_return")}
                />
              </Fragment>
            )}
          </AccountingListAction>
        </div>
      </AccountingList>
    </AccountingContainer>
  );
};
const mapStateToProps = ({
  allowance: { today },
  points: { point },
  accounting: {
    reader_visit,
    book_lending,
    book_return,
    isAccountingGet,
    isAccountingUpdate,
  },
}) => ({
  today,
  reader_visit,
  book_lending,
  book_return,
  point,
  isAccountingGet,
  isAccountingUpdate,
});

export default connect(mapStateToProps, {
  getAccounting,
  updateAccounting,
  resetAccounting,
})(Accounting);

const AccountingContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding: 15px;
  background: #ffffff;
`;

const AccountingHeader = styled.div`
  display: grid;
  align-items: center;
  column-gap: 20px;
  position: relative;

  h4 {
    margin: 0;
    font-weight: 500;
  }

  @media (min-width: 769px) {
    grid-template-columns: 148px auto;
  }

  @media (max-width: 768px) {
    grid-template-columns: 115px auto;
  }
`;

const AccountingList = styled.div`
  display: grid;
  column-gap: 20px;

  > div {
    display: grid;
    row-gap: 10px;

    > span,
    > div {
      height: 27px;
    }

    > span {
      align-items: center;
      display: flex;
      font-size: 14px;
    }
  }

  @media (min-width: 769px) {
    grid-template-columns: 148px 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 115px 1fr;
  }
`;

const AccountingListAction = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;

  span,
  input {
    font-size: 14px;
  }

  > button {
    display: flex;
  }

  > span {
    min-width: 45px;
  }

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    opacity: 0.7;

    input {
      padding-left: 5px;
      border: none;
      margin-left: 5px;
      height: 100%;
      outline: none;

      &[type="number"]::-webkit-outer-spin-button,
      &[type="number"]::-webkit-inner-spin-button {
        margin-left: 10px;
      }
    }

    span {
      opacity: 0.7;
    }
  }

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;
