import React from "react";

export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "16"}
      height={props.height || "16"}
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 2.002A2 2 0 0 1 2.002 0h11.996A2 2 0 0 1 16 2.002v11.996A2 2 0 0 1 13.998 16H2.002A2 2 0 0 1 0 13.998V2.002z"
          fill="#6554C0"
        />
        <path
          d="M11.898 7.666a.678.678 0 0 0-.564-1.04L8 6.624V3.187a.678.678 0 0 0-.666-.687.65.65 0 0 0-.54.307.693.693 0 0 0-.088.178l-2.598 5.34A.738.738 0 0 0 4 8.689c0 .38.3.687.667.687H8v3.438c0 .38.3.687.667.687a.655.655 0 0 0 .557-.331l.022-.035c.014-.029.03-.055.041-.085l2.61-5.383z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};
