import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default ({
  rows,
  columns,
  isDataGet,
  loaded,
  limit,
  handleRow,
  info,
  className,
}) => {
  const [isDataLength, setIsDataLength] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isDataGet && isDataLength);
  }, [isDataGet, isDataLength]);

  useEffect(() => {
    setIsDataLength(isDataGet && !!rows.length);
  }, [isDataGet, rows]);

  const LibrarianTableLoading = () => {
    return isDataGet ? (
      !isDataLength && <LibrarianTableInfo>{info.get}</LibrarianTableInfo>
    ) : (
      <LibrarianTableInfo>{info.loading}</LibrarianTableInfo>
    );
  };

  return (
    <LibrarianTableContainer className={className}>
      <LibrarianTable>
        <thead>
          <tr>
            {Object.keys(columns).map((column, i) => (
              <th key={i}>{columns[column]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            rows.slice(loaded, !!loaded ? loaded + limit : limit).map((row) => (
              <LibrarianTableRow key={row.id} onClick={() => handleRow(row.id)}>
                {Object.keys(columns).map((column, i) => (
                  <td key={i}>{row[column]}</td>
                ))}
              </LibrarianTableRow>
            ))}
        </tbody>
      </LibrarianTable>
      <LibrarianTableLoading />
    </LibrarianTableContainer>
  );
};

const LibrarianTableContainer = styled.div`
  overflow-x: auto;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LibrarianTable = styled.table`
  text-align: left;
  border: 1px solid #c4c4c4;
  border-collapse: collapse;

  th {
    font-weight: 500;
    white-space: nowrap;
  }

  th,
  td {
    font-size: 14px;
    padding: 8px;

    :not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }
  }

  tr:not(:last-child),
  th {
    border-bottom: 1px solid #c4c4c4;
  }

  @media (min-width: 769px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);

    th {
      white-space: pre;
    }
  }
`;

const LibrarianTableRow = styled.tr`
  @media (hover: hover) {
    :hover {
      cursor: pointer;
      background-color: #c4c4c430;
    }
  }
`;

const LibrarianTableInfo = styled.div`
  display: flex;
  padding: 8px;
  font-size: 14px;

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;
