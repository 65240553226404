import React, { useState } from "react";
import styled from "styled-components";
import ContentModal from "../../nekrasovka-ui/ContentModal";
import ButtonsGroup from "../../nekrasovka-ui/ButtonsGroup";
import Button from "../../nekrasovka-ui/Button";
import axios from "axios";
import { Link } from "react-router-dom";

const Assignment = ({ data, getBookcase }) => {
  const [selectedShelf, setSelectedShelf] = useState([]);
  const [isUser, setUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");

  const handleUser = (groupName, id) => {
    setSelectedUser(id);
    setUser(false);
  };

  const handleSave = () => {
    const params = {
      shelves: data.shelves.filter((_, i) => selectedShelf.includes(i)),
      ticket_id: data.tickets[selectedUser].id,
    };

    axios
      .post(`${process.env.REACT_APP_API}/bookcase/ticket/shelf`, params)
      .then(() => {
        getBookcase();
        handleClear();
      });
  };

  const handleClear = () => {
    setSelectedUser("");
    setSelectedShelf([]);
  };

  const handleShelf = async (id) => {
    if (selectedUser === "" || !data.tickets[selectedUser].date) return null;

    setSelectedShelf(
      selectedShelf.includes(id)
        ? selectedShelf.filter((item) => item !== id)
        : [...selectedShelf, id],
    );
  };

  const usersButtons = data.tickets.map((m) => m.name);
  const options = { user: {} };

  if (selectedUser !== "" && !data.tickets[selectedUser].date) {
    options.user = {
      to: `/backoffice/dedusting/users/${data.tickets[selectedUser].user}`,
      as: Link,
    };
  }

  return (
    <Container>
      <AssignmentHeader>
        <Section>
          Исполнитель (по полкам)
          <Input>
            <div onClick={() => !!usersButtons.length && setUser(!isUser)}>
              {selectedUser !== "" && data.tickets[selectedUser].name}
            </div>
            <Modal isContentModal={isUser} setContentModal={setUser}>
              <ModalButtonsGroup
                groupName="user"
                buttons={usersButtons}
                activeButton={selectedUser}
                handleButton={handleUser}
              />
            </Modal>
          </Input>
        </Section>
        <Section>
          Дата обеспыливания
          <Input
            {...options.user}
            isDate={selectedUser !== "" && !data.tickets[selectedUser].date}
          >
            <div>
              {selectedUser === "" ? "" : data.tickets[selectedUser].date}
            </div>
          </Input>
        </Section>
      </AssignmentHeader>
      {!!data.shelves.length && (
        <CreationMiddle nColumns={data.bookcase.x}>
          {[...Array(data.bookcase.x)].map((_, titleId) => {
            return (
              <TitleInput key={titleId}>
                <input
                  disabled={true}
                  type="text"
                  id={`title_${titleId}`}
                  value={data.bookcase.names[titleId] || ""}
                />
              </TitleInput>
            );
          })}
          {data.shelves.map((shelf, shelfId) => {
            const isActive = selectedShelf.includes(shelfId);
            let name = "";
            let date = "";

            for (const key of data.tickets) {
              if (!!key.shelves.length) {
                key.shelves.forEach((f) => {
                  if (f.shelf_id === shelf.id) {
                    name = key.name;
                    date = key.date;
                  }
                });
              }
            }

            return (
              <Shelf
                key={shelfId}
                isEmpty={shelf.is_empty}
                isActive={isActive}
                onClick={() => handleShelf(shelfId)}
              >
                {shelf.is_empty ? null : (
                  <>
                    <span>{name}</span>
                    <span>{date}</span>
                  </>
                )}
              </Shelf>
            );
          })}
        </CreationMiddle>
      )}
      <CreationFooter>
        <Button
          isDisabled={!selectedShelf.length}
          name="Сохранить"
          onClick={handleSave}
        />
        <Button name="Отменить" onClick={handleClear} />
      </CreationFooter>
    </Container>
  );
};

export default Assignment;

const AssignmentHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;

  @media (min-width: 769px) {
    column-gap: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 10px;
  }
`;

const TitleInput = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 1;

  input {
    padding: 10px;
    outline-style: none;
    margin: 0;
    color: #222222;
    background-color: #ffffff;

    width: 100%;
    height: 50px;
    border-radius: 0;
    border: none;
  }
`;

const Shelf = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  column-gap: 5px;
  padding: 15px;
  font-size: 11px;

  height: 50px;
  background-color: ${({ isEmpty, isActive }) =>
    isEmpty ? "initial" : isActive ? "green" : "#C4C4C4FF"};

  > span {
    padding: 2px 5px;
    background-color: #edeee9;
  }
`;

const CreationMiddle = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ nColumns }) => nColumns}, 330px);
  gap: 1px;

  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 769px) {
    justify-content: center;
  }
`;

const CreationFooter = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 10px;

  > button {
    white-space: nowrap;
  }

  @media (min-width: 769px) {
    > button {
      width: 180px;
    }
  }

  @media (max-width: 768px) {
    padding: 0 15px;

    > button {
      width: 100%;
    }
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    row-gap: 15px;
  }

  @media (max-width: 768px) {
    row-gap: 10px;
  }
`;

const Modal = styled(ContentModal)`
  left: 0;
  right: 0;
  margin-top: 5px;

  > div {
    ::after {
      right: 0;
      left: 15px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ModalButtonsGroup = styled(ButtonsGroup)`
  width: 100%;

  button {
    width: 100%;
    border: none;
    border-radius: 0 !important;
  }

  @media (min-width: 769px) {
    flex-direction: column;

    button {
      :not(:last-child) {
        border-bottom: 1px solid #c4c4c4;
        border-right: none;
      }
    }
  }
`;

const Input = styled.div`
  position: relative;
  font-size: 14px;
  width: 300px;
  cursor: pointer;

  > div:nth-child(1),
  input {
    padding: 10px;
    min-height: 42px;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 0;
    color: #222222;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
