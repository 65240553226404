import React from "react";

export default (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M37 18L25 30L13 18"
        stroke={props.fill}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
